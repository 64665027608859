import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Wrapper } from './styles';
import moment from 'moment';

const Timepicker = ({ disabled, onChange, placeholder, defaultValue, isError }) => {

  const handleChange = date => {
    const momentDate = moment(date);
    const formattedTime = momentDate.format('HH:mm:ss');
    onChange(formattedTime);
  };

  return (
    <Wrapper isError={isError}>
      <TimePicker
        disabled={disabled}
        onChange={handleChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        showSecond={false}
        minuteStep={30}
      />
    </Wrapper>
  );
};

export default Timepicker;
