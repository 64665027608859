import styled from 'styled-components';
import { FlexWrapper } from 'components/__styled/Layout';
import { COLORS, FONTS } from 'config/theme';

export const BottomWrapper = styled(FlexWrapper)`
  margin: 20px 0 10px 0;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
`;

export const LoaderText = styled.div`
  font-size: 12px;
  margin-left: 10px;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
`;
