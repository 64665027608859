import styled, { css } from 'styled-components';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';
import { rem } from 'polished';

export const SearchInput = styled.input`
  padding: 8px 45px;
  width: 100%;
  max-width: 500px;
  min-width: 250px;
  border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
  font-size: ${rem('12px')};
  font-family: ${FONTS.regular};
  border-radius: 6px;
  outline: none;
  min-height: 40px;
  &:active,
  &:focus {
    border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.main};;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  }
  ${({ disabled }) => disabled && css`
    background-color: rgba(0,0,0,0.05);
    opacity: 0.6;
  `};
  ${({ small }) => small && css`
    padding: 8px;
    min-height: 20px;
  `};
  @media (max-width: ${BREAKPOINTS.small}) {
   min-width: 150px; 
  }
`;

export const SearchWrapper = styled.form`
  position: relative;
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transition: fill 0.3s ease;
    fill: ${COLORS.textGray};
  }
  &:hover {
    svg {
      fill: ${COLORS.dark};
    }
  }
`;

export const SearchButton = styled(Button)`
  left: 0;
  top: 0;
`;

export const ClearButton = styled(Button)`
  right: 0;
  top: 0;
`;
