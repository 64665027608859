import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';
import { ShadowBox } from './ShadowBox';

export const SummaryBox = styled(ShadowBox)`
  margin-top: 30px;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const SummaryItem = styled.div`
  display: flex;
  align-items: flex-end;
  width: auto;
  flex: 0 0 50%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  @media (max-width: ${BREAKPOINTS.little}) {
    padding-bottom: 10px;
    flex-direction: column;
    flex: 0 0 100%;
    align-items: center;
  }
  &:nth-of-type(2n + 1) {
    border-right: 1px solid ${COLORS.lightGray};
    padding-right: 40px;
    @media (max-width: ${BREAKPOINTS.small}) {
      padding-right: 0;
      border: none;
    }
  }
  &:nth-of-type(2n) {
    padding-left: 40px;
    @media (max-width: ${BREAKPOINTS.small}) {
      padding-left: 0;
      border: none;
    }
  }
`;

export const SummaryItemText = styled.div`
  font-size: ${rem('14px')};
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
  font-size: ${rem('12px')};
  font-family: ${FONTS.medium};
  margin-right: 10px;
  padding-bottom: 5px;
  margin-left: 0;
  @media (max-width: ${BREAKPOINTS.small}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SummaryItemValue = styled.div`
  font-family: ${FONTS.bold};
  color: ${COLORS.main};
  font-size: ${rem('20px')};
  line-height: 1;
  font-variant-numeric: tabular-nums;
  padding-bottom: 5px;
`;
