import React, { useState, useEffect } from 'react';
import {
  RatesContent,
  OuterRatesContent,
  ArrowIcon,
  CarrierRatesRow,
  Name,
  NameWrapper,
  MainRow,
  AgreementLink, CarrierError, ItemError
} from './styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import FriSettlementItemSummary from "../FriSettlementItemSummary";
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  TblWrapper,
} from 'components/__styled/Table';
import {IoAlertCircle} from "react-icons/io5";

const FriSettlementContractor = ({
  settlementContractor,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };


  const style = isExpanded ? { height: 'auto' } : { height: 0 };


  return (
    <CarrierRatesRow isError={settlementContractor.errors.length}>
      <NameWrapper onClick={toggleAccordion}>
        {settlementContractor.errors.length > 0 && (
            <CarrierError>
              <IoAlertCircle
                  data-for={'mainTooltip'}
                  data-tip={settlementContractor.errors}
                  size={20}
              />
            </CarrierError>
        )}
        <Name>
          {settlementContractor.carrier.name} ({settlementContractor.summary} PLN)
        </Name>
        <ArrowIcon>
          {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </ArrowIcon>
      </NameWrapper>
      <OuterRatesContent style={style}>
        <RatesContent>
          {settlementContractor.subcategories.map((subcategory, i) => {
          return (
          <MainRow>
            <FriSettlementItemSummary
                subcategory={subcategory}
            />
                    <TblWrapper>
                      <Tbl>
                        <THead>
                          <Tr>
                            <Th>L.p</Th>
                            <Th toRight={true}>Typ</Th>
                            <Th toRight={true}>Zasób</Th>
                            <Th toRight={true}>Operacja</Th>
                            <Th toRight={true}>Stawka</Th>
                            <Th toRight={true}>Ilość</Th>
                            <Th toRight={true}>Wartość</Th>
                            <Th toRight={true}>Jednostka</Th>
                            <Th toRight={true}>Umowa</Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {subcategory.positions.map((item) => {
                            return (
                                <Tr isError={Object.keys(item.errors).length}>
                                  <Td>{item.position}</Td>
                                  <Td toRight={true}>{item.type}</Td>
                                  <Td toRight={true}>
                                    {item.errors.resource && (
                                        <ItemError>
                                          <IoAlertCircle
                                              data-for={'mainTooltip'}
                                              data-tip={item.errors.resource}
                                              size={18}
                                          />
                                        </ItemError>
                                    )}
                                    {item.resource}
                                  </Td>
                                  <Td toRight={true}>
                                    {item.errors.masterCode && (
                                        <ItemError>
                                          <IoAlertCircle
                                              data-for={'mainTooltip'}
                                              data-tip={item.errors.masterCode}
                                              size={18}
                                          />
                                        </ItemError>
                                    )}
                                    {item.masterCode}
                                  </Td>
                                  <Td toRight={true}>
                                    {item.errors.price && (
                                        <ItemError>
                                          <IoAlertCircle
                                              data-for={'mainTooltip'}
                                              data-tip={item.errors.price}
                                              size={18}
                                          />
                                        </ItemError>
                                    )}
                                    {item.price} PLN
                                  </Td>
                                  <Td toRight={true}>
                                    {item.errors.quantity && (
                                        <ItemError>
                                          <IoAlertCircle
                                              data-for={'mainTooltip'}
                                              data-tip={item.errors.quantity}
                                              size={18}
                                          />
                                        </ItemError>
                                    )}
                                    {item.quantity}
                                  </Td>
                                  <Td toRight={true}>{item.value} PLN</Td>
                                  <Td toRight={true}>{item.unit.toLowerCase()}</Td>
                                  <Td toRight={true}>
                                    {item.errors.agreement && (
                                        <ItemError>
                                          <IoAlertCircle
                                              data-for={'mainTooltip'}
                                              data-tip={item.errors.agreement}
                                              size={20}
                                          />
                                        </ItemError>
                                    )}
                                    <AgreementLink
                                      target="_blank"
                                      href={item.agreementReference}
                                  >
                                    {item.agreementName}
                                  </AgreementLink>
                                  </Td>
                                </Tr>
                            );
                          })}
                        </TBody>
                      </Tbl>
                    </TblWrapper>

          </MainRow>
          );
        })}
        </RatesContent>
      </OuterRatesContent>
    </CarrierRatesRow>
  );
}

export default FriSettlementContractor;
