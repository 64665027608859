import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Tbl,
  Td,
  Th,
  Tr,
  NoRecords,
  ActionButton
} from 'components/__styled/Table';
import Select from 'components/__common/Select';
import {
  CarrierSelectWrapper,
  ScheduleTblWrapper,
  ButtonsWrapper,
  CourseStatus,
  CourseError,
  LapSeparator,
  AbsoluteIcon,
  ScheduleBody,
  ScheduleTHead
} from './styles';
import Checkbox from 'components/__common/Checkbox';
import TasksService from 'services/TasksService';
import ModalContext from 'context/ConfirmModalContext';
import TaskContext from 'context/TaskContext';
import { VscTrash } from 'react-icons/vsc';
import { COLORS } from 'config/theme';
import { coursesStatuses } from 'config/statuses';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { IoAlertCircle } from 'react-icons/io5';
import StatusChip from 'components/Tasks/StatusChip';
import { BreakMinutesAfter, BreakMinutesAfterAdditional } from 'components/__styled/Break';
import { useParams } from 'react-router-dom';
import { BsTruck } from 'react-icons/bs';
import { MdOutlineAddCircleOutline } from 'react-icons/md';

const LogisticScheduleTable = ({ schedule, setSchedule, carriers, getTask, coursesErrors, isSchedulePlanned, readOnly = false }) => {

  const table = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [cars, setCars] = useState([]);
  const [rowCarsLoadingID, setRowCarsLoadingID] = useState(null);
  const [carrierGlobalSet, setCarrierGlobalSet] = useState(true);
  const [filterRejectedCourses, setFilterRejectedCourses] = useState(false);
  const { openModal } = useContext(ModalContext);
  const { setTaskLoading } = useContext(TaskContext);
  const { task_id } = useParams();

  useEffect(() => {
    if (table.current) {
      const offset = table.current.getBoundingClientRect();
      const win = table.current.ownerDocument.defaultView;
      setTableHeight(`${win.innerHeight - offset.top + win.pageYOffset}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    rebuildTooltip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesErrors]);

  const getCarCarriers = async (carrier_id, index, courseNumber) => {
    try {
      setCars([]);
      setRowCarsLoadingID(index);
      const res = await TasksService.getCarrierCars(carrier_id, {status: 'ACTIVE'});
      const responseCars = res.data._embedded?.item ?? [];
      let foundSelectedCarriers = schedule
        .map(i => ({ car: i._embedded?.car || null, carrier: i._embedded?.carrier || null, course: i.courseNumber }))
        .filter(i => i.car && i.carrier)
        .filter(i => i.carrier?.id === carrier_id && i.course === courseNumber);

      setRowCarsLoadingID(null);

      if (foundSelectedCarriers.length > 0) {
        const foundCarsIds = foundSelectedCarriers.map(i => i.car.id);
        const filteredCars = responseCars.filter(car => !foundCarsIds.includes(car.id));
        setCars(filteredCars);
      } else {
        setCars(responseCars);
      }
    } catch (err) {
      setRowCarsLoadingID(null);
    }
  };

  const removeCourse = id => {
    const callback = async id => {
      try {
        setTaskLoading(true);
        await TasksService.removeCourse(id);
        await getTask();
        setTaskLoading(false);
      } catch (err) {
        setTaskLoading(false);
      }
    };

    openModal(
      'Czy chcesz usunąć wybrany kurs?',
      'Pamiętaj, że operacja jest nieodwracalna.',
      () => callback(id)
    );
  };

  const addCourse = courseNumber => {
    const callback = async courseNumber => {
      try {
        setTaskLoading(true);
        await TasksService.addVehicleIntoSchedule(task_id, { after: courseNumber.toString() });
        await getTask();
        setTaskLoading(false);
      } catch (err) {
        setTaskLoading(false);
      }
    };

    openModal(
      'Czy chcesz dodać nowy pojazd na koniec kursu?',
      '',
      () => callback(courseNumber)
    );
  };

  const setCarrier = (index, carrier) => {
    const newSchedule = [...schedule];

    if (carrierGlobalSet) {
      const activeCar = newSchedule[index].carNumber;

      for (let i = index; i < newSchedule.length; i++) {
        if (newSchedule[i].carNumber === activeCar) {
          const embedded = newSchedule[i]._embedded;
          const newCar = embedded && embedded.carrier && embedded.carrier.id === carrier.value
            ? { ...embedded.car }
            : null;
          newSchedule[i] = {
            ...newSchedule[i],
            _embedded: {
              ...newSchedule[index]._embedded,
              carrier: {
                id: carrier.value,
                name: carrier.label,
              },
              car: newCar,
            }
          };
        }
      }
    } else {
      const embedded = newSchedule[index]._embedded;
      const newCar = embedded && embedded.carrier && embedded.carrier.id === carrier.value
        ? { ...embedded.car }
        : null;
      newSchedule[index] = {
        ...newSchedule[index],
        _embedded: {
          ...embedded,
          carrier: {
            id: carrier.value,
            name: carrier.label,
          },
          car: newCar,
        },
      };
    }

    setSchedule(newSchedule);
  };

  const setCar = (index, car) => {
    const newSchedule = [...schedule];
    if (carrierGlobalSet) {
      const activeCar = newSchedule[index].carNumber;

      for (let i = index; i < newSchedule.length; i++) {
        if (newSchedule[i].carNumber === activeCar) {
          newSchedule[i] = {
            ...newSchedule[i],
            _embedded: {
              ...newSchedule[index]._embedded,
              car: {
                id: car.value,
                registrationNumber: car.label,
              },
            }
          };
        }
      }
    } else {
      newSchedule[index] = {
        ...newSchedule[index],
        _embedded: {
          ...newSchedule[index]._embedded,
          car: {
            id: car.value,
            registrationNumber: car.label,
          },
        },
      };
    }
    setSchedule(newSchedule);
  };

  const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
    leading: false,
    trailing: true
  });

  const groupOptions = data => {
    return [...data.reduce((hash, car ) => {
      const current = hash.get(car.carTonnage) || { label: `${car.carTonnage} t`, options: [] };
      current.options.push({ value: car.id, label: car.registrationNumber });

      return hash.set(car.carTonnage, current);
    }, new Map()).values()];
  };

  const getPreviousBreak = index => {
    const prev = schedule ? schedule.filter((r, i) => r.breakMinutesAfter > 0 && i < index) : [];
    return prev.length > 0 ? prev[prev.length - 1] : null;
  };

  const rowRenderer = (index, schedule) => {

    const carrierOptions = carriers && carriers.map(carrier => ({
      label: carrier.name,
      value: carrier.id,
      isDisabled: !carrier.selectable,
    }));

    const carOptions = groupOptions(cars);
    const embedded = schedule[index]._embedded;
    const status = coursesStatuses[schedule[index].status];
    const weightingData = embedded.weighting;
    const courseNumber = schedule[index].courseNumber;

    const editedBy = userType => {
      switch (userType) {
        case 'CARRIER':
          return ' przewoźnika'
        case 'LOGISTIC':
          return ' logistyka'
        default:
          return '';
      }
    };

    let courseError = '';

    if (coursesErrors && coursesErrors[`carriesMap[${index}].carId`]) {
      courseError = `- ${coursesErrors[`carriesMap[${index}].carId`]}`;
    }

    if (coursesErrors && coursesErrors[`carriesMap[${index}].carrierId`]) {
      courseError = `${courseError} <br> - ${coursesErrors[`carriesMap[${index}].carrierId`]}`;
    }

    const isLastInLap = schedule && index !== schedule.length - 1 && schedule[index].courseNumber < schedule[index + 1].courseNumber;

    return (
      <React.Fragment key={index} >
        <Tr as={'div'} style={{ display: 'flex' }} isError={courseError.length}>
          {!readOnly && (
            <Td sDiv as={'div'} style={{ flex: '0 1 50px' }}>
              {courseError.length > 0 && (
                <CourseError>
                  <IoAlertCircle
                    data-for={'mainTooltip'}
                    data-tip={courseError}
                    size={20}
                  />
                </CourseError>
              )}
            </Td>
          )}
          <Td isDiv as={'div'} style={{ flex: '0 1 60px' }}>{schedule[index].courseNumber}</Td>
          <Td isDiv as={'div'} style={{ flex: '0 1 60px' }}>{schedule[index].carNumber}</Td>
          <Td isDiv isHour hideOnMobile as={'div'} style={{ flex: '1 1 100px' }}>{schedule[index].timeOfLoading}</Td>
          <Td isDiv isHour as={'div'} style={{ flex: '1 1 90px' }}>{schedule[index].timeOfBuilding}</Td>
          <Td isDiv isHour hideOnMobile as={'div'} style={{ flex: '1 1 70px' }}>{schedule[index].timeOfReturn}</Td>
          <Td isDiv isHour hideOnMobile as={'div'} style={{ flex: '1 1 80px' }}>
            <StatusChip  bgColor={null != weightingData && weightingData.weight && weightingData.documentNo ? COLORS.green : ''}>
              <div
                data-for='mainTooltip'
                data-tip={
                   weightingData &&
                   `
                <div>
                  <div>
                    <span>Waga:</span>
                    <strong>${weightingData.weight ? `${((weightingData.weight) / 1000).toFixed(1)}t` : '-'}</strong>
                  </div>
                  <div>
                    <span>Nr dokumentu:</span>
                    <strong>${weightingData.documentNo ? weightingData.documentNo : '-'}</strong>
                  </div>
                  <div>
                    <span>Nazwa receptury:</span>
                    <strong>${weightingData.recipeName ? weightingData.recipeName : '-'}</strong>
                  </div>
                </div>
              `}>
                Podejrzyj
              </div>
            </StatusChip>
          </Td>
          <Td isDiv isHour hideOnMobile as={'div'} style={{ flex: '1 1 30px' }}>
            <CourseStatus
              color={status.color}
              data-for={'mainTooltip'}
              data-tip={
                schedule[index] === 'NONE' ?
                  `<div>
                    <div>
                      <span>Status: </span>
                      <span>${status.name}</span>
                    </div>
                    <div>
                      <span>Edytowano: </span>
                      <span>${schedule[index].statusUpdatedAt}</span>
                    </div>
                    <div>
                      <span>Przez: </span>
                      <span>${editedBy(schedule[index].statusUpdatedBy)}</span>
                    </div>
                  </div>
                ` : status.name
              }
            />
          </Td>
          <Td isDiv as={'div'} style={{ flex: '1 1 150px', paddingTop: 0, paddingBottom: 0 }}>
            {readOnly ? (
              <div>
                {embedded?.carrier ? <div><strong>{embedded.carrier.name}</strong></div> : '-'}
                {embedded?.car ? <div>{embedded.car.registrationNumber}</div> : '-'}
              </div>
            ) : (
              <ButtonsWrapper>
                <CarrierSelectWrapper selected={embedded && embedded.carrier}>
                  <Select
                    options={carrierOptions}
                    onChange={value => setCarrier(index, value)}
                    value={embedded && embedded.carrier ? { value: embedded.carrier.id, label: embedded.carrier.name } : null}
                    asPortal
                  />
                </CarrierSelectWrapper>
                <CarrierSelectWrapper selected={embedded && embedded.car}>
                {embedded && embedded.carrier && (
                  <Select
                    options={carOptions}
                    onChange={value => setCar(index, value)}
                    onFocus={() => getCarCarriers(embedded.carrier.id, index, courseNumber)}
                    value={embedded.car ? { value: embedded.car.id, label: embedded.car.registrationNumber } : null}
                    isLoading={rowCarsLoadingID === index}
                    asPortal
                  />
                )}
                </CarrierSelectWrapper>
              </ButtonsWrapper>
            )}
          </Td>
          {!readOnly && (
            <Td isDiv as={'div'} style={{ flex: '1 1 40px', justifyContent: 'flex-end' }}>
              {isLastInLap && (
                <ActionButton
                  data-for={'mainTooltip'}
                  data-tip={'Dodaj nowy pojazd na koniec kursu'}
                  color={COLORS.dark}
                  onClick={() => addCourse(courseNumber)}
                >
                  <BsTruck size={24} />
                  <AbsoluteIcon>
                    <MdOutlineAddCircleOutline size={20}/>
                  </AbsoluteIcon>
                </ActionButton>
              )}
              <ActionButton
                data-for={'mainTooltip'}
                data-tip={'Usuń'}
                color={COLORS.errorRed}
                onClick={() => removeCourse(schedule[index].id)}
              >
                <VscTrash size={24} />
              </ActionButton>
            </Td>
          )}
        </Tr>
        {isLastInLap && (
          <Tr isDiv as={'div'} style={{ display: 'flex' }}>
            <LapSeparator />
          </Tr>
        )}
        {schedule[index].breakMinutesAfter ? (
          <Tr as={'div'} style={{ display: 'flex' }}>
            <Td isDiv as={'div'} style={{justifyContent: 'center', flex: '1 1 100%', textAlign: 'center', backgroundColor: COLORS.lightMain, padding: '5px 10px', height: '50px', width: '100%'}}>
              <BreakMinutesAfter>
                {
                  getPreviousBreak(index)
                    ? `po ${schedule[index].deliveryScheduleUsedTonnage - getPreviousBreak(index).deliveryScheduleUsedTonnage} t (łącznie ${schedule[index].deliveryScheduleUsedTonnage} t) `
                    : `po ${schedule[index].deliveryScheduleUsedTonnage} t `}
                + {schedule[index].breakMinutesAfter} min przerwy
                <BreakMinutesAfterAdditional>
                  pomiędzy końcem wbudowania a początkiem następnego wbudowania
                </BreakMinutesAfterAdditional>
              </BreakMinutesAfter>
            </Td>
          </Tr>
        ) : null}
      </React.Fragment>
    );
  };

  const finalSchedule = !filterRejectedCourses ? schedule : schedule.filter(el => el.status === 'REJECTED');

  return (
    <ScheduleTblWrapper tableHeight={`${tableHeight - 180}px`} ref={table} as={'div'}>
      <Tbl as={'div'}>
        <ScheduleTHead as={'div'}>
          <Tr as={'div'} style={{ display: 'flex' }}>
            {!readOnly && (
              <Th isDiv as={'div'} style={{ flex: '0 1 50px' }}>
                <IoAlertCircle
                  size={20}
                />
              </Th>
            )}
            <Th isDiv as={'div'} style={{ flex: '0 1 60px' }}>Kurs</Th>
            <Th isDiv as={'div'} style={{ flex: '0 1 60px' }}>Auto</Th>
            <Th isDiv as={'div'} hideOnMobile style={{ flex: '1 1 100px' }}>Tarowanie</Th>
            <Th isDiv as={'div'} style={{ flex: '1 1 90px' }}>Budowanie</Th>
            <Th isDiv as={'div'} hideOnMobile style={{ flex: '1 1 70px' }}>Powrót</Th>
            <Th isDiv as={'div'} hideOnMobile style={{ flex: '1 1 80px' }}>Dane z ważenia</Th>
            <Th isDiv as={'div'} hideOnMobile style={{ flex: '1 1 30px' }}>
              Status
              <Checkbox
                onChange={() => setFilterRejectedCourses(!filterRejectedCourses)}
                label={'Tylko odrzucone'}
                value={filterRejectedCourses}
              />
            </Th>
            <Th isDiv as={'div'} style={{ flex: '1 1 150px', display: 'block' }}>
              Przewoźnik / auto
              {!readOnly && (
                <Checkbox
                  onChange={() => setCarrierGlobalSet(!carrierGlobalSet)}
                  label={'Ustaw dla kolejnych kursów'}
                  value={carrierGlobalSet}
                />
              )}
            </Th>
            {!readOnly && <Th isDiv as={'div'} style={{ flex: '1 1 40px', display: 'block' }}>Akcje</Th>}
          </Tr>
        </ScheduleTHead>
        <ScheduleBody as={'div'}>
          <ReactTooltip id="mainTooltip" className="tooltip" effect="solid" html />
          {finalSchedule && finalSchedule.length > 0 ? (
            <>
              {finalSchedule.map((row, i) => {
                return rowRenderer(i, finalSchedule);
              })}
            </>
          ) : (
            <Tr as={'div'} style={{ display: 'flex' }}>
              <Td isDiv as={'div'} style={{ flex: 1 }} colSpan="7">
                <NoRecords>Brak rekordów...</NoRecords>
              </Td>
            </Tr>
          )}
        </ScheduleBody>
      </Tbl>
    </ScheduleTblWrapper>
  );
};

export default LogisticScheduleTable;
