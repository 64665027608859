import React from 'react';
import { StatusItem, StatusesRow, StatusesWrapper, CarrierConfirmStatus } from './styles';
import { scheduleStatusColors, scheduleStageNames } from 'config/statuses';

const DetailedStatuses = ({ statuses }) => {
  return (
    <StatusesWrapper>
      <StatusesRow>
        {statuses && statuses.map((status, i) => {
          const colors = scheduleStatusColors[status.stage];
          return (
            <React.Fragment key={i}>
              <StatusItem
                bgColor={colors && colors.bgColor}
                textColor={colors && colors.textColor}
              >
                {scheduleStageNames[status.levelName]}
                {status.stageData && status.stageData.accepted !== undefined && (
                  <CarrierConfirmStatus>
                    <span>{status.stageData.accepted}/</span>
                    <span>{status.stageData.rejected}/</span>
                    <span>{status.stageData.waiting} </span>
                  </CarrierConfirmStatus>
                )}
              </StatusItem>
            </React.Fragment>
          )
        })}
      </StatusesRow>
    </StatusesWrapper>
  );
};

export default DetailedStatuses;
