import styled,{keyframes} from 'styled-components';
import { BREAKPOINTS, COLORS } from 'config/theme';


const pulse = keyframes`{
    0% { -webkit-transform: scale(0);opacity: 0.0;}
    50% { -webkit-transform: scale(0.5); opacity: 1}
    100% {-webkit-transform: scale(2.5);opacity: 0.2;}
}`;

export const NavWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  width: 250px;
  border-right: 1px solid ${COLORS.lightGray};
  background-color: ${COLORS.light};
  transition: left .2s ease;
  height: 100%;
  top: 65px;
  left: ${({ navOpen }) => navOpen ? '0' : '-250px'};
  @media (max-width: ${BREAKPOINTS.large}) {
    top: 0;
    width: 240px;
    }
`;

export const NavHeader = styled.div`
  margin-bottom: 20px;
  background-color: ${COLORS.white};
  width: 100%;
  padding: 20px 35px;
  border-bottom: 1px solid ${COLORS.lightGray};
  height: 65px;
  display: none;
  @media (max-width: ${BREAKPOINTS.large}) {
    display: block;
    padding: 0 0 0 15px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NavList = styled.ul`
  margin: 0;
`;

export const NavContent = styled.nav`
  padding: 25px;
  @media (max-width: ${BREAKPOINTS.large}) {
    padding: 10px;
  }
`;

export const Logo = styled.img`
  max-width: 100px;
  display: block;
  width: 100%;
  margin-bottom: 3px;
`;

export const Vignette = styled.div`
  opacity: ${({ navOpen }) => navOpen ? 1 : 0};
  visibility: ${({ navOpen }) => navOpen ? 'visible' : 'hidden'};
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  content: '';
  display: none;
  background-color: rgba(0,0,0,0.7);
  transition: opacity .2s ease, visibility .2s ease;
  @media (max-width: ${BREAKPOINTS.large}) {
    display: block;
  }
`;

export const NotificationDotNumber = styled.div`
  width: 16px;
  color: white;
  font-size: 10px;
  height: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  justify-content: center;
  border-radius: 50%;
  background: ${COLORS.main};
`;

export const NotificationDot = styled.div`
  background-color: ${COLORS.main};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 8px;
  &:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
    background-color: ${COLORS.main};
    border-radius: 50%;
    animation: ${pulse} 1s ease-out infinite;
  }
  > {
    font-size: 10px;
  }
`;

export const RelativeBox = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  outline: none;
  width: 55px;
  height: 54px;
  padding: 3px 0 0 0;
  border: none;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${COLORS.dark};
  cursor: pointer;
  &:hover {
    svg {
      fill: ${COLORS.main};
    }
  }
  @media (max-width: ${BREAKPOINTS.large}) {
    display: flex;
  }
`;

