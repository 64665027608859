import React, { useState } from 'react';
import {
  ModalButtonsWrapper,
  ModalContent,
  ModalDescription, ModalFormWrapper,
  ModalHeader,
  ModalWrapper,
} from './styles';
import Button from 'components/__common/Button';
import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import {FormElement, FormError, Label} from "../../__styled/Form";
import Input from "../../__common/Input";

const UpdateDistanceScheduleModal = ({
  isVisible,
  closeModal,
  modalContent,
  updateProcessing,
  formErrors,
  setFormErrors,
  form,
  setForm
}) => {

  const handleFormChange = (name, value) => {
    let errors = { ...formErrors };
    delete errors[name];
    setFormErrors(errors);
    setForm( {...form, [name]: value });
  };

  const component = (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <ModalWrapper
          key={'reject-modal'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent>
            <ModalHeader>{modalContent && modalContent.header}</ModalHeader>
            <ModalDescription>{modalContent && modalContent.description}</ModalDescription>
            <ModalFormWrapper>
              <FormElement>
                <Label>Deklarowana odegłość</Label>
                <Input
                    isError={formErrors && formErrors.distance}
                    name="distance"
                    onChange={e => handleFormChange('distance', Number(e.target.value))}
                    value={form.distance}
                    type="number"
                />
                {formErrors && formErrors.distance && <FormError>{formErrors.distance}</FormError>}
              </FormElement>
            </ModalFormWrapper>
            <ModalButtonsWrapper>
              <Button
                isLoading={updateProcessing}
                disabled={updateProcessing}
                onClick={() => modalContent.callback(form)}
              >
                Tak
              </Button>
              <Button
                disabled={updateProcessing}
                secondary
                onClick={closeModal}
              >
                Anuluj
              </Button>
            </ModalButtonsWrapper>
          </ModalContent>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );

  return createPortal(component, document.body);
};

export default UpdateDistanceScheduleModal;
