import React from 'react';
import {
  NewRangeButtonWrapper,
  RangeElem,
  RangeRow,
  RangesContent,
  RemoveButton,
} from './styles';
import Input from 'components/__common/Input';
import { FormError } from 'components/__styled/Form';
import { VscTrash } from 'react-icons/vsc';
import Button from 'components/__common/Button';
import { GoPlus } from 'react-icons/go';

const RangesForm = ({ ranges, setRanges, formErrors, setFormErrors }) => {
  const removeElem = index => {
    const filteredRanges = ranges.filter((r, i) => i !== index);
    setRanges(filteredRanges);
  };

  const addRange = () => {
    const newRanges = [...ranges, { from: null, to: null }];
    setRanges(newRanges);
  };

  const handleRangesChange = (index, field, value) => {
    let errors = { ...formErrors };
    delete errors[`ranges[${index}]`];
    setFormErrors(errors);
    let newRanges = [...ranges];
    newRanges[index] = {
      ...newRanges[index],
      [field]: Number(value),
    };

    setRanges(newRanges);
  }

  return (
    <>
      <RangesContent>
        {ranges.map((range, i) => {
          return (
            <RangeRow key={i}>
              <RangeElem>
                <Input
                  type='number'
                  min={1}
                  placeholder={'Wartość w km od'}
                  onChange={e => handleRangesChange(i, 'from', e.target.value)}
                  value={range.from}
                  isError={formErrors && formErrors[`ranges[${i}]`]}
                />
                {formErrors && formErrors[`ranges[${i}]`] && <FormError>{formErrors[`ranges[${i}]`] }</FormError>}
              </RangeElem>
              <RangeElem>
                <Input
                  type='number'
                  min={1}
                  placeholder={'Wartość w km do'}
                  onChange={e => handleRangesChange(i, 'to', e.target.value)}
                  value={range.to}
                  isError={formErrors && formErrors[`ranges[${i}]`]}
                />
                {formErrors && formErrors[`ranges[${i}]`]  && <FormError>{formErrors[`ranges[${i}]`] }</FormError>}
              </RangeElem>
              {/*{range.rate && (*/}
              {/*  <RangeElem>*/}
              {/*    <Input*/}
              {/*      type='number'*/}
              {/*      min={1}*/}
              {/*      placeholder={'Stawka'}*/}
              {/*      onChange={e => handleRangesChange(i, 'rate', e.target.value)}*/}
              {/*      value={range.rate}*/}
              {/*      isError={formErrors && formErrors[`ranges[${i}]`]}*/}
              {/*    />*/}
              {/*    {formErrors && formErrors[`ranges[${i}]`]  && <FormError>{formErrors[`ranges[${i}]`] }</FormError>}*/}
              {/*  </RangeElem>*/}
              {/*)}*/}
              {/*{range.type && (*/}
              {/*  <RangeElem>*/}
              {/*    <Select*/}
              {/*      isError={formErrors && formErrors[`ranges[${i}]`]}*/}
              {/*      name="type"*/}
              {/*      options={[]}*/}
              {/*      onChange={option => handleRangesChange(i, 'type', option.value)}*/}
              {/*    />*/}
              {/*    {formErrors && formErrors[`ranges[${i}]`]  && <FormError>{formErrors[`ranges[${i}]`] }</FormError>}*/}
              {/*  </RangeElem>*/}
              {/*)}*/}
              <RemoveButton onClick={() => removeElem(i)}>
                <VscTrash size={20}/>
              </RemoveButton>
            </RangeRow>
          );
        })}
      </RangesContent>
      <NewRangeButtonWrapper>
        <Button
          leftIcon={<GoPlus size={16}/>}
          zoom
          onClick={() => addRange()}
        >
          Nowy przedział
        </Button>
      </NewRangeButtonWrapper>
    </>
  );
}

export default RangesForm;
