import { useState } from 'react';

let confirmCallback = null;
let cancelCallback = null;

const useConfirm = () => {
  let [isVisible, setModalVisible] = useState(false);
  let [modalContent, setModalContent] = useState({
    header: '',
    description: '',
  });

  const openModal = (header, description, confCallback, canclCallback = null) => {
    setModalVisible(true);
    if (header) {
      confirmCallback = confCallback;
      cancelCallback = canclCallback;
      setModalContent({
        header,
        description,
      });
    }
  };

  const onConfirm = param => {
    setModalVisible(false);
    confirmCallback && confirmCallback(param);
  };

  const onCancel = param => {
    setModalVisible(false);
    cancelCallback && cancelCallback(param);
  };

  return { isVisible, onCancel, openModal, onConfirm, modalContent };
}

export default useConfirm;
