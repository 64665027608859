import React from 'react';
import { Wrapper, Item, InfoIcon, Desc, ItemLabel, ItemValue, SummaryContent } from './styles';
import { BsTruck } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { GiWeight } from 'react-icons/gi';
import { FaTruckLoading } from 'react-icons/fa';
import { formatMinutes } from 'utils/global';

const LiveSummary = ({ summary }) => {
  return (
    <Wrapper>
      <SummaryContent>
        <Item>
          <InfoIcon>
            <GiWeight size={24}/>
          </InfoIcon>
          <Desc>
            <ItemLabel>Tony do wyprodukowania</ItemLabel>
            <ItemValue>{summary ? `${summary.remainTons} t` : '-'}</ItemValue>
          </Desc>
        </Item>
        <Item>
          <InfoIcon>
            <BsTruck size={28}/>
          </InfoIcon>
          <Desc>
            <ItemLabel>Ton w drodze</ItemLabel>
            <ItemValue>{summary ? `${summary.inWayTons} t` : '-'}</ItemValue>
          </Desc>
        </Item>
        <Item>
          <InfoIcon>
            <FaTruckLoading size={22}/>
          </InfoIcon>
          <Desc>
            <ItemLabel>Rozładowanych ton</ItemLabel>
            <ItemValue>{summary ? `${summary.unloadedTons} t` : '-'}</ItemValue>
          </Desc>
        </Item>
        <Item>
          <InfoIcon>
            <BiTimeFive size={28}/>
          </InfoIcon>
          <Desc>
            <ItemLabel>Średni czas kursu</ItemLabel>
            <ItemValue>{summary && summary.avgCourseTime ? formatMinutes(summary.avgCourseTime) : '---'}</ItemValue>
          </Desc>
        </Item>
      </SummaryContent>
    </Wrapper>
  );
};

export default LiveSummary;
