import React from 'react';
import ReactTooltip from 'react-tooltip';
import { RejectDescriptionContent } from './styles';
import { TooltipInfo, TooltipInfoBold } from 'components/__styled/Tolltip';
import { RiQuestionFill } from 'react-icons/ri';

const RejectDescription = ({ place = 'top', rejectText, status }) => {
  return (
    <>
      <ReactTooltip place={place} id="rejectInfo" className="tooltip" effect="solid">
        <TooltipInfo>Powód odrzucenia:</TooltipInfo>
        <TooltipInfoBold>{rejectText}</TooltipInfoBold>
      </ReactTooltip>
      {['FACTORY_REJECTED', 'CARRIER_REJECTED_PARTIAL', 'REJECTED'].includes(status) && (
        <>
          <RejectDescriptionContent data-tip data-for="rejectInfo">
            <RiQuestionFill />
          </RejectDescriptionContent>
        </>
      )}
    </>
  )
};

export default RejectDescription;
