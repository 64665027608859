import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper, Content, Header, Description, Icon } from './styles';
import { RiAlarmWarningLine } from 'react-icons/ri';
import history from 'config/history';

const ClientError = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      history.push('/client/not-found')
    }
  }, [location.state]);

  return (
    <Wrapper>
      <Content>
        <Icon>
          <RiAlarmWarningLine size={36}/>
        </Icon>
        <Header>{location.state && location.state.header}</Header>
        <Description>{location.state && location.state.description}</Description>
      </Content>
    </Wrapper>
  );
};

export default ClientError;
