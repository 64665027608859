import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const Wrapper = styled.div`
  padding: 40px 15px 15px;
  width: 50%;
  @media (max-width: ${BREAKPOINTS.large}) {
    width: 100%;
  }
`;
