import React, { useContext, useEffect, useState } from 'react';
import { Subtitle } from 'components/__styled/Subtitle';
import Header from 'components/__common/Header';
import TasksService from 'services/TasksService';
import ContractsService from 'services/ContractsService';
import { FaRegEdit, FaRegCopy } from 'react-icons/fa';
import { VscTrash } from 'react-icons/vsc';
import { RiSearchEyeLine } from 'react-icons/ri';
import history from 'config/history';
import { COLORS } from 'config/theme';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { IoMdAdd } from 'react-icons/io';
import { TiCancel } from 'react-icons/ti';
import { BiTask, BiDownload } from 'react-icons/bi';
import { MdManageSearch } from 'react-icons/md';
import Pagination from 'components/Pagination';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import SelectFilter from 'components/SelectFilter';
import DateFilter from 'components/DateFilter';
import moment from 'moment';
import ConfirmModalContext from 'context/ConfirmModalContext';
import TasksTable from 'components/Tasks/TasksTable';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';
import { scheduleStatuses, checkIsNewSchedule } from 'config/statuses';
import { Spinner } from 'components/__styled/Spinner';
import { displayToast } from 'utils/toast';
import { toast } from 'react-toastify';
import AcceptOrRejectScheduleModal from 'components/Tasks/AcceptOrRejectScheduleModal';
import UserContext from 'context/UserContext';
import { ROLES } from 'config/roles'
import { BiRuler } from "react-icons/bi";
import UpdateDistanceScheduleModal from "components/Tasks/UpdateDistanceScheduleModal";
import {returnValueAsNumber} from "../../../utils/global";

const TasksList = () => {
  const { hasRoleAccess, hasManageAccess } = useContext(UserContext);
  const { openModal } = useContext(ConfirmModalContext);
  const [contracts, setContracts] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDistanceVisible, setModalDistanceVisible] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [form, setForm] = useState(null);
  const [modalContent, setModalContent] = useState({
    header: '',
    description: '',
    onSubmit: undefined,
    accept: true,
  });
  const [modalDistanceContent, setModalDistanceContent] = useState({
    header: '',
    description: '',
    onSubmit: undefined,
    distance: '',
  });
  const [filters, setFilters] = useState({
    contract: null,
    date: null,
  });
  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
    page: 1,
  });
  const [scheduleReportDownloadingId, setScheduleReportDownloadingId] = useState(null);

  const goToTaskEdit = id => {
    history.push(`/panel/tasks/${id}`);
  };

  const goToCarriersPlanning = id => {
    history.push(`/panel/tasks/${id}/planner`);
  };

  const goToScheduleDataPreview = id => {
    history.push(`/panel/tasks/${id}/preview`);
  };

  const goToScheduleCoursesPreview = id => {
    history.push(`/panel/tasks/${id}/preview-schedule`);
  };

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      visible: row.status !== scheduleStatuses.CANCELLED && !row.viewer && row.editable && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => goToTaskEdit(id),
    }),
    row => ({
      label: row.distance ? 'Aktualizuj dystans (' + row.distance + ' km)' : 'Ustaw dystans',
      icon: <BiRuler size={20} />,
      visible: row.status !== scheduleStatuses.CANCELLED && !row.viewer && (
          hasRoleAccess(ROLES.ROLE_ADMIN) ||
          hasRoleAccess(ROLES.ROLE_LOGISTIC) ||
          hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => updateDistance({id: id, distance: row.distance}),
      initColor: row.distance ? COLORS.green : null,
    }),
    row => ({
      label: 'Usuń',
      icon: <VscTrash size={22} />,
      visible: checkIsNewSchedule(row._embedded?.stages) && !row.viewer && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => openModal('Czy chcesz usunąć rekord?', 'Pamiętaj, że operacja jest nieodwracalna.', () => removeTask(id)),
      color: COLORS.errorRed2,
    }),
    row => ({
      label: 'Anuluj',
      icon: <TiCancel size={30} />,
      visible: !checkIsNewSchedule(row._embedded?.stages) && !row.viewer && row.editable && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_LOGISTIC) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => cancelTask(id),
      color: COLORS.errorRed2,
    }),
    row => ({
      label: 'Podgląd danych',
      icon: <MdManageSearch size={22} />,
      visible: row.status !== scheduleStatuses.CANCELLED && !row.viewer && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_LOGISTIC) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => goToScheduleDataPreview(id),
    }),
    row => ({
      label: 'Podgląd kursów',
      icon: <RiSearchEyeLine size={22} />,
      visible: row.status !== scheduleStatuses.CANCELLED,
      event: id => goToScheduleCoursesPreview(id),
    }),
    row => ({
      label: 'Zaplanuj',
      icon: <BiTask size={22} />,
      visible: row.status !== scheduleStatuses.CANCELLED && row.editable && !row.viewer && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_LOGISTIC)
      ),
      event: id => goToCarriersPlanning(id),
    }),
    row => ({
      label: 'Skopiuj',
      icon: <FaRegCopy size={20} />,
      visible: row.status !== scheduleStatuses.CANCELLED && !row.viewer && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => copySchedule(id),
    }),
    row => ({
      label: 'Pobierz raport',
      icon: scheduleReportDownloadingId && scheduleReportDownloadingId === row.id ? <Spinner color={COLORS.gray} size={20} width={2}/> : <BiDownload size={25} />,
      visible: row.status !== scheduleStatuses.NEW && !row.viewer && (
        hasRoleAccess(ROLES.ROLE_ADMIN) ||
        hasRoleAccess(ROLES.ROLE_MANAGER)
      ),
      event: id => scheduleReportDownloadingId ? null : downloadScheduleReport(id)
    })
  ];

  const goToCreateTask = () => {
    history.push('/panel/tasks/create');
  };

  useEffect(() => {
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = getTasks(pagination); //subscribe
    return unsubscribe; //unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const copySchedule = async id => {
    const callback = async () => {
      try {
        setLoading(true);
        const res = await TasksService.copySchedule(id);
        setLoading(false);
        setModalVisible(false);
        goToTaskEdit(res.data.id);
      } catch (err) {
        setLoading(false);
        setModalVisible(false);
        console.log(err);
      }
    };

    setupModal({
      header: 'Czy na pewno chcesz skopiować wybrany harmonogram?',
      description: 'Operacja jest nieodwracalna.',
      callback: id => callback(id)
    });
  };

  const downloadScheduleReport = async id => {
    try {
      setScheduleReportDownloadingId(id);
      const res = await TasksService.downloadScheduleReport(id);
      const fileName = res.headers['content-disposition'].split('filename=')[1];
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      setScheduleReportDownloadingId(null);
      link.click();
    } catch (err) {
      toast.dismiss();
      displayToast('error', 'Nie można wygenerować raportu!');
      setScheduleReportDownloadingId(null);
    }
  }

  const updateDistance = async data => {
    const callback = async (form = null) => {
      try {
        setLoading(true);
        const res = await TasksService.saveDistance(data.id, { distance: form.distance});
        setLoading(false);
        setModalDistanceVisible(false);
        await getTasks(pagination);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setModalDistanceVisible(false);
        toast.dismiss();
        displayToast('error', 'Wystąpił błąd podczas aktualizacji dysntansu');
      }
    }

    setForm({distance: data.distance});

    setupModalDistance({
      header: 'Ustaw długość kursu',
      description: 'Po ustawieniu wartości większej niż zero raporty i rozliczenia będą uwzględniały tą długość. Dla wartości równej zero długość kursu wyliczana jest na podstawie danych GPS',
      distance: data.distance,
      callback: distance => callback(distance),
      withRejectReason: true,
    });
  }

  const removeTask = async id => {
    setLoading(true);
    await TasksService.removeTask(id);
    getTasks(pagination);
  };

  const getTasks = async pagination => {
    try {
      setLoading(true);
      const res = await TasksService.getTasks({
        page: pagination.page,
        itemsPerPage: pagination.perPage,
        date: filters.date ? moment(filters.date).format('YYYY-MM-DD') : null,
        contractId: filters.contract ? filters.contract.value : null
      });
      setTasks(res.data._embedded ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems, page: pagination.page });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getContracts = async () => {
    const res = await ContractsService.getContracts();
    const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id })) : [];
    setContracts(options);
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getTasks({ ...pagination, page });
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const setupModal = content => {
    setModalContent(content);
    setModalVisible(true);
  };

  const setupModalDistance = content => {
    setModalDistanceContent(content);
    setModalDistanceVisible(true);
  };

  const cancelTask = async id => {
    const callback = async rejectMessage => {
      try {
        setDataProcessing(true);
        await TasksService.cancelTask(id, rejectMessage);
        setDataProcessing(false);
        setModalVisible(false);
        await getTasks(pagination);
      } catch (err) {
        setDataProcessing(true);
        setModalVisible(false);
      }
    };

    setupModal({
      header: 'Czy na pewno chcesz anulować wybrany harmonogram?',
      description: 'Operacja jest nieodwracalna.',
      callback: rejectMessage => callback(rejectMessage),
      withRejectReason: true,
    });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {loading
        ? <PageLoader />
        : (
          <Fade>
            <AcceptOrRejectScheduleModal
              isVisible={modalVisible}
              closeModal={() => setModalVisible(false)}
              rejectProcessing={dataProcessing}
              modalContent={modalContent}
              rejectInputPlaceholder={'Podaj powód anulowania...'}
            />
            <UpdateDistanceScheduleModal
                isVisible={modalDistanceVisible}
                closeModal={() => setModalDistanceVisible(false)}
                updateProcessing={dataProcessing}
                modalContent={modalDistanceContent}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                form={form}
                setForm={setForm}
            />
            <FlexWrapper justify={'space-between'}>
              <div>
                <Header size={23}>Harmonogramy dostaw</Header>
                <Subtitle>Lista utworzonych harmonogramów dostaw dla konkretnego kontraktu.</Subtitle>
              </div>
              {hasManageAccess() && <Button zoom onClick={goToCreateTask} leftIcon={<IoMdAdd />}>Nowy harmonogram</Button>}
            </FlexWrapper>
            <TableBox>
              <FilterWrapper>
                <div />
                <FilterContent>
                  <SelectFilter label={'Kontrakt'} value={filters.contract} onChange={option => handleFilterChange('contract', option)} options={contracts} />
                  <DateFilter value={filters.date} onChange={date => handleFilterChange('date', date)} />
                </FilterContent>
              </FilterWrapper>
              <TasksTable
                tasks={tasks}
                pagination={pagination}
                actions={actions}
              />
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                perPage={pagination.perPage}
                page={pagination.page}
              />
            </TableBox>
          </Fade>
        )
      }
    </AnimatePresence>
  );
}

export default TasksList;
