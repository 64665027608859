import React, { useContext, useState, useEffect } from 'react';
import { ChangeCarsAmountWrapper, AmountLabel, AmountAcceptButton, Wrapper } from './styles';
import { FormError } from 'components/__styled/Form'
import Input from 'components/__common/Input';
import { BsCheck2 } from 'react-icons/bs';
import TaskContext from 'context/TaskContext';
import ConfirmModalContext from 'context/ConfirmModalContext';

const CarsAmount = ({ saveCarsAmount, saveError, setSaveError, isSchedulePlanned }) => {
  const { currentTask } = useContext(TaskContext);
  const { openModal } = React.useContext(ConfirmModalContext);
  const [amount, setAmount] = useState('');

  useEffect(() => currentTask && currentTask.availableCars && setAmount(currentTask.availableCars), [currentTask]);

  const prepareToSave = () => {
    openModal(
      'Czy chcesz zmienić ilość pojazdów?',
      'Pamiętaj, że spowoduje to przeliczenie całego harmonogramu.',
      () => saveCarsAmount(amount),
    );
  };

  return (
    <Wrapper>
      <ChangeCarsAmountWrapper>
        <>
          <AmountLabel>Dostępna liczba samochodów:</AmountLabel>
          <Input
            name="cars-amount"
            min={1}
            type="number"
            small
            placeholder="np. 5"
            value={amount}
            disabled={isSchedulePlanned}
            onChange={e => {
              setSaveError(null);
              setAmount(Number(e.target.value));
            }}
            isError={saveError && saveError.length}
          />
        </>
        {!isSchedulePlanned && (
          <AmountAcceptButton
            onClick={prepareToSave}
            data-testid="cars-amount-accept"
          >
            <BsCheck2 size={16}/>
          </AmountAcceptButton>
        )}
      </ChangeCarsAmountWrapper>
      {saveError && <FormError data-test-id="form-error">{saveError}</FormError>}
    </Wrapper>
  )
};

export default CarsAmount;
