import React, { useState } from 'react';

const TaskContext =  React.createContext();
const { Provider } = TaskContext;

const TaskProvider = ({ children }) => {
  const [currentTask, setCurrentTask] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);

  return (
    <Provider value={{ currentTask, setCurrentTask, taskLoading, setTaskLoading }}>
      {children}
    </Provider>
  );
};

export { TaskContext, TaskProvider };

export default TaskContext;
