export const prepareErrors = errorsArray => {
  let formErrors = {};
  if (errorsArray) {
    errorsArray.forEach(err => {
      formErrors = {
        ...formErrors,
        [err.propertyPath]: err.message,
      };
    });
  }

  return formErrors;
};

export const setSelectOption = (list, value) => {
  let option = null;

  if (!list.length) {
    return null;
  }

  list.forEach(el => {
    if (el.value === value) {
      option = el;
    }
  });

  return option;
};

export const setSelectMultiOptions = values => {
  if (!values.length) {
    return [];
  }
  return values.map(el => ({ label: el.label, value: el.value }));
};
