import {useEffect, useState} from "react";
import {AnimatePresence} from "framer-motion";
import PageLoader from "../../../components/PageLoader";
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import ContractsService from "../../../services/ContractsService";
import FriSettlementForm from "../../../components/FriSettlement/FriSettlementForm";
import {displayToast} from "../../../utils/toast";
import FriSettlementService from "../../../services/FriSettlementService";
import history from "../../../config/history";
import {prepareErrors} from "../../../utils/form";

const FriSettlementCreate = () => {
    const [contracts, setContracts] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const [friGenerating, setFriGenerating] = useState(false);

    const getContracts = async () => {
        try {
            const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
            const options = res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id }));
            setContracts(options);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToFriSettlementEdit = id => {
        history.push(`/panel/fri-settlements/${id}`);
    };

    const generateFri = async (form) => {
        console.log(form);
        try {
            setFriGenerating(true);
            const res = await FriSettlementService.createFriSettlement(form);
            setFriGenerating(false);
            goToFriSettlementEdit(res.data.id);
        } catch (err) {
            setFriGenerating(false);
            if (err.response && err.response.status === 422) {
                displayToast('error', 'Formularz zawiera błędy!');
                setFormErrors(prepareErrors(err.response.data.violations));
            }
        }
    };

    return (
        <AnimatePresence>
            {friGenerating ? (
                <PageLoader />
            ) : (
                <Fade>
                    <Header size={23}>
                        Generowanie rozliczenia FRI
                    </Header>
                    <Subtitle>Wybierz zakres dat i kontrakt</Subtitle>
                    <FriSettlementForm
                        contracts={contracts}
                        onSubmit={generateFri}
                        reportGenerating={friGenerating}
                    />
                </Fade>
            )}
        </AnimatePresence>
    );
};

export default FriSettlementCreate;