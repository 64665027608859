import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper, IconWrapper, DatePickerLabel, ClearDateButton } from './styles';
import { IoCalendarNumberOutline } from 'react-icons/io5';
import pl from 'date-fns/locale/pl';
import {VscClose} from 'react-icons/vsc';

registerLocale('pl', pl)

const DateFilter = ({ onChange, value }) => {

  return (
    <DatePickerWrapper>
      <DatePickerLabel>Data:</DatePickerLabel>
      <ReactDatePicker
        selected={value}
        dateFormat={'dd-MM-yyyy'}
        placeholderText={'Wybierz...'}
        onChange={date => onChange(date)}
        locale='pl'
      />
      <IconWrapper>
        <IoCalendarNumberOutline />
      </IconWrapper>
      {value && (
        <ClearDateButton onClick={() => onChange()}>
          <VscClose size={22}/>
        </ClearDateButton>
      )}
    </DatePickerWrapper>
  );
};

export default React.memo(DateFilter);
