import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const StatusesWrapper = styled.div`
  max-width: 100%;
  margin-right: -20px;
  margin-top: 10px;
`;

export const StatusesRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 15px;
`;

export const StatusItem = styled.div`
  padding: 8px 14px 8px 25px;
  font-size: 11px;
  font-family: ${FONTS.semiBold};
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray2};
  color: ${({ textColor }) => textColor ? textColor : COLORS.dark};
  min-width: 100px;
  text-align: center;
  height: 30px;
  position: relative;
  left: -15px;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid ${({ bgColor }) => bgColor ? bgColor : COLORS.white};
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -2px;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid ${COLORS.white};
  }
  &:first-of-type {
    padding-left: 16px;
    padding-right: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-of-type {
    padding-right: 20px;
    padding-left: 28px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    &::after,
    &::before {
      display: none;
    }
  }
`;

export const CarrierConfirmStatus = styled.span`
  margin-left: 5px;
  font-size: 10px;
  font-variant-numeric: tabular-nums;
`;
