import { useEffect, useState } from "react";
import MapModal from "./MapModal";
import { ModalBackground, ModalWindow, DestinationValue } from "./styles";
import { GrMapLocation } from "react-icons/gr";

const DestinationComponent= (props) =>  {
  const [coords, setCoords] = useState(null);
  const [destCoords, setDestCoords] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const position = props.position;
  const currentPosition = props.currentPosition;
  const geoZone = props.geoZone;
  const label = props.label ? props.label: 'Wskaż punkt na mapie';


  useEffect(() => {
    //navigator.geolocation.getCurrentPosition(success);
    //setCoords([54.392, 18.36]);
    if (geoZone?.points.length > 0) {
      setCoords([geoZone?.points[0].latitude, geoZone?.points[0].longitude]);
    } else {
      setCoords([54.392115082912, 18.365601013797]);
    }
    if (currentPosition) {
      setDestCoords(currentPosition);
    }
  }, []);


  position(destCoords);
  return (
    <>
      {coords && isOpen ? (
        <ModalBackground>
          <ModalWindow>
            <MapModal
              close={() => setIsOpen(false)}
              action={setDestCoords}
              coords={coords}
              apikey={process.env.REACT_APP_HERE_API_KEY}
            />
          </ModalWindow>
        </ModalBackground>
      ) : (
        <GrMapLocation data-tip={label} style={{ cursor: "pointer" }} size={24} onClick={() => setIsOpen(true)}>
          Ustaw pozycję
        </GrMapLocation>
      )}
      <DestinationValue>{destCoords ? `(${destCoords})` : ""}</DestinationValue>
    </>
  );
}

export default DestinationComponent;
