import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ShadowBox } from 'components/__styled/ShadowBox';
import { FONTS, COLORS } from 'config/theme';
import { rem } from 'polished';

export const ModalWrapper = styled(motion.div)`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const CloseModalButton = styled.button`
  position: absolute;
  border-radius: 50%;
  right: 15px;
  top: 15px;
  width: 35px;
  height: 35px;
  background-color: ${COLORS.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color .3s ease;
  svg {
    stroke: ${COLORS.gray};
    transition: stroke .3s ease;
  }
  &:hover {
    background-color: ${COLORS.textGray};
    svg {
      stroke: ${COLORS.dark};
    }
  }
`;

export const ModalContent = styled(ShadowBox)`
  position: relative;
  text-align: left;
  max-width: 700px;
  width: 100%;
  padding: 35px;
  max-height: calc(100vh - 100px);
  overflow: auto;
`;

export const ModalHeader = styled.h3`
  color: ${COLORS.dark};
  font-family: ${FONTS.bold};
  font-size: ${rem('18px')};
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`;

export const ModalDescription = styled.div`
  font-size: ${rem('14px')};
  font-family: ${FONTS.regular};
  color: ${COLORS.dark};
  margin-top: 30px;
`;

export const CurrentStatusWrapper = styled.div`
  margin-bottom: 25px;
`;

export const CurrentStatus = styled.div`
  font-size: 12px;
  font-family: ${FONTS.semiBold};
  margin-bottom: 5px;
`;

export const CurrentStatusItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: -10px;
`;

export const CurrentStatusName = styled.div`
  font-size: 12px;
`;

export const AssignedCarriers = styled.div`
  margin-bottom: 20px;
`;

export const AssignedCarrier = styled.div`
  font-size: 12px;

`;

export const AssignedCarriersLabel = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-family: ${FONTS.semiBold};
`;

export const AssignedCarrierItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: -10px
`;
