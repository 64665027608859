import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FactoriesService from 'services/FactoriesService';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';
import history from 'config/history';
import { displayToast } from 'utils/toast';
import { prepareErrors } from 'utils/form';
import FactoryForm from 'components/Factories/FactoryForm';
import LaboratoriesService from 'services/LaboratoriesService';
import UsersService from 'services/UsersService';

const FactoryManagement = () => {
  const { factory_id } = useParams();
  const [factory, setFactory] = useState(null);
  const [dataLoading, setDataLoading] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [laboratories, setLaboratories] = useState([]);
  const [zones, setZones] = useState([]);
  const [managers, setManagers] = useState([]);
  const [viewers, setViewers] = useState([]);

  useEffect(() => {
    if (factory_id && factory_id !== 'create') {
      getData(factory_id);
    } else {
      setFactory(null);
      getSelectOptions();
    }

    return () => setFactory(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factory_id]);

  const goToFactoriesList = () => {
    history.push('/panel/factories');
  };

  const getData = async id => {
    try {
      setDataLoading(true);
      await Promise.all([
        getLaboratories(),
        getFactory(id),
        getZones(),
      ]);
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  };

  const getSelectOptions = async () => {
    setDataLoading(true);
    await Promise.all([
      getLaboratories(),
      getZones(),
    ]);
    setDataLoading(false);
  };

  const getLaboratories = async () => {
    const res = await LaboratoriesService.getLaboratories({ itemsPerPage: 9999 });
    const options = res.data._embedded.item.map(lab => ({ label: lab.name, value: lab.id }));
    setLaboratories(options);
  };

  const getManagers = async search => {
    const res = await UsersService.getUsers({ name: search, itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setManagers(options);
    return options;
  };

  const getViewers = async search => {
    const res = await UsersService.getUsers({ name: search, itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setViewers(options);
    return options;
  };

  const getFactory = async id => {
    const res = await FactoriesService.getFactory(id);
    setFactory(res.data);
  };

  const createFactory = async form => {
    try {
      setFormSubmitting(true);
      await FactoriesService.createFactory(form);
      setFormSubmitting(false);
      goToFactoriesList();
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateFactory = async form => {
    try {
      setFormSubmitting(true);
      await FactoriesService.updateFactory(factory_id, form);
      setFormSubmitting(false);
      goToFactoriesList();
      displayToast('success', 'Dane wytwórni zostały zapisane!');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const getZones = async (search = '') => {
    const res = await FactoriesService.getZones({ name: search });
    const options = res.data._embedded?.item.map(z => ({ label: z.name, value: z.id }));
    setZones(options);
    return options;
  };

  return (
    <AnimatePresence>
      {dataLoading
        ? <PageLoader/>
        : (
          <Fade>
            <FactoryForm
              factory={factory}
              laboratories={laboratories}
              getZones={getZones}
              zones={zones}
              formSubmitting={formSubmitting}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onSubmit={factory ? updateFactory : createFactory}
              getManagers={getManagers}
              getViewers={getViewers}
              viewers={viewers}
              managers={managers}
              goToFactoriesList={goToFactoriesList}
            />
          </Fade>
        )
      }
    </AnimatePresence>
  );
}

export default FactoryManagement;
