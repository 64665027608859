import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import { VehicleStatus } from './styles';
import { COLORS } from 'config/theme';
import ReactTooltip from 'react-tooltip';

const VehiclesTable = ({ vehicles, actions, pagination }) => {

  const returnProperStatus = status => {
    switch (status) {
      case 'INACTIVE':
        return {
          name: 'Nieaktywny',
          bgColor: COLORS.lightGray,
          textColor: COLORS.dark,
        };
      default:
        return {
          name: 'Aktywny',
          bgColor: COLORS.green,
          textColor: COLORS.white,
        };
    }
  };

  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Nr rejestracyjny</Th>
            <Th>Status</Th>
            <Th>Tonaż</Th>
            {actions && <Th>Akcje</Th>}
          </Tr>
        </THead>
        <TBody>
          {vehicles && vehicles.length > 0 ?
            vehicles.map((row, i) => {
              const status = returnProperStatus(row.status);
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td>{row.registrationNumber}</Td>
                  <Td>
                    <VehicleStatus color={status.textColor} bgColor={status.bgColor}>
                      {status.name}
                    </VehicleStatus>
                  </Td>
                  <Td>{row.carTonnage}t</Td>
                  {actions && (
                    <Td>
                      <ActionButtonWrapper>
                        {actions.map((actionFn, i) => {
                          const action = actionFn(row);
                          return (
                            <React.Fragment key={i}>
                              <ActionButton
                                data-tip={action.label}
                                color={action.color}
                                onClick={() => action.event(row.id)}
                              >
                                {action.icon}
                              </ActionButton>
                            </React.Fragment>
                          );
                        })}
                      </ActionButtonWrapper>
                    </Td>
                  )}
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={5}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default VehiclesTable;
