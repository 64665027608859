import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';

export const SelectLabel = styled.div`
  margin-right: 10px;
  font-size: ${rem('12px')};
  font-family: ${FONTS.medium};
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
  .bx-filter {
    &__placeholder {
      font-size: ${rem('12px')};
      color: ${COLORS.textGray};
      font-family: ${FONTS.regular};
      cursor: pointer;
    }
    &__indicator {
      cursor: pointer;
    }
    &__indicator-separator {
      display: none;
    }
    &__control {
      border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
      border-radius: 6px;
      min-height: 40px;
      min-width: 150px !important;
      &:hover {
        border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.main};
      }
      &--is-focused {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
      }
    }
    &__input-container {
      cursor: pointer;
    }
    &__input {
      cursor: pointer;
      font-size: ${rem('12px')} !important;
      color: ${COLORS.dark} !important;
      font-family: ${FONTS.regular} !important;
    }

    &__option {
      background-color: white;
      font-size: ${rem('12px')};
      color: ${COLORS.dark};
      cursor: pointer;

      &--is-focused {
        background-color: ${COLORS.main};
        color: ${COLORS.dark};
      }
    }
    &__single-value {
      font-size: ${rem('12px')};
      color: ${COLORS.dark};
    }
    &__menu {
      font-size: ${rem('12px')};
      color: ${COLORS.dark};
    }
`;
