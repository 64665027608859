import React, {useEffect, useState} from 'react';
import {AnimatePresence} from 'framer-motion';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { useParams } from 'react-router-dom';
import history from 'config/history';
import { displayToast } from 'utils/toast';
import { prepareErrors } from 'utils/form';
import WeightingsService from 'services/WeightingsService';
import WeightingsForm from 'components/Weightings/WeightingsForm';

const WeightingsManagement = () => {
    const { weighting_id } = useParams();
    const [weighting, setWeighting] = useState(null);
    const [weightingLoading, setWeightingLoading] = useState(null);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    useEffect(() => {
        if (weighting_id && weighting_id !== 'create') {
            getWeighting(weighting_id);
        } else {
            setWeighting(null);
        }


        return () => setWeighting(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weighting_id]);

    const gotToWeightingsList = () => {
        history.push(`/panel/weightings`);
    };

    const getWeighting = async id => {
        try {
            setWeightingLoading(true);
            const res = await WeightingsService.getWeighting(id);
            setWeighting(res.data);
            setWeightingLoading(false);
        } catch (err) {
            console.error(err);
            setWeightingLoading(false);
        }
    };

    const updateWeighting = async (id, form) => {
        try {
            console.log(form);
            setFormSubmitting(true);
            await WeightingsService.updateWeighting(weighting_id, form);
            setFormSubmitting(false);
            gotToWeightingsList();
            displayToast('success', 'Nr rejestracyjny został zaktualizowany!');
        } catch (err) {
            setFormSubmitting(false);
            if (err.response && err.response.status === 422) {
                displayToast('error', 'Formularz zawiera błędy!');
                setFormErrors(prepareErrors(err.response.data.violations));
            }
        }
    };

    return (
        <AnimatePresence>
            {weightingLoading
                ? <PageLoader />
                : (
                    <Fade>
                        <WeightingsForm
                            weighting={weighting}
                            formSubmitting={formSubmitting}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            onSubmit={(id, form) => updateWeighting(id, form)}
                            gotToWeightingsList={gotToWeightingsList}
                        />
                    </Fade>
                )
            }
        </AnimatePresence>
    );
};

export default WeightingsManagement;