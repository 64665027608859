import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Content, RatesLoader, RatesLoaderText, NoCarriersSetError } from './styles';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { AnimatePresence } from 'framer-motion';
import ContractsService from 'services/ContractsService';
import { Spinner } from 'components/__styled/Spinner';
import { RiAlarmWarningLine } from 'react-icons/ri';
import RecipesRatesForm from 'components/Contracts/RecipesRatesForm';

const RecipesRates = ({ contract }) => {
  const [recipesRates, setRecipesRates] = useState(null);
  const [loading, setLoading] = useState(false);
  const { contract_id } = useParams();

  useEffect(() => {
    if (contract) {
      getRecipesRates(contract_id ? contract_id : contract.id);
    }
  }, [contract_id, contract]);

  const getRecipesRates = async contractId => {
    try {
      setLoading(true);
      const res = await ContractsService.getRecipesRates(contractId);
      setRecipesRates(res.data._embedded.item);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <Fade>
        <Header size={23}>
          Stawki dla recept
        </Header>
        <Subtitle>Zarządzaj stawkami dla utworzonych recept</Subtitle>
        {loading
          ? (
            <RatesLoader>
              <Spinner />
              <RatesLoaderText>Ładowanie stawek...</RatesLoaderText>
            </RatesLoader>
          ) : (
            <AnimatePresence>
              <Fade>
                {!contract ? (
                  <NoCarriersSetError>
                    <RiAlarmWarningLine size={20} />
                    Najpierw przypisz daną receptę do kontraktu!
                  </NoCarriersSetError>
                ) : (
                  <Content>
                    {recipesRates && recipesRates.map((recipe, i) => {
                      return (
                        <RecipesRatesForm
                          key={i}
                          rates={recipe.ranges}
                          recipeId={recipe.id}
                          recipeName={recipe._embedded.recipe.name}
                          setRecipesRates={setRecipesRates}
                          contractId={contract_id ? contract_id : contract.id}
                        />
                      );
                    })}
                 </Content>
                )}
            </Fade>
          </AnimatePresence>
        )}
      </Fade>
    </AnimatePresence>
  );
};

export default RecipesRates;
