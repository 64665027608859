import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const DropdownButton = styled.button`
  background-color: ${COLORS.lightGray};
  border-radius: 5px;
  padding: 5px 8px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.dark};
  transition: background-color .3s ease;
  &:hover {
    background-color: ${COLORS.lightGray2};
  }
`;

export const ActionsDropdown = styled.div`
  position: absolute;
  z-index: 2;
  top: 10px;
  right: -5px;
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 25px;
  left: 0;
  transform: translate(calc(-100% + 30px), 5px);
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  opacity: ${({ visible }) => visible ? 1 : 0};
  padding: 10px;
  background-color: ${COLORS.white};
  box-shadow: 0 0 40px rgba(59, 59, 59, 0.16);
  border-radius: 5px;
  max-width: 200px;
  width: max-content;
`;

export const Icon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  svg {
    fill: ${COLORS.main};
  }
`;

export const ActionButton = styled.button`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 11px;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
  display: flex;
  align-items: center;
  &:hover {
    color: ${COLORS.main};
  }
`;
