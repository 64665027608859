import React, { useState, useEffect } from 'react';
import { ShadowBox } from 'components/__styled/ShadowBox';
import {
  SummaryWrapper,
  SummaryItem,
  SummaryItemText,
  SummaryItemValue,
  SummaryItemSpace,
} from './styles';

const TaskSummary = ({ task }) => {
  const [summary, setSummary] = useState({
    numerOfCars: { value: '', name: 'Liczba samochodów do podstawienia' },
    carCourseTime: { value: '', name: 'Czas przejazdu' },
    numerOfCourseForCar: { value: '', name: 'Liczba kursów dla samochodu' },
    buildInTimeWithoutGaps: { value: '', name: 'Czas wbudowania bez przerw', unit: 'godz' },
    plannedDailyAmount: { value: '', name: 'Planowana ilość dzienna', unit: 't' },
  });

  useEffect(() => {
    task && setSummary({
      numerOfCars: { ...summary.numerOfCars, value: task.numerOfCars },
      carCourseTime: { ...summary.carCourseTime, value: `${task.carCourseTimeH} g ${task.carCourseTimeMin} min` },
      numerOfCourseForCar: { ...summary.numerOfCourseForCar, value: task.numerOfCourseForCar },
      buildInTimeWithoutGaps: { ...summary.buildInTimeWithoutGaps, value: task.buildInTimeWithoutGaps },
      plannedDailyAmount: { ...summary.plannedDailyAmount, value: task.plannedDailyAmount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <ShadowBox>
      <SummaryWrapper>
        {summary && Object.values(summary).map((item, i) => {
          return (
            <SummaryItem key={i}>
              <SummaryItemText>{item.name}</SummaryItemText>
              <SummaryItemSpace />
              <SummaryItemValue>{typeof item.value === 'number' ? item.value.toFixed(1) : item.value} {item.unit}</SummaryItemValue>
            </SummaryItem>
          );
        })}
      </SummaryWrapper>
    </ShadowBox>
  );
};

export default TaskSummary;
