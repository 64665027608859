import React, {useState, useEffect, useContext} from 'react';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import {CurrentTaskName, AvgLastTimeCourses, InfoOuterWrapper, AvgTimeCourses} from '../TaskForm/styles';
import { FormButtonsWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import { GrInfo } from 'react-icons/gr';
import Input from 'components/__common/Input';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoBold, InfoMedium } from 'components/Tasks/InfoBanner/styles';
import { DivWrapper } from './styles';
import { returnValueAsNumber } from 'utils/global';
import Select from 'components/__common/Select';
import { setSelectOption } from 'utils/form';
import DestinationRouteComponent from 'components/RouteModal/DestinationRouteComponent';
import ReactTooltip from "react-tooltip";

const SecondFormStep = ({ editable, task, goToPrevStep, goToNextStep, formSubmitting, formErrors, setFormErrors }) => {
  const [form, setForm] = useState({
    buildInTimeWithoutGaps: '',
    loadingPreparationTime: 5,
    weighingTheVehicle: 5,
    timeFromLoadingToConstructionSite: '',
    accessToBuiltIn: '',
    discoveryOfTheCargoBox: 1,
    replaceCarUnderPaver: 1,
    buildInTimeForCar: '',
    undocking: 5,
    fromLeavingConstructionToFactory: '',
    departureFromBuiltIn: '',
    startingNumbersOfCars: 1,
    bufferFromFactoryToConstruction: '',
    bufferFromConstructionToFactory: '',
    calculatedRouteTime: task?.calculatedRouteTime || '',
    routeDetails: task?.routeDetails || '',
    factoryGeozone: null
  });
  const [route, setRoute] = useState(null);
  const [courseTime, setCourseTime] = useState(0);
  const [routeCalculatedDetails, setRouteCalculatedDetails] = useState(null);
  useEffect(() => {
    if (task) {
      setForm({
        loadingPreparationTime: task.loadingPreparationTime !== null ? task.loadingPreparationTime : form.loadingPreparationTime,
        loadingTheMass: task.loadingTheMass !== null ? task.loadingTheMass : form.loadingTheMass,
        weighingTheVehicle: task.weighingTheVehicle !== null ? task.weighingTheVehicle : form.weighingTheVehicle,
        timeFromLoadingToConstructionSite: task.timeFromLoadingToConstructionSite !== null ? task.timeFromLoadingToConstructionSite : form.timeFromLoadingToConstructionSite,
        accessToBuiltIn: task.accessToBuiltIn !== null ? task.accessToBuiltIn : form.accessToBuiltIn,
        discoveryOfTheCargoBox: task.discoveryOfTheCargoBox !== null ? task.discoveryOfTheCargoBox : form.discoveryOfTheCargoBox,
        replaceCarUnderPaver: task.replaceCarUnderPaver !== null ? task.replaceCarUnderPaver : form.replaceCarUnderPaver,
        buildInTimeForCar: task.buildInTimeForCar !== null ? task.buildInTimeForCar : form.buildInTimeForCar,
        undocking: task.undocking !== null ? task.undocking : form.undocking,
        fromLeavingConstructionToFactory: task.fromLeavingConstructionToFactory !== null ? task.fromLeavingConstructionToFactory : form.fromLeavingConstructionToFactory,
        departureFromBuiltIn: task.departureFromBuiltIn !== null ? task.departureFromBuiltIn : form.departureFromBuiltIn,
        startingNumbersOfCars: task.startingNumbersOfCars !== null ? task.startingNumbersOfCars : form.startingNumbersOfCars,
        bufferFromFactoryToConstruction: task.bufferFromFactoryToConstruction !== null ? task.bufferFromFactoryToConstruction : form.bufferFromFactoryToConstruction,
        bufferFromConstructionToFactory: task.bufferFromConstructionToFactory !== null ? task.bufferFromConstructionToFactory : form.bufferFromConstructionToFactory,
        proposedPaverSpeed: task.proposedPaverSpeed !== null ? task.proposedPaverSpeed : form.proposedPaverSpeed,
        factoryGeozone: task?._embedded?.factory?._embedded?.geoZone || null,
      });
    }
    if (route) {
      setForm({
        ...form,
        ['timeFromLoadingToConstructionSite']: parseInt(route),
        ['calculatedRouteTime']: parseInt(route),
        ['routeDetails']: routeCalculatedDetails,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, route]);
  useEffect(() => {
    form.fromLeavingConstructionToFactory = form.timeFromLoadingToConstructionSite;
    form.departureFromBuiltIn = form.accessToBuiltIn;
    let time = (parseInt(form.loadingPreparationTime) || 0)
        + (parseInt(form.loadingTheMass) || 0)
        + (parseInt(form.weighingTheVehicle) || 0)
        + (parseInt(form.timeFromLoadingToConstructionSite) || 0)
        + (parseInt(form.accessToBuiltIn) || 0)
        + (parseInt(form.discoveryOfTheCargoBox) || 0)
        + (parseInt(form.replaceCarUnderPaver) || 0)
        + (parseInt(form.buildInTimeForCar) || 0)
        + (parseInt(form.undocking) || 0)
        + (parseInt(form.fromLeavingConstructionToFactory) || 0)
        + (parseInt(form.departureFromBuiltIn) || 0)
        + (parseInt(form.bufferFromFactoryToConstruction) || 0)
        + (parseInt(form.bufferFromConstructionToFactory) || 0)
    ;
    setCourseTime(time);
  }, [form])

  const handleFormChange = (name, value) => {
    let errors = { ...formErrors };
    delete errors[name];
    setFormErrors(errors);
    setForm({
      ...form,
      [name]: value,
    });
  };

  const generateMinutesOptions = minutes => {
    return Array.from({ length: minutes }, (_, i) => ({ label: (i + 1).toString(), value: i + 1 }));;
  };

  const fiveMinutesOptions = generateMinutesOptions(5);
  const twoMinutesOptions = generateMinutesOptions(2);

  return (
    <>
      <ReactTooltip className="tooltip" effect="solid" />
      <Header size={23}>
        Czasy przejazdu
        {task && <CurrentTaskName>{task.name}</CurrentTaskName>}
      </Header>
      <Subtitle>Podaj szacowane czasy przejazdu i czynności przygotowawczych (w minutach) do obliczeń</Subtitle>
      {task?.factoryRejectDescription && task?.factoryRejectDescription.length > 0 && (
        <InfoOuterWrapper>
          <InfoBanner>
            <span>
              <div>Powód odrzucenia przez wytwórnię:</div>
              <InfoMedium>{task.factoryRejectDescription}</InfoMedium>
            </span>
          </InfoBanner>
        </InfoOuterWrapper>
      )}
      <DivWrapper>
        {task && task.avgLastTimeCourses && (
          <AvgLastTimeCourses>
            <span><GrInfo size={22}/></span>
            <span>Średni czas kursu w skopiowanym harmonogramie wyniósł: <strong>{task.avgLastTimeCourses} min</strong> </span>
          </AvgLastTimeCourses>
        )}
      </DivWrapper>
      <FormContent>
        <FormRow>
          <FormElement>
            <InfoBanner>Ilość ton do wbudowania: <InfoBold>{task && task.plannedDailyAmount}</InfoBold></InfoBanner>
          </FormElement>
          <FormElement>
            <AvgTimeCourses>
              <span><GrInfo size={22}/></span>
              <span>Całkowity czas kursu: <InfoBold>{courseTime} min</InfoBold> </span>
              {task && task.averageCourseTime && (
                  <span>Średni czas kursu z ostanich realizacji: <InfoBold>{task.averageCourseTime} min</InfoBold> </span>
              )}
            </AvgTimeCourses>
          </FormElement>
        </FormRow>
        <FormRow>
          <FormElement>
          <Label required>Tarowanie, przygotowywanie skrzyni ładunkowej (min)</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.loadingPreparationTime}
              name="loadingPreparationTime"
              options={fiveMinutesOptions}
              onChange={option => handleFormChange('loadingPreparationTime', option.value)}
              value={setSelectOption(fiveMinutesOptions, form.loadingPreparationTime)}
            />
            {formErrors && formErrors.loadingPreparationTime && <FormError>{formErrors.loadingPreparationTime}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Załadunek MMA (min)</Label>
            <Input
              isError={formErrors && formErrors.loadingTheMass}
              type="number"
              name="loadingTheMass"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('loadingTheMass', v);
              }}
              value={form.loadingTheMass}
              disabled
            />
            {formErrors && formErrors.loadingTheMass && <FormError>{formErrors.loadingTheMass}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Od załadunku do wjazdu na budowe (min)</Label>
            <DestinationRouteComponent
                duration={setRoute}
                actionRouteDetails={setRouteCalculatedDetails}
                currentDuration={form.calculatedRouteTime}
                currentRouteDetails={form.routeDetails}
                geoZone={form.factoryGeozone}
            ></DestinationRouteComponent>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.timeFromLoadingToConstructionSite}
              type="number"
              name="timeFromLoadingToConstructionSite"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('timeFromLoadingToConstructionSite', v);
              }}
              value={form.timeFromLoadingToConstructionSite}
            />
            {formErrors && formErrors.timeFromLoadingToConstructionSite && <FormError>{formErrors.timeFromLoadingToConstructionSite}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Przykrycie skrzyni ładunkowej, ważenie (min)</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.weighingTheVehicle}
              name="weighingTheVehicle"
              options={fiveMinutesOptions}
              onChange={option => handleFormChange('weighingTheVehicle', option.value)}
              value={setSelectOption(fiveMinutesOptions, form.weighingTheVehicle)}
            />
            {formErrors && formErrors.weighingTheVehicle && <FormError>{formErrors.weighingTheVehicle}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Dojazd do miejsca wbudowania (min)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.accessToBuiltIn}
              type="number"
              name="accessToBuiltIn"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('accessToBuiltIn', v);
              }}
              value={form.accessToBuiltIn}
            />
            {formErrors && formErrors.accessToBuiltIn && <FormError>{formErrors.accessToBuiltIn}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Odkrycie skrzyni ładunkowej (min)</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.discoveryOfTheCargoBox}
              name="discoveryOfTheCargoBox"
              options={twoMinutesOptions}
              onChange={option => handleFormChange('discoveryOfTheCargoBox', option.value)}
              value={setSelectOption(twoMinutesOptions, form.discoveryOfTheCargoBox)}
            />
            {formErrors && formErrors.discoveryOfTheCargoBox && <FormError>{formErrors.discoveryOfTheCargoBox}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Czas wymiany auta pod rozściełaczem (min)</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.replaceCarUnderPaver}
              name="replaceCarUnderPaver"
              options={twoMinutesOptions}
              onChange={option => handleFormChange('replaceCarUnderPaver', option.value)}
              value={setSelectOption(twoMinutesOptions, form.replaceCarUnderPaver)}
            />
            {formErrors && formErrors.replaceCarUnderPaver && <FormError>{formErrors.replaceCarUnderPaver}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Czas wbudowywania / auto (min)</Label>
            <Input
              isError={formErrors && formErrors.buildInTimeForCar}
              type="number"
              name="buildInTimeForCar"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('buildInTimeForCar', v);
              }}
              value={form.buildInTimeForCar}
              disabled
            />
            {formErrors && formErrors.buildInTimeForCar && <FormError>{formErrors.buildInTimeForCar}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Oddokowanie, czyszczenie powierzchni ładunkowej (min)</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.undocking}
              name="undocking"
              options={fiveMinutesOptions}
              onChange={option => handleFormChange('undocking', option.value)}
              value={setSelectOption(fiveMinutesOptions, form.undocking)}
            />
            {formErrors && formErrors.undocking && <FormError>{formErrors.undocking}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Od wyjazdu z budowy do wytwórni (min)</Label>
            <Input
              isError={formErrors && formErrors.fromLeavingConstructionToFactory}
              type="number"
              name="fromLeavingConstructionToFactory"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('fromLeavingConstructionToFactory', v);
              }}
              value={form.fromLeavingConstructionToFactory}
              disabled
            />
            {formErrors && formErrors.fromLeavingConstructionToFactory && <FormError>{formErrors.fromLeavingConstructionToFactory}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Wyjazd z miejsca wbudowania (min)</Label>
            <Input
              isError={formErrors && formErrors.departureFromBuiltIn}
              type="number"
              name="departureFromBuiltIn"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('departureFromBuiltIn', v);
              }}
              value={form.departureFromBuiltIn}
              disabled
            />
            {formErrors && formErrors.departureFromBuiltIn && <FormError>{formErrors.departureFromBuiltIn}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Prędkość układania (m/min)</Label>
            <Input
              disabled
              isError={formErrors && formErrors.proposedPaverSpeed}
              type="number"
              name="proposedPaverSpeed"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('proposedPaverSpeed', v);
              }}
              value={form.proposedPaverSpeed}
            />
            {formErrors && formErrors.startingNumbersOfCars && <FormError>{formErrors.startingNumbersOfCars}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Bufor czasowy z budowy na wytwórnię (min)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.bufferFromConstructionToFactory}
              type="number"
              name="bufferFromConstructionToFactory"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('bufferFromConstructionToFactory', v);
              }}
              value={form.bufferFromConstructionToFactory}
            />
            {formErrors && formErrors.bufferFromConstructionToFactory && <FormError>{formErrors.bufferFromConstructionToFactory}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Bufor czasowy z wytwórni na budowę (min)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.bufferFromFactoryToConstruction}
              type="number"
              name="bufferFromFactoryToConstruction"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('bufferFromFactoryToConstruction', v);
              }}
              value={form.bufferFromFactoryToConstruction}
            />
            {formErrors && formErrors.bufferFromFactoryToConstruction && <FormError>{formErrors.bufferFromFactoryToConstruction}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Liczba pojazdów na start (szt)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.startingNumbersOfCars}
              type="number"
              name="startingNumbersOfCars"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('startingNumbersOfCars', v);
              }}
              value={form.startingNumbersOfCars}
            />
            {formErrors && formErrors.startingNumbersOfCars && <FormError>{formErrors.startingNumbersOfCars}</FormError>}
          </FormElement>
        </FormRow>
      </FormContent>

      <FormButtonsWrapper>
        <Button onClick={goToPrevStep} leftIcon={<BsArrowLeftShort />} secondary>Wstecz</Button>
        <Button onClick={() => goToNextStep(task.id, form)} isLoading={formSubmitting} rightIcon={<BsArrowRightShort />}>Dalej</Button>
      </FormButtonsWrapper>
    </>
  )
};

export default SecondFormStep;
