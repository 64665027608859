import React from 'react';
import { createPortal } from 'react-dom';
import ModalContext from 'context/ConfirmModalContext';
import { ModalWrapper, ModalContent, ModalButtonsWrapper, ModalHeader, ModalDescription } from './styles';
import { AnimatePresence } from 'framer-motion';
import Button from 'components/__common/Button';

const ConfirmModal = () => {
  let { modalContent, onConfirm, onCancel, isVisible } = React.useContext(ModalContext);

  const component = (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <ModalWrapper
          key={'confirm-modal'}
          data-testid={'confirm-modal'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent>
            <ModalHeader>{modalContent && modalContent.header}</ModalHeader>
            <ModalDescription>{modalContent && modalContent.description.length > 0 && modalContent.description}</ModalDescription>
            <ModalButtonsWrapper>
              <Button onClick={onConfirm}>Tak</Button>
              <Button secondary onClick={onCancel}>Anuluj</Button>
            </ModalButtonsWrapper>
          </ModalContent>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );

  return createPortal(component, document.body);
};

export default ConfirmModal;
