import styled, { css } from 'styled-components';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.large}) {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const HeadersRow = styled.div`
  max-width: 160px;
  width: 100%;
  display: none;
  ${({ showOnMobile }) => showOnMobile && css`
    @media (max-width: ${BREAKPOINTS.large}) {
      display: block;
    }
  `};
`;

export const RateRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINTS.large}) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  ${({ showOnDesktop }) => showOnDesktop && css`
    display: flex;
    margin-bottom: 10px;
    @media (max-width: ${BREAKPOINTS.large}) {
      display: none;
    }
  `};
`;

export const RateElem = styled.div`
  margin-right: 10px;
  flex: 1;
  ${({ small }) => small && css`max-width: 160px`};
  @media (max-width: ${BREAKPOINTS.large}) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
`;

export const RatesContent = styled.div`
  border-top: 1px solid ${COLORS.textGray2};
  padding-top: 20px;
`;

export const RateHeader = styled.div`
  flex: 1;
  font-size: 11px;
  font-family: ${FONTS.semiBold};
  margin-right: 10px;
  &::after {
    margin-left: 3px;
    top: 1px;
    content: '*';
    color: red;
    font-size: 11px;
    font-family: ${FONTS.regular};
    display: inline-block;
  }
  ${({ small }) => small && css`max-width: 160px`};
  @media (max-width: ${BREAKPOINTS.large}) {
    display: flex;
    flex-direction: row;
    max-width: 160px;
    width: 100%;
    margin-right: 0;
    min-height: 40px;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const Placeholder = styled.div`
  width: 40px;
`;

export const RemoveButton = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: ${COLORS.errorRed2};
  transition: background-color .2s ease;
  svg {
    fill: ${COLORS.white}
  }
  &:hover {
    background-color: ${COLORS.errorRed};
  }
  @media (max-width: ${BREAKPOINTS.large}) {
    width: auto;
    min-height: 40px;
    padding: 10px 15px;
  }
`;

export const RemoveButtonText = styled.div`
  margin-left: 5px;
  font-family: ${FONTS.semiBold};
  font-size: 12px;
  color: ${COLORS.white};
  display: none;
  @media (max-width: ${BREAKPOINTS.large}) {
    display: inline-block;
  }
`;

export const NewRateButtonWrapper = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0 20px 0;
`;

export const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Number = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${COLORS.lightGray};
  font-family: ${FONTS.bold};
  color: ${COLORS.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-family: ${FONTS.bold};
  color: ${COLORS.dark};
`;

export const OuterRatesContent = styled.div`
  overflow: hidden;
  transition: height .2s ease;
`;

export const CarrierRatesRow = styled.div`
  position: relative;
  border-radius: 7px;
  padding: 0 20px 0 20px;
  box-shadow: 0 0 10px 4px rgba(0,0,0,0.04);
  margin-bottom: 15px;
  width: 100%;
`;

export const RatesGroupSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const FilterField = styled.div`
  max-width: 160px;
  width: 100%;
  min-width: 140px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const AgreementField = styled.div`
  width: 100%;
  min-width: 140px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const AgreementLink = styled.a`
  font-size: 12px
`;

export const LoadingVignette = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RateFormButtonsWrapper = styled.div`
  margin: 5px -5px 5px -5px;
  display: flex;
  align-items: center;
`;

export const MainFieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
`;


