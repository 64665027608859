import styled, { keyframes } from 'styled-components';
import { COLORS } from 'config/theme';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid ${COLORS.dark};
  border-bottom: 2px solid ${COLORS.dark};
  animation: ${rotate} 1s linear infinite;
`;
