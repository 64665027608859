import axios from 'axios';
import { msalInstance } from 'config/msal';
import { displayToast } from 'utils/toast';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

api.interceptors.request.use(
  async request => {
    const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
      } else {
          console.log('no account');
          return false;
      }
    const req = {
      scopes: ['openid'],
      account: accounts[0],
    };

    try {
      const token = await msalInstance.acquireTokenSilent(req);
      if (token && token.idToken) {
        request.headers.Authorization = `Bearer ${token.idToken}`
      }
      return request;
    } catch (err) {
      console.log(err);
      msalInstance.logoutRedirect();
    }
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status === 401) {
      msalInstance.logoutRedirect();
    }
    if (error && error.response && error.response.status === 500) {
      displayToast('error', 'Wystąpił nieoczekiwany błąd!');
    }
    return Promise.reject(error);
  },
);


export { api as axios };
