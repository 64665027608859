import styled, { css } from 'styled-components';
import { COLORS, FONTS, BREAKPOINTS } from 'config/theme';
import { rem } from 'polished';
import { ShadowBox } from 'components/__styled/ShadowBox';

export const CarriersBox = styled(ShadowBox)`
  padding: 0;
  height: ${({ height }) => height ? height : '100%'};
  overflow: auto;
  position: relative;
  @media (max-width: ${BREAKPOINTS.huge}) {
    max-height: 400px;
    margin-bottom: 30px;
    height: auto !important;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
`;

export const CarsAmount = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const CarriersContent = styled.div`
  padding: 30px;
  margin-bottom: -80px;
`;

export const CarriersItem = styled.div`
  position: relative;
  padding: 10px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.lightGray};
  }
`;

export const ContactWrapper = styled.div`
  margin-top: 5px;
`;

export const ContactItemValue = styled.a`
  cursor: pointer;
  font-size: ${rem('11px')};
  color: ${COLORS.textGray};
  margin-left: 10px;
  max-width: 200px;
  word-break: break-word;
  text-decoration: none;
  transition: color .3s ease;
  &:hover {
    color: ${COLORS.main};
  }
`;

export const ContactItem = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  margin-bottom: 5px;
  svg {
    fill: ${COLORS.main};
  }
`;

export const CarsAmountItem = styled.div`
  display: flex;
  align-items: center;
`;

export const CarsAmountItemValue = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  user-select: none;
  font-size: ${rem('11px')};
  font-family: ${FONTS.bold};
  background-color: ${COLORS.lightGray};
  color: ${COLORS.textGray};
  ${({ available }) => available && css`
    background-color: ${COLORS.main};
    color: ${COLORS.dark};
  `};
`;

export const BottomVignette = styled.div`
  pointer-events: none;
  position: sticky;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0.11948529411764708) 19%, rgba(255,255,255,0.8281687675070029) 43%, rgba(255,255,255,1) 62%);
`;
