import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
  position: relative;
  .react-datepicker {
    &__input-container {
      input {
        cursor: pointer;
        padding: 8px 15px;
        width: 100%;
        border: 1px solid ${COLORS.middleGray};
        font-size: ${rem('12px')};
        font-family: ${FONTS.regular};
        border-radius: 6px;
        outline: none;
        min-height: 40px;
        margin: 0;
        &:active,
        &:focus {
          border-color: ${COLORS.main};
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
        }
        &::placeholder {
          font-size: 12px;
          color: ${COLORS.textGray};
          font-family: ${FONTS.regular};
        }
      }
    }
    &__day--selected,
    &__day--keyboard-selected {
      background-color: ${COLORS.main};
      color: ${COLORS.dark};
      &:hover {
        background-color: ${COLORS.dark};
        color: ${COLORS.white};
      }
    }
  }
`;

export const DatePickerLabel = styled.div`
  margin-right: 10px;
  font-size: ${rem('12px')};
  font-family: ${FONTS.medium};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 11px;
  svg {
    font-size: 18px;
    color: ${COLORS.textGray};
  }
`;

export const ClearDateButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 25px;
  top: 8px;
  cursor: pointer;
  outline: none;
  svg {
    transition: fill .3s ease;
    fill: ${COLORS.textGray};
  }
  &:hover {
    svg {
      fill: ${COLORS.dark};
    }
  }
`;
