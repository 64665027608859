import React, { useState } from 'react';
import { GenerateButtonWrapper } from './styles';
import { FormContent, FormElement, FormRow, Label } from 'components/__styled/Form';
import Datepicker from 'components/__common/Datepicker';
import Button from 'components/__common/Button';
import { AiOutlineFileExcel } from 'react-icons/ai';
import moment from 'moment';
import Select from 'components/__common/Select';
import { setSelectOption } from 'utils/form';

const DriversTimetableForm = ({ generateTimetable, timetableGenerating, contracts }) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [form, setForm] = useState({
    date: today,
    contractId: null,
  });

  const handleFormChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <FormContent>
      <FormRow>
        <FormElement>
          <Label required>Data dla rozkładu jazdy</Label>
          <Datepicker
            name="date"
            onChange={value => handleFormChange('date', value)}
            value={form.date}
          />
        </FormElement>
        <FormElement>
          <Label required>Kontrakt</Label>
          <Select
            name="contractId"
            options={contracts}
            placeholder={'Wybierz kontrakt...'}
            onChange={option => handleFormChange('contractId', option.value)}
            value={setSelectOption(contracts, form.contractId)}
          />
        </FormElement>
      </FormRow>
      <GenerateButtonWrapper>
        <Button
          onClick={() => generateTimetable(form)}
          disabled={timetableGenerating}
          isLoading={timetableGenerating}
          leftIcon={<AiOutlineFileExcel size={20} />}
          zoom
        >
          Wygeneruj rozkład
        </Button>
      </GenerateButtonWrapper>
    </FormContent>
  );
};

export default DriversTimetableForm;
