import styled, { css } from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  align-items: ${({ align }) => align ? align : 'flex-start'};
  justify-content: ${({ justify }) => justify ? justify : 'flex-start'};
  flex-wrap: ${({ flexWrap }) => flexWrap ? 'wrap' : 'nowrap'};
  ${({ fullWidth }) => fullWidth && css`width: 100%`};
`;

export const FormButtonsWrapper = styled.div`
  margin: 20px -5px 20px -5px;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin: 5px -5px;
`;
