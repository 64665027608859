import styled from "styled-components";
import {COLORS} from "../../../config/theme";

export const RowError = styled.div`
  height: 100%;
  width: 30px;
  display: flex;
  align-items: start;
  justify-content: left;
  svg {
    fill: ${COLORS.errorRed};
  }
`;