import styled from 'styled-components';
import { COLORS } from 'config/theme';

export const SelectWrapper = styled.div`
  flex: 1;
  .bx {
    
    &__control {
      border-color: ${({isError}) => isError ? COLORS.errorRed : COLORS.middleGray} !important;
      text-align: left;

      &:hover {
        border-color: ${({isError}) => isError ? COLORS.errorRed : COLORS.main} !important;
      }
    }
    
    &__menu {
      display: ${({ isCreatable, withTips }) => isCreatable ? withTips ? 'block' : 'none' : 'block'};
    }
  }
`;
