import React, { useState } from 'react';
import { Wrapper, Stage, Statuses, Status, Color, Name, ShowLegendButton, ShowLegendButtonIcon, LegendContent } from './styles';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { statusesLegend } from 'config/statuses';

const ScheduleStatusesLegend = () => {
  const [visible, setVisible] = useState(false);

  const handleLegendVisible = () => {
    setVisible(!visible);
  };

  return (
    <Wrapper>
      <ShowLegendButton onClick={handleLegendVisible}>
        {visible ? 'Ukryj legendę' : 'Pokaż legendę'}
        <ShowLegendButtonIcon>
          {visible ? <MdKeyboardArrowUp size={22}/> : <MdKeyboardArrowDown size={22}/>}
        </ShowLegendButtonIcon>
      </ShowLegendButton>
      {visible && (
        <LegendContent>
          {Object.values(statusesLegend).map((legendItem, i) => {
            return (
              <React.Fragment key={i}>
                <Stage>{legendItem.name}</Stage>
                <Statuses>
                  {Object.values(legendItem.statuses).map((status, j) => {
                    return (
                      <Status key={j}>
                        <Color color={status.color}/>
                        <Name>{status.name}</Name>
                      </Status>
                    );
                  })}
                </Statuses>
              </React.Fragment>
            )
          })}
        </LegendContent>
      )}
    </Wrapper>
  );
};

export default ScheduleStatusesLegend;
