import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import PageLoader from 'components/PageLoader';
import Header from 'components/__common/Header';
import { TableBox } from 'components/__styled/Table';
import TasksService from 'services/TasksService';
import {
  SummaryWrapper,
  SummaryItem,
  SummaryItemText,
  SummaryItemValue,
  SummaryBox
} from 'components/__styled/Summary';
import { Subtitle} from 'components/__styled/Subtitle';
import { FlexWrapper } from 'components/__styled/Layout';
import FactoryScheduleTable from 'components/Tasks/FactoryScheduleTable';
import { ButtonsWrapper } from './styles';
import Button from 'components/__common/Button';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { BsCheck2 } from 'react-icons/bs';
import AcceptOrRejectScheduleModal from 'components/Tasks/AcceptOrRejectScheduleModal';
import StatusChip from 'components/Tasks/StatusChip';
import { detailedScheduleStatuses } from 'config/statuses';
import RejectDescription from 'components/Tasks/RejectDescription';
import history from 'config/history';
import { displayToast } from 'utils/toast';

const FactoryAccept = () => {
  const [schedule, setSchedule] = useState(null);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalContent, setModalContent] = useState({
    header: '',
    description: '',
    onSubmit: undefined,
    accept: true,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const { search } = useLocation();
  const secret = new URLSearchParams(search).get('secret');

  useEffect(() => {
    getScheduleForFactory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secret]);

  const getScheduleForFactory = async () => {
    try {
      setScheduleLoading(true);
      const res = await TasksService.getTaskForExternals(secret);
      setSchedule(res.data);
      setScheduleLoading(false);
    } catch (err) {
      setScheduleLoading(false);
      if (err.response.status && err.response.status === 401) {
        history.push({
          pathname: '/client/error',
          state: {
            header: 'Link wygasł!',
            description: 'Link ze szczegółami harmonogramu dostawy stracił ważność lub jest niepoprawny.',
          }
        });
      }

      if (err.response.status && err.response.status === 403) {
        history.push({
          pathname: '/client/error',
          state: {
            header: 'Brak dostępu!',
            description: 'Nie masz dostępu do podanej strony.',
          }
        });
      }
    }
  };

  const confirmSchedule = async ()  => {
    try {
      setSubmitting(true);
      await TasksService.acceptOrRejectScheduleByFactory(secret, { status: 'ACCEPT' });
      getScheduleForFactory();
      setModalVisible(false);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setModalVisible(false);
      displayToast('error', 'Wystąpił nieoczekiwany błąd!');
    }
  };

  const rejectSchedule = ()  => {
    const rejectAction = async rejectMessage => {
      try {
        setSubmitting(true);
        await TasksService.acceptOrRejectScheduleByFactory(secret, { status: 'REJECT', description: rejectMessage });
        getScheduleForFactory();
        setModalVisible(false);
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        setModalVisible(false);
        displayToast('error', 'Wystąpił nieoczekiwany błąd!');
      }
    };

    setupModal({
      header: 'Czy na pewno chcesz odrzucić harmonogram?',
      description: 'Pamiętaj, że operacja jest nieodwracalna.',
      callback: rejectMessage => rejectAction(rejectMessage),
      withRejectReason: true,
    });
  };

  const setupModal = content => {
    setModalContent(content);
    setModalVisible(true);
  };

  return (
    <AnimatePresence>
      {scheduleLoading
        ? <PageLoader />
        : (
          <Fade>
            <div>
              <AcceptOrRejectScheduleModal
                isVisible={modalVisible}
                closeModal={() => setModalVisible(false)}
                rejectProcessing={submitting}
                modalContent={modalContent}
              />
              <FlexWrapper>
                <Header size={23} beFlex>
                  Harmonogram dostawy
                </Header>
                <StatusChip
                  withIndent
                  bgColor={schedule && detailedScheduleStatuses[schedule.status].color}
                >
                  {schedule && detailedScheduleStatuses[schedule.status].name}
                </StatusChip>
                <RejectDescription
                  rejectText={schedule && schedule.statusDescription}
                  status={schedule && schedule.status}
                  place="right"
                />
              </FlexWrapper>
              <Subtitle>{schedule && schedule.name}</Subtitle>
              {
                schedule && (
                  <SummaryBox>
                    <SummaryWrapper>
                      <SummaryItem>
                        <SummaryItemText>Warstwa drogi</SummaryItemText>
                        <SummaryItemValue>{schedule.roadLayer}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Nr recepty</SummaryItemText>
                        <SummaryItemValue>{schedule.recipeName || '---'}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Lokalizacja</SummaryItemText>
                        <SummaryItemValue>{schedule.location}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Kontrakt</SummaryItemText>
                        <SummaryItemValue>{schedule.contractName}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Data</SummaryItemText>
                        <SummaryItemValue>{schedule.date}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Rozpoczęcie załadunku</SummaryItemText>
                        <SummaryItemValue>{schedule.startOfLoadingCars}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Start wbudowywania</SummaryItemText>
                        <SummaryItemValue>{schedule.startOfUnloadingCar}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Planowana ilość / dzień</SummaryItemText>
                        <SummaryItemValue>{schedule.plannedDailyAmount} t</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Planowana wydajność</SummaryItemText>
                        <SummaryItemValue>{schedule.factoryCapacity} t/h</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Liczba pojazdów do podstawienia</SummaryItemText>
                        <SummaryItemValue>{schedule.numberOfCars}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Liczba pojazdów na start</SummaryItemText>
                        <SummaryItemValue>{schedule.startingNumbersOfCars}</SummaryItemValue>
                      </SummaryItem>
                      <SummaryItem>
                        <SummaryItemText>Łączna ilość kursów:</SummaryItemText>
                        <SummaryItemValue>{schedule._embedded.courses.length}</SummaryItemValue>
                      </SummaryItem>
                      {schedule.availableCars && (
                        <SummaryItem>
                          <SummaryItemText>Dostępnych pojazdów</SummaryItemText>
                          <SummaryItemValue>{schedule.availableCars}</SummaryItemValue>
                        </SummaryItem>
                      )}
                    </SummaryWrapper>
                  </SummaryBox>
                )
              }
              <TableBox>
                <FactoryScheduleTable
                  schedule={schedule}
                />
              </TableBox>
              {schedule && schedule.status === detailedScheduleStatuses.CONFIRMED.slug && (
                <ButtonsWrapper>
                  <Button
                    onClick={confirmSchedule}
                    disabled={submitting}
                    isLoading={submitting}
                    zoom
                    rightIcon={<BsCheck2 />}
                    style={{ opacity: 0.7 }}
                  >
                    Potwierdź wydajność
                  </Button>
                  <Button
                    onClick={rejectSchedule}
                    disabled={submitting}
                    secondary
                    zoom
                    rightIcon={<IoCloseCircleOutline />}
                    style={{ opacity: 0.7 }}
                  >
                    Odrzuć dostawę
                  </Button>
                </ButtonsWrapper>
              )}
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default FactoryAccept;
