import React, { useState } from 'react';
import { GenerateButtonWrapper } from '../ReportForm/styles';
import { FormContent, FormElement, FormRow, Label } from 'components/__styled/Form';
import Datepicker from 'components/__common/Datepicker';
import Button from 'components/__common/Button';
import { AiOutlineFileExcel } from 'react-icons/ai';
import moment from 'moment';

const ReportRecalc = ({recalc, reportRecalcing }) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [form, setForm] = useState({
    dateFrom: today,
    dateTo: today,
  });

  const handleFormChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <FormContent>
      <FormRow>
        <FormElement>
          <Label required>Data od</Label>
          <Datepicker
            name="dateFrom"
            onChange={value => handleFormChange('dateFrom', value)}
            value={form.dateFrom}
          />
        </FormElement>
        <FormElement>
          <Label required>Data do</Label>
          <Datepicker
            name="dateTo"
            onChange={value => handleFormChange('dateTo', value)}
            value={form.dateTo}
          />
        </FormElement>
      </FormRow>
      <GenerateButtonWrapper>
        <Button
          onClick={(e) => recalc(e,form)}
          disabled={reportRecalcing}
          isLoading={reportRecalcing}
          leftIcon={<AiOutlineFileExcel size={20} />}
          zoom
        >
          Przelicz raport
        </Button>
      </GenerateButtonWrapper>
    </FormContent>
  );
};

export default ReportRecalc;
