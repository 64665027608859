import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { FlexWrapper } from 'components/__styled/Layout';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import Button from 'components/__common/Button';
import { IoMdAdd } from 'react-icons/io';
import { TableBox } from 'components/__styled/Table';
import ConfirmModalContext from 'context/ConfirmModalContext';
import history from 'config/history';
import { FaRegEdit } from 'react-icons/fa';
import { VscTrash } from 'react-icons/vsc';
import { COLORS } from 'config/theme';
import Pagination from 'components/Pagination';
import FactoriesService from 'services/FactoriesService';
import FactoriesTable from 'components/Factories/FactoriesTable';

const FactoriesList = () => {
  const { openModal } = React.useContext(ConfirmModalContext);
  const [factories, setFactories] = useState([]);
  const [factoriesLoading, setFactoriesLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
    page: 1,
  });

  useEffect(() => {
    getFactories(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      visible: true,
      event: id => goToFactoryEdit(id),
    }),
    row => ({
      label: 'Usuń',
      icon: <VscTrash size={24} />,
      visible: true,
      event: id => openModal('Czy chcesz usunąć rekord?', 'Pamiętaj, że operacja jest nieodwracalna.', () => removeFactory(id)),
      color: COLORS.errorRed2,
    }),
  ];

  const removeFactory = async id => {
    try {
      setFactoriesLoading(true);
      await FactoriesService.removeFactory(id);
      await getFactories(pagination);
    } catch (err) {
      console.error(err);
    }
  };

  const getFactories = async pagination => {
    try {
      setFactoriesLoading(true);
      const res = await FactoriesService.getFactories({
        page: pagination.page,
        itemsPerPage: pagination.perPage,
      });
      setFactories(res.data._embedded ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems, page: pagination.page });
      setFactoriesLoading(false);
    } catch (err) {
      setFactoriesLoading(false);
      console.error(err);
    }
  };

  const goToFactoryEdit = id => {
    history.push(`/panel/factories/${id}`);
  };

  const goToCreateFactory = () => {
    history.push('/panel/factories/create');
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getFactories({ ...pagination, page });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {factoriesLoading
        ? <PageLoader/>
        : (
          <Fade>
            <FlexWrapper justify={'space-between'}>
              <div>
                <Header size={23}>Wytwórnie</Header>
                <Subtitle>Lista utworzonych wytwórni masy bitumicznej.</Subtitle>
              </div>
              <Button zoom onClick={goToCreateFactory} leftIcon={<IoMdAdd/>}>Nowa wytwórnia</Button>
            </FlexWrapper>
            <TableBox>
              <FactoriesTable
                factories={factories}
                pagination={pagination}
                actions={actions}
              />
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                perPage={pagination.perPage}
                page={pagination.page}
              />
            </TableBox>
          </Fade>
        )
      }
    </AnimatePresence>
  );
};

export default FactoriesList;
