import { useEffect, useState } from "react";
import MapModal from "./MapModal";
import { ModalBackground, ModalWindow, DestinationValue } from "./styles";
import { FaRoute } from "react-icons/fa";

const DestinationRouteComponent= (props) => {
  const [coords, setCoords] = useState(null);
  const [destCoords, setDestCoords] = useState(null);
  const [routeDetails, setRouteDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const duration = props.duration;
  const actionRouteDetails = props.actionRouteDetails;
  const currentDuration = props.currentDuration;
  const currentRouteDetails = props.currentRouteDetails;
  const geoZone = props.geoZone;

  useEffect(() => {
    //navigator.geolocation.getCurrentPosition(success);
    if (geoZone?.points.length > 0) {
      setCoords([geoZone?.points[0].latitude, geoZone?.points[0].longitude]);
    } else {
      setCoords([54.392115082912, 18.365601013797]);
    }

    if (currentDuration) {
      setDestCoords(currentDuration);
    }

    if (currentRouteDetails) {
      setRouteDetails(currentRouteDetails);
    }
  }, []);
  //setDuration(destCoords);
  duration(destCoords);
  actionRouteDetails(routeDetails);
  return (
    <>
      {coords && isOpen ? (
        <ModalBackground>
          <ModalWindow>
            <MapModal
              close={() => setIsOpen(false)}
              action={setDestCoords}
              actionRouteDetails={setRouteDetails}
              currentRoute={routeDetails}
              coords={coords}
              geoZone={geoZone}
              apikey={process.env.REACT_APP_HERE_API_KEY}
            />
          </ModalWindow>
        </ModalBackground>
      ) : (
        <FaRoute data-tip="Wyznacz trasę" style={{ cursor: "pointer" }} size={24} onClick={() => setIsOpen(true)}>
          Ustaw pozycję
        </FaRoute>
      )}
      <DestinationValue>{destCoords ? `(${destCoords} min)` : ""}</DestinationValue>
    </>
  );
}

export default DestinationRouteComponent;
