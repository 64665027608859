import styled, { css } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 30px 0 20px 0;
`;

export const PageButton = styled.button`
  background-color: ${({ active }) => active ? COLORS.lightGray : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.semiBold};
  color: ${({ active }) => active ? COLORS.dark : COLORS.textGray};
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 35px;
  min-height: 35px;
  margin: 5px;
  transition: color .2s ease, background-color .2s ease;
  &:hover {
    background-color: ${COLORS.lightGray};
    color: ${COLORS.dark};
  }
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `};
`;

export const PaginationDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${COLORS.lightGray}
`;
