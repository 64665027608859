import styled from 'styled-components';
import { COLORS, FONTS, BREAKPOINTS } from 'config/theme';

export const Wrapper = styled.div`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.bold};
  font-size: 11px;
  margin-right: 10px;
  background-color: ${COLORS.lightGray};
  color: ${COLORS.gray};
`;

export const UserName = styled.div`
  font-family: ${FONTS.semiBold};
  font-size: 12px;
  color: ${COLORS.dark};
  margin-right: 6px;
  @media (max-width: ${BREAKPOINTS.small}) {
    display: none;
  }
`;

export const UserContextMenu = styled.div`
  position: absolute;
  right: 39px;
  top: 68px;
  width: 150px;
  background-color: ${COLORS.white};
  box-shadow: 0 0 26px rgb(59 59 59 / 44%);
  padding: 10px 0;
  border-radius: 7px;
`;

export const LogoutButton = styled.button`
  font-size: 12px;
  width: 100%;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.gray};
  border: none;
  background: none;
  padding: 10px 25px;
  cursor: pointer;
  transition: color .2s ease;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    margin-right: 10px;
  }
  &:hover {
    color: ${COLORS.dark};
  }
`;
