import React, { useState,useEffect } from 'react';
import AlertsService from "../services/AlertsService";

const NotificationContext =  React.createContext();
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }) => {
    const [notification, setNotificationAlert] = useState(null);
    const [notificationCountAlert, setNotificationCountAlert] = useState(null);

    const getAlertsCount = async () => {
        const alerts =  await AlertsService.getAlertsCount();
        setNotificationAlert(alerts.data.count > 0 ? 'Alerty' : false);
        setNotificationCountAlert(alerts.data.count);
    }

    useEffect(() => {
        getAlertsCount();
        const notificationsInterval = setInterval(() => {
            getAlertsCount();
        }, 300000);

        return () => clearInterval(notificationsInterval);
    }, []);



    return (
        <Provider value={{ notification,notificationCountAlert }}>
            {children}
        </Provider>
    );
};

export { NotificationContext, NotificationProvider };

export default NotificationContext;
