import React, {useEffect, useState} from 'react';
import {
  ModalButtonsWrapper,
  ModalContent,
  ModalDescription, ModalFormWrapper,
  ModalHeader,
  ModalWrapper,
} from './styles';
import Button from 'components/__common/Button';
import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import {FormElement, FormError, Label} from "../../__styled/Form";
import Input from "../../__common/Input";
import {Parser} from "html-to-react";

const FormModal = ({
  isVisible,
  closeModal,
  modalContent,
  updateProcessing,
  setInputValue,
  handleChange,
  value,
    name
}) => {
  const [form, setForm] = useState({
    field: value
  });

  const handleFormChange = (name, value) => {
    setForm({'field': value});
  };

  useEffect(() => {
    if (value) {
      setForm({'field': value});
    }
  }, [value]);

  const handleFormSubmit = () => {
    setInputValue(form.field);
    handleChange('paidStopover', form.field);
    closeModal();
  };

  const component = (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <ModalWrapper
          key={'reject-modal'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent>
            <ModalHeader>{modalContent && modalContent.header}</ModalHeader>
            <ModalDescription>{Parser().parse(modalContent.description)}</ModalDescription>
            <ModalFormWrapper>
              <FormElement>
                <Label>{modalContent && modalContent.inputLabel}</Label>
                <Input
                    name="field"
                    onChange={e => handleFormChange('field', Number(e.target.value))}
                    value={form.field}
                    type="number"
                />
              </FormElement>
            </ModalFormWrapper>
            <ModalButtonsWrapper>
              <Button
                isLoading={updateProcessing}
                disabled={updateProcessing}
                onClick={() => handleFormSubmit()}
              >
                Tak
              </Button>
              <Button
                disabled={updateProcessing}
                secondary
                onClick={closeModal}
              >
                Anuluj
              </Button>
            </ModalButtonsWrapper>
          </ModalContent>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );

  return createPortal(component, document.body);
};

export default FormModal;
