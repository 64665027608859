import { toast } from 'react-toastify';

export const displayToast = (type = 'info', message) => {
  switch (type) {
    case 'success':
      return toast.success(message);
    case 'warning':
      return toast.warn(message);
    case 'error':
      return toast.error(message);
    default:
      return toast.info(message);
  }
};
