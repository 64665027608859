import styled, { css } from 'styled-components';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.large}) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  max-width: 220px;
  width: 100%;
  margin-right: 10px;
  padding-top: 15px;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin: 0;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  margin-left: 10px;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin: 0;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.large}) {
    flex-direction: row;
    margin-left: -5px;
    margin-right: -5px;
  }
`;

export const Tab = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  max-width: 200px;
  background-color: ${COLORS.lightGray2};
  color: ${COLORS.gray};
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-family: ${FONTS.semiBold};
  svg {
    margin-right: 10px;
  }
  ${({ active }) => active && css`
    background-color: ${COLORS.main};
    color: ${COLORS.dark};
  `};
  @media (max-width: ${BREAKPOINTS.large}) {
    display: inline-block;
    min-width: 150px;
    margin: 5px;
  }
`;

export const TabContent = styled.div`
  padding: 10px;
  @media (max-width: ${BREAKPOINTS.large}) {
    padding: 25px 0 0;
  }
`;
