import React, { useState } from 'react';
import Header from 'components/__common/Header';
import Input from 'components/__common/Input';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import Select from 'components/__common/Select';

const LaboratoryForm = ({ laboratory, formErrors, setFormErrors, onSubmit, formSubmitting, gotToLaboratoriesList }) => {

  const [form, setForm] = useState({
    email: laboratory && laboratory.email ? laboratory.email.split(';').map(el => ({ label: el, value: el })) : '',
    phone: laboratory && laboratory.phone ? laboratory.phone : '',
    name: laboratory && laboratory.name ? laboratory.name : '',
  });

  const handleFormChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (formErrors && setFormErrors) {
      let errors = { ...formErrors };
      delete errors[name];
      setFormErrors(errors);
    }
  };

  const handleSubmit = () => {
    const requestForm = {
      ...form,
      email: form.email.map(el => el.value).join(';'),
    };
    onSubmit(requestForm);
  };

  return (
    <>
      <Header size={23}>
        {laboratory ? laboratory.name : 'Nowe laboratorium'}
      </Header>
      <Subtitle>
        {laboratory
          ? 'Formularz edycji utworzonego wcześniej laboratorium'
          : 'Formularz dodawania nowego laboratorium'
        }
      </Subtitle>
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Nazwa</Label>
            <Input
              isError={formErrors && formErrors.name}
              name="name"
              onChange={e => handleFormChange('name', e.target.value)}
              value={form.name}
            />
            {formErrors && formErrors.name && <FormError>{formErrors.name}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Email</Label>
            <Select
              isError={formErrors && formErrors.email}
              name="email"
              isMulti={true}
              isCreatable={true}
              onChange={values => {
                handleFormChange('email', values);
              }}
              placeholder={'Wpisz adres email...'}
              options={[]}
              value={form.email}
            />
            {formErrors && formErrors.email && <FormError>{formErrors.email}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Nr telefonu</Label>
            <Input
              isError={formErrors && formErrors.phone}
              name="phone"
              onChange={e => handleFormChange('phone', e.target.value)}
              value={form.phone}
            />
            {formErrors && formErrors.phone && <FormError>{formErrors.phone}</FormError>}
          </FormElement>
          <FormElement />
        </FormRow>
        <FlexWrapper justify={'flex-end'}>
          <Button
            secondary
            onClick={() => gotToLaboratoriesList()}
            leftIcon={<BsArrowLeftShort />}
            disabled={formSubmitting}
          >
            Wróć
          </Button>
          <Button
            onClick={() => handleSubmit()}
            zoom
            rightIcon={<BsCheck2 size={18} />}
            isLoading={formSubmitting}
            disabled={formSubmitting}
          >
            Zapisz
          </Button>
        </FlexWrapper>
      </FormContent>
    </>
  );
};

export default LaboratoryForm;
