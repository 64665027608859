import { axios } from 'api';

const AlertsService = {
  getAlerts: params => axios.get(`/delivery-schedule/alerts`, { params: { ...params }}),
  getAlertsCount: params => axios.get(`/delivery-schedule/alerts/count`, { params: { ...params }}),
  getAlert: id => axios.get(`/delivery-schedule/alerts/${id}`),
  getAlertsTypes: () => axios.get(`/delivery-schedule/alerts/types`),
};

export default AlertsService;
