import React from 'react';
import { NoRecords, Tbl, TBody, Td, Th, THead, Tr } from 'components/__styled/Table';
import {ButtonsWrapper, CarrierSelectWrapper, ScheduleTblWrapper} from '../LogisticScheduleTable/styles';
import Select from 'components/__common/Select';
import Checkbox from 'components/__common/Checkbox';
import { FormError } from 'components/__styled/Form';

const CarrierScheduleTable = ({
  currentTask,
  schedule,
  setSchedule,
  cars,
  canEdit,
  acceptedCourses,
  handleAcceptedCoursesChange,
  toggleAcceptAllCourses,
  coursesErrors,
  setCoursesErrors,
}) => {
  const setCar = (index, car) => {
    const filteredCoursesErrors = {...coursesErrors};
    delete filteredCoursesErrors[`cars[${index}].carId`];
    setCoursesErrors(filteredCoursesErrors);

    const newSchedule = [...schedule];
    newSchedule[index] = {
      ...newSchedule[index],
      _embedded: {
        ...newSchedule[index]._embedded,
        car: {
          id: car.value,
          registrationNumber: car.label,
        },
      },
    };
    setSchedule(newSchedule);
  };

  const groupOptions = data => {
    return [...data.reduce((hash, car ) => {
      const current = hash.get(car.carTonnage) || { label: `${car.carTonnage} t`, options: [] };

      current.options.push({ value: car.id, label: car.registrationNumber });

      return hash.set(car.carTonnage, current);
    }, new Map()).values()];
  };

  const rowRenderer = (i, row) => {
    let carsList;
    if (row._embedded && row._embedded.car) {
      carsList = cars.filter(car => car.carTonnage === row._embedded.car.carTonnage)
    } else {
      carsList = [...cars];
    }
    const carOptions = groupOptions(carsList);
    const carPerCourse = row._embedded && row._embedded.car;

    return (
      <Tr key={i}>
        {row.status === 'LOGISTIC_CONFIRMED' && (
          <Td width={100}>
            <Checkbox
              value={acceptedCourses.includes(row.id)}
              onChange={() => handleAcceptedCoursesChange(row.id)}
            />
          </Td>
        )}
        <Td>
          <ButtonsWrapper style={{display: 'flex', justifyContent: 'flex-start'}}>
            <CarrierSelectWrapper>
              <Select
                disabled={!canEdit}
                options={carOptions}
                onChange={value => setCar(i, value)}
                value={carPerCourse ? {value: carPerCourse.id, label: carPerCourse.registrationNumber} : null}
                asPortal
                isError={coursesErrors && coursesErrors[`cars[${i}].carId`]}
              />
              {coursesErrors && coursesErrors[`cars[${i}].carId`] && <FormError>{coursesErrors[`cars[${i}].carId`]}</FormError>}
            </CarrierSelectWrapper>
          </ButtonsWrapper>
        </Td>
        <Td>{row.carNumber}</Td>
        <Td>{row.timeOfLoading}</Td>
        <Td>{row.timeOfBuilding}</Td>
        <Td>{row.timeOfReturn}</Td>
      </Tr>
    );
  };

  return (
    <ScheduleTblWrapper>
      <Tbl>
        <THead>
          <Tr>
            {currentTask && currentTask.status === 'LOGISTIC_CONFIRMED' && (
              <Th width={100}>
                <Checkbox
                  value={acceptedCourses.length === schedule.length}
                  onChange={() => toggleAcceptAllCourses()}
                />
              </Th>
            )}
            <Th>Samochód</Th>
            <Th>Pozycja samochodu w kolejce</Th>
            <Th>Podstawienie na wytwórnię</Th>
            <Th>Przyjazd na budowę</Th>
            <Th>Powrót na WMB</Th>
          </Tr>
        </THead>
        <TBody>
          {schedule && schedule.length > 0 ? (
            <>
              {schedule.map((row, i) => {
                return rowRenderer(i, row);
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan="8">
                <NoRecords>Brak rekordów...</NoRecords>
              </Td>
            </Tr>
          )}
        </TBody>
      </Tbl>
    </ScheduleTblWrapper>
  );
};

export default CarrierScheduleTable;
