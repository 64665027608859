import styled, { css } from 'styled-components';
import { ShadowBox } from 'components/__styled/ShadowBox';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';
import { rem } from 'polished';
import { motion } from 'framer-motion';

export const TblWrapper = styled.div`
  overflow: auto;
  position: relative;
  &:after {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(255, 255, 255, 0.6);
  }
  ${({ loading }) => loading && css`
    &:after {
      display: block;
    }
  `};
`;

export const TableBox = styled(ShadowBox)`
  margin-top: 30px;
  min-height: 200px;
  position: relative;
`;

export const Th = styled.th`
  font-size: ${rem('12px')};
  color: ${COLORS.dark};
  font-family: ${FONTS.bold};
  text-align: ${({ toRight }) => toRight ? 'right' : 'left'};
  padding: 10px;
  width: ${({ width }) => width ? `${width}px !important` : 'auto'};
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: ${({ hideOnMobile, isDiv }) => hideOnMobile ? 'none' :  isDiv ? 'flex' : 'table-cell'};
  }
  svg {
    fill: ${COLORS.gray};
  }
`;

export const Td = styled.td`
  font-size: ${rem('12px')};
  font-family: ${FONTS.regular};
  color: ${COLORS.dark2};
  text-align: ${({ toRight }) => toRight ? 'right' : 'left'};
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
  width: ${({ width }) => width ? `${width}px !important` : 'auto'};
  ${({ isHour }) => isHour && css`font-variant-numeric: tabular-nums;`};
  display: table-cell;
  ${({ isDiv }) => isDiv ? css`display: flex; align-items: center` : css`display: table-cell`};
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: ${({ hideOnMobile, isDiv }) => hideOnMobile ? 'none' :  isDiv ? 'flex' : 'table-cell'};
  }
  ${({ numeric }) => numeric && css`font-variant-numeric: tabular-nums;`};
  &:last-of-type {
    text-align: right;
  }
`;

export const Tbl = styled.table`
  width: 100%;
  border-spacing: 0;
  min-width: 400px;
`;

export const Tr = styled(motion.tr)`
  ${({ isError }) => isError && css`
    background-color: rgba(255, 0, 0, 0.10);
    ${Td} {
      border-bottom: 1px solid rgba(255, 0, 0, 0.10);
    }
  `}
`;

export const THead = styled.thead`
  ${Tr} {
    ${Th} {
      border-bottom: 1px solid ${COLORS.lightGray};
      &:last-of-type {
        text-align: right;
      }
    }
  }
`;

export const TBody = styled.tbody`
  overflow: auto;
  ${Tr} {
    &:last-of-type {
      ${Td} {
        border: none;
      }
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 2px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    transition: fill .2s ease;
    fill: ${({ initColor }) => initColor ? initColor : COLORS.textGray};
  }
  &:hover {
    svg {
      fill: ${({ color }) => color ? color : COLORS.dark};
    }
  };
`;

export const NoRecords = styled.div`
  text-align: center;
  padding: 15px 25px;
  font-family: ${FONTS.regular};
  color: ${COLORS.dark};
  width: 100%;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
