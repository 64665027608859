import React, { useEffect, useState } from 'react';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import BreaksForm from 'components/Tasks/BreaksForm';
import { Wrapper, OuterWrapper } from './styles';
import { FormButtonsWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import TasksService from 'services/TasksService';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoBold } from 'components/Tasks/InfoBanner/styles';
import { InfoOuterWrapper } from '../TaskForm/styles';

const ThirdFormStep = ({ editable, goToPrevStep, goToNextStep, task }) => {
  const [scheduleBreaks, setScheduleBreaks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBreaks(), []);

  const getBreaks = async () => {
    try {
      setLoading(true);
      const res = await TasksService.getScheduleBreaks(task.id);
      setLoading(false);
      setScheduleBreaks(res.data._embedded.item);
    } catch (err) {
      setLoading(false);
    }
  };

  const updateBreaks = async () => {
    try {
      setFormSubmitting(true);
      await TasksService.updateScheduleBreaks(task.id, { scheduleBreaks });
      setFormSubmitting(false);
      goToNextStep();
    } catch (err) {
      setFormSubmitting(false);
    }
  };

  return (
    <>
      {loading
        ? <PageLoader/>
        : (
          <Fade>
            <OuterWrapper>
              <Wrapper>
                <Header size={16}>Przerwy w harmonogramie</Header>
                <Subtitle>Formularz dodawania przerw w wybranym harmonogramie.</Subtitle>
                <InfoOuterWrapper>
                  <InfoBanner>Ilość ton do wbudowania: <InfoBold>{task && task.plannedDailyAmount}</InfoBold></InfoBanner>
                </InfoOuterWrapper>
                <BreaksForm
                  editable={editable}
                  scheduleBreaks={scheduleBreaks}
                  setScheduleBreaks={setScheduleBreaks}
                  formSubmitting={formSubmitting}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                />
              </Wrapper>
              <FormButtonsWrapper>
                <Button onClick={goToPrevStep} leftIcon={<BsArrowLeftShort />} secondary>Wstecz</Button>
                <Button onClick={editable ? updateBreaks : goToNextStep} isLoading={formSubmitting} rightIcon={<BsArrowRightShort />}>Dalej</Button>
              </FormButtonsWrapper>
            </OuterWrapper>
          </Fade>
        )
      }
    </>
  );
};

export default ThirdFormStep;
