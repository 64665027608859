import styled from 'styled-components';
import { COLORS } from 'config/theme';

export const StatusDot = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 7px 10px;
  background-color: ${({ color }) => color ? color : COLORS.textGray2};
`;
