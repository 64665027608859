import React from 'react';
import { ClientScreenWrapper, ClientContent } from './styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoutes } from 'router/routes';

const Client = () => {
  return (
    <ClientScreenWrapper>
      <ClientContent>
        <Switch>
          {clientRoutes.map(route => (
            <Route exact={route.exact} key={route.name} path={route.path} component={route.component} />
          ))}
          <Redirect to={'/client/not-found'} />
        </Switch>
      </ClientContent>
    </ClientScreenWrapper>
  );
};

export default Client;
