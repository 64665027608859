import React, {useEffect, useState} from 'react';
import PageLoader from 'components/PageLoader';
import CarrierForm from 'components/Carriers/CarrierForm';
import CarriersService from 'services/CarriersService';
import { displayToast } from 'utils/toast';
import history from 'config/history';
import { useParams } from 'react-router-dom';
import { prepareErrors } from 'utils/form';

const CarrierManagement = () => {
  const { carrier_id } = useParams();
  const [carrierLoading, setCarrierLoading] = useState(false);
  const [carrier, setCarrier] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (carrier_id && carrier_id !== 'create') {
      getCarrier(carrier_id);
    } else {
      setCarrier(null);
    }

    return () => setCarrier(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrier_id]);

  const getCarrier = async id => {
    try {
      setCarrierLoading(true);
      const res = await CarriersService.getCarrier(id);
      setCarrier(res.data);
      setCarrierLoading(false);
    } catch (err) {
      setCarrierLoading(false);
    }
  };

  const createCarrier = async form => {
    try {
      setFormSubmitting(true);
      await CarriersService.createCarrier(form);
      displayToast('success', 'Poprawnie dodano przewoźnika!');
      history.push('/panel/carriers');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateCarrier = async (id, form) => {
    try {
      setFormSubmitting(true);
      await CarriersService.updateCarrier(id, form);
      displayToast('success', 'Dane przewoźnika zostały zaktualizowane!');
      history.push('/panel/carriers');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <>
      {carrierLoading
        ? <PageLoader />
        : <CarrierForm
            carrier={carrier}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            formSubmitting={formSubmitting}
            onSubmit={carrier ? (id, form) => updateCarrier(id, form) : form => createCarrier(form)}
        />
      }
    </>
  );
};

export default CarrierManagement;
