import React, { useState } from 'react';
import { ActionsDropdown, ActionsWrapper, ActionButton, Icon, DropdownButton } from './styles';
import { BsThreeDots, BsCheck2 } from 'react-icons/bs';

const Actions = ({ actions }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const allActionsPrevented = actions && actions.every(action => !action.visible);

  return (
    <React.Fragment>
      {actions && actions.length > 0 && !allActionsPrevented && (
        <ActionsDropdown>
          <DropdownButton type="button" onClick={() => setDropdownVisible(!dropdownVisible)}>
            <BsThreeDots />
          </DropdownButton>
          {dropdownVisible && (
            <ActionsWrapper visible={dropdownVisible}>
              {actions && actions.map((action, i) => {
                return (
                  <React.Fragment key={i}>
                    <ActionButton
                      type="button"
                      onClick={() => {
                        setDropdownVisible(false);
                        action.callback();
                      }}>
                        <Icon><BsCheck2 size={16}/></Icon>
                        {action.name}
                    </ActionButton>
                  </React.Fragment>
                );
              })}
            </ActionsWrapper>
          )}
        </ActionsDropdown>
      )}
    </React.Fragment>
  );
};
export default Actions;
