import React, {useState, useEffect, useContext} from 'react';
import { AnimatePresence } from 'framer-motion';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { FlexWrapper } from 'components/__styled/Layout';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import Button from 'components/__common/Button';
import { IoMdAdd } from 'react-icons/io';
import {FilterContent, FilterWrapper, TableBox} from 'components/__styled/Table';
import ConfirmModalContext from 'context/ConfirmModalContext';
import history from 'config/history';
import { FaRegEdit } from 'react-icons/fa';
import { VscTrash } from 'react-icons/vsc';
import { COLORS } from 'config/theme';
import Pagination from 'components/Pagination';
import SelectFilter from "components/SelectFilter";
import ContractsService from "services/ContractsService";
import FriSettlementService from "services/FriSettlementService";
import FriSettlementTable from 'components/FriSettlement/FriSettlementTable';
import {MdManageSearch} from "react-icons/md";
import {scheduleStatuses} from "../../../config/statuses";
import {ROLES} from "../../../config/roles";
import UserContext from "../../../context/UserContext";

const FriSettlementList = () => {
    const { hasRoleAccess, hasManageAccess } = useContext(UserContext);
    const {openModal} = React.useContext(ConfirmModalContext);
    const [contracts, setContracts] = useState([]);
    const [filters, setFilters] = useState({
        contract: null,
    });
    const [friSettlements, setFriSettlement] = useState([]);
    const [friSettlementLoading, setFriSettlementLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems: 0,
        perPage: 10,
        page: 1,
    });

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFriSettlements(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const getContracts = async () => {
        try {
            const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
            const options = res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id }));
            setContracts(options);
        } catch (err) {
            console.error(err);
        }
    };

    const handleFilterChange = (name, value) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const handlePaginationChange = page => {
        setPagination({...pagination, page});
        getFriSettlements({...pagination, page});
    };

    const goToFriSettlementEdit = id => {
        history.push(`/panel/fri-settlements/${id}`);
    };

    const goToCreateFriSettlement = () => {
        history.push('/panel/fri-settlements/create');
    };

    const actions = [
        row => ({
            label: 'Podgląd danych',
            icon: <MdManageSearch size={22} />,
            visible: (
                hasRoleAccess(ROLES.ROLE_ADMIN) ||
                hasRoleAccess(ROLES.ROLE_LOGISTIC) ||
                hasRoleAccess(ROLES.ROLE_MANAGER)
            ),
            event: id => goToFriSettlementEdit(id),
        }),
    ];

    const getFriSettlements = async pagination => {
        try {
            setFriSettlementLoading(true);
            let options = {
                itemsPerPage: pagination.perPage,
                page: pagination.page,
            };
            if (filters && filters.contract) options.contractId = filters.contract.value;
            const res = await FriSettlementService.getFriSettlements(options);
            setFriSettlement(res.data._embedded ? res.data._embedded.item : []);
            setPagination({...pagination, totalItems: res.data.totalItems, page: pagination.page});
            setFriSettlementLoading(false);
        } catch (err) {
            setFriSettlementLoading(false);
            console.error(err);
        }
    };

    return (
        <AnimatePresence exitBeforeEnter>
            {friSettlementLoading
                ? <PageLoader/>
                : (
                    <Fade>
                        <FlexWrapper justify={'space-between'}>
                            <div>
                                <Header size={23}>Rozliczenia FRI</Header>
                                <Subtitle>Lista utworzonych rozliczeń.</Subtitle>
                            </div>
                            <Button zoom onClick={goToCreateFriSettlement} leftIcon={<IoMdAdd/>}>Nowe rozliczenie</Button>
                        </FlexWrapper>
                        <TableBox>
                            <FilterWrapper>
                                <FilterContent>
                                    <SelectFilter label={'Kontrakt'} value={filters.contract} onChange={option => handleFilterChange('contract', option)} options={contracts} />
                                </FilterContent>
                            </FilterWrapper>
                            <FriSettlementTable
                                friSettlements={friSettlements}
                                pagination={pagination}
                                actions={actions}
                            />
                            <Pagination
                                onChange={page => handlePaginationChange(page)}
                                totalItems={pagination.totalItems}
                                perPage={pagination.perPage}
                                page={pagination.page}
                            />
                        </TableBox>
                    </Fade>
                )
            }
        </AnimatePresence>
    );
};

export default FriSettlementList;