import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';
import { ShadowBox } from 'components/__styled/ShadowBox';

export const ProgressBox = styled(ShadowBox)`
  margin-right: 20px;
  min-width: 250px;
  @media (max-width: ${BREAKPOINTS.large}) {
    min-width: 100%;
    display: flex;
    margin-bottom: 30px;
    margin-right: 0;
    justify-content: space-between;
    padding: 20px;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    justify-content: flex-start;
  }
`;
