import React, {useRef, useState, useEffect, useContext} from 'react';
import Header from 'components/__common/Header';
import { BsTelephone } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import { FlexWrapper } from 'components/__styled/Layout';
import ReactTooltip from 'react-tooltip';
import {
  CarriersBox,
  CarriersContent,
  ContactWrapper,
  CarriersItem,
  ContactItem,
  ContactItemValue,
  CarsAmountItem,
  CarsAmountItemValue,
  BottomVignette,
  CarsAmount,
  HeaderWrapper,
} from './styles';
import StatusChip from 'components/Tasks/StatusChip';
import {
  TooltipInfoRow,
  TooltipInfo,
  TooltipInfoBold,
} from 'components/__styled/Tolltip';
import { detailedScheduleStatuses } from 'config/statuses';
import Actions from 'components/Carriers/Actions';
import TasksService from 'services/TasksService';
import { useParams } from 'react-router-dom';
import ConfirmModalContext from 'context/ConfirmModalContext';
import TaskContext from 'context/TaskContext';
import { displayToast } from 'utils/toast';
import RejectDescription from 'components/Tasks/RejectDescription';

const TaskCarriers = ({ carriers, getCarriers, getTask }) => {
  const carriersBoxRef = useRef(null);
  const { task_id } = useParams();
  const { setTaskLoading } = useContext(TaskContext);
  const { openModal } = useContext(ConfirmModalContext);
  const [carriersBoxOffsetTop, setCarriersOffsetTop] = useState('100%');

  useEffect(() => {
    const offset = carriersBoxRef.current.offsetTop;
    setCarriersOffsetTop(offset);
  }, []);

  const acceptSchedulePlanAsLogistic = carrier_id => {
    const callback = async () => {
      try {
        setTaskLoading(true);
        await TasksService.acceptScheduleAsLogistic(task_id, carrier_id);
        await getCarriers(task_id);
        await getTask(task_id);
        setTaskLoading(false);
        displayToast('success', 'Zaakceptowano pojazdy dla wybranego przewoźnika!');
      } catch (err) {
        setTaskLoading(false);
        displayToast('error', 'Wystąpił problem z akceptacją przewoźnika!');
      }
    }

    openModal(
      'Czy na pewno chcesz potwierdzić harmonogram za przewoźnika?',
      'Operacja jest nieodwracalna.',
      callback,
    );
  };

  const editedBy = userType => {
    switch (userType) {
      case 'CARRIER':
        return ' przewoźnika'
      case 'LOGISTIC':
        return ' logistyka'
      default:
        return '';
    }
  };

  return (
    <CarriersBox height={`calc(100vh - ${carriersBoxOffsetTop}px - 50px)`} ref={carriersBoxRef}>
      <CarriersContent>
        {carriers && carriers.map(carrier => {
          const actions = [
            {
              name: 'Potwierdź pojazdy',
              callback: () => acceptSchedulePlanAsLogistic(carrier.id),
              visible: carrier.status.length && (carrier.status !== 'LOGISTIC_ACCEPTED' && carrier.status !== 'CARRIER_ACCEPTED')
            }
          ];

          return (
            <CarriersItem key={carrier.id}>
              <Actions actions={actions}/>
              <HeaderWrapper>
                <Header size={13}>{carrier.name}</Header>
                <CarsAmount>
                  <ReactTooltip className="tooltip" effect="solid" />
                  <CarsAmountItem>
                    <CarsAmountItemValue data-tip="Wszystkie auta">{carrier.cars}</CarsAmountItemValue>
                  </CarsAmountItem>
                  <CarsAmountItem>
                    <CarsAmountItemValue data-tip="Dostępne auta" available>{carrier.availableCars}</CarsAmountItemValue>
                  </CarsAmountItem>
                </CarsAmount>
              </HeaderWrapper>
              <ContactWrapper>
                <ContactItem href={`tel:${carrier.phone}`}>
                  <BsTelephone size={16}/>
                  <ContactItemValue>{carrier.phone}</ContactItemValue>
                </ContactItem>
                <ContactItem href={`mailto:${carrier.email}`}>
                  <GoMail size={18}/>
                  <ContactItemValue>{carrier.email}</ContactItemValue>
                </ContactItem>
              </ContactWrapper>
              {carrier.status && carrier.status.length > 0 && (
                <FlexWrapper>
                  {carrier.status !== 'NONE' && (
                    <ReactTooltip id="editInfo" className="tooltip" effect="solid">
                      <TooltipInfoRow>
                        <TooltipInfo>Edytowano:</TooltipInfo>
                        <TooltipInfoBold>{carrier.statusUpdatedAt}</TooltipInfoBold>
                      </TooltipInfoRow>
                      <TooltipInfoRow>
                        <TooltipInfo>Przez:</TooltipInfo>
                        <TooltipInfoBold>{editedBy(carrier.statusUpdatedBy)}</TooltipInfoBold>
                      </TooltipInfoRow>
                    </ReactTooltip>
                  )}
                  <FlexWrapper>
                    <div
                      data-tip
                      data-for="editInfo"
                    >
                      <StatusChip bgColor={detailedScheduleStatuses[carrier.status].color}>
                        {detailedScheduleStatuses[carrier.status].name}
                      </StatusChip>
                    </div>
                    <RejectDescription
                      rejectText={carrier.description}
                      status={carrier.status}
                    />
                  </FlexWrapper>
                </FlexWrapper>
              )}
            </CarriersItem>
          );
        })}
      </CarriersContent>
      <BottomVignette />
    </CarriersBox>
  );
};

export default TaskCarriers;
