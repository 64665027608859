import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from 'config/theme';

export const Bar = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 0 40px;
  border-bottom: 1px solid ${COLORS.lightGray};
  background-color: ${COLORS.white};
  height: 65px;
  @media (max-width: ${BREAKPOINTS.large}) {
    height: 55px;
    display: block;
    padding: 0 20px 0 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
`;

export const MenuButton = styled.button`
  outline: none;
  width: 55px;
  height: 54px;
  margin-left: -22px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${COLORS.dark};
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin-left: 0;
  }
  &:hover {
    svg {
      fill: ${COLORS.main};
    }
  }
`;

export const Logo = styled.img`
  max-width: 100px;
  margin-left: 10px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.large}) {
    display: block;
  }
`;
