import React, { useState, useEffect } from 'react';
import DriversTimetableForm from 'components/DriversTimetable/DriversTimetableForm';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import ContractsService from 'services/ContractsService';
import { displayToast } from 'utils/toast';
import PageLoader from 'components/PageLoader';

const DriversTimetable = () => {
  const [timetableGenerating, setTimetableGenerating] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => getContracts(), []);

  const generateTimetable = async params => {
    try {
      setTimetableGenerating(true);
      const res = await ContractsService.generateDriversTimetable(params);
      const fileName = res.headers['content-disposition'].split('"')[1];
      const blob = new Blob([res.data], {
        type: 'application/pdf'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      setTimetableGenerating(false);
      link.click();
    } catch (err) {
      setTimetableGenerating(false);
      if (err.response.status === 404) {
        displayToast('error', 'Nie utworzono kursów dla kierowców w wybranym dniu!');
      }
      if (err.response.status === 422) {
        displayToast('error', 'Wybierz kontrakt!');
      }
    }
  };

  const getContracts = async () => {
    try {
      setLoading(true);
      const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
      const options = res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id }));
      setLoading(false);
      setContracts(options);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <AnimatePresence>
          <Fade>
            <Header size={23}>
              Rozkład jazdy kierowców
            </Header>
            <Subtitle>Wygeneruj rozkład jazdy z podziałem na kierowców dla danego dnia</Subtitle>
            <DriversTimetableForm
              generateTimetable={generateTimetable}
              timetableGenerating={timetableGenerating}
              contracts={contracts}
            />
          </Fade>
        </AnimatePresence>
      )}
    </>
  );
};

export default DriversTimetable;
