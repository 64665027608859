import { axios } from 'api';
import { clientAxios } from 'api/clientApi';

const TasksService = {
  saveFirstTaskStep: form => axios.post(`/delivery-schedule/schedules`, form),
  updateFirstTaskStep: (id, form) => axios.patch(`/delivery-schedule/schedules/${id}`, form),
  saveSecondTaskStep: (id, form) => axios.patch(`/delivery-schedule/schedules/${id}/times`, form),
  saveFinalTaskStep: id => axios.patch(`/delivery-schedule/schedules/${id}/confirm`),
  getTasks: params => axios.get(
    `/delivery-schedule/schedules`,
    { params },
    { headers: {'Accept': 'application/hal+json'}
  }),
  getTask: id => axios.get(`/delivery-schedule/schedules/${id}`),
  getCarrierTask: (task_id, carrier_id) => clientAxios.get(`/delivery-schedule/schedules/${task_id}/carrier/${carrier_id}`),
  getTaskForExternals: secret => clientAxios.get(`/delivery-schedule/schedules/external/_default_?secret=${secret}`),
  removeTask: id => axios.delete(`/delivery-schedule/schedules/${id}`),
  cancelTask: (id, reason) => axios.patch(`/delivery-schedule/schedules/${id}/cancel`, { reason }),
  getCarriers: (id, params) => axios.get(`/delivery-schedule/schedules/${id}/carriers`, {
    params,
  }),
  getCarrierCars: (id, params) => axios.get(`/delivery-schedule/carriers/${id}/cars`, { params }),
  saveScheduleWithCarries: (id, list) => axios.patch(`/delivery-schedule/schedules/${id}/courses`, list),
  saveCarsAmount: (id, availableCars) => axios.patch(`/delivery-schedule/schedules/${id}/available-cars`, availableCars),
  saveDistance: (id, distance) => axios.patch(`/delivery-schedule/schedules/${id}/distance`, distance),
  removeCourse: id => axios.delete(`/delivery-schedule/courses/${id}`),
  confirmScheduleAsLogistic: (task_id, list) => axios.patch(
    `/delivery-schedule/schedules/${task_id}/confirm-logistic`,
    list,
    { headers: {'Accept': 'application/hal+json'}
  }),
  confirmRejectScheduleAsCarrier: (secret, list) => clientAxios.patch(`/delivery-schedule/schedules/external/_default_?secret=${secret}`, list),
  acceptScheduleAsLogistic: (task_id, carrier_id) => axios.patch(`/delivery-schedule/schedules/${task_id}/carrier/${carrier_id}/logistic-accept`),
  rejectScheduleAsCarrier: (task_id, carrier_id, description) => clientAxios.patch(`/delivery-schedule/schedules/${task_id}/carrier/${carrier_id}/reject`, description),
  restoreCourses: id => axios.patch(`/delivery-schedule/schedules/${id}/restore`),
  downloadScheduleReport: id => axios.get(`/delivery-schedule/schedules/${id}/report`, { responseType: 'blob' }),
  getScheduleForFactory: id => axios.get(`/delivery-schedule/schedules/${id}/factory`),
  acceptOrRejectScheduleByFactory: (secret, form) => clientAxios.patch(`/delivery-schedule/schedules/external/_default_?secret=${secret}`, form),
  getCarrierCarsByCarrier: (id, secret) => clientAxios.get(`/delivery-schedule/carriers/${id}/cars?secret=${secret}`),
  suggestCarriers: id => axios.get(`/delivery-schedule/schedules/${id}/suggest-queue`),
  updateDevicePosition: (id, coords) => axios.patch(`/delivery-schedule/contracts/${id}/update-unloading-location`, coords),
  getScheduleBreaks: (id) => axios.get(`/delivery-schedule/schedules/${id}/breaks`),
  updateScheduleBreaks: (id, form) => axios.patch(`/delivery-schedule/schedules/${id}/breaks`, form),
  copySchedule: (id) => axios.patch(`/delivery-schedule/schedules/${id}/copy`),
  getSubcategories: () => axios.get(`/delivery-schedule/schedules/subcategories`),
  addVehicleIntoSchedule: (id, form) => axios.patch(`/delivery-schedule/schedules/${id}/add-car`, form)
};

export default TasksService;
