import { axios } from 'api';

const DictionariesService = {
  getDictionaries: params => axios.get(`/delivery-schedule/dictionaries`, { params: { ...params }}),
  getDictionary: id => axios.get(`/delivery-schedule/dictionaries/${id}`),
  getDictionaryTypes: () => axios.get(`/delivery-schedule/dictionaries/types`),
  createDictionary: form => axios.post(`/delivery-schedule/dictionaries`, form),
  removeDictionary: id => axios.delete(`/delivery-schedule/dictionaries/${id}`),
  updateDictionary: (id, form) => axios.put(`/delivery-schedule/dictionaries/${id}`, form),
  getSubcategories: () => axios.get(`/delivery-schedule/dictionaries/subcategories`),
};

export default DictionariesService;
