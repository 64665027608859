import { COLORS } from 'config/theme';

export const checkIsNewSchedule = stages => {
  if (!stages) {
    return false;
  }
  return stages.every(stage => stage.stage === 'NONE');
};

export const scheduleStatuses = {
  CANCELED: { slug: 'CANCELED', color: COLORS.white, name: 'Anulowany', bgColor: COLORS.errorRed },
  LOGISTIC_CONFIRMED: { slug: 'LOGISTIC_CONFIRMED', name: 'Potwierdzony przez logistyka', bgColor: COLORS.main, color: COLORS.white },
  CONFIRMED: { slug: 'CONFIRMED', name: 'Potwierdzony', bgColor: COLORS.green, color: COLORS.white },
  NEW: { slug: 'NEW', name: 'Nowy', bgColor: COLORS.textGray2, color: COLORS.dark },
  ACCEPTED: { slug: 'ACCEPTED', name: 'Zaakceptowany', bgColor: COLORS.green, color: COLORS.white },
  REJECTED: { slug: 'REJECTED', name: 'Odrzucony', bgColor: COLORS.errorRed, color: COLORS.white },
  TIMES: { slug: 'TIMES', name: 'Ustalono czasy', bgColor: COLORS.main, color: COLORS.white },
};

export const detailedScheduleStatuses = {
  CANCELED: { slug: 'CANCELED', color: COLORS.errorRed, name: 'Anulowany' },
  NEW: { slug: 'NEW', color: COLORS.textGray2, name: 'Nowe' },
  CONFIRMED: { slug: 'CONFIRMED', color: COLORS.green, name: 'Przygotowano przez kierownika' },
  SYSTEM_ACCEPTED: { slug: 'SYSTEM_ACCEPTED', color: COLORS.green, name: 'Zaakceptowane przez system' },
  LOGISTIC_CONFIRMED: { slug: 'LOGISTIC_CONFIRMED', color: COLORS.main, name: 'Oczekuje na przewoźnika' },
  LOGISTIC_EDIT: { slug: 'LOGISTIC_EDIT', color: COLORS.main, name: 'W trakcie modyfikacji przez logistyka'},
  LOGISTIC_ACCEPTED: { slug: 'LOGISTIC_ACCEPTED', color: COLORS.green, name: 'Zaakceptowano przez logistyka' },
  CARRIER_ACCEPTED: { slug: 'CARRIER_ACCEPTED', color: COLORS.green, name: 'Zaakceptowano przez przewoźnika' },
  CARRIER_REJECTED_PARTIAL: { slug: 'CARRIER_REJECTED_PARTIAL', color: COLORS.errorRed, name: 'Odrzucono częściowo' },
  CARRIER_ACCEPTED_PARTIAL: { slug: 'CARRIER_ACCEPTED_PARTIAL', color: COLORS.main, name: 'Zaakceptowane częściowo' },
  CARRIER_REJECTED: { slug: 'CARRIER_REJECTED', color: COLORS.errorRed, name: 'Odrzucono przez przewoźnika' },
  LOGISTIC_REJECTED: { slug: 'LOGISTIC_REJECTED', color: COLORS.errorRed, name: 'Odrzucono przez logistyka' },
  FACTORY_REJECTED: { slug: 'FACTORY_REJECTED', color: COLORS.errorRed, name: 'Odrzucono przez wytwórnię' },
  FACTORY_ACCEPTED: { slug: 'FACTORY_ACCEPTED', color: COLORS.green, name: 'Zaakceptowano przez wytwórnię' },
};

export const coursesStatuses = {
  NEW: { color: COLORS.textGray2, name: 'Nowy' },
  LOGISTIC_CONFIRMED: { color: COLORS.main, name: 'Oczekujący' },
  ACCEPTED: { color: COLORS.green, name: 'Zaakceptowany' },
  REJECTED: { color: COLORS.errorRed, name: 'Odrzucony' },
};

export const scheduleStatusColors = {
  DONE: { bgColor: COLORS.green, textColor: COLORS.white },
  WAIT: { bgColor: COLORS.main, textColor: COLORS.white },
  REJECT: { bgColor: COLORS.errorRed, textColor: COLORS.white },
  NONE: { bgColor: COLORS.textGray2, textColor: COLORS.dark },
};

export const scheduleStageNames = {
  FACTORY: 'Wytwórnia',
  LOGISTIC: 'Harmonogram',
  CARRIER: 'Przewoźnicy',
  REALIZATION: 'Realizacja',
};

export const statusesLegend = {
  FACTORY: {
    name: 'Wytwórnia',
    statuses: {
      DONE: { color: COLORS.green, name: 'wytwórnia potwierdziła zlecenie' },
      WAIT: { color: COLORS.main, name: 'zlecenie wstępnie zatwierdzone przez wytwórnię' },
      REJECT: { color: COLORS.errorRed, name: 'wytwórnia odrzuciła zlecenie' },
      NONE: { color: COLORS.textGray2, name: 'oczekiwanie na zlecenie przez wytwórnię' },
    }
  },
  LOGISTIC: {
    name: 'Harmonogram',
    statuses: {
      DONE: { color: COLORS.green, name: 'przypisanie przewoźników / pojazdów wykonane i przesłane do przewoźników' },
      WAIT: { color: COLORS.main, name: 'przypisanie przewoźników / pojazdów wykonane i oczekuje na przesłanie do przewoźników' },
      REJECT: { color: COLORS.errorRed, name: 'brak przypisania przewoźników / pojazdów, a wytwórnia potwierdziła zlecenie' },
      NONE: { color: COLORS.textGray2, name: 'brak przypisania przewoźników / pojazdów, a wytwórnia jeszcze nie potwierdziła zlecenia' },
    }
  },
  CARRIER: {
    name: 'Przewoźnik',
    statuses: {
      DONE: { color: COLORS.green, name: 'zatwierdzone przez wszystkich przewoźników' },
      WAIT: { color: COLORS.main, name: 'oczekuje na akceptację przewoźników, wszystkich lub jednego (zaakceptowało / odrzuciło / oczekuje)' },
      REJECT: { color: COLORS.errorRed, name: 'odrzucenie zlecenie przez przynajmniej jednego przewoźnika' },
      NONE: { color: COLORS.textGray2, name: 'brak przypisania przewoźników' },
    }
  },
  REALIZATION: {
    name: 'Realizacja',
    statuses: {
      DONE: { color: COLORS.green, name: 'realizacja została ukończona według harmonogramu' },
      WAIT: { color: COLORS.main, name: 'w trakcie realizacji harmonogramu' },
      NONE: { color: COLORS.textGray2, name: 'oczekuje na realizację' },
    }
  }
};

export const friSettlementStatusColors = {
  DRAFT: { name: "Robocze", color: COLORS.textGray },
  CONFIRMED: { name: "Zatwierdzone", color: COLORS.main },
  INVOICED: { name: "Zafakturowane", color: COLORS.green },
};
