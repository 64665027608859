import { axios } from 'api';

const ContractsService = {
  getContracts: params => axios.get(`/delivery-schedule/contracts`, { params: { ...params }, headers: {'Accept': 'application/hal+json'} }),
  getContract: id => axios.get(`/delivery-schedule/contracts/${id}`),
  createContract: form => axios.post(`/delivery-schedule/contracts`, form),
  updateContract: (id, form) => axios.patch(`/delivery-schedule/contracts/${id}`, form),
  getContractRates: id => axios.get(`/delivery-schedule/contracts/${id}/rates`),
  getSingleRatesGroup: (contractId, rateId) => axios.get(`/delivery-schedule/contracts/${contractId}/rates/${rateId}`),
  createContractRates: (id, form) => axios.post(`/delivery-schedule/contracts/${id}/rates`, form),
  updateContractRates: (contractId, rateId, form) => axios.patch(`/delivery-schedule/contracts/${contractId}/rates/${rateId}`, form),
  removeSingleRatesGroup: (contractId, rateId) => axios.delete(`/delivery-schedule/contracts/${contractId}/rates/${rateId}`),
  generateReportForContract: params => axios.get(`/delivery-schedule/report`, { responseType: 'blob', params: { ...params }} ),
  recalcReportForContract: params => axios.get(`/delivery-schedule/report-recalculate`,{params: { ...params }}),
  getRatesByDate: (id, dateFrom) => axios.get(`/delivery-schedule/contracts/${id}/rates-by-date/${dateFrom}`),
  generateDriversTimetable: params => axios.get(`/delivery-schedule/driver-plan`, { responseType: 'blob', params: { ...params }} ),
  getRecipesRates: (contractId) => axios.get(`/delivery-schedule/contracts/${contractId}/recipe-rates`),
  createRecipeRate: (contractId, recipeId, form) => axios.post(`/delivery-schedule/contracts/${contractId}/recipe-rates/${recipeId}/ranges`, form),
  updateRecipeRate: (contractId, rateId, rangeId, form) => axios.patch(`/delivery-schedule/contracts/${contractId}/recipe-rates/${rateId}/ranges/${rangeId}`, form),
  updateWork: (contractId, form) => axios.patch(`delivery-schedule/contracts/${contractId}/update-works`, form),
  getCarsPositions: id => axios.get(`/delivery-schedule/contracts/${id}/cars`),
  getScheduleSummary: id => axios.get(`/delivery-schedule/contracts/${id}/today-summary`),
  getContractVehicles: (contractId, params) => axios.get(`/delivery-schedule/contracts/${contractId}/cars-inventory`, { params: { ...params } }),
  getContractDevices: (contractId, params) => axios.get(`/delivery-schedule/contracts/${contractId}/devices`, { params: { ...params } }),
  setDeviceForVehicle: (contractId, form) => axios.patch(`/delivery-schedule/contracts/${contractId}/cars-inventory`, form),
  getContractCarriers: id => axios.get(`/delivery-schedule/contracts/${id}/carriers`),
};

export default ContractsService;
