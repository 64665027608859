import React from 'react';
import {
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  Tbl,
  TblWrapper,
  TBody,
  Td,
  Th,
  THead,
  Tr
} from 'components/__styled/Table';
import TinyStatuses from '../TinyStatuses';
import ReactTooltip from 'react-tooltip';
import { scheduleStatuses } from 'config/statuses';
import StatusChip from 'components/Tasks/StatusChip';
import { COLORS } from 'config/theme';

const TasksTable = ({ tasks, tasksLoading, actions, pagination }) => {

  return (
    <TblWrapper loading={tasksLoading}>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Data</Th>
            <Th>Kontrakt</Th>
            <Th>Ton/dzień</Th>
            <Th>Warstwa drogi</Th>
            <Th>Start budowy</Th>
            <Th>Status</Th>
            <Th>Akcje</Th>
          </Tr>
        </THead>
        <TBody>
          {tasks && tasks.length > 0 ?
            tasks.map((row, i) => {
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td numeric>{row.date}</Td>
                  <Td>{row._embedded && row._embedded.contract.name}</Td>
                  <Td numeric>{row.plannedDailyAmount}</Td>
                  <Td>{row.roadLayer}</Td>
                  <Td numeric>{row.startOfUnloadingCar.substr(0, 5)}</Td>
                  <Td>
                    {row.status === scheduleStatuses.CANCELED.slug ? (
                      <StatusChip bgColor={COLORS.dark} color={COLORS.white} data-tip={row.cancelationReason}>Anulowany</StatusChip>
                    ) : (
                      <TinyStatuses statuses={row._embedded?.stages} scheduleId={row.id}/>
                    )}
                  </Td>
                  <Td>
                    {row.status === scheduleStatuses.CANCELED.slug ? (
                      <div />
                    ) : (
                      <ActionButtonWrapper>
                        {actions.map((actionFn, i) => {
                          const action = actionFn(row);
                          return (
                            <React.Fragment key={i}>
                              {action.visible ? (
                                <ActionButton
                                  data-tip={action.label}
                                  color={action.color}
                                  initColor={action.initColor}
                                  onClick={() => action.event(row.id)}
                                >
                                  {action.icon}
                                </ActionButton>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </ActionButtonWrapper>
                    )}
                  </Td>
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={7}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )
          }
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default TasksTable;
