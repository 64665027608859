import React from 'react';
import { Wrapper, Spinner } from './styles';

const PageLoader = () => {
  return (
    <Wrapper data-testid="page-loader">
      <Spinner />
    </Wrapper>
  );
};

export default PageLoader;
