import React, { useState, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { TableBox, FilterWrapper } from 'components/__styled/Table';
import SearchBar from 'components/SearchBar';
import Pagination from 'components/Pagination';
import { AnimatePresence } from 'framer-motion';
import ContractsService from 'services/ContractsService';
import ContractsTable from 'components/Contracts/ContractsTable';
import { FaRegEdit } from 'react-icons/fa';
import {FlexWrapper} from 'components/__styled/Layout';
import { IoMdAdd } from 'react-icons/io';
import Button from 'components/__common/Button';
import history from 'config/history';

const ContractsList = () => {
  const [contractsLoading, setContractsLoading] = useState(false);
  const [contacts, setContracts] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  useEffect(() => {
    getContracts(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => history.push(`/panel/contracts/${id}`),
    }),
  ];

  const getContracts = async (pagination, searchParam = search) => {
    try {
      setContractsLoading(true);

      let options = {
        itemsPerPage: pagination.perPage,
        page: pagination.page,
      };

      if (searchParam.length) options.name = searchParam;

      const res = await ContractsService.getContracts(options);
      setContracts(res.data._embedded?.item ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems });
      setContractsLoading(false);
    } catch (err) {
      setContractsLoading(false);
      console.error(err);
    }
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getContracts({ ...pagination, page });
  };

  const goToCreateContract = () => {
    history.push('/panel/contracts/create');
  };

  return (
    <AnimatePresence>
      {contractsLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-preview-content'>
              <FlexWrapper justify={'space-between'}>
                <div>
                  <Header size={23} beFlex>Kontrakty</Header>
                  <Subtitle>Lista utworzonych kontraktów w systemie</Subtitle>
                </div>
                <Button zoom onClick={goToCreateContract} leftIcon={<IoMdAdd />}>Nowy kontrakt</Button>
              </FlexWrapper>
              <TableBox>
                <FilterWrapper>
                  <SearchBar
                    placeholder={'Wyszukaj...'}
                    onSubmit={searchParam => getContracts(pagination, searchParam)}
                    onChange={setSearch}
                    value={search}
                  />
                </FilterWrapper>
                <ContractsTable
                  contracts={contacts}
                  pagination={pagination}
                  actions={actions}
                />
              </TableBox>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
}

export default ContractsList;
