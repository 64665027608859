import React, { useContext } from 'react';
import NavItem from 'components/NavItem';
import logo from 'assets/img/logo.png';
import {
  NavWrapper,
  NavContent,
  NavList,
  NavHeader,
  Logo,
  Vignette,
  CloseButton,
} from './styles';
import { IoDocumentsOutline } from 'react-icons/io5';
import { BsTruck } from 'react-icons/bs';
import { IoClose, IoNavigateCircleOutline, IoNotificationsOutline } from 'react-icons/io5';
import { AiOutlineSchedule, AiOutlineFileExcel, AiOutlineDollar  } from 'react-icons/ai';
import { TbSteeringWheel } from 'react-icons/tb';
import { GiWeightScale } from 'react-icons/gi';
import { BiBuildings } from 'react-icons/bi';
import { BsBook } from 'react-icons/bs';
import { ImLab } from 'react-icons/im';
import Topbar from 'components/Topbar';
import history from 'config/history';
import UserContext from 'context/UserContext';
import { ROLES } from 'config/roles';

const Nav = ({ navOpen, setNavOpen }) => {
  const { user } = useContext(UserContext);
  const navItems = [
    { label: 'Alerty',  to: '/panel/alerts', icon: <IoNotificationsOutline size={22} />, availableFor: [ROLES.ROLE_ADMIN,ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER] },
    { label: 'Śledzenie pojazdów',to: '/panel/map', icon: <IoNavigateCircleOutline size={22} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_VIEWER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER] },
    { label: 'Kontrakty',  to: '/panel/contracts', icon: <IoDocumentsOutline size={20} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC] },
    { label: 'Harmonogramy',  to: '/panel/tasks', icon: <AiOutlineSchedule size={20} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_VIEWER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER] },
    { label: 'Przewoźnicy', to: '/panel/carriers', icon: <BsTruck size={20} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER] },
    { label: 'Wytwórnie', to: '/panel/factories', icon: <BiBuildings size={20} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER] },
    { label: 'Laboratoria',  to: '/panel/laboratories', icon: <ImLab size={18} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER] },
    { label: 'Raporty', to: '/panel/reports', icon: <AiOutlineFileExcel size={21} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC] },
    { label: 'Rozliczenia FRI', to: '/panel/fri-settlements', icon: <AiOutlineDollar size={21} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC] },
    { label: 'Ważenia', to: '/panel/weightings', icon: <GiWeightScale size={21} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER] },
    { label: 'Rozkład jazdy', to: '/panel/drivers-timetable', icon: <TbSteeringWheel size={21} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER, ROLES.ROLE_LOGISTIC] },
    { label: 'Słownik', to: '/panel/dictionaries', icon: <BsBook size={21} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER] },
    { label: 'Pojazdy w kontraktach', to: '/panel/contract-cars', icon: <BsTruck size={20} />, availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER, ROLES.ROLE_LOGISTIC] },
  ];

  const renderNavItem = item => {
    const isAvailable = item.availableFor.some(el => user.roles.includes(el));
    if (!isAvailable) {
      return null;
    }

    return (
      <NavItem
        isActive={history.location.pathname.includes(item.to)}
        key={item.to}
        label={item.label}
        to={item.to}
        icon={item.icon}
      />
    );
  };

  return (
    <>
      <Topbar setNavOpen={() => setNavOpen(!navOpen)} />
      <NavWrapper navOpen={navOpen}>
        <NavHeader>
          <Logo src={logo}/>
          <CloseButton onClick={() => setNavOpen(false)}>
            <IoClose size={24}/>
          </CloseButton>
        </NavHeader>
        <NavContent>
          <NavList>
            {user && navItems.map(item => (
              renderNavItem(item)
            ))}
          </NavList>
        </NavContent>
      </NavWrapper>
      <Vignette onClick={() => setNavOpen(!false)} navOpen={navOpen} />
    </>
  );
};

export default Nav;
