import React, {useEffect, useState} from 'react';
import { InputWrapper, SystemInput, IconWrapper } from './styles';
import { FaRegEdit } from 'react-icons/fa';
import FormModal from "../FormModal";

const InputModal = ({
  disabled = false,
  type = 'text',
  onChange,
  handleChange,
  name,
  value,
  isError,
  small = false,
  placeholder = '',
  min = 0,
                        description = '',
                        inputLabel = ''
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        header: '',
        description: '',
        onSubmit: undefined,
        inputLabel: '',
    });
    const [inputValue, setInputValue] = useState(value);
    const [dataProcessing, setDataProcessing] = useState(false);
    const setupModal = content => {
        setModalContent(content);
        setModalVisible(true);
    };

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    const updateValue = async data => {
        const callback = async (form = null) => {
            setDataProcessing(true);
            setDataProcessing(false);
            console.log(form);
        }

        setInputValue(value);

        setupModal({
            header: 'Ustaw wartość',
            description: description,
            inputLabel: inputLabel,
            value: inputValue,
            callback: response => callback(response),
        });
    }

  return (
    <InputWrapper>
      <SystemInput
        min={min}
        small={small}
        data-testid={`input-${name}`}
        name={name}
        disabled={disabled}
        isError={isError}
        onChange={onChange}
        type={type}
        value={inputValue !== null ? inputValue : ''}
        placeholder={placeholder}
      />
      <IconWrapper>
        <FaRegEdit
            onClick={() => updateValue(value)}
        />
      </IconWrapper>
        <FormModal
            isVisible={modalVisible}
            closeModal={() => setModalVisible(false)}
            updateProcessing={dataProcessing}
            modalContent={modalContent}
            handleChange={handleChange}
            name={name}
            value={value}
            setInputValue = {setInputValue}
        />
    </InputWrapper>
  );
};

export default InputModal;
