import React from 'react';
import { Wrapper } from './styles';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import SubcategoryForm from 'components/Contracts/SubcategoryForm';

const Subcategories = ({ subcategories, setSubcategories, formErrors, setFormErrors, options }) => {
  return (
    <Wrapper>
      <Header size={14}>Podkategorie</Header>
      <Subtitle>Konfiguracja podkategorii i przypisanych kodów master.</Subtitle>
      <SubcategoryForm
        items={subcategories}
        options={options}
        setItems={setSubcategories}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </Wrapper>
  );
};

export default Subcategories;
