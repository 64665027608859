import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
  MapWrapper,
  MapFlexWrapper,
  MainWrapper,
  MapLegend,
  MapLegendItem,
  LegendItemColor,
  LegendItemValue,
  MapLegendHeader,
  MapLegendContent, MapOuterWrapper,
} from './styles';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker'
import 'leaflet/dist/leaflet.css';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import MapSummary from 'components/Map/MapSummary';
import ContractsService from 'services/ContractsService';
import { AnimatePresence } from 'framer-motion';
import PageLoader from '../../components/PageLoader';
import Fade from '../../components/Fade';
import LiveSummary from 'components/Map/LiveSummary';
import VehicleMarker from 'components/Map/VehicleMarker';
import PaverMarker from 'components/Map/PaverMarker';
import { COLORS } from 'config/theme';
import WMBMarker from 'components/Map/WMBMarker';
import H from "@here/maps-api-for-javascript";

const Map = () => {
  const [map, setMap] = useState(null);
  const [carsLoading, setCarsLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [currentContract, setCurrentContract] = useState(null);
  const [carsPositions, setCarsPositions] = useState([]);
  const [summary, setSummary] = useState(null);
  const [showRouteTime, setShowRouteTime] = useState(false);
  const [calculatedPosition, setCalculatedPosition] = useState(null);
  const platform = useRef(null);


  platform.current = new H.service.Platform({apikey: process.env.REACT_APP_HERE_API_KEY});

  function calculateSummary(route) {
    let duration = 0,
        distance = 0;

    route.sections.forEach((section) => {
      distance += section.travelSummary.length;
      duration += section.travelSummary.duration;
    });
    return Math.round(duration / 60);
  }

  useEffect(() => {
    setCarsPositions([]);
    setSummary(null);

    if (currentContract) {
      getData(currentContract.value, true);
    }

    const carsPositionsInterval = setInterval(() => {
      if (currentContract) {
        getData(currentContract.value, false);
      }
    }, 60000);

    return () => clearInterval(carsPositionsInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContract]);

  useEffect(() => {
    if (currentContract) {
      getData(currentContract.value, false);
    }
  }, [showRouteTime]);

  useEffect(() => {
    let contractId = 'init';
    if (currentContract && currentContract.value) {
      contractId = currentContract.value;
    }
    let bounds = [];
    carsPositions.forEach(m => {
      if (m.location) {
        bounds = [...bounds, [m.location.lat, m.location.lon]];
        if (m.type === 'PAVER') {
          savePaver(contractId, m.location);
        }
      }

    });

    if (summary?.geoZone) {
      bounds = [...bounds, [summary.geoZone.points[0].latitude, summary.geoZone.points[0].longitude]];
      saveWmb(contractId,{lat: summary.geoZone.points[0].latitude, lon: summary.geoZone.points[0].longitude});
    }

    if (map && bounds.length > 0) {
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, carsPositions, summary]);


  const updateRouteTime = async (contractId, m, showTime) => {
    if (showTime) {
      let destinationLocation;
      if (m.loadStatus === 'LOADED') {
        destinationLocation = getPaver(contractId);
      } else {
        destinationLocation = getWmb(contractId);
      }

      if (destinationLocation) {
        const router = platform.current.getRoutingService(null, 8),
            routeRequestParams = {
              routingMode: "short",
              transportMode: "truck",
              'vehicle[grossWeight]': 40000,
              'vehicle[height]': 300,
              origin: `${m.location.lat},${m.location.lon}`,
              destination: `${destinationLocation.lat},${destinationLocation.lon}`,
              return: "travelSummary",
            };

        router.calculateRoute(routeRequestParams, onSuccess, onError);

        function onSuccess(result) {
          var route = result.routes[0];
          m.time = calculateSummary(route);
          setCalculatedPosition(m);
          saveVehicle(contractId, m);
        }

        function onError(error) {
          console.log(error);
        }
      }
    } else {
      if (m.time !== 0) {
        m.time = 0;
        saveVehicle(contractId, m);
      }
    }
  };


  const renderMarker = (data) => {
    return data.type === 'CAR' ? <VehicleMarker data={data} /> : <PaverMarker data={data} />;
  };

  const getContracts = async () => {
    const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
    const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id })) : [];
    setContracts(options);
    setCurrentContract(options[0]);
    return options;
  };

  const getCarsPositions = async (id, withLoader = true) => {
    try {
      withLoader && setCarsLoading(true);
      const res = await ContractsService.getCarsPositions(id);
      let items = res.data._embedded.item;

      if (items.length) {
        items.forEach(m => {
          if (m.type !== 'PAVER') {
            calculateVehicleRoute(id, m);
          } else {
            savePaver(id, m.location);
          }
        });
      }
      setCarsPositions(items);
      withLoader && setCarsLoading(false);
    } catch (err) {
      withLoader && setCarsLoading(false);
    }
  };

  const calculateVehicleRoute = async (contractId, vehicle) => {
    let showTime = getShowRouteTime();
    let lastPosition = getVehicle(contractId ,vehicle.id);
    if (!lastPosition) {
      saveVehicle(contractId, vehicle);
      await updateRouteTime(contractId, vehicle, showTime);
    } else {
      if (showTime) {
        if (lastPosition.time) {
          vehicle.time = lastPosition.time ? lastPosition.time : 0;
        }
      } else {
        vehicle.time = 0;
      }

      if (!vehicle.time
          || lastPosition.location.lat !== vehicle.location.lat
          || lastPosition.location.lon !== vehicle.location.lon
          || lastPosition.loadStatus !== vehicle.loadStatus
      ) {
        await updateRouteTime(contractId, vehicle, showTime);
      }
    }
  }

  const getScheduleSummary = async (id, withLoader = true) => {
    try {
      withLoader && setSummaryLoading(true);
      const res = await ContractsService.getScheduleSummary(id);
      setSummary(res.data);
      withLoader && setSummaryLoading(false);
    } catch (err) {
      withLoader && setSummaryLoading(false);
    }
  };

  const getData = useCallback(async (id, withLoader) => {
    getCarsPositions(id, withLoader);
    getScheduleSummary(id, withLoader);
  }, []);

  useEffect(() => {
    const initialGet = async () => {
      const contracts = await getContracts();
      if (contracts.length > 0) {
        setCurrentContract(contracts[0]);
      } else {
        setCarsLoading(false);
        setSummaryLoading(false);
      }
    }

    initialGet();
  }, [getData]);

  const geoZone = summary?.geoZone?.points.map(point => {
    return [point.latitude, point.longitude];
  });

  const getStorageKey = (contractId, type = 'vehicle', id = null) => {
    let key;
    if (contractId) {
      key = contractId + '_';
      if (type === 'paver') {
        key += 'p';
      } else if (type === 'wmb') {
        key += 'w';
      } else {
        key += id;
      }
    } else {
      key = '';
    }

    return key;
  }

  const savePaver = (contractId, obj) => {
    if (contractId) {
      let key = getStorageKey(contractId, 'paver');
      localStorage.removeItem(key);
      localStorage.setItem(key, JSON.stringify(obj));
    }
  }

  const getPaver = (contractId) => {
    return JSON.parse(localStorage.getItem(getStorageKey(contractId, 'paver')));
  }

  const saveWmb = (contractId, obj) => {
    if (contractId) {
      let index = getStorageKey(contractId, 'wmb');
      localStorage.removeItem(index);
      localStorage.setItem(index, JSON.stringify(obj));
    }
  }

  const getWmb = (contractId) => {
    return JSON.parse(localStorage.getItem(getStorageKey(contractId, 'wmb')));
  }

  const saveVehicle = (contractId, obj) => {
    if (contractId) {
      let index = getStorageKey(contractId, 'vehicle', obj.id);
      localStorage.removeItem(index);
      localStorage.setItem(index, JSON.stringify(obj));
    }
  }

  const getVehicle = (contractId, id) => {
    return JSON.parse(localStorage.getItem(getStorageKey(contractId, 'vehicle', id)));
  }

  const getShowRouteTime = () => {
    return localStorage.getItem('show-route-time') === 'true';
  }

  return (
    <AnimatePresence>
      {carsLoading && summaryLoading
        ? <PageLoader/>
        : (
          <Fade>
            <div>
              <Header size={23} beFlex>Śledzenie pojazdów</Header>
              <Subtitle>Śledź pozycję pojazdów w zależności od kontraktu w czasie rzeczywistym.</Subtitle>
            </div>
            <LiveSummary summary={summary}/>
            <MainWrapper>
              <MapFlexWrapper>
                <MapOuterWrapper>
                  <MapWrapper>
                    <MapContainer
                      center={[51.984876, 19.601698]}
                      zoom={5}
                      scrollWheelZoom={true}
                      whenCreated={m => setMap(m)}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {geoZone?.length > 0 && <Polygon color={COLORS.dark} positions={geoZone} />}
                      {geoZone?.length > 0 && <Marker position={geoZone[0]} icon={<WMBMarker />} />}
                      {carsPositions.map((m, i) => {
                        return (
                          <React.Fragment key={i}>
                            {m.location && <Marker position={[m.location.lat, m.location.lon]} icon={renderMarker(m)} />}
                          </React.Fragment>
                        );
                      })}
                    </MapContainer>
                  </MapWrapper>
                  <MapLegend>
                    <MapLegendHeader>Legenda dla pojazdów:</MapLegendHeader>
                    <MapLegendContent>
                      <MapLegendItem>
                        <LegendItemColor color={COLORS.main} />
                        <LegendItemValue>Pojazd załadowany</LegendItemValue>
                      </MapLegendItem>
                      <MapLegendItem>
                        <LegendItemColor color={'#73727282'}/>
                        <LegendItemValue>Pojazd po rozładunku</LegendItemValue>
                      </MapLegendItem>
                    </MapLegendContent>
                  </MapLegend>
                </MapOuterWrapper>
                <MapSummary
                  getData={() => getData(currentContract.value, true)}
                  contracts={contracts}
                  summary={summary}
                  currentContract={currentContract}
                  setCurrentContract={setCurrentContract}
                  setShowRouteTime={setShowRouteTime}
                  showTime={getShowRouteTime()}
                />
              </MapFlexWrapper>
            </MainWrapper>
          </Fade>
        )
      }
    </AnimatePresence>
  );
};

export default Map;
