import React, { useState, useEffect } from 'react';
import history from 'config/history';
import { ShadowBox } from 'components/__styled/ShadowBox';
import {
  SummaryWrapper,
  SummaryItem,
  SummaryItemText,
  SummaryItemValue
} from 'components/__styled/Summary';
import {
  EditButtonsWrapper,
  BottomSummaryPart,
} from './styles';
import Button from 'components/__common/Button';
import CarsAmount from 'components/Tasks/CarsAmount';
import { formatToHoursAndMinutes } from 'utils/global';

const PlannerSummary = ({ task, saveError, setSaveError, saveCarsAmount, isSchedulePlanned, readOnly = false }) => {
  const [summary, setSummary] = useState({
    numerOfCars: { value: '', name: 'Liczba samochodów do podstawienia' },
    carCourseTime: { value: '', name: 'Czas przejazdu' },
    numerOfCourseForCar: { value: '', name: 'Liczba kursów dla samochodu' },
    buildInTimeWithoutGaps: { value: '', name: 'Czas wbudowania bez przerw' },
    plannedDailyAmount: { value: '', name: 'Planowana ilość dzienna', unit: 't' },
    buildInTime: { value: null, name: 'Łączny czas wbudowania' },
  });

  useEffect(() => {
    task && setSummary({
      numerOfCars: { ...summary.numerOfCars, value: task.numerOfCars ? task.numerOfCars.toFixed(1) : 0 },
      carCourseTime: { ...summary.carCourseTime, value: task.carCourseTimeH > 0 ? `${task.carCourseTimeH} g ${task.carCourseTimeMin} min` : `${task.carCourseTimeMin} min` },
      numerOfCourseForCar: { ...summary.numerOfCourseForCar, value: task.numerOfCourseForCar ? task.numerOfCourseForCar.toFixed(1) : 0 },
      buildInTimeWithoutGaps: { ...summary.buildInTimeWithoutGaps, value: task.buildInTimeWithoutGaps ? formatToHoursAndMinutes(task.buildInTimeWithoutGaps) : 0},
      plannedDailyAmount: { ...summary.plannedDailyAmount, value: task.plannedDailyAmount ? task.plannedDailyAmount.toFixed(1) : 0 },
      buildInTime: { ...summary.buildInTime, value: task.buildInTime ? formatToHoursAndMinutes(task.buildInTime) : 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const goToFirstStepTaskForm = () => {
    history.push({
      pathname: `/panel/tasks/${task.id}`,
      state: { goToPlannerAfterSave: true, formStep: 1 }
    });
  };

  const goToSecondStepTaskForm = () => {
    history.push({
      pathname: `/panel/tasks/${task.id}`,
      state: { goToPlannerAfterSave: true, formStep: 2 }
    });
  };

  const goToThirdStepTaskForm = () => {
    history.push({
      pathname: `/panel/tasks/${task.id}`,
      state: { goToPlannerAfterSave: true, formStep: 3 }
    });
  };

  return (
    <ShadowBox>
      <SummaryWrapper>
        {summary && Object.values(summary).map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item && item.value && (
                <SummaryItem>
                  <SummaryItemText>{item.name}</SummaryItemText>
                  <SummaryItemValue>
                    {item.value}
                    {item.unit && ` ${item.unit}`}
                  </SummaryItemValue>
                </SummaryItem>
              )}
            </React.Fragment>
          );
        })}
      </SummaryWrapper>
      <BottomSummaryPart>
        {!readOnly && <CarsAmount
          saveCarsAmount={saveCarsAmount}
          saveError={saveError}
          setSaveError={setSaveError}
          isSchedulePlanned={isSchedulePlanned}
        />}
        {(!isSchedulePlanned && !readOnly) && (
          <EditButtonsWrapper>
            <Button onClick={() => goToThirdStepTaskForm()} small secondary>Edytuj przerwy</Button>
            <Button onClick={() => goToFirstStepTaskForm()} small secondary>Edytuj dane</Button>
            <Button onClick={() => goToSecondStepTaskForm()} small secondary>Edytuj czasy</Button>
          </EditButtonsWrapper>
        )}
      </BottomSummaryPart>
    </ShadowBox>
  );
};

export default PlannerSummary;
