import React from 'react';
import { Wrapper, Ball, Number, StepContent, StepIndex, StepTitle, Check } from './styles';
import { GrCheckmark } from 'react-icons/gr';

const TaskProgressItem = ({ step, stepTitle, isActive, setActiveStep, lastFinishedStep }) => {
  return (
    <Wrapper disabled={step > lastFinishedStep + 1} isActive={isActive} onClick={() => step > lastFinishedStep + 1 ? null : setActiveStep(step)}>
      <Ball>
        {lastFinishedStep >= step ? <Check><GrCheckmark /></Check> : <Number>{step}</Number> }
      </Ball>
      <StepContent isActive={isActive}>
        <StepIndex>Krok {step}</StepIndex>
        <StepTitle>{stepTitle}</StepTitle>
      </StepContent>
    </Wrapper>
  )
};

export default TaskProgressItem;
