import React, {useEffect, useState} from 'react';
import { StepWrapper, Wrapper, FormContent } from './styles';
import FirstFormStep from 'components/Tasks/FirstFormStep'
import SecondFormStep from 'components/Tasks/SecondFormStep'
import ThirdFormStep from 'components/Tasks/ThirdFormStep'
import FourthFormStep from 'components/Tasks/FourthFormStep';
import TaskFormProgress from '../TaskFormProgress';
import TasksService from 'services/TasksService';
import { prepareErrors } from 'utils/form';
import { useLocation } from 'react-router-dom';
import history from 'config/history';
import TaskContext from 'context/TaskContext';
import { displayToast } from 'utils/toast';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';

const TaskForm = ({ contracts, factories, laboratories, editable }) => {

  const [activeStep, setActiveStep] = useState(1);
  const [lastFinishedStep, setLastFinishedStep] = useState(editable ? 0 : 4);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const { currentTask, setCurrentTask } = React.useContext(TaskContext);
  const location = useLocation();

  const formSteps = [
    { component: FirstFormStep, title: 'Dane podstawowe' },
    { component: SecondFormStep, title: 'Czasy przejazdu' },
    { component: ThirdFormStep, title: 'Przerwy' },
    location.state && location.state.goToPlannerAfterSave ? null : { component: FourthFormStep, title: 'Wyliczenia'},
  ];

  useEffect(() => {
    if (location.state && location.state.formStep) {
      setActiveStep(location.state.formStep);
    }
  }, [location]);

  useEffect(() => {
    if (currentTask && currentTask.status === 'CONFIRMED') {
      setLastFinishedStep(4);
    }
  }, [currentTask]);

  const goToNextStep = () => {
    const { state } = location;
    if (state && state.goToPlannerAfterSave) {
      history.push(`/panel/tasks/${currentTask.id}/planner`);
    }
    setLastFinishedStep(activeStep)
    activeStep < formSteps.length && setActiveStep(activeStep + 1);
  };

  const goToPrevStep = () => {
    activeStep > 1 && setActiveStep(activeStep - 1);
  };

  const saveFirstStep = async form => {
    setFormSubmitting(true);
    setFormErrors(null);
    try {
      const response = currentTask ? await TasksService.updateFirstTaskStep(currentTask.id, form) : await TasksService.saveFirstTaskStep(form);
      setCurrentTask(response.data);
      setFormSubmitting(false);
      goToNextStep();
    } catch (err) {
      console.error(err);
      setFormSubmitting(false);
      if (err.response.status === 422) {
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const saveSecondStep = async (id, form) => {
    setFormSubmitting(true);
    try {
      const response = await TasksService.saveSecondTaskStep(id, form);
      setCurrentTask(response.data);
      setFormSubmitting(false);
      goToNextStep();
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const saveFinalStep = async id => {
    setFormSubmitting(true);
    try {
      const response = await TasksService.saveFinalTaskStep(id);
      setCurrentTask(response.data);
      setFormSubmitting(false);
      history.push('/panel/tasks');
      displayToast('success', 'Pomyślnie przesłano harmonogram do wytwórni!');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <Wrapper>
      <TaskFormProgress
        formSteps={formSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        lastFinishedStep={lastFinishedStep}
      />
      <AnimatePresence>
        <FormContent>
          {
            activeStep === 1 && (
              <Fade>
                <StepWrapper>
                  <FirstFormStep
                    editable={editable}
                    goToPrevStep={goToPrevStep}
                    goToNextStep={ editable ? saveFirstStep : goToNextStep}
                    formSubmitting={formSubmitting}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    contracts={contracts}
                    factories={factories}
                    laboratories={laboratories}
                    task={currentTask}
                  />
                </StepWrapper>
              </Fade>
            )
          }
          {
            activeStep === 2 && (
              <Fade>
                <StepWrapper>
                  <SecondFormStep
                    editable={editable}
                    goToPrevStep={goToPrevStep}
                    goToNextStep={editable ? saveSecondStep : goToNextStep}
                    formSubmitting={formSubmitting}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    task={currentTask}
                  />
                </StepWrapper>
              </Fade>
            )
          }
          {
            activeStep === 3 && (
              <Fade>
                <StepWrapper>
                  <ThirdFormStep
                    editable={editable}
                    goToPrevStep={goToPrevStep}
                    goToNextStep={goToNextStep}
                    task={currentTask}
                  />
                </StepWrapper>
              </Fade>
            )
          }
          {
            activeStep === 4 && (
              <Fade>
                <StepWrapper>
                  <FourthFormStep
                    editable={editable}
                    goToNextStep={goToNextStep}
                    saveFinalStep={editable ? saveFinalStep : goToNextStep}
                    task={currentTask}
                    formSubmitting={formSubmitting}
                  />
                </StepWrapper>
              </Fade>
            )
          }
        </FormContent>
      </AnimatePresence>
    </Wrapper>
  );
};

export default TaskForm;
