import React, { useEffect, useContext, useState } from 'react';
import TaskForm from 'components/Tasks/TaskForm';
import TasksService from 'services/TasksService';
import PageLoader from 'components/PageLoader';
import { useParams } from 'react-router-dom';
import TaskContext from 'context/TaskContext';
import ContractsService from 'services/ContractsService';
import FactoriesService from 'services/FactoriesService';
import LaboratoriesService from 'services/LaboratoriesService';

const TaskManagement = ({ editable }) => {
  const { task_id } = useParams();
  const { setCurrentTask, setTaskLoading, taskLoading } = useContext(TaskContext);
  const [contracts, setContracts] = useState([]);
  const [factories, setFactories] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  useEffect(() => {
    if (task_id && task_id !== 'create') {
      getTask(task_id);
    } else {
      setCurrentTask(null);
      getSelectOptions();
    }

    return () => setCurrentTask(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  const getTask = async id => {
    try {
      setTaskLoading(true);
      const res = await TasksService.getTask(id);
      setCurrentTask(res.data)
      setTaskLoading(false);
      await getSelectOptions();
    } catch (err) {
      console.error(err);
      setTaskLoading(false);
    }
  };

  const getSelectOptions = async () => {
    try {
      setTaskLoading(true);
      await Promise.all([
        getContracts(),
        getFactories(),
        getLaboratories(),
      ]);
      setTaskLoading(false);
    } catch (err) {
      setTaskLoading(false);
    }
  };

  const getContracts = async () => {
    const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
    const options = res.data._embedded.item.map(contract => ({
      label: contract.name,
      value: contract.id,
      defaultFactory: contract._embedded ? contract._embedded.factory : null,
      subcategories: contract._embedded?.subcategories ? contract._embedded.subcategories : [],
      recipes: contract.recipeName
    }));
    setContracts(options);
  };

  const getFactories = async () => {
    const res = await FactoriesService.getFactories({ itemsPerPage: 99999 });
    const options = res.data._embedded.item.map(factory => ({
      label: factory.name,
      value: factory.id,
      factoryCapacity: factory.factoryCapacity,
      defaultLaboratory: factory._embedded ? factory._embedded.laboratory : null,
      geoZone: factory._embedded ? factory._embedded.geoZone : null,
    }));
    setFactories(options);
  };

  const getLaboratories = async () => {
    const res = await LaboratoriesService.getLaboratories({ itemsPerPage: 99999 });
    const options = res.data._embedded.item.map(lab => ({ label: lab.name, value: lab.id }));
    setLaboratories(options);
  };

  return (
    <>
      {taskLoading
        ? <PageLoader />
        : (
          <TaskForm
            editable={editable}
            contracts={contracts}
            factories={factories}
            laboratories={laboratories}
          />
        )}
    </>
  );
};

export default TaskManagement;
