import React from 'react';
import {
  AuthWrapper,
  Wrapper,
  Header,
  Content,
} from './styles'
import { Spinner } from 'components/__styled/Spinner';


const AuthInProgress = () => {
  return (
    <AuthWrapper>
      <Wrapper>
        <Content>
          <Spinner />
          <Header>Trwa autoryzacja...</Header>
        </Content>
      </Wrapper>
    </AuthWrapper>
  );
};

export default AuthInProgress;
