import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';

export const FormButtonsWrapper = styled.div`
  margin: 20px -5px 20px -5px;
  display: flex;
  justify-content: flex-end;
`;

export const StepWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: ${BREAKPOINTS.large}) {
    flex-direction: column;
  }
`;

export const FormContent = styled.div`
  padding: 0;
  margin-left: 20px;
  flex: 1;
  max-width: 910px;
  position: relative;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin-left: 0;
  }
`;

export const CurrentTaskName = styled.div`
  font-size: ${rem('12px')};
  font-family: ${FONTS.semiBold};
  color: ${COLORS.gray};
  margin-bottom: 10px;
`;

export const AvgLastTimeCourses = styled.div`
  font-size: 12px;
  padding: 15px;
  display: inline-block;
  margin-top: 20px;
  font-family: ${FONTS.medium};
  background-color: #efc21017;
  color: ${COLORS.main};
  border-radius: 7px;
  
  svg path {
    stroke: ${COLORS.main};
  }
  
  span {
    vertical-align: middle;
  }
  
  span:last-of-type {
    margin-left: 20px;
  }
  
  strong {
    font-family: ${FONTS.bold};
  }
`;

export const AvgTimeCourses = styled.div`
  font-size: 12px;
  padding: 15px;
  display: block;
  margin-top: 0;
  font-family: ${FONTS.medium};
  background-color: #efc21017;
  color: ${COLORS.main};
  border-radius: 7px;
  
  svg path {
    stroke: ${COLORS.main};
  }
  
  span {
    vertical-align: middle;
  }
  
  span:nth-child(2) {
    margin-left: 20px;
  }
    span:nth-child(3) {
        display: inline-block;
        margin-left: 40px;
    }
  
  strong {
    font-family: ${FONTS.bold};
  }
`;

export const InfoOuterWrapper = styled.div`
  margin-top: 15px;
`;