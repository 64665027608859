import styled from 'styled-components';
import { COLORS, FONTS, BREAKPOINTS } from 'config/theme';

export const FormContentInner = styled.div`
  max-width: 1000px;
`;

export const FormContent = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 30px;
  max-width: 900px;
`;

export const FormError = styled.div`
  color: ${COLORS.errorRed};
  font-size: 11px;
  margin-top: 3px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  font-size: 12px;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
  &::after {
    margin-left: 3px;
    top: 1px;
    content: '*';
    color: red;
    font-size: 12px;
    font-family: ${FONTS.regular};
    display: ${({ required }) => required ? 'inline-block' : 'none'};
  }
`;

export const SubLabel = styled.span`
  display: block;
  color: ${COLORS.gray};
  font-size: 10px;
  font-family: ${FONTS.medium};
`;

export const FormRow = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
  }
`;

export const FormElement = styled.div`
  margin: 10px;
  width: calc(50% - 20px);
  @media (max-width: ${BREAKPOINTS.large}) {
    width: calc(100% - 20px);
  }
`;
