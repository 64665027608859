import styled from 'styled-components';
import { COLORS } from 'config/theme';

export const ItemRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const ItemElem = styled.div`
  margin-right: 10px;
  flex: 1;
`;

export const ItemsContent = styled.div`
  padding-top: 20px;
`;

export const RemoveButton = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  background-color: ${COLORS.errorRed2};
  transition: background-color .2s ease;
  svg {
    fill: ${COLORS.white}
  }
  &:hover {
    background-color: ${COLORS.errorRed};
  }
`;

export const NewItemButtonWrapper = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`;
