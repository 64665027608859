import styled, { css, keyframes } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const IconWrapper = styled.div`
  ${({ left }) => left ? css`left: -10px` : css`left: 10px`};
  font-size: 20px;
  display: flex;
  align-items: center;
  position: relative;
  transition: left 0.2s ease, transform 0.2s ease;
`;

export const StyledButton = styled.button`
  font-size: 12px;
  font-family: ${FONTS.bold};
  padding: 5px 20px;
  min-width: 50px;
  max-width: ${({ isLong }) => isLong ? '400px' : '200px'};
  border-radius: 6px;
  outline: none;
  background-color: ${COLORS.main};
  color: ${COLORS.dark};
  border: none;
  min-height: 40px;
  margin: 10px 5px;
  cursor: ${({ disabled }) => disabled ? 'disable' : 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  &:hover {
    ${({ disabled }) => !disabled && css`
      background-color: ${COLORS.dark};
      color: ${COLORS.white};
      
      ${IconWrapper} {
        ${({ zoom, leftIcon }) => zoom
          ? css`transform: scale(1.2)`
          : css`left: ${leftIcon ? '-15px' : '15px'}`
        };
      }
    `}
  }
  
  ${({ secondary }) => secondary && css`
    background-color: ${COLORS.textGray};
    color: ${COLORS.white};
  `};
  
  ${({ small }) => small && css`
    padding: 9px 25px;
    text-transform: none;
    min-height: 25px;
  `};
`;

export const Loader = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid ${COLORS.dark};
  border-bottom: 2px solid ${COLORS.dark};
  animation: ${rotate} 1s linear infinite;
`;
