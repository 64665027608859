import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const VehicleStatus = styled.div`
  font-size: 10px;
  font-family: ${FONTS.regular};
  border-radius: 5px;
  padding: 4px 10px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray2};
  color: ${({ color }) => color ? color : COLORS.dark};
  display: inline-block;
`;
