import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const PanelScreenWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
`;

export const PanelContent = styled.div`
  padding: 80px 40px 40px;
  padding-left: ${({ fullWidth }) => fullWidth ? '40px' : '290px'};
  width: 100%;
  height: 100%;
  transition: padding-left 0.2s ease;
  @media (max-width: ${BREAKPOINTS.large}) {
    padding: 25px;
    padding-top: 80px;
  }
`;
