import React, { useState, useEffect } from 'react';
import { Wrapper, Tab, TabContent, TabsWrapper, LeftSide, RightSide } from './styles';

const Tabs = ({ children, defaultTabName }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  useEffect(() => {
    if (defaultTabName) {
      setActiveTab(defaultTabName);
    }
  }, [defaultTabName]);

  const onTabClick = label => {
    setActiveTab(label);
  };

  return (
    <Wrapper>
      <LeftSide>
        <TabsWrapper>
          {children.map((child, i) => {
            return (
              <Tab key={i} onClick={() => onTabClick(child.props.label)} active={child.props.label === activeTab}>
                {child.props.label}
              </Tab>
            );
          })}
        </TabsWrapper>
      </LeftSide>
      <RightSide>
        <TabContent>
          {children.map(child => {
            if (child.props.label !== activeTab) return null;
            return child;
          })}
        </TabContent>
      </RightSide>
    </Wrapper>
  );
};

export default Tabs;
