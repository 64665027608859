import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'config/theme';
import { ShadowBox } from 'components/__styled/ShadowBox';

export const SummaryLeft = styled(ShadowBox)`
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: ${COLORS.light};
  display: inline-block;
  @media (max-width: ${BREAKPOINTS.little}) {
    width: 100%;
  }
`;
