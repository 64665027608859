import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper, IconWrapper } from './styles';
import pl from 'date-fns/locale/pl';
import { IoCalendarNumberOutline } from 'react-icons/io5';
import moment from 'moment';
registerLocale('pl', pl)

const Datepicker = ({ disabled, onChange, value, isError }) => {
  return (
    <DatePickerWrapper isError={isError}>
      <ReactDatePicker
        disabled={disabled}
        selected={new Date()}
        onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
        locale='pl'
        value={value}
        portalId="root"
      />
      <IconWrapper>
        <IoCalendarNumberOutline />
      </IconWrapper>
    </DatePickerWrapper>
  );
};

export default Datepicker;
