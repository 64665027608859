import React from 'react';
import {
  NewItemButtonWrapper,
  ItemElem,
  ItemRow,
  ItemsContent,
  RemoveButton,
} from './styles';
import Input from 'components/__common/Input';
import Select from 'components/__common/Select';
import { FormError } from 'components/__styled/Form';
import { VscTrash } from 'react-icons/vsc';
import Button from 'components/__common/Button';
import { GoPlus } from 'react-icons/go';

const SubcategoryForm = ({ items, setItems, formErrors, setFormErrors, options }) => {
  const removeElem = index => {
    const filteredItems = items.filter((r, i) => i !== index);
    setItems(filteredItems);
  };

  const addItem = () => {
    const newItems = [...items, { label: null, value: null }];
    setItems(newItems);
  };

  const handleItemsChange = (index, field, value) => {
    let errors = { ...formErrors };
    delete errors[`items[${index}]`];
    setFormErrors(errors);
    let newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: value,
    };


    setItems(newItems);
  }

  const handleItemsSelectChange = (index, value) => {
    let errors = { ...formErrors };
    delete errors[`items[${index}]`];
    setFormErrors(errors);
    let newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      ['value']: value.value,
      ['label']: value.label,
    };

    setItems(newItems);
  }

  return (
    <>
      <ItemsContent>
        {items.map((item, i) => {
          return (
            <ItemRow key={i}>
              <ItemElem>
                <Select
                      isError={formErrors && formErrors[`items[${i}]`]}
                      name="value"
                      options={options}
                      onChange={option => handleItemsSelectChange(i, option)}
                      value={item}
                />
                {formErrors && formErrors[`items[${i}]`] && <FormError>{formErrors[`items[${i}]`] }</FormError>}
              </ItemElem>
              <ItemElem>
                <Input
                  type='text'
                  name="code"
                  placeholder={'Kod master'}
                  onChange={e => handleItemsChange(i, 'code', e.target.value)}
                  value={item.code}
                  isError={formErrors && formErrors[`items[${i}]`]}
                />
                {formErrors && formErrors[`items[${i}]`]  && <FormError>{formErrors[`items[${i}]`] }</FormError>}
              </ItemElem>
              {/*{item.rate && (*/}
              {/*  <ItemElem>*/}
              {/*    <Input*/}
              {/*      type='number'*/}
              {/*      min={1}*/}
              {/*      placeholder={'Stawka'}*/}
              {/*      onChange={e => handleItemsChange(i, 'rate', e.target.value)}*/}
              {/*      value={item.rate}*/}
              {/*      isError={formErrors && formErrors[`items[${i}]`]}*/}
              {/*    />*/}
              {/*    {formErrors && formErrors[`items[${i}]`]  && <FormError>{formErrors[`items[${i}]`] }</FormError>}*/}
              {/*  </ItemElem>*/}
              {/*)}*/}
              {/*{item.type && (*/}
              {/*  <ItemElem>*/}
              {/*    <Select*/}
              {/*      isError={formErrors && formErrors[`items[${i}]`]}*/}
              {/*      name="type"*/}
              {/*      options={[]}*/}
              {/*      onChange={option => handleItemsChange(i, 'type', option.value)}*/}
              {/*    />*/}
              {/*    {formErrors && formErrors[`items[${i}]`]  && <FormError>{formErrors[`items[${i}]`] }</FormError>}*/}
              {/*  </ItemElem>*/}
              {/*)}*/}
              <RemoveButton onClick={() => removeElem(i)}>
                <VscTrash size={20}/>
              </RemoveButton>
            </ItemRow>
          );
        })}
      </ItemsContent>
      <NewItemButtonWrapper>
        <Button
          leftIcon={<GoPlus size={16}/>}
          zoom
          onClick={() => addItem()}
        >
          Nowa podkategoria
        </Button>
      </NewItemButtonWrapper>
    </>
  );
}

export default SubcategoryForm;
