import React, {useCallback, useState} from 'react';
import UsersService from 'services/UsersService';

const UserContext =  React.createContext();
const { Provider } = UserContext;

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const getUserProfile = useCallback(async (isAuth = true) => {
    try {
      setUserLoading(true);
      const res = isAuth ? await UsersService.getAuthUserProfile() : await UsersService.getClientProfile();
      setUserLoading(false);

      const { name, roles, functions, id } = res.data;
      setUser({ name, roles, functions, id });
    } catch (err) {
      setUserLoading(false);
    }
  }, [setUser]);

  const hasManageAccess = () => {
    return user && !user.roles.includes('ROLE_VIEWER');
  };

  const hasRoleAccess = role => {
    return user && role && user.roles.includes(role);
  };

  const hasFunctionAccess = func => {
    return user && func && user.functions.includes(func);
  };

  return (
    <Provider value={{ user, userLoading, getUserProfile, hasManageAccess, hasRoleAccess, hasFunctionAccess }}>
      {children}
    </Provider>
  );
};

export { UserContext, UserProvider };

export default UserContext;
