import React from 'react';
import { ToastContainer } from 'react-toastify';
import { GlobalStyles } from './styles';
import { ModalProvider } from 'context/ConfirmModalContext';
import { TaskProvider } from 'context/TaskContext';
import { ScheduleStatusModalProvider } from 'context/ScheduleStatusModalContext';
import { UserProvider } from 'context/UserContext';
import { MsalProvider} from '@azure/msal-react';
import { msalInstance } from 'config/msal';
import history from 'config/history';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { mainRoutes } from 'router/routes';
import {NotificationProvider} from "../../context/NotificationContext";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      <MsalProvider instance={msalInstance}>
        <UserProvider>
          <ModalProvider>
            <TaskProvider>
             <NotificationProvider>
              <ScheduleStatusModalProvider>
                <Router history={history}>
                  <Switch>
                    {mainRoutes.map(route => (
                      <Route exact={route.exact} key={route.name} path={route.path} component={route.component} />
                    ))}
                    <Redirect to={'/panel'}/>
                  </Switch>
                </Router>
              </ScheduleStatusModalProvider>
             </NotificationProvider>
            </TaskProvider>
          </ModalProvider>
        </UserProvider>
      </MsalProvider>
    </>
  );
};

export default App;
