import React, { useState } from 'react';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import Input from 'components/__common/Input';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import Select from 'components/__common/Select';
import { setSelectOption } from 'utils/form';

const DictionaryForm = ({
  dictionary,
  dictionaryTypes,
  formErrors,
  setFormErrors,
  onSubmit,
  formSubmitting,
  goToDictionariesList
}) => {
  const [form, setForm] = useState({
    type: dictionary && dictionary.type ? dictionary.type : '',
    value: dictionary && dictionary.value ? dictionary.value : '',
  });

  const handleFormChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (formErrors && setFormErrors) {
      let errors = { ...formErrors };
      delete errors[name];
      setFormErrors(errors);
    }
  };

  const handleSubmit = () => {
    const dictionaryForm = {
      value: form.value,
      type: form.type?.value || ''
    };

    onSubmit(dictionaryForm);
  };

  return (
    <>
      <Header size={23}>
        {dictionary ? 'Edycja pozycji słownika' : 'Nowa pozycja słownika'}
      </Header>
      <Subtitle>
        {dictionary
          ? 'Formularz edycji pozycji słownika'
          : 'Formularz dodawania nowej pozycji słownika.'
        }
      </Subtitle>
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Typ / nazwa</Label>
            <Select
              isError={formErrors && formErrors.type}
              name="type"
              isCreatable={true}
              onChange={values => {
                handleFormChange('type', values);
              }}
              withTips={true}
              placeholder={'Wybierz z listy lub wpisz nowy typ'}
              options={dictionaryTypes}
              value={setSelectOption(dictionaryTypes, form.type) || form.type}
            />
            {formErrors && formErrors.type && <FormError>{formErrors.type}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Wartość</Label>
            <Input
              isError={formErrors && formErrors.value}
              name="value"
              onChange={e => handleFormChange('value', e.target.value)}
              value={form.value}
            />
            {formErrors && formErrors.value && <FormError>{formErrors.value}</FormError>}
          </FormElement>
        </FormRow>

        <FlexWrapper justify={'flex-end'}>
          <Button
            secondary
            onClick={() => goToDictionariesList()}
            leftIcon={<BsArrowLeftShort />}
            disabled={formSubmitting}
          >
            Wróć
          </Button>
          <Button
            onClick={() => handleSubmit()}
            zoom
            rightIcon={<BsCheck2 size={18} />}
            isLoading={formSubmitting}
            disabled={formSubmitting}
          >
            Zapisz
          </Button>
        </FlexWrapper>
      </FormContent>
    </>
  );
};

export default DictionaryForm;
