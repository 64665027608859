import styled from 'styled-components';
import { rem } from 'polished';
import { COLORS, FONTS } from 'config/theme';

export const ChangeCarsAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 80px;
  }
`;

export const AmountLabel = styled.div`
  margin-right: 15px;
  font-size: ${rem('12px')};
  font-family: ${FONTS.medium};
  color: ${COLORS.dark};
`;

export const AmountAcceptButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 7px;
  min-height: 32px;
  min-width: 32px;
  background-color: ${COLORS.main};
  margin: 5px;
  font-size: ${rem('12px')};
  font-family: ${FONTS.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease;
  svg {
    transition: fill .2s ease;
  }
  &:hover {
    background-color: ${COLORS.dark};
    svg {
      fill: ${COLORS.white};
    }
  }
`;

export const Wrapper = styled.div``;
