import React, { useState } from 'react';
import Header from 'components/__common/Header';
import Input from 'components/__common/Input';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import history from 'config/history';

const WeightingsForm = ({ weighting, formErrors, setFormErrors, onSubmit, formSubmitting, gotToWeightingsList }) => {

  const [form, setForm] = useState({
    registrationNumber: weighting && weighting.registrationNumber ? weighting.registrationNumber : '',
  });

  const handleFormChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (formErrors && setFormErrors) {
      let errors = { ...formErrors };
      delete errors[name];
      setFormErrors(errors);
    }
  };

  return (
    <>
      <Header size={23}>
        {weighting && weighting.documentNo ? weighting.documentNo : 'Ważenie'}
      </Header>
      <Subtitle>
        Formularz edycji  nr rejestracyjnego zapisanego ważenia
      </Subtitle>
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Nr rejestracyjny</Label>
            <Input
              isError={formErrors && formErrors.registrationNumber}
              name="registrationNumber"
              onChange={e => handleFormChange('registrationNumber', e.target.value)}
              value={form.registrationNumber}
            />
            {formErrors && formErrors.registrationNumber && <FormError>{formErrors.registrationNumber}</FormError>}
          </FormElement>
        </FormRow>

        <FlexWrapper justify={'flex-end'}>
          <Button
            secondary
            onClick={() => history.push('/panel/weightings')}
            leftIcon={<BsArrowLeftShort />}
            disabled={formSubmitting}
          >
            Wróć
          </Button>
          <Button
            onClick={() => onSubmit(weighting.id, form)}
            zoom
            rightIcon={<BsCheck2 size={18} />}
            isLoading={formSubmitting}
          >
            Zapisz
          </Button>
        </FlexWrapper>
      </FormContent>
    </>
  );
};

export default WeightingsForm;
