import React, { useEffect, useState } from 'react';
import { Subtitle } from 'components/__styled/Subtitle';
import Header from 'components/__common/Header';
import WeightingsService from 'services/WeightingsService';
import { FlexWrapper } from 'components/__styled/Layout';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import DateFilter from 'components/DateFilter';
import moment from 'moment';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';
import Table from 'components/__common/Table';
import {BsCheck2, BsTruck, BsHourglass} from 'react-icons/bs';
import { COLORS } from 'config/theme';
import SelectFilter from "components/SelectFilter";
import ContractsService from "services/ContractsService";
import {Desc, InfoIcon, Item, ItemLabel, ItemValue} from "components/Map/LiveSummary/styles";
import {SummaryLeft} from "./styles";
import history from "../../../config/history";
import {FaRegEdit} from "react-icons/fa";

const Weightings = () => {
  const stateFromLocation = JSON.parse(localStorage.getItem('state'));
  const [weightings, setWeightings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [summaryWeight, setSummaryWeight] = useState();
  const [filters, setFilters] = useState({
    date: stateFromLocation?.date ? new Date(stateFromLocation?.date) : new Date(),
  });

  const headers = [
    { name: 'registrationNumber', label: 'Nr rej.'},
    { name: 'contractName', label: 'Kontrakt'},
    { name: 'carrierName', label: 'Przewoźnik'},
    { name: 'recipeName', label: 'Nr recepty'},
    { name: 'documentNo', label: 'Nr dokumentu'},
    { name: 'weight', label: 'Waga (kg)'},
    { name: 'weighingTime', label: 'Data ważenia'},
    { name: 'taringTime', label: 'Data tarowania'},
    { name: 'connected', label: 'Powiązany'},
  ];

  useEffect(() => {
      getContracts();
    const unsubscribe = getWeightings(); //subscribe
    return unsubscribe; //unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);


/*
  useEffect(() => {
  //pętla
      const getContracts = async () => {
          const res = await ContractsService.getContracts();
          const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.mpk + ' - ' + contract.name, value: contract.id })) : [];
          if (stateFromLocation && stateFromLocation.contractId) {
              let activeFilter = options.filter(item => item.value === stateFromLocation.contractId );
              setFilters({
                  ...filters,
                  contract : activeFilter,
              });
          }

          setContracts(options);
      };

    getContracts();
  },[filters,stateFromLocation]);
*/
    const getContracts = async () => {
        const res = await ContractsService.getContracts();
        const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id })) : [];
        setContracts(options);
    };

  const getWeightings = async () => {
    try {
      setLoading(true);
      const res = await WeightingsService.getWeightings({
        date: filters.date ? moment(filters.date).format('YYYY-MM-DD') : null,
        contractId: filters.contract ? filters.contract.value : null,
      });

      const data = res.data._embedded && res.data._embedded.item.map(i => ({
        ...i,
        registrationNumber: i.registrationNumberOriginal && i.registrationNumber !== i.registrationNumberOriginal
            ? i.registrationNumber + ' (' + i.registrationNumberOriginal + ')'
            : i.registrationNumber,
        contractName: i.mpk ? i.mpk : '---',
          carrierName: i.carrierName ? i.carrierName : '---',
        connected: i.recalculate ? <BsHourglass size={22} color={COLORS.gray}/> : (i.connected ? <BsCheck2 size={22} color={COLORS.green}/> : '---')
      }));

      if (data ) {
          const reducer = (accumulator, currentValue) => accumulator + currentValue.weight;
          let summary = data.reduce( reducer,0 );
          setSummaryWeight(summary);
      } else {
          setSummaryWeight(0);
      }


      setWeightings(data ? data : []);
      setLoading(false);

    } catch (err) {
        console.log("err",err);
      setLoading(false);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

    const actions = [
        row => ({
            label: 'Edytuj',
            icon: <FaRegEdit size={20}/>,
            event: id => goToEditWeighting(id),
        }),
    ];

    const goToEditWeighting = weightingId => {
        history.push(`/panel/weightings/${weightingId}`);
    };

  return (
    <AnimatePresence exitBeforeEnter>
      {loading
        ? <PageLoader />
        : (
          <Fade>
            <FlexWrapper align={'center'} flexWrap={'wrap'}  justify={'space-between'}>
              <div>
                <Header size={23}>Ważenia</Header>
                <Subtitle>Lista odbytych ważeń na wagomacie.</Subtitle>
              </div>
                {summaryWeight ? (
                    <SummaryLeft>
                        <Item>
                            <InfoIcon>
                                <BsTruck size={28}/>
                            </InfoIcon>
                            <Desc>
                                <ItemLabel> Podsumowanie ważeń: </ItemLabel>
                                <ItemValue>{summaryWeight ? `${summaryWeight/1000} t` : '-'}</ItemValue>
                            </Desc>
                        </Item>
                    </SummaryLeft>
                ) : null}
            </FlexWrapper>
            <TableBox>
              <FilterWrapper>
                <div />
                <FilterContent>
                  <SelectFilter label={'Kontrakt'} value={filters.contract} onChange={option => handleFilterChange('contract', option)} options={contracts} />
                  <DateFilter value={filters.date} onChange={date => handleFilterChange('date', date)} />
                </FilterContent>
              </FilterWrapper>
              <Table
                headers={headers}
                rows={weightings}
                actions={actions}
              />
            </TableBox>
          </Fade>
        )
      }
    </AnimatePresence>
  );
}

export default Weightings;
