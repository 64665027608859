import React, { useState } from 'react';
import Header from 'components/__common/Header';
import Input from 'components/__common/Input';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import Select from 'components/__common/Select';

const VehicleForm = ({ vehicle, formErrors, setFormErrors, onSubmit, formSubmitting, goToVehiclesList }) => {

  const vehicleStatuses = [
    { label: 'Aktywny', value: 'ACTIVE' },
    { label: 'Nieaktywny', value: 'INACTIVE' },
  ];

  const returnProperStatus = status => {
    switch (status) {
      case 'INACTIVE':
        return {
          label: 'Nieaktywny',
          value: 'INACTIVE',
        };
      default:
        return {
          label: 'Aktywny',
          value: 'ACTIVE',
        };
    }
  };

  const [form, setForm] = useState({
    registrationNumber: vehicle && vehicle.registrationNumber ? vehicle.registrationNumber : '',
    carTonnage: vehicle && vehicle.carTonnage ? vehicle.carTonnage : '',
    brand: vehicle && vehicle.brand ? vehicle.brand : '',
    model: vehicle && vehicle.model ? vehicle.model : '',
    locatorId: vehicle && vehicle.locatorId ? vehicle.locatorId : '',
    status: vehicle && vehicle.status ? vehicle.status : '',
  });

  const handleFormChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (formErrors && setFormErrors) {
      let errors = { ...formErrors };
      delete errors[name];
      setFormErrors(errors);
    }
  };

  const handleSubmit = () => {
    const requestForm = {
      ...form,
      carTonnage: Number(form.carTonnage),
      locatorId: Number(form.locatorId),
    };
    onSubmit(requestForm);
  };

  return (
    <>
      <Header size={23}>
        {vehicle ? vehicle.registrationNumber : 'Nowy pojazd'}
      </Header>
      <Subtitle>
        {vehicle
          ? 'Formularz edycji utworzonego wcześniej pojazdu'
          : 'Formularz dodawania nowego pojazdu dla wybranego przewoźnika'
        }
      </Subtitle>
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Nr rejestracyjny</Label>
            <Input
              isError={formErrors && formErrors.registrationNumber}
              name="registrationNumber"
              onChange={e => handleFormChange('registrationNumber', e.target.value)}
              value={form.registrationNumber}
            />
            {formErrors && formErrors.registrationNumber && <FormError>{formErrors.registrationNumber}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Tonaż</Label>
            <Input
              type="number"
              isError={formErrors && formErrors.carTonnage}
              name="carTonnage"
              onChange={e => handleFormChange('carTonnage', e.target.value)}
              value={form.carTonnage}
            />
            {formErrors && formErrors.carTonnage && <FormError>{formErrors.carTonnage}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Marka</Label>
            <Input
              isError={formErrors && formErrors.brand}
              name="brand"
              onChange={e => handleFormChange('brand', e.target.value)}
              value={form.brand}
            />
            {formErrors && formErrors.brand && <FormError>{formErrors.brand}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Model</Label>
            <Input
              isError={formErrors && formErrors.model}
              name="model"
              onChange={e => handleFormChange('model', e.target.value)}
              value={form.model}
            />
            {formErrors && formErrors.model && <FormError>{formErrors.model}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label>Id lokalizatora</Label>
            <Input
              type="number"
              isError={formErrors && formErrors.locatorId}
              name="locatorId"
              onChange={e => handleFormChange('locatorId', e.target.value)}
              value={form.locatorId}
            />
            {formErrors && formErrors.locatorId && <FormError>{formErrors.locatorId}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Status</Label>
            <Select
              isError={formErrors && formErrors.status}
              name="status"
              options={vehicleStatuses}
              onChange={option => handleFormChange('status', option.value)}
              value={form.status ? returnProperStatus(form.status) : ''}
            />
            {formErrors && formErrors.status && <FormError>{formErrors.status}</FormError>}
          </FormElement>
        </FormRow>
        <FlexWrapper justify={'flex-end'}>
          <Button
            secondary
            onClick={() => goToVehiclesList()}
            leftIcon={<BsArrowLeftShort />}
            disabled={formSubmitting}
          >
            Wróć
          </Button>
          <Button
            onClick={() => handleSubmit()}
            zoom
            rightIcon={<BsCheck2 size={18} />}
            isLoading={formSubmitting}
            disabled={formSubmitting}
          >
            Zapisz
          </Button>
        </FlexWrapper>
      </FormContent>
    </>
  );
};

export default VehicleForm;
