import React, {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { TableBox } from 'components/__styled/Table';
import VehiclesTable from 'components/Vehicles/VehiclesTable';
import VehiclesService from 'services/VehiclesService';
import Pagination from 'components/Pagination';
import { Subtitle } from 'components/__styled/Subtitle';
import { IoMdAdd } from 'react-icons/io';
import { VscTrash } from 'react-icons/vsc';
import { FaRegEdit } from 'react-icons/fa';
import { BsArrowLeftShort } from 'react-icons/bs';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import history from 'config/history';
import { COLORS } from 'config/theme';
import ModalContext from 'context/ConfirmModalContext';
import { BottomWrapper } from './styles';
import CarriersService from 'services/CarriersService';

const VehiclesList = () => {
  const { carrier_id } = useParams();
  const { openModal } = useContext(ModalContext);
  const [carrier, setCarrier] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => goToEditVehicle(id),
    }),
    row => ({
      label: 'Usuń',
      icon: <VscTrash size={24} />,
      event: id => openModal('Czy chcesz usunąć rekord?', 'Pamiętaj, że operacja jest nieodwracalna.', () => removeVehicle(id)),
      color: COLORS.errorRed2,
    }),
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getVehicles({ ...pagination, page });
  };

  const getData = async () => {
    try {
      setDataLoading(true);
      await getCarrier(carrier_id);
      await getVehicles(pagination);
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  };

  const getCarrier = async () => {
    const res = await CarriersService.getCarrier(carrier_id);
    setCarrier(res.data);
  };

  const getVehicles = async pagination => {
    const res = await VehiclesService.getCarrierVehicles(carrier_id, pagination);
    setVehicles(res.data._embedded?.item ? res.data._embedded.item : []);
    setPagination({ ...pagination, totalItems: res.data.totalItems });
  };

  const removeVehicle = async id => {
    try {
      setDataLoading(true);
      await VehiclesService.removeVehicle(id);
      await getVehicles(pagination);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
      setDataLoading(false);
    }
  };

  const goToEditVehicle = vehicleId => {
    history.push(`/panel/carriers/${carrier_id}/vehicles/${vehicleId}`)
  };

  const goToCreateVehicle = () => {
    history.push(`/panel/carriers/${carrier_id}/vehicles/create`)
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {dataLoading
        ? <PageLoader />
        : (
          <Fade>
            <FlexWrapper data-testid='planner-preview-content' justify={'space-between'}>
              <div>
                <Header size={23} beFlex>Pojazdy - {carrier && carrier.name}</Header>
                <Subtitle>Lista dostępnych pojazdów dla wybranego przewoźnika</Subtitle>
              </div>
              <Button zoom onClick={goToCreateVehicle} leftIcon={<IoMdAdd />}>Nowy pojazd</Button>
            </FlexWrapper>
            <TableBox>
              <VehiclesTable
                vehicles={vehicles}
                pagination={pagination}
                actions={actions}
              />
            </TableBox>
            <BottomWrapper>
              <Button
                onClick={() => history.push(`/panel/carriers/`)}
                leftIcon={<BsArrowLeftShort />}
                secondary
              >
                Wróć
              </Button>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </BottomWrapper>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default VehiclesList;
