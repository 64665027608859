import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormContentInner, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import Input from 'components/__common/Input';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import history from 'config/history';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';

const CarrierForm = ({ carrier, formErrors, setFormErrors, onSubmit, formSubmitting }) => {
  const [form, setForm] = useState({
    name: carrier && carrier.name ? carrier.name : '',
    email: carrier && carrier.email ? carrier.email : '',
    phone: carrier && carrier.phone ? carrier.phone : '',
    vatNumber: carrier && carrier.vatNumber ? carrier.vatNumber : '',
  });

  const handleFormChange = (name, value) => {
    let errors = { ...formErrors };
    delete errors[name];
    setFormErrors(errors);
    setForm( {...form, [name]: value });
  };

  return (
    <AnimatePresence>
      <Fade>
        <Header size={23}>
          {carrier ? `Kontrakt ${carrier.name}` : `Nowy przewoźnik`}
        </Header>
        <Subtitle>{carrier ? 'Edytuj dane przewoźnika' : 'Podaj dane nowego przewoźnika.'}</Subtitle>
        <FormContent>
          <FormContentInner>
            <FormRow>
              <FormElement>
                <Label required>Nazwa przewoźnika</Label>
                <Input
                  isError={formErrors && formErrors.name}
                  name="name"
                  onChange={e => handleFormChange('name', e.target.value)}
                  value={form.name}
                />
                {formErrors && formErrors.name && <FormError>{formErrors.name}</FormError>}
              </FormElement>
              <FormElement>
                <Label required>Adres email</Label>
                <Input
                  isError={formErrors && formErrors.email}
                  name="email"
                  onChange={e => handleFormChange('email', e.target.value)}
                  value={form.email}
                />
                {formErrors && formErrors.email && <FormError>{formErrors.email}</FormError>}
              </FormElement>
            </FormRow>

            <FormRow>
              <FormElement>
                <Label required>Nr telefonu</Label>
                <Input
                  isError={formErrors && formErrors.phone}
                  name="phone"
                  onChange={e => handleFormChange('phone', e.target.value)}
                  value={form.phone}
                />
                {formErrors && formErrors.phone && <FormError>{formErrors.phone}</FormError>}
              </FormElement>
              <FormElement>
                <Label required>NIP</Label>
                <Input
                  isError={formErrors && formErrors.vatNumber}
                  name="vatNumber"
                  onChange={e => {
                    if (e.target.value.length > 0) {
                      if (!/[0-9]/.test(e.target.value)) {
                        e.preventDefault();
                      } else {
                        handleFormChange('vatNumber', e.target.value)
                      }
                    } else {
                      handleFormChange('vatNumber', e.target.value)
                    }
                  }}
                  value={form.vatNumber}
                />
                {formErrors && formErrors.vatNumber && <FormError>{formErrors.vatNumber}</FormError>}
              </FormElement>
            </FormRow>

            <FlexWrapper justify={'flex-end'}>
              <Button
                onClick={() => history.push('/panel/carriers')}
                leftIcon={<BsArrowLeftShort />}
                secondary
              >
                Wróć
              </Button>
              <Button
                onClick={() => carrier ? onSubmit(carrier.id, form) : onSubmit(form)}
                leftIcon={<BsCheck2 />}
                zoom
                isLoading={formSubmitting}
              >
                Zapisz
              </Button>
            </FlexWrapper>
          </FormContentInner>
        </FormContent>
      </Fade>
    </AnimatePresence>
  );
};

export default CarrierForm;
