import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import ReactTooltip from 'react-tooltip';

const LaboratoriesList = ({ laboratories, actions, pagination }) => {
  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Nazwa</Th>
            <Th>Email</Th>
            <Th>Nr telefonu</Th>
            {actions && <Th>Akcje</Th>}
          </Tr>
        </THead>
        <TBody>
          {laboratories && laboratories.length > 0 ?
            laboratories.map((row, i) => {
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td>{row.name}</Td>
                  <Td>{row.email}</Td>
                  <Td>{row.phone}</Td>
                  {actions && (
                    <Td>
                      <ActionButtonWrapper>
                        {actions.map((actionFn, i) => {
                          const action = actionFn(row);
                          return (
                            <React.Fragment key={i}>
                              <ActionButton
                                data-tip={action.label}
                                color={action.color}
                                onClick={() => action.event(row.id)}
                              >
                                {action.icon}
                              </ActionButton>
                            </React.Fragment>
                          );
                        })}
                      </ActionButtonWrapper>
                    </Td>
                  )}
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={5}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default LaboratoriesList;
