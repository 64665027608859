import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';
import history from 'config/history';
import { displayToast } from 'utils/toast';
import { prepareErrors } from 'utils/form';
import DictionaryForm from 'components/Dictionaries/DictionaryForm';
import DictionariesService from 'services/DictionariesService';

const DictionaryManagement = () => {
  const { dictionary_id } = useParams();
  const [dictionary, setDictionary] = useState(null);
  const [dictionaryTypes, setDictionaryTypes] = useState([]);
  const [dataLoading, setDataLoading] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    getData(dictionary_id);

    return () => setDictionary(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionary_id]);

  const goToDictionariesList = () => {
    history.push('/panel/dictionaries');
  };

  const getData = async id => {
    try {
      let promises = [getDictionaryTypes()];

      if (dictionary_id && dictionary_id !== 'create') {
        promises = [...promises, getDictionary(id)];
      } else {
        setDictionary(null);
      }

      setDataLoading(true);
      await Promise.all(promises);
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  };

  const getDictionary = async id => {
    const res = await DictionariesService.getDictionary(id);
    setDictionary(res.data);
  };

  const getDictionaryTypes = async () => {
    const res = await DictionariesService.getDictionaryTypes();
    const options = res.data.map(cat => ({ label: cat, value: cat }));
    setDictionaryTypes(options);
  };

  const createDictionary = async form => {
    try {
      setFormSubmitting(true);
      await DictionariesService.createDictionary(form);
      setFormSubmitting(false);
      goToDictionariesList();
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateDictionary = async form => {
    try {
      setFormSubmitting(true);
      await DictionariesService.updateDictionary(dictionary_id, form);
      setFormSubmitting(false);
      goToDictionariesList();
      displayToast('success', 'Słownik został zaktualizowany!');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <AnimatePresence>
      {dataLoading
        ? <PageLoader/>
        : (
          <Fade>
            <DictionaryForm
              dictionary={dictionary}
              dictionaryTypes={dictionaryTypes}
              formSubmitting={formSubmitting}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onSubmit={dictionary ? updateDictionary : createDictionary}
              goToDictionariesList={goToDictionariesList}
            />
          </Fade>
        )
      }
    </AnimatePresence>
  );
}

export default DictionaryManagement;
