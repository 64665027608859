import React, { useCallback, useMemo } from 'react';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Wrapper, PageButton, PaginationDots } from './styles';

const Pagination = ({ totalItems, perPage, page, onChange }) => {

  const generatePages = useCallback(() => {
    const length = totalItems / perPage;
    let arr = [];
    for (let i = 1; i < length + 1; i++) {
      arr = [...arr, i];
    }
    return arr;
  }, [totalItems, perPage]);

  const pages = useMemo(() => generatePages(), [generatePages]);

  const generatePagination = () => {
    const pagesLength = totalItems / perPage;

    if (pagesLength > 5) {
      return paginationWithDots();
    } else {
      return simplePagination();
    }
  };

  const paginationWithDots = () => {
    let dotsLeft = false,
      dotsRight = false,
      middlePages;

    if ((page - 1) - pages[0] >= 2) {
      dotsLeft = true;
    }
    if ((pages[pages.length - 1] - (page + 1)) >= 2) {
      dotsRight = true;
    }

    if (page > 2 && page < pages[pages.length - 2]) {
      middlePages = [page - 1, page, page + 1];
    } else {
      if (dotsRight) {
        middlePages = Array.from(Array(3), (_, i) => 2 + i);
      } else {
        middlePages = Array.from(Array(3), (_, i) => (pages.length - 1) - i).reverse();
      }
    }

    return (
      <>
        <PageButton key={1} active={page === 1} onClick={() => onChange(1)}>1</PageButton>
        {dotsLeft && <PaginationDots>...</PaginationDots>}
        {middlePages.map(p => {
          return (
            <PageButton key={p} active={page === p} onClick={() => onChange(p)}>{p}</PageButton>
          )
        })}
        {dotsRight && <PaginationDots>...</PaginationDots>}
        <PageButton key={pages.length} active={page === pages.length} onClick={() => onChange(pages.length)}>{pages.length}</PageButton>
      </>
    );
  };

  const simplePagination = () => {
    return (
      <>
        {pages && pages.map((p, i) => {
          return (
            <PageButton key={i} active={page === i + 1} onClick={() => onChange(i + 1)}>{i + 1}</PageButton>
          );
        })}
      </>
    );
  };

  return (
    <>
      {pages.length > 1 && (
        <Wrapper>
          <PageButton disabled={page <= 1} onClick={() => onChange(page - 1)}>
            <MdOutlineArrowBackIos />
          </PageButton>
          {generatePagination()}
          <PageButton disabled={page >= pages.length} onClick={() => onChange(page + 1)}><MdOutlineArrowForwardIos /> </PageButton>
        </Wrapper>
      )}
    </>
  );
};

export default Pagination;
