import styled from 'styled-components';
import { FONTS, COLORS } from 'config/theme';
import { rem } from 'polished';
import { ShadowBox } from 'components/__styled/ShadowBox';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

export const Content = styled(ShadowBox)`
  padding: 20px 35px 35px;
  max-width: 400px;
`;

export const Header = styled.h2`
  font-family: ${FONTS.bold};
  font-size: ${rem('24px')};
  color: ${COLORS.dark};
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Description = styled.div`
  color: ${COLORS.gray};
  font-size: ${rem('13px')};
  text-align: center;
  line-height: 1.4;
`;

export const Icon = styled.div`
  text-align: center;
  margin-bottom: 10px;
  svg {
    fill: ${COLORS.errorRed};
  }
`;
