import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const StatusList = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusItem = styled.div`
  opacity: 1;
  visibility: visible;
  min-width: 30px;
  height: 25px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray};
  color: ${({ textColor }) => textColor ? textColor : COLORS.dark};
  font-family: ${FONTS.semiBold};
  border-right: 2px solid ${COLORS.white};
  transition: opacity .3s ease, visibility .3s ease;
  font-variant-numeric: tabular-nums;
  &:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  };
  &:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none;
  };
`;

export const DetailsText = styled.div`
  opacity: 0;
  visibility: hidden;
  font-size: 12px;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
  transition: opacity .3s ease, visibility .3s ease;
  position: absolute;
  top: 0;
  padding: 6px 12px;
  border-radius: 5px;
  background-color: ${COLORS.textGray2};
`;

export const StatusButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  &:hover {
    ${DetailsText} {
      opacity: 1;
      visibility: visible;
    }
    ${StatusItem} {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const CarrierConfirmStatus = styled.span`
  margin-left: 5px;
  font-size: 10px;
`;
