import React from 'react';
import { Bar, Wrapper, MenuButton, Logo } from './styles';
import { BiMenuAltLeft } from 'react-icons/bi';
import logo from 'assets/img/logo.png';
import UserInfo from 'components/UserInfo';
import { FlexWrapper } from 'components/__styled/Layout';

const Topbar = ({ setNavOpen }) => {
  return (
    <Bar>
      <Wrapper>
        <UserInfo />
        <FlexWrapper align={'center'}>
          <MenuButton onClick={setNavOpen}>
            <BiMenuAltLeft size={24} />
          </MenuButton>
          <Logo src={logo}/>
        </FlexWrapper>
      </Wrapper>
    </Bar>
  );
};

export default Topbar;
