import React, {useEffect, useState} from 'react';
import PageLoader from 'components/PageLoader';
import ContractForm from 'components/Contracts/ContractForm';
import { useParams } from 'react-router-dom';
import ContractsService from 'services/ContractsService';
import { displayToast } from 'utils/toast';
import history from 'config/history';
import { prepareErrors } from 'utils/form';
import FactoriesService from 'services/FactoriesService';
import CarriersService from 'services/CarriersService';
import UsersService from 'services/UsersService';
import Tabs from 'components/Tabs';
import CarrierRates from 'components/Contracts/CarriersRates';
import DictionariesService from 'services/DictionariesService';
import RecipesRates from 'components/Contracts/RecipesRates';

const ContractManagement = () => {
  const { contract_id } = useParams();
  const [contract, setContract] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [defaultTabName, setDefaultTabName] = useState('Ogólne');
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [factories, setFactories] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (contract_id && contract_id !== 'create') {
      getData(contract_id);
    } else {
      setContract(null);
      setFactories([]);
      getSelectOptions();
    }

    return () => {
      setContract(null);
      setFactories([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract_id]);

  const getData = async contract_id => {
    try {
      setDataLoading(true);
      await Promise.all([
        getContract(contract_id),
        getFactories(),
        getCarriers(),
        getAllUsers(),
        getSubcategories(),
        getAllUsers()
      ]);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
      setDataLoading(false);
    }
  };

  const getSelectOptions = async () => {
    try {
      setDataLoading(true);
      await Promise.all([
        getFactories(),
        getCarriers(),
        getAllUsers(),
        getSubcategories()
      ]);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
      setDataLoading(false);
    }
  };

  const getFactories = async () => {
    const res = await FactoriesService.getFactories({ itemsPerPage: 9999 });
    const options = res.data._embedded?.item.map(factory => ({ label: factory.name, value: factory.id }));
    setFactories(options);
  };

  const getContract = async id => {
    const res = await ContractsService.getContract(id);
    setContract(res.data);
  };

  const getCarriers = async () => {
    const res = await CarriersService.getCarriers({ itemsPerPage: 99999 });
    const options = res.data._embedded?.item.map(carrier => ({ label: `${carrier.name} (NIP: ${carrier.vatNumber})`, value: carrier.id }));
    setCarriers(options);
  };

  const getAllUsers = async () => {
    const res = await UsersService.getUsers({ itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setManagers(options);
    setLogistics(options);
    setViewers(options);
  };

  const getManagers = async search => {
    const res = await UsersService.getUsers({ name: search, itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setManagers(options);
    return options;
  };

  const getLogistics = async search => {
    const res = await UsersService.getUsers({ name: search, itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setLogistics(options);
    return options;
  };

  const getViewers = async search => {
    const res = await UsersService.getUsers({ name: search, itemsPerPage: 10 });
    const options = res.data._embedded?.item.map(user => ({ label: user.name, value: user.id }));
    setViewers(options);
    return options;
  };

  const getSubcategories = async () => {
    const res = await DictionariesService.getSubcategories();
    const options = res.data._embedded?.item.map(cat => ({ label: cat.value, value: cat.id }));
    setSubcategories(options);
  };

  const createContract = async form => {
    try {
      setFormSubmitting(true);
      const res = await ContractsService.createContract(form);
      setContract(res.data);
      displayToast('success', 'Poprawnie dodano nowy kontrakt!');
      setDefaultTabName('Stawki');
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateContract = async (id, form) => {
    try {
      setFormSubmitting(true);
      await ContractsService.updateContract(id, form);
      displayToast('success', 'Dane zostały zaktualizowane!');
      history.push('/panel/contracts');
      setFormSubmitting(false);
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <>
      {dataLoading
        ? <PageLoader />
        : (
          <Tabs defaultTabName={defaultTabName}>
            <ContractForm
              label={'Ogólne'}
              contract={contract}
              factories={factories}
              carriers={carriers}
              managers={managers}
              logistics={logistics}
              subcategories={subcategories}
              viewers={viewers}
              getManagers={getManagers}
              getLogistics={getLogistics}
              getViewers={getViewers}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              formSubmitting={formSubmitting}
              onSubmit={contract ? (form, id) => updateContract(id, form) : form => createContract(form)}
            />
            <CarrierRates
              label={'Stawki dla przewoźników'}
              contract={contract}
              ranges={contract && contract.ranges}
            />
            <RecipesRates
              label={'Stawki dla recept'}
              contract={contract}
            />
          </Tabs>
        )
      }
    </>
  );
};

export default ContractManagement;
