import React, { useState } from 'react';
import { Wrapper } from './styles';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label, SubLabel } from 'components/__styled/Form';
import Input from 'components/__common/Input';
import { FormButtonsWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import history from 'config/history';
import Fade from 'components/Fade';
import { AnimatePresence } from 'framer-motion';
import Select from 'components/__common/Select';
import { setSelectOption, setSelectMultiOptions } from 'utils/form';
import DistanceRanges from 'components/Contracts/DistanceRanges';
import Subcategories from 'components/Contracts/Subcategories';
import Textarea from 'components/__common/Textarea';
import Resources from "../Resources";
import Checkbox from "../../__common/Checkbox";

const ContractForm = ({
  contract,
  factories,
  carriers,
  managers,
  viewers,
  subcategories,
  logistics,
  formErrors,
  setFormErrors,
  formSubmitting,
  onSubmit,
  getLogistics,
  getManagers,
  getViewers,
}) => {
  const [form, setForm] = useState({
    name: contract && contract.name ? contract.name : '',
    locationApiKey: contract && contract.locationApiKey ? contract.locationApiKey : '',
    factoryId: contract && contract._embedded?.factory?.id ? contract._embedded.factory?.id : '',
    carrierIds: contract && contract._embedded?.carriers ? contract._embedded?.carriers.map(el => ({ label: el.vatNumber ? `${el.name} (NIP: ${el.vatNumber})` : `${el.name}`, value: el.id })) : [],
    managersIds: contract && contract._embedded?.managers ? contract._embedded?.managers.map(el => ({ label: el.name, value: el.id })) : [],
    logisticsIds: contract && contract._embedded?.logistics ? contract._embedded?.logistics.map(el => ({ label: el.name, value: el.id })) : [],
    viewersIds: contract && contract._embedded?.viewers ? contract._embedded?.viewers.map(el => ({ label: el.name, value: el.id })) : [],
    additionalEmails: contract && contract.additionalEmails ? contract.additionalEmails.map(el => ({ label: el, value: el })) : [],
    recipeName: contract && contract.recipeName ? contract.recipeName.map(el => ({ label: el, value: el })) : [],
    ranges: contract && contract.ranges ? contract.ranges : [],
    contactData: contract && contract.contactData ? contract.contactData : '',
    mpk: contract && contract.mpk ? contract.mpk : '',
    externalId: contract && contract.externalId ? contract.externalId : '',
    subcategories: contract && contract._embedded?.subcategories ? contract._embedded?.subcategories.map(el => ({ label: el.value, value: el.id, code: el.code })) : [],
    resourceRide: contract && contract.resourceRide ? contract.resourceRide : '',
    resourceStoppageAtFactory: contract && contract.resourceStoppageAtFactory ? contract.resourceStoppageAtFactory : '',
    resourceStoppageAtConstruction: contract && contract.resourceStoppageAtConstruction ? contract.resourceStoppageAtConstruction : '',
    stoppageRoundCalculate: contract && contract.stoppageRoundCalculate ? contract.stoppageRoundCalculate : false,
  });

  const handleSubmit = (form, contractId) => {
    console.log(form.subcategories);
    const data = {
      ...form,
      externalId: form.externalId ? Number(form.externalId) : null,
      carrierIds: form.carrierIds.map(c => c.value),
      managersIds: form.managersIds.map(m => m.value),
      logisticsIds: form.logisticsIds.map(l => l.value),
      viewersIds: form.viewersIds.map(l => l.value),
      additionalEmails: form.additionalEmails.map(l => l.value),
      recipeName: form.recipeName.map(l => l.value),
      subcategories: form.subcategories.map(sc => ({ 'id': sc.value, 'code': sc.code}))
    };

    if (contractId) {
      onSubmit(data, contractId)
    } else {
      onSubmit(data);
    }
  };

  const handleFormChange = (name, value) => {
    let errors = { ...formErrors };
    delete errors[name];
    setFormErrors(errors);
    setForm( {...form, [name]: value });
  };

  const setRanges = ranges => {
    setForm({
      ...form,
      ranges,
    });
  };

  const setSubcategories = subcategories => {
    setForm({
      ...form,
      subcategories,
    });
  };

  return (
    <AnimatePresence>
      <Fade>
        <Header size={23}>
          {contract ? `Kontrakt ${contract.name}` : `Nowy kontrakt`}
        </Header>
        <Subtitle>{contract ? 'Edytuj dane kontraktu' : 'Podaj dane nowego kontraktu.'}</Subtitle>
        <Wrapper>
          <FormContent>
            <FormRow>
              <FormElement>
                <Label required>Nazwa kontraktu</Label>
                <Input
                  isError={formErrors && formErrors.name}
                  name="name"
                  onChange={e => handleFormChange('name', e.target.value)}
                  value={form.name}
                />
                {formErrors && formErrors.name && <FormError>{formErrors.name}</FormError>}
              </FormElement>
              <FormElement>
                <Label>Klucz API NaviFleet</Label>
                <Input
                  isError={formErrors && formErrors.locationApiKey}
                  name="locationApiKey"
                  onChange={e => handleFormChange('locationApiKey', e.target.value)}
                  value={form.locationApiKey}
                />
                {formErrors && formErrors.locationApiKey && <FormError>{formErrors.locationApiKey}</FormError>}
              </FormElement>
            </FormRow>

            <FormRow>
              <FormElement>
                <Label required>Wytwórnia</Label>
                <Select
                  isError={formErrors && formErrors.factoryId}
                  name="factoryId"
                  options={factories}
                  onChange={option => handleFormChange('factoryId', option.value)}
                  value={setSelectOption(factories, form.factoryId)}
                />
                {formErrors && formErrors.factoryId && <FormError>{formErrors.factoryId}</FormError>}
              </FormElement>

              <FormElement>
                <Label required>Przewoźnicy</Label>
                <Select
                  isError={formErrors && formErrors.carrierIds}
                  name="carrierIds"
                  options={carriers}
                  onChange={values => {
                    handleFormChange('carrierIds', values);
                  }}
                  value={setSelectMultiOptions(form.carrierIds)}
                  isMulti={true}
                />
                {formErrors && formErrors.carrierIds && <FormError>{formErrors.carrierIds}</FormError>}
              </FormElement>
            </FormRow>

            <FormRow>
              <FormElement>
                <Label required>Kierownicy robót bitumicznych</Label>
                <Select
                  isError={formErrors && formErrors.managersIds}
                  name="managersIds"
                  loadOptions={getManagers}
                  options={managers}
                  isMulti={true}
                  onChange={values => {
                    handleFormChange('managersIds', values);
                  }}
                  isAsync={true}
                  placeholder={'Wyszukaj...'}
                  value={setSelectMultiOptions(form.managersIds)}
                />
                {formErrors && formErrors.managersIds && <FormError>{formErrors.managersIds}</FormError>}
              </FormElement>

              <FormElement>
                <Label required>Logistycy</Label>
                <Select
                  isError={formErrors && formErrors.logisticsIds}
                  name="logisticsIds"
                  loadOptions={getLogistics}
                  options={logistics}
                  isMulti={true}
                  onChange={values => {
                    handleFormChange('logisticsIds', values);
                  }}
                  isAsync={true}
                  placeholder={'Wyszukaj...'}
                  value={setSelectMultiOptions(form.logisticsIds)}
                />
                {formErrors && formErrors.logisticsIds && <FormError>{formErrors.logisticsIds}</FormError>}
              </FormElement>
            </FormRow>
            <FormRow>
              <FormElement>
                <Label>Pracownicy</Label>
                <Select
                  isError={formErrors && formErrors.viewersIds}
                  name="viewersIds"
                  loadOptions={getViewers}
                  options={viewers}
                  isMulti={true}
                  onChange={values => {
                    handleFormChange('viewersIds', values);
                  }}
                  isAsync={true}
                  placeholder={'Wyszukaj...'}
                  value={setSelectMultiOptions(form.viewersIds)}
                />
                {formErrors && formErrors.viewersIds && <FormError>{formErrors.viewersIds}</FormError>}
              </FormElement>
              <FormElement>
                <Label>Dodatkowi użytkownicy</Label>
                <Select
                  isError={formErrors && formErrors.additionalEmails}
                  name="additionalEmails"
                  isMulti={true}
                  isCreatable={true}
                  onChange={values => {
                    handleFormChange('additionalEmails', values);
                  }}
                  placeholder={'Wpisz adres email...'}
                  options={[]}
                  value={form.additionalEmails}
                />
                {formErrors && formErrors.additionalEmails && <FormError>{formErrors.additionalEmails}</FormError>}
              </FormElement>
            </FormRow>

            <FormRow>
              <FormElement>
                <Label>Recepty</Label>
                <Select
                  isError={formErrors && formErrors.recipeName}
                  name="recipeName"
                  isMulti={true}
                  isCreatable={true}
                  onChange={values => {
                    handleFormChange('recipeName', values);
                  }}
                  placeholder={'Dodaj receptę...'}
                  options={[]}
                  value={form.recipeName}
                />
                {formErrors && formErrors.recipeName && <FormError>{formErrors.recipeName}</FormError>}
              </FormElement>
              {/*
              <FormElement>
                <Label>Podkategorie</Label>
                <Select
                  isError={formErrors && formErrors.subcategories}
                  name="subcategories"
                  isMulti={true}
                  isCreatable={false}
                  onChange={values => {
                    handleFormChange('subcategories', values);
                  }}
                  placeholder={'Dodaj podkategorię...'}
                  options={subcategories}
                  value={setSelectMultiOptions(form.subcategories)}
                />
                {formErrors && formErrors.recipeName && <FormError>{formErrors.recipeName}</FormError>}
              </FormElement>
              */}
              <FormElement>
                <Label>MPK</Label>
                <Input
                    isError={formErrors && formErrors.mpk}
                    name="mpk"
                    onChange={e => handleFormChange('mpk', e.target.value)}
                    value={form.mpk}
                />
                {formErrors && formErrors.mpk && <FormError>{formErrors.mpk}</FormError>}
              </FormElement>
            </FormRow>
            {/*
            <FormRow>

              <FormElement>
                <Label>Zewnętrzny identyfikator OUS</Label>
                <Input
                  isError={formErrors && formErrors.externalId}
                  name="externalId"
                  onChange={e => handleFormChange('externalId', e.target.value)}
                  value={form.externalId}
                />
                {formErrors && formErrors.externalId && <FormError>{formErrors.externalId}</FormError>}
              </FormElement>

            </FormRow>
            */}

            <FormRow>
              <FormElement>
                <Label>
                  Dane kontaktowe dla przewoźników
                  <SubLabel>(podaj nr tel: do logistyka, do kierowników robót bitumicznych i kierownika WMB</SubLabel>
                </Label>
                <Textarea
                  isError={formErrors && formErrors.contactData}
                  name="contactData"
                  onChange={e => handleFormChange('contactData', e.target.value)}
                  value={form.contactData}
                />
                {formErrors && formErrors.contactData && <FormError>{formErrors.contactData}</FormError>}
              </FormElement>
              <Resources
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  setForm={setForm}
                  form={form}
              />
            </FormRow>

            <FormRow>
              <Subcategories
                  subcategories={form.subcategories}
                  setSubcategories={setSubcategories}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  options={subcategories}
              />

              <DistanceRanges
                ranges={form.ranges}
                setRanges={setRanges}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            </FormRow>
            <FormRow>
              <FormElement>
                <Label>Godziny postojowe</Label>
              <Checkbox
                  label={'Zaokrąglaj godziny postojowe do 0.5h'}
                  value={form.stoppageRoundCalculate}
                  onChange={() => handleFormChange('stoppageRoundCalculate', !form.stoppageRoundCalculate)}
              />
              </FormElement>
            </FormRow>

            <FormButtonsWrapper>
              <Button
                onClick={() => history.push('/panel/contracts')}
                leftIcon={<BsArrowLeftShort />}
                secondary
              >
                Wróć
              </Button>
              <Button
                onClick={() => contract ? handleSubmit(form, contract.id) : handleSubmit(form)}
                leftIcon={<BsCheck2 />}
                zoom
                isLoading={formSubmitting}
              >
                Zapisz
              </Button>
            </FormButtonsWrapper>
          </FormContent>
        </Wrapper>
      </Fade>
    </AnimatePresence>
  );
};

export default ContractForm;
