import React, { useEffect, useContext, useState } from 'react';
import Nav from 'components/Nav';
import { PanelScreenWrapper, PanelContent } from './styles';
import UserContext from 'context/UserContext';
import PageLoader from 'components/PageLoader';
import { Redirect, Switch } from 'react-router-dom';
import { panelRoutes, panelRedirect } from 'router/routes';
import AccessCheckRoute from 'components/AccessCheckRoute';

const Panel = () => {
  const { userLoading, getUserProfile } = useContext(UserContext);
  const [navOpen, setNavOpen] = useState(true);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    
    if (windowWidth < 1200) {
      setNavOpen(false);
    }
  }, [])

  return (
    <>
      { userLoading ? (
        <PageLoader />
      ) : (
        <PanelScreenWrapper>
          <Nav navOpen={navOpen} setNavOpen={setNavOpen}/>
          <PanelContent fullWidth={!navOpen}>
            <Switch>
              {panelRoutes.map(route => (
                <AccessCheckRoute
                  isProtected={route.isProtected}
                  availableFor={route.availableFor}
                  exact={route.exact}
                  key={route.name}
                  path={route.path}
                  component={route.component}
                />
              ))}
              <Redirect from={panelRedirect.from} to={panelRedirect.to} />
            </Switch>
          </PanelContent>
        </PanelScreenWrapper>
      )}
    </>
  );
};

export default Panel;
