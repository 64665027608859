import React, { useState, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import Pagination from 'components/Pagination';
import { AnimatePresence } from 'framer-motion';
import { FaRegEdit } from 'react-icons/fa';
import {FlexWrapper} from 'components/__styled/Layout';
import { IoMdAdd } from 'react-icons/io';
import Button from 'components/__common/Button';
import history from 'config/history';
import DictionariesService from 'services/DictionariesService';
import DictionariesTable from 'components/Dictionaries/DictionariesTable';
import ConfirmModalContext from 'context/ConfirmModalContext';
import { VscTrash } from 'react-icons/vsc';
import { COLORS } from 'config/theme';
import SearchBar from 'components/SearchBar';
import SelectFilter from 'components/SelectFilter';

const DictionariesList = () => {
  const { openModal } = React.useContext(ConfirmModalContext);
  const [dictionariesLoading, setDictionariesLoading] = useState(false);
  const [dictionaries, setDictionaries] = useState([]);
  const [dictionaryTypes, setDictionaryTypes] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    type: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  useEffect(() => {
    getDictionaries(pagination);
    getDictionaryTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDictionaries(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => history.push(`/panel/dictionaries/${id}`)
    }),
    row => ({
      label: 'Usuń',
      icon: <VscTrash size={24} />,
      event: id => openModal('Czy chcesz usunąć rekord?', 'Pamiętaj, że operacja jest nieodwracalna.', () => removeDictionary(id)),
      color: COLORS.errorRed2,
    }),
  ];

  const removeDictionary = async id => {
    try {
      setDictionariesLoading(true);
      await DictionariesService.removeDictionary(id);
      await getDictionaries(pagination);
    } catch (err) {
      console.error(err);
      setDictionariesLoading(false);
    }
  };

  const getDictionaries = async (pagination, searchParam = search) => {
    try {
      setDictionariesLoading(true);

      let options = {
        itemsPerPage: pagination.perPage,
        page: pagination.page,
        type: filters.type?.value?.length ? filters.type.value : null,
      };

      if (searchParam.length) options.value = searchParam;

      const res = await DictionariesService.getDictionaries(options);

      setDictionaries(res.data._embedded?.item ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems });
      setDictionariesLoading(false);
    } catch (err) {
      setDictionariesLoading(false);
      console.error(err);
    }
  };

  const getDictionaryTypes = async () => {
    const res = await DictionariesService.getDictionaryTypes();
    const options = res.data.map(cat => ({ label: cat, value: cat }));
    setDictionaryTypes(options);
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getDictionaries({ ...pagination, page });
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const goToCreateContract = () => {
    history.push('/panel/dictionaries/create');
  };

  return (
    <AnimatePresence>
      {dictionariesLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-preview-content'>
              <FlexWrapper justify={'space-between'}>
                <div>
                  <Header size={23} beFlex>Słowniki</Header>
                  <Subtitle>Lista utworzonych słowników w systemie</Subtitle>
                </div>
                <Button zoom onClick={goToCreateContract} leftIcon={<IoMdAdd />}>Nowy słownik</Button>
              </FlexWrapper>
              <TableBox>
                <FilterWrapper>
                  <SearchBar
                    placeholder={'Wyszukaj...'}
                    onSubmit={searchParam => getDictionaries(pagination, searchParam)}
                    onChange={setSearch}
                    value={search}
                  />
                  <FilterContent>
                    <SelectFilter label={'Typ'} value={filters.type} onChange={option => handleFilterChange('type', option)} options={dictionaryTypes} />
                  </FilterContent>
                </FilterWrapper>
                <DictionariesTable
                  dictionaries={dictionaries}
                  pagination={pagination}
                  actions={actions}
                />
              </TableBox>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
}

export default DictionariesList;
