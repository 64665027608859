import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const Wrapper = styled.div`
  font-size: 10px;
  cursor: default;
  margin-bottom: 5px;
  font-family: ${FONTS.semiBold};
  margin-top: 3px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray};
  color: ${({ color }) => color ? color : COLORS.white};
  margin-left: ${({ withIndent }) => withIndent ? '15px' : 0};
`;
