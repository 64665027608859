import React, { useState, useEffect } from 'react';
import {
  NewRateButtonWrapper,
  RatesContent,
  OuterRatesContent,
  ArrowIcon,
  CarrierRatesRow,
  Name,
  NameWrapper,
  RatesGroupSelectWrapper,
  FilterField,
  LoadingVignette,
  MainFieldsWrapper,
  InfoWrapper
} from './styles';
import Input from 'components/__common/Input';
import Select from 'components/__common/Select';
import Button from 'components/__common/Button';
import { FormError, Label } from 'components/__styled/Form';
import { GoPlus } from 'react-icons/go';
import { BsCheck2 } from 'react-icons/bs';
import { prepareErrors } from 'utils/form';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Datepicker from 'components/__common/Datepicker';
import { FlexWrapper } from 'components/__styled/Layout';
import moment from 'moment';
import ContractsService from 'services/ContractsService';
import { Spinner } from 'components/__styled/Spinner';
import { displayToast } from 'utils/toast';
import { MdCancel } from 'react-icons/md';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoOuterWrapper } from 'components/Tasks/TaskForm/styles';

const RecipesRatesForm = ({
  rates,
  recipeName,
  recipeId,
  setRecipesRates,
  contractId
}) => {
  const [formSending, setFormSending] = useState(false);
  const [newRatesGroup, setNewRatesGroup] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRateOption, setCurrentRateOption] = useState(null);
  const [formData, setFormData] = useState({
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    value: null
  });

  useEffect(() => {
    if (rates.length > 0) {
      setCurrentRateOption({ label: rates[0].dateFrom, value: rates[0].id });
      setFormData({
        dateFrom: rates[0].dateFrom,
        value: rates[0].value
      });
    }
  }, [rates]);

  const handleFormChange = (field, value) => {
    let errors = { ...formErrors };
    delete errors[field];
    setFormErrors(errors);
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = (saveAll = false) => {
    if (newRatesGroup) {
      createCarrierRates(formData, saveAll);
    } else {
      updateCarrierRates(formData, saveAll);
    }
  };

  const handleChangeCurrentRate = option => {
    const curr = rates.find(rec => rec.id === option.value);
    setCurrentRateOption(curr);
    setFormData({
      dateFrom: curr.dateFrom,
      value: curr.value
    });
  };

  const updateCarrierRates = async (form, saveAll = false) => {
    try {
      setFormSending(true);
      const data = {...form, saveAll };
      await ContractsService.updateRecipeRate(contractId, recipeId, currentRateOption.value, data);
      const res = await ContractsService.getRecipesRates(contractId);
      setRecipesRates(res.data._embedded.item);
      setFormSending(false);
      setFormErrors(null);
      displayToast('success', 'Zaktualizowano cennik!');
    } catch (err) {
      setFormSending(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const createCarrierRates = async (form, saveAll = false) => {
    try {
      setFormSending(true);
      const data = {...form, saveAll };
      await ContractsService.createRecipeRate(contractId, recipeId, data);
      const res = await ContractsService.getRecipesRates(contractId);
      setRecipesRates(res.data._embedded.item);
      setFormSending(false);
      setFormErrors(null);
      setNewRatesGroup(false);
      displayToast('success', 'Dodano nowy cennik!');
    } catch (err) {
      setFormSending(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const options = rates?.map(r => ({ label: r.dateFrom, value: r.id }));
  const style = isExpanded ? { height: 'auto' } : { height: 0 };

  return (
    <CarrierRatesRow>
      {formSending && (
        <LoadingVignette>
          <Spinner />
        </LoadingVignette>
      )}
      <NameWrapper onClick={toggleAccordion}>
        <Name>{recipeName}</Name>
        <ArrowIcon>
          {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </ArrowIcon>
      </NameWrapper>
      <OuterRatesContent style={style}>
        <RatesContent>
          {!newRatesGroup && (
            <RatesGroupSelectWrapper>
              <FlexWrapper>
                <FilterField>
                  <Label>Cennik</Label>
                  <Select
                    placeholder={'Wybierz cennik'}
                    name="ratesGroup"
                    options={options}
                    asPortal={true}
                    value={currentRateOption}
                    onChange={option => handleChangeCurrentRate(option)}
                  />
                </FilterField>
              </FlexWrapper>
            </RatesGroupSelectWrapper>
          )}
          {rates.length > 0 || newRatesGroup ? (
            <MainFieldsWrapper>
              <FilterField>
                <Label>Stawka</Label>
                <Input
                  type='number'
                  min={1}
                  placeholder={'Stawka'}
                  onChange={e => handleFormChange('value', Number(e.target.value))}
                  value={formData.value}
                  isError={formErrors && formErrors[`value`]}
                />
                {formErrors && formErrors[`value`]  && <FormError>{formErrors[`value`] }</FormError>}
              </FilterField>
              <FilterField>
                <Label>Data rozpoczęcia</Label>
                <Datepicker
                  name="dateFrom"
                  onChange={value => handleFormChange('dateFrom', value)}
                  value={formData.dateFrom}
                />
              </FilterField>
            </MainFieldsWrapper>
          ) : (
            <InfoOuterWrapper>
              <InfoWrapper>
                <InfoBanner>Brak zdefiniowanych stawek!</InfoBanner>
              </InfoWrapper>
            </InfoOuterWrapper>
          )}
        </RatesContent>
        <NewRateButtonWrapper>
          <FlexWrapper justify={'space-between'} fullWidth>
            <FlexWrapper>
              <Button
                disabled={!formData.value}
                secondary
                leftIcon={<BsCheck2 size={18}/>}
                zoom
                onClick={() => handleSubmit(true)}
              >
                {newRatesGroup ? 'Utwórz dla wszystkich' : 'Zapisz dla wszystkich'}
              </Button>
              <Button
                disabled={!formData.value}
                leftIcon={<BsCheck2 size={18}/>}
                zoom
                onClick={() => handleSubmit()}
              >
                {newRatesGroup ? 'Utwórz cennik' : 'Zapisz cennik'}
              </Button>
            </FlexWrapper>
            <div>
              <Button
                zoom
                onClick={() => setNewRatesGroup(!newRatesGroup)}
                leftIcon={newRatesGroup ? <MdCancel size={16} /> : <GoPlus size={16} />}
              >
                {!newRatesGroup ? 'Nowy cennik' : 'Anuluj'}
              </Button>
            </div>
          </FlexWrapper>
        </NewRateButtonWrapper>
      </OuterRatesContent>
    </CarrierRatesRow>
  );
}

export default RecipesRatesForm;
