import React from 'react';
import { ItemWrapper, ItemContent, ItemLink, ItemLabel, ItemIcon } from './styles';
import {NotificationDot, NotificationDotNumber, RelativeBox} from "../Nav/styles";
import NotificationContext from "../../context/NotificationContext";


const NavItem = ({ label, to, icon, isActive }) => {
  const { notification,notificationCountAlert } = React.useContext(NotificationContext);

  return (
    <ItemWrapper isActive={isActive}>
      <ItemContent>
        <ItemLink
          to={to}
          onClick={
              () => {
                  if (localStorage.getItem('state')) {
                      localStorage.removeItem('state')
                  }

                  if (localStorage.getItem('register_number')) {
                      localStorage.removeItem('register_number')
                  }
              }
          }
        >
            <RelativeBox>
                {icon ? <ItemIcon>{icon}</ItemIcon>: null}
                {notification === label ? <> <NotificationDot/>  </> : null}
            </RelativeBox>
          <ItemLabel>{label}</ItemLabel>
            {notificationCountAlert && notification === label ? (
                <>
                    <NotificationDotNumber>
                        {notificationCountAlert}
                    </NotificationDotNumber>
                </>
            ) : null }

        </ItemLink>
      </ItemContent>
    </ItemWrapper>
  );
};

export default NavItem;
