import React, { useState, useEffect } from 'react';
import { ShadowBox } from 'components/__styled/ShadowBox';
import {
  SummaryWrapper,
  SummaryItem,
  SummaryItemText,
  SummaryItemValue,
  SummaryItemSpace,
} from './styles';

const FriSettlementItemSummary = ({ subcategory }) => {

  return (

      <SummaryWrapper>
        <SummaryItem>
          <SummaryItemText>Podkategoria</SummaryItemText>
          <SummaryItemSpace />
          <SummaryItemValue>{subcategory.subcategory}</SummaryItemValue>
        </SummaryItem>
      </SummaryWrapper>

  );
};

export default FriSettlementItemSummary;
