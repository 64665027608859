import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import { VehicleStatus, SelectWrapper, ConfirmButton } from './styles';
import { COLORS } from 'config/theme';
import ReactTooltip from 'react-tooltip';
import Select from 'components/__common/Select';
import { BsCheck2 } from 'react-icons/bs';
import { BiTrashAlt } from 'react-icons/bi';
import { Spinner } from 'components/__styled/Spinner';

const ContractVehiclesTable = ({ vehicles, devices, carriers, pagination, assignDeviceToVehicle, toggleVehicleDevice, deviceSetLoading }) => {

  const returnProperStatus = status => {
    switch (status) {
      case 'INACTIVE':
        return {
          name: 'Nieaktywny',
          bgColor: COLORS.lightGray,
          textColor: COLORS.dark,
        };
      default:
        return {
          name: 'Aktywny',
          bgColor: COLORS.green,
          textColor: COLORS.white,
        };
    }
  };

  const findCarrierName = id => {
    const found = carriers.find(c => c.value === id);
    return found ? found.label : '';
  };

  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Nr rejestracyjny</Th>
            <Th>Przewoźnik</Th>
            <Th>Status</Th>
            <Th>Tonaż</Th>
            <Th>Lokalizator</Th>
          </Tr>
        </THead>
        <TBody>
          {vehicles && vehicles.length > 0 ?
            vehicles.map((row, i) => {
              const vehicle = row._embedded.car;
              const device = row._embedded.device;
              const status = returnProperStatus(vehicle.status);
              const carrierName = findCarrierName(row._embedded.car._embedded.carrier.id);
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td>{vehicle.registrationNumber}</Td>
                  <Td>{carrierName}</Td>
                  <Td>
                    <VehicleStatus color={status.textColor} bgColor={status.bgColor}>
                      {status.name}
                    </VehicleStatus>
                  </Td>
                  <Td>{vehicle.carTonnage}t</Td>
                  <Td>
                    <SelectWrapper>
                      <Select
                        disabled={device && device.saved}
                        asPortal={true}
                        onChange={option => assignDeviceToVehicle(i, option)}
                        options={devices}
                        value={device ? { value: device.id, label: device.name } : null}
                        clearable={device && !device.saved}
                      />
                      <ConfirmButton
                        bgColor={device && device.saved ? COLORS.gray : COLORS.main}
                        textColor={device && device.saved ? COLORS.white : COLORS.dark}
                        disabled={!device || deviceSetLoading}
                        onClick={() => toggleVehicleDevice(vehicle.id, device.id, device.name, !device || !device.saved, row.id)}
                      >
                        {
                          deviceSetLoading && deviceSetLoading === row.id
                            ? <Spinner size={15} color={device?.saved ? COLORS.white : COLORS.dark}/>
                            : !device?.saved
                              ? <BsCheck2 size={15}/> : <BiTrashAlt size={15} />
                        }
                      </ConfirmButton>
                    </SelectWrapper>
                  </Td>
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={5}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default ContractVehiclesTable;
