import styled, { css } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const InputWrapper = styled.div`
  flex: 1;
  position: relative;
`;

export const SystemInput = styled.input`
  padding: 8px 15px;
  width: 100%;
  border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
  font-size: 12px;
  font-family: ${FONTS.regular};
  border-radius: 6px;
  outline: none;
  min-height: 40px;
  &:active,
  &:focus {
    border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.main};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  }
  ${({ disabled }) => disabled && css`
    background-color: #ffffff;
  `};
  ${({ small }) => small && css`
    padding: 8px;
    min-height: 20px;
  `};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 11px;
  svg {
    font-size: 18px;
    color: ${COLORS.textGray};
  }
`;
