import React from 'react';
import { Area } from './styles';

const Textarea = ({ disabled, name, placeholder = 'Wpisz tekst...', onChange, value, isError }) => {
  return (
    <Area disabled={disabled} onChange={onChange} placeholder={placeholder} rows={8} value={value} isError={isError}/>
  );
};

export default Textarea;
