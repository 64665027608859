import React, { useState, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import Pagination from 'components/Pagination';
import { AnimatePresence } from 'framer-motion';
import {FlexWrapper} from 'components/__styled/Layout';
import AlertsService from 'services/AlertsService';
import AlertsTable from 'components/Alerts/AlertsTable';
import SearchBar from 'components/SearchBar';
import SelectFilter from 'components/SelectFilter';
import {BsCheck2} from "react-icons/bs";
import {COLORS} from "../../../config/theme";
import DateFilter from "../../../components/DateFilter";
import ContractsService from "../../../services/ContractsService";
import moment from "moment/moment";

const AlertsList = () => {
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [alertsTypes, setAlertsTypes] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    type: '',
    date: new Date(),
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  useEffect(() => {
    getAlerts(pagination);
    getAlertsTypes();
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAlerts(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const actions = [
      /* przeczytane
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => history.push(`/panel/dictionaries/${id}`)
    }),
    */
  ];

  const getContracts = async () => {
    const res = await ContractsService.getContracts();
    const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id })) : [];
    setContracts(options);
  };

  const getAlerts = async (pagination, searchParam = search) => {
    try {
      setAlertsLoading(true);
      let options = {
        itemsPerPage: pagination.perPage,
        page: pagination.page,
        date: filters.date ? moment(filters.date).format('YYYY-MM-DD') : null,
        contractId: filters.contract ? filters.contract.value : null,
        type: filters.type?.value?.length ? filters.type.value : null,
      };

      if (searchParam.length) options.value = searchParam;
      const res = await AlertsService.getAlerts(options);

      const data = res.data._embedded && res.data._embedded.item.map(i => ({
        ...i,
        resolved: i.resolved ? <BsCheck2 size={22} color={COLORS.green}/> : '---'
      }));
      setAlerts(data ? data : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems });
      setAlertsLoading(false);
    } catch (err) {
      setAlertsLoading(false);
      console.error(err);
    }
  };

  const getAlertsTypes = async () => {
    const res = await AlertsService.getAlertsTypes();
    const options = Object.entries(res.data).map(e => ({ label: e[1], value: e[0] }));
    setAlertsTypes(options);
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getAlerts({ ...pagination, page });
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <AnimatePresence>
      {alertsLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-preview-content'>
              <FlexWrapper justify={'space-between'}>
                <div>
                  <Header size={23} beFlex>Alerty</Header>
                  <Subtitle>Lista wygenerowanych alertów</Subtitle>
                </div>
              </FlexWrapper>
              <TableBox>
                <FilterWrapper>
                  <SearchBar
                    placeholder={'Wyszukaj...'}
                    onSubmit={searchParam => getAlerts(pagination, searchParam)}
                    onChange={setSearch}
                    value={search}
                  />
                  <FilterContent>
                    <SelectFilter label={'Kontrakt'} value={filters.contract} onChange={option => handleFilterChange('contract', option)} options={contracts} />
                    <SelectFilter label={'Typ'} value={filters.type} onChange={option => handleFilterChange('type', option)} options={alertsTypes} />
                    <DateFilter value={filters.date} onChange={date => handleFilterChange('date', date)} />
                  </FilterContent>
                </FilterWrapper>
                <AlertsTable
                  alerts={alerts}
                  pagination={pagination}
                  actions={actions}
                />
              </TableBox>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
}

export default AlertsList;
