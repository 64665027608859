import React, { useState } from 'react';
import { GenerateButtonWrapper } from './styles';
import { FormContent, FormElement, FormRow, Label } from 'components/__styled/Form';
import { setSelectOption } from 'utils/form';
import Datepicker from 'components/__common/Datepicker';
import Select from 'components/__common/Select';
import Button from 'components/__common/Button';
import { AiOutlineFileExcel } from 'react-icons/ai';
import moment from 'moment';

const typesOptions = [
  { value: 'SETTLING', label: 'Rozliczenie' },
  { value: 'WEIGHTING', label: 'Ważenia' }
];

const ReportForm = ({ contracts, generateReport, reportGenerating }) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [form, setForm] = useState({
    contractId: null,
    type: typesOptions[0].value,
    dateFrom: today,
    dateTo: today,
  });

  const handleFormChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };



  return (
    <FormContent>
      <FormRow>
        <FormElement>
          <Label required>Typ</Label>
          <Select
              name="type"
              options={typesOptions}
              onChange={option => handleFormChange('type', option.value)}
              value={setSelectOption(typesOptions, form.type)}
              placeholder={'Wybierz typ...'}
          />
        </FormElement>
        <FormElement>
          <Label required>Kontrakt</Label>
          <Select
            name="contractId"
            options={contracts}
            placeholder={'Wybierz kontrakt...'}
            onChange={option => handleFormChange('contractId', option.value)}
            value={setSelectOption(contracts, form.contractId)}
          />
        </FormElement>
      </FormRow>
      <FormRow>
        <FormElement>
          <Label required>Data od</Label>
          <Datepicker
            name="dateFrom"
            onChange={value => handleFormChange('dateFrom', value)}
            value={form.dateFrom}
          />
        </FormElement>
        <FormElement>
          <Label required>Data do</Label>
          <Datepicker
            name="dateTo"
            onChange={value => handleFormChange('dateTo', value)}
            value={form.dateTo}
          />
        </FormElement>
      </FormRow>
      <GenerateButtonWrapper>
        <Button
          onClick={() => generateReport(form)}
          disabled={reportGenerating}
          isLoading={reportGenerating}
          leftIcon={<AiOutlineFileExcel size={20} />}
          zoom
        >
          Wygeneruj raport
        </Button>
      </GenerateButtonWrapper>
    </FormContent>
  );
};

export default ReportForm;
