import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'config/theme';
import { ShadowBox } from 'components/__styled/ShadowBox';

export const Wrapper = styled(ShadowBox)`
  margin-top: 40px;
  display: inline-block;
  @media (max-width: ${BREAKPOINTS.large}) {
    width: 100%;
  }
`;

export const SummaryContent = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.large}) {
    justify-content: center;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 70px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-right: 30px;
    margin-bottom: 20px;
  } 
`;

export const Desc = styled.div`
  padding-left: 20px;
`;

export const InfoIcon = styled.div`
  min-width: 50px;
  width: 50px;
  height: 50px;
  background-color: ${COLORS.lightGray2};
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${COLORS.dark2};
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    svg {
      width: 60%;
    }
  }
`;

export const ItemLabel = styled.div`
  font-size: 13px;
  color: ${COLORS.dark};
  margin-bottom: 3px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`;

export const ItemValue = styled.div`
  font-size: 20px;
  color: ${COLORS.main};
  font-family: ${FONTS.bold};
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 17px;
  }
`;
