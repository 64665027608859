import styled, { css } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const VehicleStatus = styled.div`
  font-size: 10px;
  font-family: ${FONTS.regular};
  border-radius: 5px;
  padding: 4px 10px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray2};
  color: ${({ color }) => color ? color : COLORS.dark};
  display: inline-block;
`;

export const SelectWrapper = styled.div`
  width: 180px;
  margin: 0 0 0 auto;
  display: flex;
  .bx__group-heading {
    font-size: 15px !important;
    margin-bottom: 10px;
    color: ${COLORS.dark};
  }
  .bx {
    &__placeholder {
      font-size: 11px;
      font-family: ${FONTS.regular};
    }
    &__control {
      min-height: 30px !important;
      height: 30px;
      min-width: 130px;
      width: 100%;
      background-color: ${({ selected }) => selected ? COLORS.lightGray2 : COLORS.white};
    }
    &__input {
      font-size: 11px !important;
    }
    &__indicator {
      padding: 4px;
    }
    &__option {
      font-size: 11px;
    }
    &__single-value {
      font-size: 11px;
    }
    &__menu {
      font-size: 11px;
    }
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.main};
  color: ${({ textColor }) => textColor ? textColor : COLORS.dark};
  outline: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: default;
  `};
  &:hover {
    background-color: ${COLORS.darkGray};
    color: ${COLORS.white};
    ${({ disabled }) => disabled && css`
      background-color: ${COLORS.main};
      color: ${COLORS.dark};
    `};
  }
`;
