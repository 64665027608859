export const COLORS = {
  main: '#FBC900',
  lightMain: '#fbc90308',
  light: '#FDFDFD',
  dark: '#4e4e4e',
  dark2: '#666666',
  lightGray: '#ebebeb',
  lightGray2: '#f3f3f3',
  gray: '#ADAAAA',
  middleGray: '#DEDEDE',
  darkGray: '#727272',
  white: '#FFF',
  textGray: '#C7C7C7',
  textGray2: '#E6E6E6',
  errorRed: '#FF4F3C',
  errorRed2: '#ff9f95',
  green: '#64c967',
};

export const BREAKPOINTS = {
  tiny: '420px',
  little: '600px',
  small: '767px',
  medium: '992px',
  large: '1200px',
  big: '1300px',
  huge: '1400px',
};

export const FONTS = {
  regular: 'Montserrat, sans-serif',
  medium: 'MontserratMedium, sans-serif',
  semiBold: 'MontserratSemiBold, sans-serif',
  bold: 'MontserratBold, sans-serif',
  extraBold: 'MontserratExtraBold, sans-serif',
  light: 'MontserratLight, sans-serif',
};

export const theme = {
  COLORS,
  FONTS,
  BREAKPOINTS,
};

export default theme;
