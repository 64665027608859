import React from 'react';
import { StatusItem, StatusButton, DetailsText, StatusList, CarrierConfirmStatus } from './styles';
import { scheduleStatusColors, scheduleStageNames } from 'config/statuses';
import ScheduleStatusModalContext from 'context/ScheduleStatusModalContext';

const TinyStatuses = ({ statuses, scheduleId }) => {

  const { openStatusesModal } = React.useContext(ScheduleStatusModalContext);

  const getStatusesDetails = () => {
    openStatusesModal(statuses, scheduleId);
  };

  return (
    <StatusButton onClick={getStatusesDetails}>
      <StatusList>
        {statuses && statuses.map((status, i) => {
          const styles = scheduleStatusColors[status.stage];
          return (
            <StatusItem key={i} bgColor={styles.bgColor} textColor={styles.textColor}>
              {scheduleStageNames[status.levelName].charAt(0)}
              {status.stageData && status.stageData.accepted !== undefined && (
                <CarrierConfirmStatus>
                  <span>{status.stageData.accepted}/</span>
                  <span>{status.stageData.rejected}/</span>
                  <span>{status.stageData.waiting} </span>
                </CarrierConfirmStatus>
              )}
            </StatusItem>
          );
        })}
      </StatusList>
      <DetailsText>Szczegóły</DetailsText>
    </StatusButton>
  );
};

export default TinyStatuses;
