import styled, { css } from 'styled-components';
import { FONTS, COLORS } from 'config/theme';

export const Checkmark = styled.span`
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: ${COLORS.lightGray};
  &::after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ${({ isLabel }) => !isLabel && css`
    position: relative;
    display: inline-block;
  `};
`;

export const Check = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: 1px solid ${COLORS.lightGray};
  &:checked ~ ${Checkmark} {
    background-color: ${COLORS.main};
    border-color: ${COLORS.main};
    &::after {
      display: ${({ isLabel }) => !isLabel ? 'inline-block' : 'block'};
      border-color: ${COLORS.dark}
    }
  }
`;

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 11px;
  font-family: ${FONTS.regular};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    ${Check} ~ ${Checkmark} {
      background-color: ${COLORS.main};
      display: ${({ isLabel }) => !isLabel ? 'inline-block' : 'block'};
    }
    ${Check} ~ ${Checkmark}::after {
      border-color: ${COLORS.dark};
    }
  }
`;
