import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import { SelectWrapper } from './styles';

const Select = ({
  disabled = false,
  onChange,
  onFocus,
  options,
  value,
  isError,
  asPortal = false,
  isLoading = false,
  isMulti = false,
  withTips = false,
  isAsync = false,
  isCreatable = false,
  loadOptions,
  placeholder = 'Wybierz...',
  clearable = false
}) => {

  const returnProperSelect = () => {
    if (isAsync) {
      return (
        <AsyncSelect
          loadOptions={loadOptions}
          defaultOptions={options}
          isDisabled={disabled}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          classNamePrefix='bx'
          placeholder={placeholder}
          noOptionsMessage={()=> "Brak wyników..."}
          menuPortalTarget={asPortal ? document.querySelector('body') : null}
          isLoading={isLoading}
          isMulti={isMulti}
          isClearable={clearable}
        />
      );
    }

    if (isCreatable) {
      return (
        <CreatableSelect
          classNamePrefix='bx'
          isMulti={isMulti}
          placeholder={placeholder}
          noOptionsMessage={() => "Brak wyników..."}
          formatCreateLabel={input => `Dodaj ${input}`}
          onChange={onChange}
          options={options}
          value={value}
          isClearable
        />
      );
    }

    return (
      <ReactSelect
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        classNamePrefix='bx'
        placeholder={placeholder}
        options={options}
        noOptionsMessage={()=> "Brak wyników..."}
        menuPortalTarget={asPortal ? document.querySelector('body') : null}
        isLoading={isLoading}
        isMulti={isMulti}
        isClearable={clearable}
      />
    );
  }

  return (
    <SelectWrapper isError={isError} isCreatable={isCreatable} withTips={withTips}>
      {returnProperSelect()}
    </SelectWrapper>
  );
};

export default Select;
