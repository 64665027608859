import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import ReactTooltip from 'react-tooltip';

const DictionariesTable = ({ dictionaries, actions, pagination }) => {
  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Typ</Th>
            <Th>Wartość</Th>
            <Th width={100}>Akcje</Th>
          </Tr>
        </THead>
        <TBody>
          {dictionaries && dictionaries.length > 0 ?
            dictionaries.map((row, i) => {
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td>{row.type}</Td>
                  <Td>{row.value}</Td>
                  {actions && (
                    <Td width={100}>
                      <ActionButtonWrapper>
                        {actions.map((actionFn, i) => {
                          const action = actionFn(row);
                          return (
                            <React.Fragment key={i}>
                              <ActionButton
                                data-tip={action.label}
                                color={action.color}
                                onClick={() => action.event(row.id)}
                              >
                                {action.icon}
                              </ActionButton>
                            </React.Fragment>
                          );
                        })}
                      </ActionButtonWrapper>
                    </Td>
                  )}
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={6}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default DictionariesTable;
