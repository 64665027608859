import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, FONTS } from 'config/theme';

export const ItemContent = styled.div``;

export const ItemIcon = styled.div`
  margin-right: 15px;
  color: ${COLORS.textGray};
  stroke: ${COLORS.textGray};
  transition: color .2s ease;
`;

export const ItemLabel = styled.span`
  font-size: 13px;
  color: ${COLORS.textGray};
  font-family: ${FONTS.semiBold};
  transition: color .2s ease;
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 7px;
  transition: background-color 0.2s ease;
  border-radius: 10px;
  position: relative;
  &:hover {
    ${ItemIcon} {
      color: ${COLORS.dark};
    }
    ${ItemLabel} {
      color: ${COLORS.dark};
    }
  }
`;

export const ItemWrapper = styled.li`
    ${({ isActive }) => isActive && css`
        ${ItemIcon} {
            color: ${COLORS.dark};
        }
        ${ItemLabel} {
            color: ${COLORS.dark};
        }
    `};
`;


