import React from 'react';
import { InputWrapper, SystemInput } from './styles';

const Input = ({
  disabled = false,
  type = 'text',
  onChange,
  name,
  value,
  isError,
  small = false,
  placeholder = '',
  min = 0,
}) => {

  return (
    <InputWrapper>
      <SystemInput
        min={min}
        small={small}
        data-testid={`input-${name}`}
        name={name}
        disabled={disabled}
        isError={isError}
        onChange={onChange}
        type={type}
        value={value !== null ? value : ''}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
};

export default Input;
