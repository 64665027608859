import React from 'react';
import {
    Tbl,
    THead,
    TBody,
    Td,
    Th,
    Tr,
    ActionButton,
    ActionButtonWrapper,
    NoRecords,
    TblWrapper,
} from 'components/__styled/Table';
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import {friSettlementStatusColors} from "../../../config/statuses";
import StatusChip from "../../Tasks/StatusChip";
import {IoAlertCircle} from "react-icons/io5";
import {RowError} from "./styles";
import {COLORS} from "../../../config/theme";

const FriSettlementTable = ({friSettlements, actions, pagination}) => {
    return (
        <TblWrapper>
            <ReactTooltip className="tooltip" effect="solid" />
            <Tbl>
                <THead>
                    <Tr>
                        <Th></Th>
                        <Th>Lp</Th>
                        <Th>Kontrakt</Th>
                        <Th>Status</Th>
                        <Th>Data Od</Th>
                        <Th>Data Do</Th>
                        <Th>Utworzono</Th>
                        <Th>Zatwierdzono</Th>
                        <Th width={100}>Akcje</Th>
                    </Tr>
                </THead>
                <TBody>
                    {friSettlements && friSettlements.length > 0 ?
                        friSettlements.map((row,i) =>
                        {
                            return (
                                <Tr key={i} >
                                    <Td>
                                        {row && row.errors.length
                                            ? <RowError>
                                                <IoAlertCircle
                                                    data-tip={row.errors}
                                                    size={20}
                                                />
                                            </RowError>
                                            : ''
                                        }
                                    </Td>
                                    <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                                    <Td>
                                        {row._embedded && row._embedded.contract
                                            ? row._embedded.contract.name
                                            : '-'
                                        }
                                    </Td>
                                    <Td>
                                        <StatusChip
                                            withIndent
                                            bgColor={row && friSettlementStatusColors[row.status].color}
                                        >
                                            {row && friSettlementStatusColors[row.status].name}
                                        </StatusChip>
                                        {row && row.duplicated
                                            ? <StatusChip withIndent bgColor={COLORS.errorRed}>Duplikat</StatusChip>
                                            : ''
                                        }
                                    </Td>
                                    <Td>{moment(row.dateFrom).format('YYYY-MM-DD')}</Td>
                                    <Td>{moment(row.dateTo).format('YYYY-MM-DD')}</Td>
                                    <Td>{row.createdByName}<br/>{row.createdAt}</Td>
                                    <Td>{row.confirmedByName}<br/>{row.confirmedAt}</Td>
                                    <Td>
                                        <ActionButtonWrapper>
                                            {actions.map((actionFn, i) => {
                                                const action = actionFn(row);
                                                return (
                                                    <React.Fragment key={i}>
                                                        <ActionButton
                                                            data-tip={action.label}
                                                            color={action.color}
                                                            onClick={() => action.event(row.id)}
                                                        >
                                                            {action.icon}
                                                        </ActionButton>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </ActionButtonWrapper>
                                    </Td>
                                </Tr>
                            );
                        }) : (
                            <Tr>
                                <Td colSpan={6}>
                                    <NoRecords>Brak rekordów...</NoRecords>
                                </Td>
                            </Tr>
                        )
                    }
                </TBody>
            </Tbl>
        </TblWrapper>
    )
};

export default FriSettlementTable;