import React from 'react';
import { ProgressBox } from './styles';
import TaskProgressItem from '../TasksProgressItem';

const TaskFormProgress = ({ activeStep, formSteps, setActiveStep, lastFinishedStep }) => {
  return (
    <ProgressBox>
      {formSteps && formSteps.map((step, i) => {
        return (
          <React.Fragment key={i}>
            {step && (
              <TaskProgressItem
                key={i}
                step={i+1}
                isActive={activeStep === i + 1}
                stepTitle={step.title}
                setActiveStep={setActiveStep}
                lastFinishedStep={lastFinishedStep}
              />
            )}
          </React.Fragment>
        );
      })}
    </ProgressBox>
  );
};

export default TaskFormProgress;
