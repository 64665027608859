import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const Wrapper = styled.div``;

export const Stage = styled.div`
  padding: 20px 0;
  font-family: ${FONTS.semiBold};
  font-size: 12px;
`;

export const Statuses = styled.div``;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Color = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${({ color }) => color ? color : COLORS.textGray2};
`;

export const Name = styled.div`
  font-size: 12px;
`;

export const ShowLegendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.textGray};
  color: ${COLORS.white};
  border: none;
  font-family: ${FONTS.semiBold};
  margin-top: 15px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color .3s ease;
  &:hover {
    background-color: ${COLORS.dark};
  }
`;

export const ShowLegendButtonIcon = styled.div`
  margin-left: 10px;
`;

export const LegendContent = styled.div``;
