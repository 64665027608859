import React, {useContext, useState} from 'react';
import { LogoutButton, UserAvatar, UserContextMenu, UserName, Wrapper } from './styles';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';
import {useMsal} from '@azure/msal-react';
import UserContext from 'context/UserContext';

const UserInfo = () => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const { instance } = useMsal();
  const { user } = useContext(UserContext);

  const formatUsernameAvatar = () => {
    if (user) {
      const name = user ? user.name : 'Unknown';
      const split = name.split(' ');
      return split[1] ? `${split[0].charAt(0)}${split[1].charAt(0)}` : `${split[0].charAt(0)}`;
    } else {
      return '';
    }
  };

  return (
    <Wrapper onClick={() => setContextMenuOpen(!contextMenuOpen)}>
      <UserAvatar>{formatUsernameAvatar()}</UserAvatar>
      <UserName>{user ? user.name : 'Unknown'}</UserName>
      <IoMdArrowDropdown />
      {contextMenuOpen && (
        <UserContextMenu>
          <LogoutButton onClick={() => instance.logoutRedirect()}>
            <FiLogOut size={20}/>
            Wyloguj
          </LogoutButton>
        </UserContextMenu>
      )}
    </Wrapper>
  );
};

export default UserInfo;
