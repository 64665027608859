import styled from 'styled-components';
import { ShadowBox } from 'components/__styled/ShadowBox';
import { FONTS } from 'config/theme';

export const AuthWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(ShadowBox)`
  padding: 20px 30px;
  width: 350px;
`;

export const Header = styled.h2`
  font-family: ${FONTS.semiBold};
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
