import React, { useState } from 'react';
import { GenerateButtonWrapper } from './styles';
import { FormContent, FormElement, FormRow, Label } from 'components/__styled/Form';
import { setSelectOption } from 'utils/form';
import Datepicker from 'components/__common/Datepicker';
import Select from 'components/__common/Select';
import Button from 'components/__common/Button';
import { AiOutlineFileExcel } from 'react-icons/ai';
import moment from 'moment';


const FriSettlementForm = ({ contracts, onSubmit, reportGenerating }) => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    const [form, setForm] = useState({
        contractId: null,
        dateFrom: today,
        dateTo: today,
    });

    const handleFormChange = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        onSubmit(form);
    };



    return (
        <FormContent>
            <FormRow>
                <FormElement>
                    <Label required>Kontrakt</Label>
                    <Select
                        name="contractId"
                        options={contracts}
                        placeholder={'Wybierz kontrakt...'}
                        onChange={option => handleFormChange('contractId', option.value)}
                        value={setSelectOption(contracts, form.contractId)}
                    />
                </FormElement>
            </FormRow>
            <FormRow>
                <FormElement>
                    <Label required>Data od</Label>
                    <Datepicker
                        name="dateFrom"
                        onChange={value => handleFormChange('dateFrom', value)}
                        value={form.dateFrom}
                    />
                </FormElement>
                <FormElement>
                    <Label required>Data do</Label>
                    <Datepicker
                        name="dateTo"
                        onChange={value => handleFormChange('dateTo', value)}
                        value={form.dateTo}
                    />
                </FormElement>
            </FormRow>
            <GenerateButtonWrapper>
                <Button
                    onClick={() => handleSubmit()}
                    disabled={reportGenerating}
                    isLoading={reportGenerating}
                    leftIcon={<AiOutlineFileExcel size={20} />}
                    zoom
                >
                    Wygeneruj FRI
                </Button>
            </GenerateButtonWrapper>
        </FormContent>
    );
};

export default FriSettlementForm;