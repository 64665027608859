import styled from 'styled-components';
import { FONTS, BREAKPOINTS, COLORS } from 'config/theme';
import { TblWrapper, Tbl, Td, Th, TBody, THead } from 'components/__styled/Table';
import { StatusDot } from 'components/__styled/StatusDot';

export const ButtonsWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const CourseError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${COLORS.errorRed};
  }
`;

export const CarrierSelectWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  .bx__group-heading {
    font-size: 15px !important;
    margin-bottom: 10px;
    color: ${COLORS.dark};
  }
  .bx {
    &__placeholder {
      font-size: 11px;
      font-family: ${FONTS.regular};
    }
    &__control {
      min-height: 30px !important;
      height: 30px;
      min-width: 130px;
      width: 100%;
      background-color: ${({ selected }) => selected ? COLORS.lightGray2 : COLORS.white};
    }
    &__input {
      font-size: 11px !important;
    }
    &__indicator {
      padding: 4px;
    }
    &__option {
      font-size: 11px;
    }
    &__single-value {
      font-size: 11px;
    }
    &__menu {
      font-size: 11px;
    }
  }
`;

export const ScheduleTblWrapper = styled(TblWrapper)`
  height: ${({ tableHeight }) => tableHeight ? tableHeight : '100%' };
  overflow-y: visible;
  ${Tbl} {
    min-width: 600px;
    @media (max-width: ${BREAKPOINTS.medium}) {
      min-width: 100px;
    }
  }
  ${Td} {
    align-items: center;
    width: auto;
    min-width: auto;
    align-self: center;
    height: 80px;
  }
  ${Th} {
    width: auto;
    min-width: auto;
  }
`;

export const CourseStatus = styled(StatusDot)`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ color }) => color ? color : COLORS.textGray};
  margin: 4px;
  cursor: pointer;
`;

export const LapSeparator = styled.div`
  display: flex;
  height: 7px;
  background-color: #fffcfc;
  width: 100%;
`;

export const AbsoluteIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: white;
  svg {
    transition: fill .2s ease;
    stroke: ${COLORS.textGray};
  }
  &:hover {
    svg {
      stroke: ${({ color }) => color ? color : COLORS.dark};
    }
  };
`;

export const ScheduleBody = styled(TBody)`
  overflow: auto;
`;

export const ScheduleTHead = styled(THead)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
`;
