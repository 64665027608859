import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS, FONTS, COLORS } from 'config/theme';
import { rem } from 'polished';
import Montserrat from 'assets/fonts/Montserrat-Regular.ttf';
import MontserratBold from 'assets/fonts/Montserrat-Bold.ttf'
import MontserratSemiBold from 'assets/fonts/Montserrat-SemiBold.ttf';
import MontserratExtraBold from 'assets/fonts/Montserrat-ExtraBold.ttf';
import MontserratLight from 'assets/fonts/Montserrat-Light.ttf';
import MontserratMedium from 'assets/fonts/Montserrat-Medium.ttf'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url(${Montserrat});
  }

  @font-face {
    font-family: 'MontserratBold';
    src: url(${MontserratBold});
  }

  @font-face {
    font-family: 'MontserratSemiBold';
    src: url(${MontserratSemiBold});
  }

  @font-face {
    font-family: 'MontserratExtraBold';
    src: url(${MontserratExtraBold});
  }

  @font-face {
    font-family: 'MontserratLight';
    src: url(${MontserratLight});
  }

  @font-face {
    font-family: 'MontserratMedium';
    src: url(${MontserratMedium});
  }
  
  * {
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }

  html {
    font-size: 16px;
    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: 14px;
    }
  }

  body {
    margin: 0;
    overflow-x: hidden;
    font-family: Montserrat, sans-serif;
    color: ${COLORS.dark};
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .tooltip {
    padding: 5px 10px !important;
    font-size: 10px !important;
    font-family: ${FONTS.medium};
    z-index: 1001 !important;
  }
  .tooltip:before {
      display: none;
  }
  
  .rc-time-picker-panel-input {
    font-family: ${FONTS.regular};
    text-align: center;
  }
  
  .rc-time-picker-panel-narrow {
    max-width: 150px !important;
  }
  
  .rc-time-picker-panel-select {
    width: 74px !important;
  }
  
  .Toastify__toast {
    font-family: ${FONTS.medium};
    padding: 20px;
    font-size: ${rem('14px')};
    border-radius: 7px;
  }
  .bx {
    &__multi-value__label {
      font-size: 11px !important;
      padding: 5px 8px !important;
    }
    &__placeholder {
      font-size: 12px;
      color: ${COLORS.textGray};
      font-family: ${FONTS.regular};
    }
    &__indicator {
      cursor: pointer;
    }
    &__indicator-separator {
      display: none;
    }
    &__control {
      border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray} !important;
      border-radius: 6px;
      min-height: 40px !important;
      &:hover {
        border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.main} !important;
      }
      &--is-focused {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05) !important;
      }
    }
    &__input-container {
      cursor: pointer;
    }
    &__input {
      font-size: 12px !important;
      color: ${COLORS.dark} !important;
      font-family: ${FONTS.regular} !important;
    }

    &__option {
      background-color: white !important;
      font-size: 12px !important;
      color: ${COLORS.dark};
      cursor: pointer !important;
      &--is-focused,
      &--is-selected,
      &:focus,
      &:active {
        background-color: ${COLORS.main} !important;
        color: ${COLORS.dark} !important;
      }
    }
    &__single-value {
      font-size: 12px;
      color: ${COLORS.dark};
    }
    &__menu {
      font-size: 12px !important;
      color: ${COLORS.dark} !important;
      z-index: 10000 !important;
      &-notice--loading {
        font-size: 0;
        &::after {
          font-size: 12px;
          content: 'Ładowanie...';
        }
      }
    }
  }
  .bx__menu-portal {
    .bx__group {
      .bx__group-heading {
        font-size: 11px;
        color: ${COLORS.gray};
        font-family: ${FONTS.semiBold};
        margin-bottom: 10px;
      }
      .bx__option {
        padding-left: 20px;
        font-size: 11px !important;
      }
    } 
  }
  .react-datepicker-wrapper {
      width: 100%;
  }
`;
