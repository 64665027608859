import React from 'react';
import { COLORS, FONTS } from 'config/theme';

const WMBMarker = () => {
  return (
    <div style={{position: 'relative', left: '32px'}}>
      <div style={{
        position: 'absolute',
        left: '0',
        top: '0',
        borderRadius: '4px',
        backgroundColor: '#4d4d4d',
        padding: '3px 8px',
        color: `${COLORS.white}`,
        fontFamily: `${FONTS.semiBold}`,
        fontSize: '10px',
        width: '46px'
      }}>
        WMB
      </div>
    </div>
  );
};

export default WMBMarker;
