import styled from 'styled-components';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';
import { rem } from 'polished';
import { Label } from 'components/__styled/Form';
import { FlexWrapper } from 'components/__styled/Layout';

export const Header = styled.h2`
  margin-bottom: 10px;
  margin-top: 0;
  font-size: ${rem('14px')};
  font-family: ${FONTS.bold};
  color: ${COLORS.dark};
`;

export const SubHeader = styled.h3`
  margin-bottom: 10px;
  margin-top: 0;
  font-size: ${rem('12px')};
  font-family: ${FONTS.bold};
  color: ${COLORS.dark};
`;

export const Summary = styled.div`
  width: 400px;
  min-width: 370px;
  margin-left: 15px;
  @media (max-width: ${BREAKPOINTS.big}) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

export const SummaryItems = styled.div`
  @media (max-width: ${BREAKPOINTS.big}) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const SummaryItem = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SummaryItemValue = styled.div`
  margin-left: 10px;
  color: ${COLORS.main};
  font-size: ${rem('16px')};
  font-family: ${FONTS.bold};
  line-height: 1;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex: 1;
    text-align: right;
  }
`;

export const SummaryItemLabel = styled.div`
  color: ${COLORS.dark};
  font-size: ${rem('12px')};
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex: 1;
  }
`;

export const SummaryPart = styled.div`
  flex: 1;
  margin-bottom: 40px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    &:first-of-type {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin-left: 0;
    }
  }
`;

export const SummaryContent = styled.div`
  margin-top: 40px;
  button {
    margin-bottom: 0;
  }
  @media (max-width: ${BREAKPOINTS.big}) {
    display: flex;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const ContractWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  ${Label} {
    font-size: ${rem('14px')};
  }
`;

export const ContractSelect = styled.div`
  min-width: 200px;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINTS.big}) {
    max-width: 400px;
  }
`;

export const SummaryFlexWrapper = styled(FlexWrapper)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-wrap: wrap;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 -5px;
  width: 100%;
`;
