import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Content, RatesLoader, RatesLoaderText, NoCarriersSetError } from './styles';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { AnimatePresence } from 'framer-motion';
import RatesForm from 'components/Contracts/RatesForm';
import ContractsService from 'services/ContractsService';
import { Spinner } from 'components/__styled/Spinner';
import { RiAlarmWarningLine } from 'react-icons/ri';

const CarriersRates = ({ contract, ranges }) => {
  const [carriersRates, setCarriersRates] = useState(null);
  const [loading, setLoading] = useState(false);
  const { contract_id } = useParams();

  useEffect(() => {
    if (contract) {
      getContractRates(contract_id ? contract_id : contract.id);
    }
  }, [contract_id, contract]);

  const getContractRates = async contractId => {
    try {
      setLoading(true);
      const res = await ContractsService.getContractRates(contractId);
      setCarriersRates(res.data.carriersRates._embedded.item);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <Fade>
        <Header size={23}>
          Stawki dla przewoźników
        </Header>
        <Subtitle>Zarządzaj stawkami dla poszczególnych przewoźników</Subtitle>
        {loading
          ? (
            <RatesLoader>
              <Spinner />
              <RatesLoaderText>Ładowanie stawek...</RatesLoaderText>
            </RatesLoader>
          ) : (
            <AnimatePresence>
              <Fade>
                {!contract ? (
                  <NoCarriersSetError>
                    <RiAlarmWarningLine size={20} />
                    Najpierw przypisz przewoźników do kontraktu!
                  </NoCarriersSetError>
                ) : (
                  <Content>
                    {carriersRates && carriersRates.map((carrier, i) => {
                      return (
                        <RatesForm
                          key={i}
                          ranges={ranges}
                          ratesId={carrier.id}
                          ratesDates={carrier.allRanges}
                          dateFrom={carrier.dateFrom}
                          paidStopover={carrier.paidStopover}
                          rangeSleepRate={carrier.rangeSleepRate}
                          rangeWorkRate={carrier.rangeWorkRate}
                          defaultRates={carrier.carriersRatesRanges}
                          carrier={carrier._embedded?.carrier}
                          contractId={contract_id ? contract_id : contract.id}
                          setCarriersRates={setCarriersRates}
                          agreements={carrier.carrierAgreements}
                          agreement={carrier.agreement}
                        />
                      );
                    })}
                 </Content>
                )}
            </Fade>
          </AnimatePresence>
        )}
      </Fade>
    </AnimatePresence>
  );
};

export default CarriersRates;
