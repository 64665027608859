import React from 'react';
import { SelectWrapper, SelectLabel } from './styles';
import ReactSelect from 'react-select';

const SelectFilter = ({ options, onChange, value, label = 'Kontrakt' }) => {
  return (
    <SelectWrapper>
      <SelectLabel>{label}:</SelectLabel>
      <ReactSelect
        value={value}
        isClearable
        isSearchable={false}
        onChange={val => onChange(val)}
        classNamePrefix='bx-filter'
        placeholder={'Wybierz'}
        options={options}
        noOptionsMessage={()=> 'Brak wyników...'}
      />
    </SelectWrapper>
  );
};

export default React.memo(SelectFilter);
