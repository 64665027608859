import React from 'react';
import useConfirm from 'hooks/useConfirm';
import ConfirmModal from 'components/ConfirmModal';

const ModalContext =  React.createContext();
const { Provider } = ModalContext;

const ModalProvider = ({ children }) => {
  let { isVisible, onConfirm, onCancel, openModal, modalContent } = useConfirm();
  return (
    <Provider value={{ isVisible, onConfirm, onCancel, openModal, modalContent }}>
      <ConfirmModal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };

export default ModalContext;
