export const formatToHoursAndMinutes = hours => {
  if (!hours) {
    return '';
  }

  const minutes = hours * 60;
  const fullHours = Math.floor(minutes / 60);
  const fullMinutes = Math.round(minutes % 60);


  if (fullHours > 0) {
    return `${fullHours} g ${fullMinutes} min`;
  } else {
    return `${fullMinutes} min`;
  }
};

export const formatMinutes = minutes => {
  if (!minutes) {
    return '';
  }

  const fullHours = Math.floor(minutes / 60);
  const fullMinutes = Math.round(minutes % 60);

  if (fullHours > 0) {
    return `${fullHours}g ${fullMinutes}m`;
  } else {
    return `${fullMinutes}m`;
  }
};

export const returnValueAsNumber = value => {
  const replaced = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
  return replaced.length > 0 ? Number(replaced) : replaced;
};
