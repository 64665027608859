import React from 'react';
import TasksList from 'views/Tasks/TaskList';
import CarriersPlanner from 'views/Tasks/CarriersPlanner';
import Panel from 'views/Panel';
import Client from 'views/Client';
import ClientError from 'views/ClientError';
import CarrierAccept from 'views/Tasks/CarrierAccept';
import TaskManagement from 'views/Tasks/TaskManagement';
import CarrierList from 'views/Carriers/CarrierList';
import CarrierManagement from 'views/Carriers/CarrierManagement';
import VehiclesList from 'views/Vehicles/VehiclesList';
import VehicleManagement from 'views/Vehicles/VehicleManagement';
import ContractsList from 'views/Contracts/ContractsList';
import ContractManagement from 'views/Contracts/ContractManagement';
import FactoriesList from 'views/Factories/FactoriesList';
import FactoryManagement from 'views/Factories/FactoryManagement';
import FactoryAccept from 'views/Tasks/FactoryAccept';
import LaboratoriesList from 'views/Laboratories/LaboratoriesList';
import LaboratoryManagement from 'views/Laboratories/LaboratoryManagement';
import NotFound from 'views/NotFound';
import { MsalAuthenticationTemplate} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import AuthError from 'components/Auth/AuthError';
import AuthInProgress from 'components/Auth/AuthInProgress';
import Reports from 'views/Reports';
import Map from 'views/Map';
import Weightings from 'views/Weightings/WeightingsList';
import WeightingsManagement from 'views/Weightings/WeightingsManagement';
import SchedulePreview from 'views/Tasks/SchedulePreview';
import DriversTimetable from 'views/DriversTimetable';
import DictionariesList from 'views/Dictionaries/DictionariesList';
import DictionaryManagement from 'views/Dictionaries/DictionaryManagement';
import ContractVehicles from 'views/ContractVehicles';
import AlertsList from 'views/Alerts/AlertsList';
import FriSettlementList from "views/FriSettlement/FriSettlementList";
import { ROLES } from 'config/roles';
import FriSettlementCreate from "../views/FriSettlement/FriSettlementCreate";
import FriSettlementManagement from "../views/FriSettlement/FriSettlementManagement";

export const mainRoutes = [
  {
    path: '/panel',
    name: 'panel',
    exact: false,
    authRequired: true,
    component: () => (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={AuthError}
        loadingComponent={AuthInProgress}
      >
        <Panel />
      </MsalAuthenticationTemplate>
    ),
  },
  {
    path: '/client',
    name: 'client',
    exact: false,
    authRequired: false,
    component: () => <Client />,
  }
];

export const clientRoutes = [
  {
    path: '/client/carrier-accept',
    name: 'carrier-accept',
    exact: true,
    authRequired: false,
    component: () => <CarrierAccept />,
  },
  {
    path: '/client/factory-accept',
    name: 'factory-accept',
    exact: true,
    authRequired: false,
    component: () => <FactoryAccept />,
  },
  {
    path: '/client/not-found',
    name: 'not-found',
    exact: false,
    authRequired: false,
    component: () => <NotFound />,
  },
  {
    path: '/client/error',
    name: 'error',
    exact: false,
    authRequired: false,
    component: () => <ClientError />,
  },
];

export const panelRoutes = [
  {
    path: '/panel/map',
    name: 'map',
    exact: true,
    authRequired: true,
    isProtected: false,
    component: () => <Map />,
  },
  {
    path: '/panel/tasks',
    name: 'tasks',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_VIEWER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER],
    component: () => <TasksList />,
  },
  {
    path: '/panel/tasks/:task_id/preview',
    name: 'schedule-preview',
    exact: false,
    authRequired: true,
    isProtected: false,
    component: () => <TaskManagement editable={false} />,
  },
  {
    path: '/panel/tasks/:task_id/preview-schedule',
    name: 'schedule-preview',
    exact: false,
    authRequired: true,
    isProtected: false,
    component: () => <SchedulePreview />,
  },
  {
    path: '/panel/tasks/:task_id/planner',
    name: 'carriers-planner',
    exact: false,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <CarriersPlanner />,
  },
  {
    path: '/panel/tasks/:task_id/:carrier_id/accept',
    name: 'carrier-accept',
    exact: false,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <CarrierAccept />,
  },
  {
    path: '/panel/tasks/:task_id/factory-accept',
    name: 'factory-accept',
    exact: false,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER],
    component: () => <FactoryAccept />,
  },
  {
    path: '/panel/tasks/:task_id',
    name: 'single-task',
    exact: false,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <TaskManagement editable={true} />,
  },
  {
    path: '/panel/tasks/create',
    name: 'create-task',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <TaskManagement editable={true} />,
  },
  {
    path: '/panel/carriers',
    name: 'carriers-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <CarrierList />,
  },
  {
    path: '/panel/carriers/:carrier_id',
    name: 'create-carrier',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <CarrierManagement />,
  },
  {
    path: '/panel/carriers/create',
    name: 'create-carrier',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <CarrierManagement />,
  },
  {
    path: '/panel/carriers/:carrier_id/vehicles',
    name: 'vehicles-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <VehiclesList />,
  },
  {
    path: '/panel/carriers/:carrier_id/vehicles/create',
    name: 'create-vehicle',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <VehicleManagement />,
  },
  {
    path: '/panel/carriers/:carrier_id/vehicles/:vehicle_id',
    name: 'single-vehicle',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <VehicleManagement />,
  },
  {
    path: '/panel/contracts',
    name: 'contracts-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC],
    component: () => <ContractsList />,
  },
  {
    path: '/panel/contracts/create',
    name: 'create-contract',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <ContractManagement />,
  },
  {
    path: '/panel/contracts/:contract_id',
    name: 'single-contract',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC],
    component: () => <ContractManagement />,
  },
  {
    path: '/panel/factories',
    name: 'factories-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <FactoriesList />,
  },
  {
    path: '/panel/factories/create',
    name: 'create-factory',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <FactoryManagement />,
  },
  {
    path: '/panel/factories/:factory_id',
    name: 'single-factory',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <FactoryManagement />,
  },
  {
    path: '/panel/laboratories',
    name: 'laboratories-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <LaboratoriesList />,
  },
  {
    path: '/panel/laboratories/create',
    name: 'create-laboratory',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <LaboratoryManagement />,
  },
  {
    path: '/panel/laboratories/:laboratory_id',
    name: 'single-laboratory',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER],
    component: () => <LaboratoryManagement />,
  },
  {
    path: '/panel/reports',
    name: 'reports',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC],
    component: () => <Reports />
  },
  {
    path: '/panel/weightings',
    name: 'weightings',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER],
    component: () => <Weightings />
  },
  {
    path: '/panel/weightings/:weighting_id',
    name: 'single-weighting',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER],
    component: () => <WeightingsManagement />
  },
  {
    path: '/panel/drivers-timetable',
    name: 'drivers-timetable',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER],
    component: () => <DriversTimetable />
  },
  {
    path: '/panel/dictionaries',
    name: 'dictionaries',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <DictionariesList />
  },
  {
    path: '/panel/dictionaries/:dictionary_id',
    name: 'single-dictionary',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <DictionaryManagement />
  },
  {
    path: '/panel/dictionaries',
    name: 'dictionarie-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <DictionariesList />
  },
  {
    path: '/panel/dictionaries/create',
    name: 'create-dictionary',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_LOGISTIC],
    component: () => <DictionaryManagement />,
  },
  {
    path: '/panel/contract-cars',
    name: 'contract-cars-list',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_MANAGER, ROLES.ROLE_FACTORY_MANAGER, ROLES.ROLE_FACTORY_WORKER, ROLES.ROLE_LOGISTIC],
    component: () => <ContractVehicles />,
  },
  {
    path: '/panel/alerts',
    name: 'alerts',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <AlertsList />
  },
  {
    path: '/panel/fri-settlements',
    name: 'fri-settlements',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <FriSettlementList />
  },
  {
    path: '/panel/fri-settlements/create',
    name: 'create-fri-settlements',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <FriSettlementCreate />,
  },
  {
    path: '/panel/fri-settlements/:fri_id',
    name: 'single-fri',
    exact: true,
    authRequired: true,
    isProtected: true,
    availableFor: [ROLES.ROLE_ADMIN, ROLES.ROLE_LOGISTIC, ROLES.ROLE_MANAGER],
    component: () => <FriSettlementManagement />
  },
];

export const panelRedirect = {
  from: '/panel',
  to: '/panel/tasks',
};
