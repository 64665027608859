import { axios } from 'api';

const FactoriesService = {
  getFactories: params => axios.get(`/delivery-schedule/factories`, { params: { ...params }} ),
  createFactory: form => axios.post(`/delivery-schedule/factories`, form),
  removeFactory: id => axios.delete(`/delivery-schedule/factories/${id}`),
  updateFactory: (id, form) => axios.put(`/delivery-schedule/factories/${id}`, form),
  getFactory: id => axios.get(`/delivery-schedule/factories/${id}`),
  getZones: params => axios.get(`/delivery-schedule/geozones`, { params: { ...params }} ),
  getSingleZone: id => axios.get(`/delivery-schedule/geozones/${id}`),
};

export default FactoriesService;
