import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';
import { theme } from 'config/theme';
import { FlexWrapper } from 'components/__styled/Layout';

export const MapFlexWrapper = styled(FlexWrapper)`
  @media (max-width: ${BREAKPOINTS.big}) {
    flex-direction: column;
    height: calc(100vh - 200px);
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    height: auto;
  }
`;

export const MapOuterWrapper = styled.div`
  width: 100%;
`;

export const MapWrapper = styled.div`
  flex: 1;
  position: relative;
  height: 550px;
  overflow: hidden;
  border-radius: 15px;
  margin-right: 15px;
  @media (max-width: ${BREAKPOINTS.big}) {
    width: 100%;
    flex: 1;
  }
  @media (max-width: ${BREAKPOINTS.large}) {
    flex: none;
    height: 400px;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    height: 300px;
  }
  .leaflet-container {
    height: 100%;
  }
`;

export const MainWrapper = styled.div`
  margin-top: 30px;
  max-width: 1540px;
`;

export const LoadingVignette = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapLegend = styled.div`
  padding: 25px 0;
`;

export const MapLegendHeader = styled.h3`
  font-size: 13px;
  margin: 0;
  font-weight: 600;
  color: ${theme.COLORS.dark};
  margin-bottom: 5px;
`;

export const MapLegendContent = styled.div`
  display: flex;
`;

export const MapLegendItem = styled.div`
  margin-right: 15px;
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

export const LegendItemColor = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${props => props.color ? props.color : 'lightgrey'};
  margin-right: 10px;
  border-radius: 50%;
`;

export const LegendItemValue = styled.div`
  font-size: 12px;
`;
