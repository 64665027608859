import styled, { css } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';

export const StyledHeader = styled.div`
  font-family: ${FONTS.bold};
  color: ${COLORS.dark};
  font-size: ${({ size }) => rem(`${size}px`)};
  margin-top: 0;
  margin-bottom: 5px;
  ${({ beFlex }) => beFlex && css`
    display: flex;
    align-items: center;
    > div {
      margin-bottom: 0;
      margin-left: 15px;
    }
  `};
`;
