import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const OuterWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const Wrapper = styled.div`
  padding: 40px 15px 15px;
  min-height: 400px;
  @media (max-width: ${BREAKPOINTS.large}) {
    width: 100%;
  }
`;
