import React, { useState, useEffect } from 'react';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormContent, FormElement, FormError, FormRow, Label } from 'components/__styled/Form';
import Input from 'components/__common/Input';
import { FlexWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import Select from 'components/__common/Select';
import { setSelectMultiOptions, setSelectOption } from 'utils/form';
import AreaMap from 'components/Factories/AreaMap';
import FactoriesService from 'services/FactoriesService';

const FactoryForm = ({
  factory,
  zones,
  laboratories,
  formErrors,
  setFormErrors,
  onSubmit,
  formSubmitting,
  goToFactoriesList,
  getZones,
  managers,
  viewers,
  getManagers,
  getViewers
}) => {
  const [form, setForm] = useState({
    name: factory && factory.name ? factory.name : '',
    address: factory && factory.address ? factory.address : '',
    phone: factory && factory.phone ? factory.phone : '',
    factoryCapacity: factory && factory.factoryCapacity ? factory.factoryCapacity : '',
    laboratoryId: factory && factory._embedded?.laboratory?.id ? factory._embedded?.laboratory?.id  : '',
    zoneId: factory && factory._embedded?.geoZone?.id ? factory._embedded?.geoZone?.id : '',
    managersIds: factory && factory._embedded?.managers ? factory._embedded?.managers.map(el => ({ label: el.name, value: el.id })) : [],
    viewersIds: factory && factory._embedded?.viewers ? factory._embedded?.viewers.map(el => ({ label: el.name, value: el.id })) : [],
  });
  const [currentZone, setCurrentZone] = useState(null);
  const [zoneLoading, setZoneLoading] = useState(false);

  useEffect(() => {
    if (factory && factory._embedded?.geoZone) {
      const { id, name, points } = factory._embedded.geoZone;
      const zone = {
        id,
        name,
        points: points.map(p => ([p.latitude, p.longitude])),
      }
      setCurrentZone(zone);
    }
  }, [factory]);

  const handleFormChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (formErrors && setFormErrors) {
      let errors = { ...formErrors };
      delete errors[name];
      setFormErrors(errors);
    }
  };

  const handleSubmit = () => {
    const requestForm = {
      ...form,
      factoryCapacity: Number(form.factoryCapacity),
      managersIds: form.managersIds.map(m => m.value),
      viewersIds: form.viewersIds.map(l => l.value)
    };
    onSubmit(requestForm);
  };

  const getSingleZonePoints = async zoneId => {
    try {
      setZoneLoading(true);
      const res = await FactoriesService.getSingleZone(zoneId);
      setZoneLoading(false);
      const { id, name, points } = res.data;
      const zone = {
        id,
        name,
        points: points.map(p => ([p.latitude, p.longitude])),
      }
      setCurrentZone(zone);
    } catch (err) {
      console.log(err);
      setZoneLoading(false);
    }
  };

  return (
    <>
      <Header size={23}>
        {factory ? 'Edycja wytwórni' : 'Nowa wytwórnia'}
      </Header>
      <Subtitle>
        {factory
          ? 'Formularz edycji utworzonej wcześniej wytwórni.'
          : 'Formularz dodawania wytwórni masy bitumicznej.'
        }
      </Subtitle>
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Nazwa</Label>
            <Input
              isError={formErrors && formErrors.name}
              name="name"
              onChange={e => handleFormChange('name', e.target.value)}
              value={form.name}
            />
            {formErrors && formErrors.name && <FormError>{formErrors.name}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Adres</Label>
            <Input
              isError={formErrors && formErrors.address}
              name="address"
              onChange={e => handleFormChange('address', e.target.value)}
              value={form.address}
            />
            {formErrors && formErrors.address && <FormError>{formErrors.address}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Nr telefonu</Label>
            <Input
              isError={formErrors && formErrors.phone}
              name="phone"
              onChange={e => handleFormChange('phone', e.target.value)}
              value={form.phone}
            />
            {formErrors && formErrors.phone && <FormError>{formErrors.phone}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Wydajność (ton/h)</Label>
            <Input
              isError={formErrors && formErrors.factoryCapacity}
              type="number"
              name="factoryCapacity"
              onChange={e => handleFormChange('factoryCapacity', e.target.value)}
              value={form.factoryCapacity}
            />
            {formErrors && formErrors.factoryCapacity && <FormError>{formErrors.factoryCapacity}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Kierownicy</Label>
            <Select
              isError={formErrors && formErrors.managersIds}
              name="managersIds"
              loadOptions={getManagers}
              options={managers}
              isMulti={true}
              onChange={values => {
                handleFormChange('managersIds', values);
              }}
              isAsync={true}
              placeholder={'Wyszukaj...'}
              value={setSelectMultiOptions(form.managersIds)}
            />
            {formErrors && formErrors.managersIds && <FormError>{formErrors.managersIds}</FormError>}
          </FormElement>

          <FormElement>
            <Label required>Pracownicy</Label>
            <Select
              isError={formErrors && formErrors.viewersIds}
              name="viewersIds"
              loadOptions={getViewers}
              options={viewers}
              isMulti={true}
              onChange={values => {
                handleFormChange('viewersIds', values);
              }}
              isAsync={true}
              placeholder={'Wyszukaj...'}
              value={setSelectMultiOptions(form.viewersIds)}
            />
            {formErrors && formErrors.viewersIds && <FormError>{formErrors.viewersIds}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Laboratorium</Label>
            <Select
              isError={formErrors && formErrors.laboratoryId}
              name="laboratoryId"
              options={laboratories}
              onChange={option => handleFormChange('laboratoryId', option.value)}
              value={setSelectOption(laboratories, form.laboratoryId)}
            />
            {formErrors && formErrors.laboratoryId && <FormError>{formErrors.factoryId}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Strefa</Label>
            <Select
              isError={formErrors && formErrors.zoneId}
              name="zoneId"
              loadOptions={getZones}
              isAsync={true}
              options={zones}
              onChange={option => {
                handleFormChange('zoneId', option.value);
                getSingleZonePoints(option.value);
              }}
              value={setSelectOption(zones, form.zoneId)}
              placeholder={'Wybierz lub wyszukaj...'}
            />
            {formErrors && formErrors.laboratoryId && <FormError>{formErrors.factoryId}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label>Podgląd strefy</Label>
            <AreaMap points={currentZone && currentZone.points} isLoading={zoneLoading}/>
          </FormElement>
        </FormRow>
        <FlexWrapper justify={'flex-end'}>
          <Button
            secondary
            onClick={() => goToFactoriesList()}
            leftIcon={<BsArrowLeftShort />}
            disabled={formSubmitting}
          >
            Wróć
          </Button>
          <Button
            onClick={() => handleSubmit()}
            zoom
            rightIcon={<BsCheck2 size={18} />}
            isLoading={formSubmitting}
            disabled={formSubmitting}
          >
            Zapisz
          </Button>
        </FlexWrapper>
      </FormContent>
    </>
  );
};

export default FactoryForm;
