import React, {useEffect, useState} from 'react';
import {AnimatePresence} from 'framer-motion';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import LaboratoryForm from 'components/Laboratories/LaboratoryForm';
import { useParams } from 'react-router-dom';
import history from 'config/history';
import LaboratoriesService from 'services/LaboratoriesService';
import { displayToast } from 'utils/toast';
import { prepareErrors } from 'utils/form';

const LaboratoryManagement = () => {
  const { laboratory_id } = useParams();
  const [laboratory, setLaboratory] = useState(null);
  const [laboratoryLoading, setLaboratoryLoading] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    if (laboratory_id && laboratory_id !== 'create') {
      getLaboratory(laboratory_id);
    } else {
      setLaboratory(null);
    }

    return () => setLaboratory(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laboratory_id]);

  const gotToLaboratoriesList = () => {
    history.push(`/panel/laboratories`);
  };

  const getLaboratory = async id => {
    try {
      setLaboratoryLoading(true);
      const res = await LaboratoriesService.getLaboratory(id);
      setLaboratory(res.data);
      setLaboratoryLoading(false);
    } catch (err) {
      console.error(err);
      setLaboratoryLoading(false);
    }
  };

  const createLaboratory = async form => {
    try {
      setFormSubmitting(true);
      await LaboratoriesService.createLaboratory(form);
      setFormSubmitting(false);
      gotToLaboratoriesList();
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateLaboratory = async form => {
    try {
      setFormSubmitting(true);
      await LaboratoriesService.updateLaboratory(laboratory_id, form);
      setFormSubmitting(false);
      gotToLaboratoriesList();
      displayToast('success', 'Dane laboratorium zostały zapisane!');
    } catch (err) {
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <AnimatePresence>
      {laboratoryLoading
        ? <PageLoader />
        : (
          <Fade>
            <LaboratoryForm
              laboratory={laboratory}
              formSubmitting={formSubmitting}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onSubmit={laboratory ? updateLaboratory : createLaboratory}
              gotToLaboratoriesList={gotToLaboratoriesList}
            />
          </Fade>
        )
      }
    </AnimatePresence>
  );
};

export default LaboratoryManagement;
