import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';
import { rem } from 'polished';

export const DatePickerWrapper = styled.div`
  flex: 1;
  position: relative;
  .react-datepicker {
    &__input-container {
      input {
        cursor: pointer;
        padding: 8px 15px;
        width: 100%;
        border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
        font-size: ${rem('12px')};
        font-family: ${FONTS.regular};
        border-radius: 6px;
        outline: none;
        min-height: 40px;
        margin: 0;
        &:active,
        &:focus {
          border-color: ${COLORS.main};
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
        }
      }
    }
    &__day--selected,
    &__day-keyboard-selected {
      background-color: ${COLORS.main};
      color: ${COLORS.dark};
      &:hover {
        background-color: ${COLORS.dark};
        color: ${COLORS.white};
      }
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 11px;
  svg {
    font-size: 18px;
    color: ${COLORS.textGray};
  }
`;
