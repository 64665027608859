import styled from 'styled-components';
import { FONTS, COLORS } from 'config/theme';

export const Wrapper = styled.div`
  .rc-time-picker {
    width: 100%;
  }
  .rc-time-picker-input {
    height: 40px;
    padding: 8px 15px;
    width: 100%;
    border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
    font-size: 12px;
    font-family: ${FONTS.regular};
    color: ${COLORS.dark};
    border-radius: 6px;
    outline: none;
    min-height: 40px;
    &:active,
    &:focus {
      border-color: ${COLORS.main};
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    }
    &::placeholder {
      color: ${COLORS.textGray};
      font-family: ${FONTS.regular};
    }
  }
  .rc-time-picker-panel-input {
    font-size: 12px;
    font-family: ${FONTS.regular};
  }
  .rc-time-picker-clear {
    top: 9px;
    right: 10px;
  }
`;
