import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ShadowBox } from 'components/__styled/ShadowBox';
import { FONTS, COLORS } from 'config/theme';
import { rem } from 'polished';

export const ModalWrapper = styled(motion.div)`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled(ShadowBox)`
  text-align: center;
  max-height: calc(100% - 50px);
  overflow: auto;
`;

export const ModalHeader = styled.h3`
  color: ${COLORS.dark};
  font-family: ${FONTS.bold};
  font-size: ${rem('20px')};
  text-align: center;
  margin: 10px auto 20px auto;
  max-width: 400px;
`;

export const ModalDescription = styled.p`
  font-size: ${rem('14px')};
  font-family: ${FONTS.regular};
  color: ${COLORS.dark};
  max-width: 400px;
  margin: 0 auto 10px auto;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const ModalFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
