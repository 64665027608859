import styled from 'styled-components';
import { FONTS } from 'config/theme';

export const TooltipInfoRow = styled.div`
  display: flex;
`;

export const TooltipInfo = styled.div`
  font-size: 10px;
  margin-right: 4px;
`;

export const TooltipInfoBold = styled.div`
  font-size: 10px;
  font-family: ${FONTS.semiBold};
`;
