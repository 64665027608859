import React, { useState, useEffect } from 'react';
import { MapWrapper, LoadingVignette } from './styles';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import { Spinner } from 'components/__styled/Spinner';

import 'leaflet/dist/leaflet.css';

const AreaMap = ({ points, isLoading }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && points && !isLoading) {
      map.flyToBounds(points, {
        zoom: 15,
        animate: false,
      });
    }
  }, [points, map, isLoading]);

  useEffect(() => {
    return () => setMap(null);
  }, []);

  return (
    <MapWrapper>
      {isLoading && (
        <LoadingVignette>
          <Spinner />
        </LoadingVignette>
      )}
      <MapContainer
        center={[51.984876, 19.601698]}
        zoom={5}
        scrollWheelZoom={false}
        whenCreated={m => setMap(m)}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {points && <Polygon positions={points} color="red" />}
      </MapContainer>
    </MapWrapper>
  );
};

export default AreaMap;
