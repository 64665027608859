import styled from 'styled-components';
import { FONTS, COLORS }  from 'config/theme';

export const Content = styled.div`
  padding: 25px 0;
  max-width: 900px;
  width: 100%;
`;

export const RatesLoader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
`;

export const RatesLoaderText = styled.div`
  font-size: 12px;
  margin-left: 10px;
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
`;

export const NoCarriersSetError = styled.div`
  padding: 15px 25px;
  background-color: #f6c9001f;
  color: ${COLORS.main};
  border-radius: 5px;
  font-size: 12px;
  font-family: ${FONTS.semiBold};
  display: inline-block;
  margin-top: 20px;
  vertical-align: middle;
  svg {
    vertical-align: text-bottom;
    margin-right: 10px;
  }
`;
