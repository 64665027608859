import styled from 'styled-components';
import { FONTS, COLORS } from 'config/theme';

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ScheduleStatusInHeader = styled.div`
  border-radius: 5px;
  margin-left: 15px;
  margin-bottom: 5px;
  padding: 7px 16px;
  font-size: 10px;
  font-family: ${FONTS.medium};
  background-color: ${({ bgColor }) => bgColor ? bgColor : COLORS.textGray};
  color: ${({ color }) => color ? color : COLORS.white};
`;

export const ContactInfo = styled.div`
  font-size: 12px;
  padding: 15px;
  display: inline-block;
  margin-top: 15px;
  font-family: ${FONTS.medium};
  background-color: #efc21017;
  color: ${COLORS.main};
  border-radius: 7px;
  
  svg path {
    stroke: ${COLORS.main};
  }
  
  span {
    vertical-align: middle;
  }
  
  span:last-of-type {
    margin-left: 20px;
  }
  
  strong {
    font-family: ${FONTS.bold};
  }
`;

export const HeaderWrapper = styled.div`
  margin: 25px 0;
`;

export const TabelLegend = styled.div`
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 12px;
  color: ${COLORS.dark};
  display: flex;
  
  span {
    color: red;
    font-size: 16px;
    margin-top: -3px;
  }
`;

export const ScheduleTitle = styled.div`
  display: inline-block;
  padding: 15px 20px;
  background-color: #fbfbfb;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 10px;
  font-size: 13px;
  color: #535353;
`;

export const ScheduleTitleWrapper = styled.div`
  display: block;
`;

export const ScheduleName = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.gray};
  margin: 0;
  margin-bottom: 15px;
  margin-top: 0;
`;

export const ContactTels = styled.div`
  display: inline-block;
  margin: 15px 0;
  min-width: 250px;
`;

export const ContactTelsHeader = styled.h3`
  font-size: 14px;
  margin-top: 0;
  color: ${COLORS.dark};
`;

export const ContactTelsData = styled.div`
  display: flex;
`;

export const ContactTelsValue = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.dark};
`;

export const ContactTelsIcon = styled.div`
  margin-right: 10px;
  color: ${COLORS.main};
`;
