import { PublicClientApplication } from '@azure/msal-browser';

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    // knownAuthorities: [process.env.REACT_APP_AZURE_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false
  },
}, {
  scopes: ['openid'],
});
