import React, { useState, useEffect } from 'react';
import { FormContent, FormElement, Label, FormRow, FormError } from 'components/__styled/Form';
import { CurrentTaskName, InfoOuterWrapper } from 'components/Tasks/TaskForm/styles';
import Header from 'components/__common/Header';
import Input from 'components/__common/Input';
import Datepicker from 'components/__common/Datepicker';
import Select from 'components/__common/Select';
import Textarea from 'components/__common/Textarea';
import Timepicker from 'components/__common/Timepicker';
import { Subtitle } from 'components/__styled/Subtitle';
import { FormButtonsWrapper } from 'components/__styled/Layout';
import Button from 'components/__common/Button';
import { BsArrowRightShort } from 'react-icons/bs';
import moment from 'moment';
import { setSelectOption } from 'utils/form';
import { returnValueAsNumber } from 'utils/global';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoMedium } from 'components/Tasks/InfoBanner/styles';
import DestinationComponent from 'components/MapModal/DestinationComponent';
import ReactTooltip from "react-tooltip";

const FirstFormStep = ({
  editable,
  goToNextStep,
  formErrors,
  setFormErrors,
  formSubmitting,
  task,
  contracts,
  factories,
  laboratories
}) => {
  const [recipes, setRecipes] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [form, setForm] = useState({
    usedRecipeName: task?.usedRecipeName || '',
    date: task?.date || moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    contractId: task?._embedded?.contract?.id || '',
    factoryId: task?._embedded?.factory?.id || '',
    taskFactoryId: task?._embedded?.factory?.id || '',
    laboratoryId: task?._embedded?.laboratory?.id || '',
    roadLayer: task?.roadLayer || '',
    location: task?.location || '',
    description: task?.description || '',
    startOfUnloadingCar: task?.startOfUnloadingCar || '07:00:00',
    factoryCapacity: task?.factoryCapacity || '',
    lengthOfThePlannedRoadSection: task?.lengthOfThePlannedRoadSection || '',
    builtInWidth: task?.builtInWidth || '',
    builtInThickness: task?.builtInThickness || '',
    volumetricWeight: task?.volumetricWeight || '',
    carTonnage: task?.carTonnage || 25,
    locationFrom: task?.locationFrom || '',
    locationTo: task?.locationTo || '',
    subcategory: task?.subcategory || '',
    locationFromPosition: task?.locationFromPosition || null,
    locationToPosition: task?.locationToPosition || null,
    factoryGeozone: task?._embedded?.factory?._embedded?.geoZone || null,
  });

  useEffect(() => {
    contracts.forEach(c => {
      if (task && task._embedded.contract?.id === c.value) {
        setRecipes(c.recipes.map(el => ({ value: el, label: el })));
      }
    });
  }, [contracts, task]);

  useEffect(() => {
    const factory = factories.find(f => f.value === form.factoryId);
    if (factory) {
      let errors = { ...formErrors };
      delete errors['laboratoryId'];
      delete errors['factoryCapacity'];
      setFormErrors(errors);
      setForm({
        ...form,
        laboratoryId: factory.defaultLaboratory ? factory.defaultLaboratory.id : null,
        factoryCapacity: task?.factoryCapacity || factory.factoryCapacity,
        factoryGeozone: factory.geoZone,
        taskFactoryId: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laboratories, factories, form.factoryId]);

  useEffect(() => {
    const contract = contracts.find(c => c.value === form.contractId);
    if (contract && contract.defaultFactory && form.taskFactoryId == '') {
      handleFormChange('factoryId', contract.defaultFactory.id);
    }

    if (contract) {
      const subc = contract.subcategories.map(sc => ({ label: sc.value, value: sc.value }));
      setSubcategories(subc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factories, form.contractId]);

  useEffect(() => {
    if (fromLocation) {
      setForm({
        ...form,
        ['locationFromPosition']: fromLocation,
      });
    }
  }, [fromLocation]);

  useEffect(() => {
    if (toLocation) {
      setForm({
        ...form,
        ['locationToPosition']: toLocation,
      });
    }
  }, [toLocation]);

  const localizationOptions = [
    { value: 'Trasa główna', label: 'Trasa główna' },
    { value: 'Droga serwisowa', label: 'Droga serwisowa' },
    { value: 'MOP', label: 'MOP' },
    { value: 'Droga poprzeczna', label: 'Droga poprzeczna' },
    { value: 'Droga dojazdowa', label: 'Droga dojazdowa' },
    { value: 'Węzeł', label: 'Węzeł' },
    { value: 'Ścieżka rowerowa', label: 'Ścieżka rowerowa' }
  ];

  const roadLayers = [
    { value: 'Podbudowa', label: 'Podbudowa'},
    { value: 'Wiążaca', label: 'Wiążaca'},
    { value: 'Ścieralna z BA', label: 'Ścieralna z BA'},
    { value: 'Ścieralna z SMA', label: 'Ścieralna z SMA'}
  ];

  const handleFormChange = (name, value) => {
    let errors = { ...formErrors };
    delete errors[name];
    setFormErrors(errors);
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <ReactTooltip className="tooltip" effect="solid" />
      <Header size={23}>
        Dane podstawowe
        {task && <CurrentTaskName>{task.name}</CurrentTaskName>}
      </Header>
      <Subtitle>Podaj podstawowe dane aktualnego zlecenia dla wybranego kontraktu.</Subtitle>
      {task?.factoryRejectDescription && task?.factoryRejectDescription.length > 0 && (
        <InfoOuterWrapper>
          <InfoBanner>
            <span>
              <div>Powód odrzucenia przez wytwórnię:</div>
              <InfoMedium>{task.factoryRejectDescription}</InfoMedium>
            </span>
          </InfoBanner>   
        </InfoOuterWrapper>
      )}
      <FormContent>
        <FormRow>
          <FormElement>
            <Label required>Kontrakt</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.contractId}
              name="contractId"
              options={contracts}
              onChange={option => {
                const options = option.recipes.map(o => ({ label: o, value: o }));
                setRecipes(options);
                handleFormChange('contractId', option.value)
              }}
              value={setSelectOption(contracts, form.contractId)}
            />
            {formErrors && formErrors.contractId && <FormError>{formErrors.contractId}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Recepta</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.usedRecipeName}
              name="laboratoryId"
              options={recipes}
              onChange={option => handleFormChange('usedRecipeName', option.value)}
              value={setSelectOption(recipes, form.usedRecipeName)}
            />
            {formErrors && formErrors.laboratoryId && <FormError>{formErrors.laboratoryId}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label>Podkategoria</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.subcategory}
              name="subcategory"
              options={subcategories}
              onChange={option => handleFormChange('subcategory', option.value)}
              value={setSelectOption(subcategories, form.subcategory)}
            />
            {formErrors && formErrors.subcategory && <FormError>{formErrors.subcategory}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Laboratorium</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.laboratoryId}
              name="laboratoryId"
              options={laboratories}
              onChange={option => handleFormChange('laboratoryId', option.value)}
              value={setSelectOption(laboratories, form.laboratoryId)}
            />
            {formErrors && formErrors.laboratoryId && <FormError>{formErrors.laboratoryId}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Wytwórnia</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.factoryId}
              name="factoryId"
              options={factories}
              onChange={option => {
                handleFormChange('factoryId', option.value);
              }}
              value={setSelectOption(factories, form.factoryId)}
            />
            {formErrors && formErrors.contractId && <FormError>{formErrors.contractId}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Data zlecenia</Label>
            <Datepicker
              disabled={!editable}
              isError={formErrors && formErrors.date}
              name="date"
              onChange={value => handleFormChange('date', value)}
              value={form.date}
            />
            {formErrors && formErrors.date && <FormError>{formErrors.date}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Lokalizacja</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.location}
              name="location"
              options={localizationOptions}
              onChange={option => handleFormChange('location', option.value)}
              value={setSelectOption(localizationOptions, form.location)}
            />
            {formErrors && formErrors.location && <FormError>{formErrors.location}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Warstwa drogi</Label>
            <Select
              disabled={!editable}
              isError={formErrors && formErrors.roadLayer}
              name="roadLayer"
              options={roadLayers}
              onChange={option => handleFormChange('roadLayer', option.value)}
              value={setSelectOption(roadLayers, form.roadLayer)}
            />
            {formErrors && formErrors.roadLayer && <FormError>{formErrors.roadLayer}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Początek wyładunku na budowie (czas)</Label>
            <Timepicker
              disabled={!editable}
              isError={formErrors && formErrors.startOfUnloadingCar}
              name="startOfUnloadingCar"
              defaultValue={moment(form.startOfUnloadingCar || 7, 'HH:mm')}
              placeholder={'Wybierz godzinę...'}
              onChange={time => handleFormChange('startOfUnloadingCar', time)}
            />
            {formErrors && formErrors.startOfUnloadingCar && <FormError>{formErrors.startOfUnloadingCar}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Wydajność WMB/h (t)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.factoryCapacity}
              type="number"
              name="factoryCapacity"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('factoryCapacity', v);
              }}
              value={form.factoryCapacity}
            />
            {formErrors && formErrors.factoryCapacity && <FormError>{formErrors.factoryCapacity}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Dł. planowanego odcinka (m)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.lengthOfThePlannedRoadSection}
              type="number"
              name="lengthOfThePlannedRoadSection"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('lengthOfThePlannedRoadSection', v);
              }}
              value={form.lengthOfThePlannedRoadSection}
            />
            {formErrors && formErrors.lengthOfThePlannedRoadSection && <FormError>{formErrors.lengthOfThePlannedRoadSection}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Szerokość wbudowywania (m)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.builtInWidth}
              type="number"
              name="builtInWidth"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('builtInWidth', v);
              }}
              value={form.builtInWidth}
            />
            {formErrors && formErrors.builtInWidth && <FormError>{formErrors.builtInWidth}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Grubość wbudowywania (cm)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.builtInThickness}
              type="number"
              name="builtInThickness"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('builtInThickness', v);
              }}
              value={form.builtInThickness}
            />
            {formErrors && formErrors.builtInThickness && <FormError>{formErrors.builtInThickness}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Ciężar objętościowy (t/m3)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.volumetricWeight}
              type="number"
              name="volumetricWeight"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('volumetricWeight', v);
              }}
              value={form.volumetricWeight}
            />
            {formErrors && formErrors.volumetricWeight && <FormError>{formErrors.volumetricWeight}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Początek odcinka</Label>
            <DestinationComponent
                position={setFromLocation}
                currentPosition={form.locationFromPosition}
                geoZone={form.factoryGeozone}
                label={"Wskaż na mapie miejsce początku odcinka"}
            ></DestinationComponent>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.locationFrom}
              type="text"
              name="locationFrom"
              onChange={e => handleFormChange('locationFrom', e.target.value)}
              value={form.locationFrom}
            />
            {formErrors && formErrors.locationFrom && <FormError>{formErrors.locationFrom}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Koniec odcinka</Label>
            <DestinationComponent
                position={setToLocation}
                currentPosition={form.locationToPosition}
                geoZone={form.factoryGeozone}
                label={"Wskaż na mapie miejsce końca odcinka"}
            ></DestinationComponent>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.locationTo}
              type="text"
              name="locationTo"
              onChange={e => handleFormChange('locationTo', e.target.value)}
              value={form.locationTo}
            />
            {formErrors && formErrors.locationTo && <FormError>{formErrors.locationTo}</FormError>}
          </FormElement>
        </FormRow>

        <FormRow>
          <FormElement>
            <Label required>Tonaż / auto (t)</Label>
            <Input
              disabled={!editable}
              isError={formErrors && formErrors.carTonnage}
              type="number"
              name="carTonnage"
              onChange={e => {
                const v = returnValueAsNumber(e.target.value);
                handleFormChange('carTonnage', v);
              }}
              value={form.carTonnage}
            />
            {formErrors && formErrors.carTonnage && <FormError>{formErrors.carTonnage}</FormError>}
          </FormElement>
          <FormElement>
            <Label required>Opis</Label>
            <Textarea
              disabled={!editable}
              isError={formErrors && formErrors.description}
              name="description"
              onChange={e => handleFormChange('description', e.target.value)}
              value={form.description}
            />
            {formErrors && formErrors.description && <FormError>{formErrors.description}</FormError>}
          </FormElement>
        </FormRow>
      </FormContent>

      <FormButtonsWrapper>
        <Button
          onClick={() => goToNextStep(form)}
          rightIcon={<BsArrowRightShort />}
          isLoading={formSubmitting}
        >
          Dalej
        </Button>
      </FormButtonsWrapper>
    </>
  );
};

export default FirstFormStep;
