import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import PageLoader from 'components/PageLoader';
import Header from 'components/__common/Header';
import { TableBox } from 'components/__styled/Table';
import CarrierScheduleTable from 'components/Tasks/CarrierScheduleTable';
import AcceptOrRejectScheduleModal from 'components/Tasks/AcceptOrRejectScheduleModal';
import TasksService from 'services/TasksService';
import TaskContext from 'context/TaskContext';
import Button from 'components/__common/Button';
import { BsCheck2} from 'react-icons/bs';
import { ButtonsWrapper, ContactTelsIcon, TabelLegend, HeaderWrapper, ScheduleName, ContactTels, ContactTelsHeader, ContactTelsData, ContactTelsValue } from './styles';
import StatusChip from 'components/Tasks/StatusChip';
import { displayToast } from 'utils/toast';
import { scheduleStatuses, detailedScheduleStatuses } from 'config/statuses';
import { FlexWrapper } from 'components/__styled/Layout';
import RejectDescription from 'components/Tasks/RejectDescription';
import history from 'config/history';
import { prepareErrors } from 'utils/form';
import { HiOutlinePhone } from 'react-icons/hi';
import { ShadowBox } from 'components/__styled/ShadowBox';

const CarrierAccept = () => {
  const [modalContent, setModalContent] = useState({
    header: '',
    description: '',
    onSubmit: undefined,
    accept: true,
  });
  const [schedule, setSchedule] = useState([]);
  const [cars, setCars] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [acceptedCourses, setAcceptedCourses] = useState([]);
  const [coursesErrors, setCoursesErrors] = useState(null);

  const { search } = useLocation();
  const secret = new URLSearchParams(search).get('secret');
  const { currentTask, setCurrentTask, setTaskLoading, taskLoading } = useContext(TaskContext);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secret]);

  useEffect(() => {
    toggleAcceptAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  const getData = async () => {
    try {
      setTaskLoading(true);
      const task = await getCarrierTask();
      await getCars(task.externalId);
      setTaskLoading(false);
    } catch (err) {
      setTaskLoading(false);

      if (err.response.status && err.response.status === 401) {
        history.push({
          pathname: '/client/error',
          state: {
            header: 'Link wygasł!',
            description: 'Link ze szczegółami harmonogramu dostawy stracił ważność lub jest niepoprawny.',
          }
        });
      }

      if (err.response.status && err.response.status === 403) {
        history.push({
          pathname: '/client/error',
          state: {
            header: 'Brak dostępu!',
            description: 'Nie masz dostępu do podanej strony.',
          }
        });
      }
    }
  };

  const handleAcceptedCoursesChange = courseId => {
    if (acceptedCourses.includes(courseId)) {
      const filteredCourses = acceptedCourses.filter(acceptedCourseId => acceptedCourseId !== courseId);
      setAcceptedCourses(filteredCourses);
    } else {
      setAcceptedCourses([...acceptedCourses, courseId]);
    }
  };

  const toggleAcceptAllCourses = () => {
    if (acceptedCourses.length < schedule.length) {
      const ids = schedule.map(item => item.id);
      setAcceptedCourses(ids);
    } else {
      setAcceptedCourses([]);
    }
  };

  const getCarrierTask = async () => {
    const res = await TasksService.getTaskForExternals(secret);
    setCurrentTask(res.data);
    setSchedule(res.data._embedded ? res.data._embedded.courses : []);
    return res.data;
  };

  const getCars = async carrierId => {
    const res = await TasksService.getCarrierCarsByCarrier(carrierId, secret);
    setCars(res.data._embedded ? res.data._embedded.item : []);
  };

  const setupModal = content => {
    setModalContent(content);
    setModalVisible(true);
  };

  const acceptRejectSchedule = () => {
    const callback = async (rejectDescription = null) => {
      try {
        setDataProcessing(true);
        const list = schedule
          .map(item => ({ courseId: item.id, carId: item._embedded.car.id, accept: acceptedCourses.includes(item.id) }));

        let data = {
          status: rejectDescription && rejectDescription.length ? 'REJECT' : 'ACCEPT',
          cars: list,
          description: rejectDescription,
        };

        if (rejectDescription) data.description = rejectDescription;
        await TasksService.confirmRejectScheduleAsCarrier(secret, data);
        await getData();
        setModalVisible(false);
        setDataProcessing(false);
        displayToast('success', 'Twoje potwierdzenie zostało przekazane!');
      } catch (err) {
        setDataProcessing(false);
        setModalVisible(false);
        if (err.response && err.response.status === 422) {
          displayToast('error', 'Musisz przypisać przewoźnika i pojazdy do wszystkich kursów!');
          setCoursesErrors(prepareErrors(err.response.data.violations));
        } else {
          displayToast('error', 'Wystąpił nieoczekiwany błąd!');
        }
      }
    };

    const rejectedCourses = schedule
      .map((course, i) => ({ ...course, lp: i + 1 }))
      .filter(course => !acceptedCourses.includes(course.id));

    setupModal({
      header: 'Czy na pewno chcesz wysłać harmonogram w podanej formie?',
      callback: msg => callback(msg),
      rejectedCourses,
      withRejectReason: rejectedCourses.length > 0,
    });
  };

  return (
    <AnimatePresence>
      {taskLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-preview-content'>
              <AcceptOrRejectScheduleModal
                isVisible={modalVisible}
                closeModal={() => setModalVisible(false)}
                rejectProcessing={dataProcessing}
                modalContent={modalContent}
              />
              <FlexWrapper>
                <Header size={23} beFlex>
                  Harmonogram dostawy
                </Header>
                <StatusChip
                  withIndent
                  bgColor={currentTask && detailedScheduleStatuses[currentTask.status].color}
                >
                  {currentTask && detailedScheduleStatuses[currentTask.status].name}
                </StatusChip>
                <RejectDescription
                  status={currentTask && currentTask.status}
                  place="right"
                  rejectText={currentTask && currentTask.statusDescription}
                />
              </FlexWrapper>
              <ScheduleName>
                {currentTask?.name}
              </ScheduleName>
              {currentTask?.contactData && currentTask?.contactData.length > 0 && (
                <ContactTels>
                  <ShadowBox>
                    <ContactTelsHeader>
                      Dane kontaktowe:
                    </ContactTelsHeader>
                    <ContactTelsData>
                      <ContactTelsIcon>
                        <HiOutlinePhone size={18} />
                      </ContactTelsIcon>
                      <ContactTelsValue>
                        {currentTask?.contactData}
                      </ContactTelsValue>
                    </ContactTelsData>
                  </ShadowBox>
                </ContactTels>
              )}
              <HeaderWrapper>
                <Header size={23}>Wybierz samochody</Header>
              </HeaderWrapper>
              <TableBox>
                <TabelLegend>
                  <span>*</span>
                  Jeśli któreś z poniższych aut nie będzie mogło jeździć odznacz je poniżej,
                  nie zostanie ono wówczas uwzględnione w harmonogramie.
                </TabelLegend>
                <CarrierScheduleTable
                  currentTask={currentTask}
                  cars={cars}
                  schedule={schedule}
                  setSchedule={setSchedule}
                  acceptedCourses={acceptedCourses}
                  coursesErrors={coursesErrors}
                  handleAcceptedCoursesChange={handleAcceptedCoursesChange}
                  toggleAcceptAllCourses={toggleAcceptAllCourses}
                  setCoursesErrors={setCoursesErrors}
                  canEdit={currentTask && currentTask.status === scheduleStatuses.LOGISTIC_CONFIRMED.slug}
                />
              </TableBox>
              {currentTask && currentTask.status === scheduleStatuses.LOGISTIC_CONFIRMED.slug && (
                <ButtonsWrapper>
                  <Button
                    isLoading={dataProcessing}
                    disabled={dataProcessing}
                    zoom
                    rightIcon={<BsCheck2 />}
                    onClick={() => acceptRejectSchedule()}
                  >
                    Potwierdź wybrane
                  </Button>
                </ButtonsWrapper>
              )}
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default CarrierAccept;
