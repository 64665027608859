import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const MapWrapper = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 7px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 200px;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    height: 300px;
  }
  
  .leaflet-container {
    height: 100%;
  }
`;

export const LoadingVignette = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
