import styled from "styled-components";
import {COLORS} from "../../config/theme";

export const OpenModalBtn = styled.button`
  border: none;
  background-color: yellow;
  font-weight: 600;
  padding: 12px 32px 12px 32px;
  border-radius: 6px;
  cursor: pointer;
`;

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const ModalWindow = styled.div`
  background-color: white;
  width: 90vw;
  height: 70vh;
  border: solid 1px #FBC900;
  border-radius: 15px;
  box-shadow: 0 0 40px rgba(59, 59, 59, 0.16);
  overflow: hidden;
`;

export const AcceptWindow = styled.div`
  position: absolute;
  padding: 12px;
  z-index: 10;
  top: 20%;
  right: 10%;
  background-color: white;
  border: 1px solid;
  border-radius: 6px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
`;

export const ButtonAcc = styled.button`
  padding: 8px 22px 8px 22px;
  border: 1px solid;
  background-color: #16a34a;
  cursor: pointer;
`;

export const ButtonDecline = styled.button`
  padding: 8px 22px 8px 22px;
  border: 1px solid;
  background-color: #dc2626;
  cursor: pointer;
`;

export const DestinationValue = styled.span`
  margin-right: 20px;
  margin-left: 10px;
    line-height: 20px;
  font-size: 14px;
`;
export const CloseModalButton = styled.button`
  position: absolute;
  border-radius: 50%;
    top: 16%;
    right: 5%;
  width: 35px;
  height: 35px;
  background-color: ${COLORS.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color .3s ease;
  svg {
    stroke: ${COLORS.gray};
    transition: stroke .3s ease;
  }
  &:hover {
    background-color: ${COLORS.textGray};
    svg {
      stroke: ${COLORS.dark};
    }
  }
`;
