import styled from 'styled-components';
import { FONTS } from 'config/theme';

export const InfoWrapper = styled.div`
  margin-top: 6px;
  display: inline-block;
  background-color: #fbc9001f;
  padding: 15px 20px;
  border-radius: 7px;
  display: inline-block;
  
  svg {
    color: #f6c221;
    vertical-align: middle;
  }
`;

export const InfoWrapperContent = styled.div`
  display: flex;
`;

export const InfoText = styled.div`
  margin-left: 15px;
  font-family: ${FONTS.medium};
  font-size: 12px;
  color: #f6c221;
  padding-top: 6px;
`;

export const InfoMedium = styled.div`
  font-family: ${FONTS.semiBold};
  font-size: 12px;
  line-height: 1.6;
  margin-top: 2px;
`;

export const InfoBold = styled.span`
  margin-left: 7px;
  font-family: ${FONTS.bold};
  font-size: 16px;
`;
