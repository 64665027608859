import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import ReactTooltip from 'react-tooltip';

const Table = ({ headers, rows, actions, pagination = null }) => {
  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            {headers && headers.map((th, i) => {
              return (
                <Th key={i}>{th.label}</Th>
              );
            })}
            {actions && actions.length > 0 && <Th>Akcje</Th>}
          </Tr>
        </THead>
        <TBody>
          {rows && rows.length > 0 ?
            rows.map((row, i) => {
              return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  {headers && headers.map(header => {
                    return Object.keys(row).map((td, i) => {
                      return header.name === td ? <Td key={i}>{row[td] ? row[td] : '-'}</Td> : null;
                    });
                  })}
                  {actions && actions.length > 0 && (
                    <Td>
                      <ActionButtonWrapper>
                        {actions.map((actionFn, i) => {
                          const action = actionFn(row);
                          return (
                            <ActionButton
                              key={i}
                              color={action.color}
                              onClick={() => action.event(row.id)}
                              data-tip={action.label}
                            >
                              {action.icon}
                            </ActionButton>
                          );
                        })}
                      </ActionButtonWrapper>
                    </Td>
                  )}
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={actions ? headers.length + 2 : headers.length + 1}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default Table;
