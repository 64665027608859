import React from 'react';
import { COLORS, FONTS } from 'config/theme';
import gpsSignal from "../../../assets/img/gps_signal.png";

const GPS_DATA_DIFF_IN_SECONDS = 60;

const PaverMarker = ({ data }) => {
  const now = new Date();
  const lastVehicleGpsDate = new Date(data.location.date);
  const diff = Math.abs(now - lastVehicleGpsDate);
  const diffInMinutes = Math.floor((diff / 1000) / 60);
  console.log(diffInMinutes);
  const isGpsOutOfDate = diffInMinutes > GPS_DATA_DIFF_IN_SECONDS;

  return (
    <div style={{position: 'relative', left: '32px'}}>
      <div style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: '#c36efb',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{
          width: '19px',
          height: '19px',
          backgroundColor: '#c36efb',
          borderRadius: '50%',
          border: '2px solid white'
        }}/>
      </div>
      <div style={{
        position: 'absolute',
        left: '40px',
        top: '8px',
        borderRadius: '4px',
        backgroundColor: '#4d4d4d',
        padding: '3px 8px',
        color: `${COLORS.white}`,
        fontFamily: `${FONTS.semiBold}`,
        fontSize: '10px',
        minWidth: '65px',
        display: 'flex',
      }}>
        {isGpsOutOfDate && (
            <img
                style={{
                  minWidth: '15px !important',
                  maxWidth: '15px !important',
                  maxHeight: '15px !important',
                  marginRight: '4px'
                }}
                src={gpsSignal}
                alt="gps_signal"
            />
        )}
        {data.name}
      </div>
    </div>
  );
};

export default PaverMarker;
