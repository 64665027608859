import React, { useState, useEffect, useContext } from 'react';
import TasksService from 'services/TasksService';
import PageLoader from 'components/PageLoader';
import { useParams } from 'react-router-dom';
import Header from 'components/__common/Header';
import PlannerSummary from 'components/Tasks/PlannerSummary';
import { Subtitle } from 'components/__styled/Subtitle';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import { Content, LeftSide, RightSide, ButtonsWrapper, HeaderContent, GetStatusDetails } from './styles';
import { FlexWrapper} from 'components/__styled/Layout';
import { TableBox } from 'components/__styled/Table';
import LogisticScheduleTable from 'components/Tasks/LogisticScheduleTable';
import TaskCarriers from 'components/Tasks/TaskCarriers';
import Button from 'components/__common/Button';
import { displayToast } from 'utils/toast';
import history from 'config/history';
import { BsArrowLeftShort, BsArrowRightShort, BsCheck2, BsTruck } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';
import ModalContext from 'context/ConfirmModalContext';
import TaskContext from 'context/TaskContext';
import DetailedStatuses from 'components/Tasks/DetailedStatuses';
import ScheduleStatusModalContext from 'context/ScheduleStatusModalContext';
import { prepareErrors } from 'utils/form';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoOuterWrapper } from 'components/Tasks/TaskForm/styles';

const CarriersPlanner = () => {
  const [scheduleSaving, setScheduleSaving] = useState(false);
  const [scheduleConfirming, setScheduleConfirming] = useState(false);
  const [carriesSuggesting, setCarriesSuggesting] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [saveError, setSaveError] = useState(null);
  const [coursesErrors, setCoursesErrors] = useState(null);
  const { task_id } = useParams();
  const { currentTask, setCurrentTask, setTaskLoading, taskLoading } = useContext(TaskContext);
  const { openStatusesModal } = React.useContext(ScheduleStatusModalContext);
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    return () => setCurrentTask(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData(task_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  const checkIsSchedulePlanned = stages => {
    if (!stages) {
      return false;
    }

    return stages.some(stage => stage.levelName === 'LOGISTIC' && stage.stage === 'DONE');
  };

  const getData = async task_id => {
    try {
      setTaskLoading(true);
      await getTask(task_id);
      await getCarriers(task_id);
      setTaskLoading(false);
    } catch (err) {
      setTaskLoading(false);
    }
  };

  const getTask = async id => {
    const res = await TasksService.getTask(id);
    setCurrentTask(res.data);
    setSchedule(res.data._embedded ? res.data._embedded.courses : []);
  };

  const getCarriers = async task_id => {
    const res = await TasksService.getCarriers(task_id, { itemsPerPage: 1000 });
    setCarriers(res.data._embedded ? res.data._embedded.item : []);
  };
 
  const saveCarriersPlan = async () => {
    try {
      setCoursesErrors(null);
      setScheduleSaving(true);
      const list = schedule
        .filter(item => item._embedded && item._embedded.carrier)
        .map(item => {
          let requestCar = { carrierId: item._embedded.carrier.id, courseId: item.id }
          if (item._embedded.car) {
            return { ...requestCar, carId: item._embedded.car.id }
          } else {
            return requestCar
          }
        });

      await TasksService.saveScheduleWithCarries(currentTask.id, { carriesMap: list });
      getData(currentTask.id);
      setScheduleSaving(false);
      displayToast('success', 'Harmonogram został poprawnie zapisany!');
    } catch (err) {
      setScheduleSaving(false);
      if (err.response && err.response.status === 404) {
        displayToast('error', err.response.detail);
      }
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Musisz przypisać przewoźnika i pojazdy do wszystkich kursów!');
        setCoursesErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const confirmCarriersPlan = () => {
    const callback = async () => {
      try {
        setScheduleConfirming(true);
        const list = schedule
          .map(item => {
            let requestCar = { carrierId: null, courseId: item.id };
            if (item._embedded && item._embedded.carrier) {
              requestCar = { ...requestCar, carrierId: item._embedded.carrier.id};
              if (item._embedded.car) {
                return { ...requestCar, carId: item._embedded.car.id }
              } else {
                return requestCar;
              }
            } else {
              return requestCar;
            }
          });

        await TasksService.confirmScheduleAsLogistic(task_id, { carriesMap: list });
        await getData(task_id);
        setScheduleConfirming(false);
        setCoursesErrors(null);
        displayToast('success', 'Harmonogram został zapisany i przekazany do przewoźników!');
      } catch (err) {
        if (err.response && err.response.status === 422) {
          displayToast('error', 'Wystąpił błąd podczas przypisywania przewoźników i pojazdów!');
          setCoursesErrors(prepareErrors(err.response.data.violations));
        }
        if (err.response && err.response.status === 404) {
          displayToast('error', err.response.data.detail);
        }
        setScheduleConfirming(false);
      }
    }

    openModal(
      'Czy chcesz zapisac harmonogram i rozesłać go do przewoźników?',
      'Pamiętaj, że operacja jest nieodwracalna.',
      callback,
    );
  };

  const restoreCourses = () => {
    const callback = async () => {
      try {
        setTaskLoading(true);
        await TasksService.restoreCourses(currentTask.id);
        await getTask(task_id);
        setTaskLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    openModal(
      'Czy chcesz przywrócić harmonogram do stanu sprzed usunięcia kursu/kursów?',
      'Pamiętaj, że operacja jest nieodwracalna.',
      callback,
    );
  };

  const saveCarsAmount = async amount => {
    try {
      setTaskLoading(true);
      await TasksService.saveCarsAmount(currentTask.id, { availableCars: amount});
      await getTask(task_id);
      setTaskLoading(false);
    } catch (err) {
      setTaskLoading(false);
      if (err.response && err.response.status === 422) {
        setSaveError(err.response.data.violations[0].message);
      }
    }
  };

  const getStatusesDetails = () => {
    openStatusesModal(currentTask && currentTask._embedded?.stages, task_id);
  };

  const canConfirmSchedule = () => {
    let canConfirm = false;
    currentTask && currentTask._embedded?.stages.forEach(stage => {
      if (stage.levelName === 'FACTORY' && (stage.stage === 'WAIT' || stage.stage === 'DONE')) {
        canConfirm = true;
      }
    });

    return canConfirm;
  };

  const suggestCarriersPlan = async () => {
    try {
      setCarriesSuggesting(true);
      const res = await TasksService.suggestCarriers(currentTask.id);
      setSchedule(res.data._embedded ? res.data._embedded.courses : []);
      setCarriesSuggesting(false);
    } catch (err) {
      setCarriesSuggesting(false);
    }
  };

  return (
    <AnimatePresence>
      {taskLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-content'>
              <FlexWrapper justify={'space-between'} flexWrap>
                <HeaderContent>
                  <Header size={23} beFlex>
                    Planowanie
                  </Header>
                  <Subtitle>{currentTask && currentTask.name}</Subtitle>
                </HeaderContent>
                <GetStatusDetails onClick={getStatusesDetails}>
                  <DetailedStatuses statuses={currentTask && currentTask._embedded?.stages}/>
                </GetStatusDetails>
              </FlexWrapper>
              {currentTask && currentTask._embedded.courses ? (
                <Content>
                  <LeftSide>
                    <PlannerSummary
                      task={currentTask}
                      saveError={saveError}
                      setSaveError={setSaveError}
                      saveCarsAmount={saveCarsAmount}
                      isSchedulePlanned={checkIsSchedulePlanned(currentTask && currentTask._embedded.stages)}
                    />
                    <TableBox>
                      <LogisticScheduleTable
                        carriers={carriers}
                        schedule={schedule}
                        setSchedule={setSchedule}
                        coursesErrors={coursesErrors}
                        getTask={() => getTask(task_id)}
                        isSchedulePlanned={checkIsSchedulePlanned(currentTask && currentTask._embedded.stages)}
                      />
                    </TableBox>
                    <ButtonsWrapper>
                      <Button
                        name='back-button'
                        leftIcon={<BsArrowLeftShort />}
                        secondary
                        onClick={() => history.push('/panel/tasks')}
                      >
                        Wróć
                      </Button>
                      {currentTask && currentTask.queueEnable && (
                        <Button
                          leftIcon={<BsTruck size={18} />}
                          zoom
                          isLoading={carriesSuggesting}
                          disabled={scheduleSaving || scheduleConfirming || carriesSuggesting}
                          onClick={suggestCarriersPlan}
                        >
                          Kontynuuj kolejkę
                        </Button>
                      )}
                      {!checkIsSchedulePlanned(currentTask && currentTask._embedded.stages) && (
                        <Button
                          rightIcon={<BsCheck2 size={18} />}
                          isLoading={scheduleSaving}
                          disabled={scheduleSaving || scheduleConfirming || carriesSuggesting}
                          onClick={saveCarriersPlan}
                          zoom
                        >
                          Zapisz
                        </Button>
                      )}
                      {canConfirmSchedule() && (
                        <Button
                          rightIcon={<BsArrowRightShort />}
                          isLoading={scheduleConfirming}
                          disabled={scheduleSaving || scheduleConfirming || carriesSuggesting}
                          onClick={confirmCarriersPlan}
                        >
                          Potwierdź
                        </Button>
                      )}
                      {currentTask && currentTask.canRestore && (
                        <Button
                          name='back-button'
                          leftIcon={<MdRefresh size={20} />}
                          zoom
                          secondary
                          onClick={() => restoreCourses()}
                        >
                          Reset
                        </Button>
                      )}
                    </ButtonsWrapper>
                  </LeftSide>
                  <RightSide>
                    <TaskCarriers
                      carriers={carriers}
                      getTask={getTask}
                      getCarriers={getCarriers}
                    />
                  </RightSide>
                </Content>
              ) : (
                <InfoOuterWrapper>
                  <InfoBanner>Harmonogram nie został jeszcze ustalony przez logistyka!</InfoBanner>
                </InfoOuterWrapper>
              )}
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default CarriersPlanner;
