import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import {
  AcceptWindow,
  ButtonContainer,
  CloseModalButton,
} from "./styles";
import Button from 'components/__common/Button';
import locationIcon from 'assets/img/location_icon.png';
import {IoCloseOutline} from "react-icons/io5";

const MapModal = (props) => {
  const mapRef = useRef(null);
  const map = useRef(null);
  const close = props.close;
  const platform = useRef(null);
  const action = props.action;
  const coords = props.coords;
  const apikey = props.apikey;
  const [isOpenAccept, setIsOpenAccept] = useState(false);
  const [destCoords, setDestCoords] = useState(null);

  var pngIcon = new H.map.Icon(locationIcon, { size: { w: 18, h: 32 } });

  useEffect(() => {
    let isAdding = false;
    if (!map.current) {
      platform.current = new H.service.Platform({ apikey });
      const rasterTileService = platform.current.getRasterTileService({
        queryParams: {
          style: "explore.day",
          features: 'vehicle_restrictions:active_and_inactive,pois:disabled',
          size: 512,
        },
      });
      const rasterTileProvider = new H.service.rasterTile.Provider(
        rasterTileService
      );
      const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
      const newMap = new H.Map(mapRef.current, rasterTileLayer, {
        pixelRatio: window.devicePixelRatio,
        center: {
          lat: coords[0],
          lng: coords[1],
        },
        zoom: 12,
      });

      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );

      newMap.addEventListener("tap", (evt) => {
        if (!isAdding) {
          const coord = newMap.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY
          );
          const marker = new H.map.Marker(coord,  { icon: pngIcon });
          newMap.addObject(marker);
          isAdding = true;
          setIsOpenAccept(true);
          setDestCoords([coord.lat.toFixed(4), coord.lng.toFixed(4)]);
        }
      });
      map.current = newMap;
    }
  }, [isOpenAccept]);

  const handleConfirm = () => {
    close();
    action(destCoords);
  };

  const handleDecline = () => {
    close();
  };

  return (
    <>
      <div style={{ width: "100%", height: "100%" }} ref={mapRef}></div>
      {isOpenAccept ? (
        <AcceptWindow>
          <span>Potwierdź wybrany punkt</span>
          <ButtonContainer>
            <Button onClick={handleConfirm}>Tak</Button>
            <Button secondary onClick={handleDecline}>Nie</Button>
          </ButtonContainer>
        </AcceptWindow>
      ) : (
        ""
      )}
      <CloseModalButton onClick={handleDecline}>
        <IoCloseOutline size={20}/>
      </CloseModalButton>
    </>
  );
};

export default MapModal;
