import { axios } from 'api';

const FriSettlementService = {
    getFriSettlements: params => axios.get(`/delivery-schedule/fri-settlements`, { params: { ...params }}),
    getFriSettlement: id => axios.get(`/delivery-schedule/fri-settlements/${id}`),
    createFriSettlement: form => axios.post(`/delivery-schedule/fri-settlements`, form),
    confirmFriSettlement: id => axios.patch(`/delivery-schedule/fri-settlements/${id}/confirm`, {},{ responseType: 'blob'}),
    refreshFriSettlement: id => axios.patch(`/delivery-schedule/fri-settlements/${id}/refresh`,{}),
    invoiceFriSettlement: id => axios.patch(`/delivery-schedule/fri-settlements/${id}/invoice`,{}),
};

export default FriSettlementService;