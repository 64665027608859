import { axios } from 'api';

const VehiclesService = {
  getCarrierVehicles: ( id, { page, perPage }) => axios.get(`/delivery-schedule/carriers/${id}/cars?page=${page}&itemsPerPage=${perPage}`),
  createVehicle: form => axios.post(`/delivery-schedule/carriers-cars`, form),
  removeVehicle: id => axios.delete(`/delivery-schedule/carriers-cars/${id}`),
  updateVehicle: (id, form) => axios.put(`/delivery-schedule/carriers-cars/${id}`, form),
  getVehicle: id => axios.get(`/delivery-schedule/carriers-cars/${id}`),
};

export default VehiclesService;
