import React, {useEffect, useRef, useState} from 'react';
import markerIconGray from 'assets/img/arrow_nav_gray.png';
import markerIconYellow from 'assets/img/arrow_nav_yellow.png'
import gpsSignal from 'assets/img/gps_signal.png';
import { COLORS, FONTS } from 'config/theme';
import H from "@here/maps-api-for-javascript";

const GPS_DATA_DIFF_IN_SECONDS = 30;

const VehicleMarker = ({ data }) => {
  const now = new Date();
  const lastVehicleGpsDate = new Date(data.location.date);
  const diff = Math.abs(now - lastVehicleGpsDate);
  const diffInMinutes = Math.floor((diff / 1000) / 60);
  const isGpsOutOfDate = diffInMinutes > GPS_DATA_DIFF_IN_SECONDS;


  return (
    <div style={{position: 'relative', left: '32px'}}>
      <div style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: data.loadStatus && data.loadStatus === 'LOADED' ? '#f6c90069' : '#73727282',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img
          style={{
            width: '23px',
            transform: `rotate(${data.location.direction}deg)`,
            transformOrigin: '50% 50%'
          }}
          src={data.loadStatus && data.loadStatus === 'LOADED' ? markerIconYellow : markerIconGray}
          alt="vehicle_marker"
        />
      </div>
      <div style={{
        position: 'absolute',
        left: '40px',
        top: '8px',
        borderRadius: '4px',
        backgroundColor: '#4d4d4d',
        padding: '3px 8px',
        color: `${COLORS.white}`,
        fontFamily: `${FONTS.semiBold}`,
        fontSize: '10px',
        minWidth: '65px',
        display: 'flex',
      }}>
        {isGpsOutOfDate && (
          <img
            style={{
              minWidth: '15px !important',
              maxWidth: '15px !important',
              maxHeight: '15px !important',
              marginRight: '4px'
            }}
            src={gpsSignal}
            alt="gps_signal"
          />
        )}
          <span>{data.name}</span>
          {( data.weight > 0) && (
              <span
                  style={{
                      marginLeft: '4px',
                      display: 'inline-block',
                      color: `${COLORS.green}`
                  }}
              >{data.weight}t</span>
          )}
          {( data.time > 0) && (
              <span
                  style={{
                      marginLeft: '4px',
                      minWidth: '40px',
                      display: 'inline-block',
                      color: data.loadStatus && data.loadStatus === 'LOADED' ? `${COLORS.main}` : `${COLORS.main}`
                  }}
              >{data.time} min</span>
          )}

      </div>
    </div>
  );
};

export default VehicleMarker;
