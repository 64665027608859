import React from 'react';
import { Wrapper, Content, Header, Description, Icon } from './styles';
import { RiAlarmWarningLine } from 'react-icons/ri';

const NotFound = () => {
  return (
    <Wrapper>
      <Content>
        <Icon><RiAlarmWarningLine size={36}/></Icon>
        <Header>Błąd 404!</Header>
        <Description>Strona, której szukasz nie została znaleziona!</Description>
      </Content>
    </Wrapper>
  );
};

export default NotFound;
