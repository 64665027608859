import styled from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const BreakMinutesAfter = styled.span`
  font-size: 12px;
  color: ${COLORS.main};
  margin-left: 2px;
  font-family: ${FONTS.semiBold};
`;

export const BreakMinutesAfterAdditional = styled.div`
  font-size: 10px;
  color: ${COLORS.textGray};
  font-family: ${FONTS.regular};
  font-style: italic;
  margin-top: 3px;
`;
