import React from 'react';
import {
  NewBreakButtonWrapper,
  BreakElem,
  BreakHeader,
  BreakRow,
  BreaksContent,
  RemoveButton,
} from './styles';
import Input from 'components/__common/Input';
import { FormError, Label } from 'components/__styled/Form';
import { VscTrash } from 'react-icons/vsc';
import Button from 'components/__common/Button';
import { GoPlus } from 'react-icons/go';
import { returnValueAsNumber } from 'utils/global';

const BreaksForm = ({ editable, scheduleBreaks, setScheduleBreaks, formErrors, setFormErrors }) => {
  const removeElem = index => {
    const filteredBreaks = scheduleBreaks.filter((r, i) => i !== index);
    setScheduleBreaks(filteredBreaks);
  };

  const addBreak = () => {
    const newBreaks = [...scheduleBreaks, { breakAfterWeight: '', breakMinutes: '' }];
    setScheduleBreaks(newBreaks);
  };

  const handleBreaksChange = (index, field, value) => {
    let errors = { ...formErrors };
    delete errors[`ranges[${index}]`];
    setFormErrors(errors);

    let newBreaks = [...scheduleBreaks];
    newBreaks[index] = {
      ...newBreaks[index],
      [field]: value,
    };

    setScheduleBreaks(newBreaks);
  }

  return (
    <>
      <BreaksContent>
        {scheduleBreaks.length >= 1 && (
          <BreakRow>
            <BreakHeader>
              <Label required>Ilość ton po jakiej ma wystąpić przerwa</Label>
            </BreakHeader>
            <BreakHeader>
              <Label required>Długość przerwy (w minutach)</Label>
            </BreakHeader>
          </BreakRow>
        )}
        {scheduleBreaks.map((breakItem, i) => {
          return (
            <BreakRow key={i}>
              <BreakElem>
                <Input
                  disabled={!editable}
                  type="number"
                  placeholder={'Wpisz wartość...'}
                  onChange={e => {
                    const v = returnValueAsNumber(e.target.value);
                    handleBreaksChange(i, 'breakAfterWeight', v);
                  }}
                  value={breakItem.breakAfterWeight.toString()}
                  isError={formErrors && formErrors[`scheduleBreaks[${i}]`]}
                />
                {formErrors && formErrors[`scheduleBreaks[${i}]`] && <FormError>{formErrors[`scheduleBreaks[${i}]`] }</FormError>}
              </BreakElem>
              <BreakElem>
                <Input
                  disabled={!editable}
                  type="number"
                  placeholder={'Wpisz wartość...'}
                  onChange={e => {
                    const v = returnValueAsNumber(e.target.value);
                    handleBreaksChange(i, 'breakMinutes', v);
                  }}
                  value={breakItem.breakMinutes.toString()}
                  isError={formErrors && formErrors[`scheduleBreaks[${i}]`]}
                />
                {formErrors && formErrors[`scheduleBreaks[${i}]`]  && <FormError>{formErrors[`scheduleBreaks[${i}]`] }</FormError>}
              </BreakElem>
              {editable && (
                <RemoveButton onClick={() => removeElem(i)}>
                  <VscTrash size={20}/>
                </RemoveButton>
              )}
            </BreakRow>
          );
        })}
      </BreaksContent>
      {editable && (
        <NewBreakButtonWrapper>
          <Button
            leftIcon={<GoPlus size={16}/>}
            zoom
            onClick={() => addBreak()}
          >
            Dodaj przerwę
          </Button>
        </NewBreakButtonWrapper>
      )}
    </>
  );
}

export default BreaksForm;
