import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from 'context/UserContext';

const AccessCheckRoute = ({ component, isProtected, availableFor, ...options }) => {
  const { user } = useContext(UserContext);
  let isAvailable = false;

  if (user) {
    if (isProtected) {
      isAvailable = availableFor.some(el => user.roles.includes(el));
    } else {
      isAvailable = true;
    }
  }

  if (isProtected && user && !isAvailable) {
    return <Redirect to={'/panel/tasks'} />
  }

  return <Route {...options} component={component} />;
};

export default AccessCheckRoute;
