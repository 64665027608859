import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const Content = styled.div`
  display: flex;
  margin-top: 30px;
  @media (max-width: ${BREAKPOINTS.huge}) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  flex: 1 1 100%;
  margin-right: 15px;
  @media (max-width: ${BREAKPOINTS.huge}) {
    margin-right: 0;
  }
`;

export const RightSide = styled.div`
  flex: 1 1 270px;
  min-width: 270px;
  margin-left: 15px;
  @media (max-width: ${BREAKPOINTS.huge}) {
    margin-left: 0;
    margin-top: 30px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderContent = styled.div`
  margin-right: 10px;
`;

export const GetStatusDetails = styled.button`
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
`;
