import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const EditButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.tiny}) {
    flex-direction: column;
    margin-top: 10px;
    button {
      margin: 3px;
    }
  }
`;

export const BottomSummaryPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;
