import React from 'react';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { InfoWrapper, InfoText, InfoWrapperContent } from './styles';

const InfoBanner = ({ children }) => {
  return (
    <InfoWrapper>
      <InfoWrapperContent>
        <RiAlarmWarningLine size={25} style={{minWidth: '25px'}}/>
        <InfoText>{children}</InfoText>  
      </InfoWrapperContent>
    </InfoWrapper>
  );
};

export default InfoBanner;
