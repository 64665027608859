import React, { useState } from 'react';
import ScheduleStatusModal from 'components/Tasks/ScheduleStatusModal';

const ScheduleStatusModalContext =  React.createContext();
const { Provider } = ScheduleStatusModalContext;

const ScheduleStatusModalProvider = ({ children }) => {
  const [scheduleStatuses, setScheduleStatuses] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const openStatusesModal = (statuses, scheduleId) => {
    setScheduleStatuses(statuses);
    setCurrentSchedule(scheduleId);
    setModalVisible(true);
  };

  const closeStatusesModal = () => {
    setModalVisible(false);
    setScheduleStatuses(null);
    setCurrentSchedule(null);
  };

  return (
    <Provider value={{ modalVisible, openStatusesModal, closeStatusesModal, scheduleStatuses, currentSchedule, setCurrentSchedule }}>
      <ScheduleStatusModal />
      {children}
    </Provider>
  );
};

export { ScheduleStatusModalContext, ScheduleStatusModalProvider };

export default ScheduleStatusModalContext;
