import styled from 'styled-components';
import { FONTS, COLORS } from 'config/theme';

export const Area = styled.textarea`
  padding: 8px 15px;
  width: 100%;
  border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
  font-size: 12px;
  font-family: ${FONTS.regular};
  border-radius: 6px;
  outline: none;
  min-height: 40px;
  resize: none;
  &:active,
  &:focus {
    border-color: ${COLORS.main};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  }
`;
