import React from 'react';
import { NoRecords, Tbl, TBody, Td, Th, THead, Tr } from 'components/__styled/Table';
import { ScheduleTblWrapper } from '../LogisticScheduleTable/styles';
import { COLORS } from 'config/theme';
import { BreakMinutesAfter, BreakMinutesAfterAdditional } from 'components/__styled/Break';

const FactoryScheduleTable = ({ schedule }) => {

  const getPreviousBreak = index => {
    const prev = schedule ? schedule._embedded.courses.filter((r, i) => r.breakMinutesAfter > 0 && i < index) : [];
    return prev.length > 0 ? prev[prev.length - 1] : null;
  };

  return (
    <ScheduleTblWrapper>
      <Tbl>
        <THead>
          <Tr>
            <Th>Kurs</Th>
            <Th>Podstawienie na wytwórnię</Th>
            <Th>Wyjazd spod wytwórni</Th>
            <Th>Nr rej. pojazdu</Th>
          </Tr>
        </THead>
        <TBody>
          {schedule && schedule._embedded?.courses ? (
            <>
              {schedule._embedded.courses.map((row, i) => {
                return (
                  <React.Fragment key={i}>
                    <Tr>
                      <Td>{row.carNumber}</Td>
                      <Td>{row.timeOfLoading}</Td>
                      <Td>{row.timeOfLeaving}</Td>
                      <Td>{row._embedded && row._embedded.car ? row._embedded.car.registrationNumber : '---'}</Td>
                    </Tr>
                    {row.breakMinutesAfter ? (
                      <Tr >
                        <Td colSpan={'5'} style={{justifyContent: 'center', flex: '1 1 100%', textAlign: 'center', backgroundColor: COLORS.lightMain, padding: '5px 10px', height: '50px', width: '100%'}}>
                          <BreakMinutesAfter>
                            {
                              getPreviousBreak(i)
                                ? `po ${row.deliveryScheduleUsedTonnage - getPreviousBreak(i).deliveryScheduleUsedTonnage} t (łącznie ${row.deliveryScheduleUsedTonnage} t) `
                                : `po ${row.deliveryScheduleUsedTonnage} t `}
                            + {row.breakMinutesAfter} min przerwy
                            <BreakMinutesAfterAdditional>
                              pomiędzy końcem wbudowania a początkiem następnego wbudowania
                            </BreakMinutesAfterAdditional>
                          </BreakMinutesAfter>
                        </Td>
                      </Tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan="6">
                <NoRecords>Brak rekordów...</NoRecords>
              </Td>
            </Tr>
          )}
        </TBody>
      </Tbl>
    </ScheduleTblWrapper>
  );
};

export default FactoryScheduleTable;
