import React from 'react';
import {
  AuthWrapper,
  Wrapper,
  Header,
  Content
} from './styles'
import { RiErrorWarningLine } from 'react-icons/ri';

const AuthError = () => {
  return (
    <AuthWrapper>
      <Wrapper>
        <Content>
          <RiErrorWarningLine size={22} />
          <Header>Wystąpił błąd podczas autoryzacji.</Header>
        </Content>
      </Wrapper>
    </AuthWrapper>
  );
};

export default AuthError;
