import styled, { css } from 'styled-components';
import {BREAKPOINTS, COLORS, FONTS} from 'config/theme';
import { rem } from 'polished';

export const Ball = styled.div`
  border-radius: 50%;
  min-width: ${rem('40px')};
  min-height: ${rem('40px')};
  background-color: ${COLORS.lightGray};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  transition: all .3s ease;
`;

export const Number = styled.span`
  font-family: ${FONTS.bold};
  font-size: ${rem('14px')};
  color: ${COLORS.textGray};
  transition: color .3s ease;
  @media (max-width: ${BREAKPOINTS.large}) {
    font-size: ${rem('11px')};
  }
`;

export const StepContent = styled.div`
  @media (max-width: ${BREAKPOINTS.small}) {
    display: ${({ isActive }) => isActive ? 'block' : 'none'};
  }
`;

export const StepIndex = styled.h4`
  font-family: ${FONTS.bold};
  color: ${COLORS.textGray2};
  font-size: ${rem('12px')};
  text-transform: uppercase;
  margin: 0;
  transition: color .3s ease;
`;

export const StepTitle = styled.h3`
  font-family: ${FONTS.semiBold};
  color: ${COLORS.textGray};
  font-size: ${rem('14px')};
  margin: 0;
  transition: color .3s ease;
`;

export const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg * {
    stroke: ${COLORS.textGray}
  };
`;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'};
  @media (max-width: ${BREAKPOINTS.large}) {
    flex: 1;
    padding-right: 15px;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    flex: none;
  }
  &:not(:last-of-type) {
    padding-bottom: 70px;
    @media (max-width: ${BREAKPOINTS.large}) {
      padding-bottom: 0;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 20px;
    height: calc(100% - 60px);
    border-left: 2px solid ${COLORS.lightGray};
    @media (max-width: ${BREAKPOINTS.large}) {
      display: none;
    }
  }
  ${Ball} {
    ${({ isActive }) => isActive && css`
      background-color: ${COLORS.main};
      box-shadow: 0 0 15px rgba(83, 83, 83, .25);
    `};
  }
  ${Number} {
    ${({ isActive }) => isActive && `color: ${COLORS.dark}`};
  }
  ${StepTitle} {
    ${({ isActive }) => isActive && `color: ${COLORS.dark}`};
  }
  ${StepIndex} {
    ${({ isActive }) => isActive && `color: ${COLORS.textGray}`};
  }
  ${Check} {
    ${({ isActive }) => isActive && css`
      svg * {
        stroke: ${COLORS.dark}
      };
    `}
  }
  @media (max-width: ${BREAKPOINTS.little}) {
    display: ${({ isActive }) => isActive ? 'flex' : 'none'};
  }
  &:hover {
    ${Ball} {
      background-color: ${COLORS.main};
      box-shadow: 0 0 15px rgba(83, 83, 83, .25);
    }
    ${Number} {
      color: ${COLORS.dark};
    }
    ${StepTitle} {
      color: ${COLORS.dark};
    }
    ${StepIndex} {
      color: ${COLORS.textGray};
    }
    ${Check} {
      svg * {
        stroke: ${COLORS.dark}
      };
    }
  }
`;
