import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import Pagination from 'components/Pagination';
import { Subtitle } from 'components/__styled/Subtitle';
import { FlexWrapper } from 'components/__styled/Layout';
import { BottomWrapper, Content } from 'views/ContractVehicles/styles';
import ContractsService from 'services/ContractsService';
import ContractVehiclesTable from 'components/Contracts/ContracVehiclesTable';
import PageLoader from 'components/PageLoader';
import SearchBar from 'components/SearchBar';
import SelectFilter from 'components/SelectFilter';
import { displayToast } from 'utils/toast';

const ContractVehicles = (props) => {

  const [contractVehicles, setContractVehicles] = useState([]);
  const [contractDevices, setContractDevices] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [deviceSetLoading, setDeviceSetLoading] = useState(null);
  const [search, setSearch] = useState('');
  const [contracts, setContracts] = useState([]);
  const [currentContract, setCurrentContract] = useState( null )
  const [contractCarriers, setContractCarriers] = useState([]);
  const [currentCarrier, setCurrentCarrier] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 30,
    totalItems: 1,
  });


  const stateFromLocation = JSON.parse(localStorage.getItem('state'));
  const stateRegisterNumber = localStorage.getItem('register_number');

  useEffect(() => {
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRegisterNumber) {
        setSearch(stateRegisterNumber);
    }
    if (currentContract) {
      getData(stateFromLocation?.contractId ? stateFromLocation?.contractId : currentContract.value, currentCarrier?.value || null);
    } else {
      setContractVehicles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContract, currentCarrier]);

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getContractVehicles(currentContract.value, search, { ...pagination, page }, true);
  };

  const getData = async (contractId, carrierId) => {
    try {
      setDataLoading(true);
      await Promise.all([
        getContractVehicles(contractId, search, pagination, carrierId),
        getContractDevices(contractId, pagination),
        getContractCarriers(contractId)
      ]);
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  };

  const getContracts = async () => {
    try {
      setDataLoading(true);
      const res = await ContractsService.getContracts({itemsPerPage: 99999});
      const options = res.data._embedded ? res.data._embedded.item.map(contract => ({
        label: contract.name,
        value: contract.id
      })) : [];
        if (stateFromLocation && stateFromLocation.contractId) {
            let activeFilter = options.filter(item => item.value === stateFromLocation.contractId );
            setCurrentContract(activeFilter);
        }
      setContracts(options);
      setDataLoading(false);
      return options;
    } catch (err) {
      setDataLoading(false);
    }
  };

  const getContractDevices = async (contractId, withLoader = false) => {
    withLoader && setDataLoading(true);
    const res = await ContractsService.getContractDevices(contractId);
    const options = res.data._embedded ? res.data._embedded.item.map(device => ({
      label: device.name,
      value: device.id
    })) : [];
    setContractDevices(options);
    withLoader && setDataLoading(false);
  };

  const getContractVehicles = async (contractId, search, pagination, carrier, withLoader = false) => {
    withLoader && setDataLoading(true);
    const { perPage, page } = pagination;
    const res = await ContractsService.getContractVehicles(contractId, { search, perPage, page, carrier });
    const vehicles = res.data._embedded?.item?.map(el => {
      return {
        ...el,
        _embedded: {
          ...el._embedded,
          device: el._embedded.device ? {
            id: el._embedded.device.id,
            name: el._embedded.device.name,
            saved: true
          } : null
        }
      }
    });
    setContractVehicles(vehicles || []);
    setPagination({ ...pagination, totalItems: res.data.totalItems });
    withLoader && setDataLoading(false);
  };

  const getContractCarriers = async contractId => {
    const res = await ContractsService.getContractCarriers(contractId);
    const options = res.data._embedded ? res.data._embedded.item.map(carrier => ({
      label: carrier.name,
      value: carrier.id
    })) : [];
    setContractCarriers(options);
  };

  const searchContractVehicles = async (contractId, search, pagination, carrier) => {
    if (contractId) {
      await getContractVehicles(contractId, search, pagination, carrier,true);
    }
  };

  const handleContractChange = option => {
    setCurrentCarrier(null);
    setCurrentContract(option);
  };

  const handleContractCarrierChange = option => {
    setCurrentCarrier(option);
  };

  const assignDeviceToVehicle = (index, option) => {
    const vehicles = [...contractVehicles];
    vehicles[index]._embedded.device = option ? { id: option.value, name: option.label, saved: false } : null;
    setContractVehicles(vehicles);
  };

  const toggleVehicleDevice = async (carId, deviceId, deviceName, setting = true, recordId) => {
    try {
      setDeviceSetLoading(recordId);
      const data = { carId, deviceId: setting ? Number(deviceId) : null, deviceName};
      const res = await ContractsService.setDeviceForVehicle(currentContract.value, data);

      if (res?.status === 200) {
        setContractVehicles(prev => {
          const foundRecordIndex = prev.findIndex(el => el.id === recordId);
          let foundRecord = prev[foundRecordIndex];

          if (setting) {
            foundRecord = {
              ...foundRecord,
              _embedded: {
                ...foundRecord._embedded,
                device: {
                  name: deviceName,
                  id: deviceId,
                  saved: true
                }
              }
            }

            prev[foundRecordIndex] = foundRecord;
            return prev;
          } else {
            if (foundRecordIndex) {
              prev[foundRecordIndex]._embedded.device = null;
            }
            return prev;
          }

        });

        setDeviceSetLoading(null);
        displayToast('success', setting ? 'Nadajnik został przypisany!' : 'Nadajnik został rozłączony z pojazdem!');
      } else {
        setDeviceSetLoading(null);
      }
    } catch (err) {
      setDeviceSetLoading(null);
      console.log(err);
    }
  };

  return (
    <>
      {dataLoading
        ? <PageLoader/>
        : (
          <AnimatePresence exitBeforeEnter>
            <Content>
              <Fade>
                <FlexWrapper data-testid='planner-preview-content' justify={'space-between'}>
                  <div>
                    <Header size={23} beFlex>Pojazdy w kontraktach</Header>
                    <Subtitle>Lista dostępnych pojazdów dla wybranego kontraktu</Subtitle>
                  </div>
                </FlexWrapper>
                <>
                  <TableBox>
                    <FilterWrapper>
                      <SearchBar
                        placeholder={'Wyszukaj...'}
                        onSubmit={searchParam => searchContractVehicles(currentContract?.value, searchParam, pagination, currentCarrier?.value)}
                        onChange={setSearch}
                        value={search}
                      />
                      <FilterContent>
                        <SelectFilter
                          label={'Kontrakt'}
                          value={currentContract}
                          onChange={option => handleContractChange(option)}
                          options={contracts}
                        />
                        {currentContract && (
                          <SelectFilter
                            label={'Przewoźnik'}
                            value={currentCarrier}
                            onChange={option => handleContractCarrierChange(option)}
                            options={contractCarriers}
                          />
                        )}
                      </FilterContent>
                    </FilterWrapper>
                    <ContractVehiclesTable
                      vehicles={contractVehicles}
                      devices={contractDevices}
                      carriers={contractCarriers}
                      assignDeviceToVehicle={assignDeviceToVehicle}
                      toggleVehicleDevice={toggleVehicleDevice}
                      pagination={pagination}
                      deviceSetLoading={deviceSetLoading}
                    />
                  </TableBox>
                  <BottomWrapper justify={'flex-end'}>
                    <Pagination
                      onChange={page => handlePaginationChange(page)}
                      totalItems={pagination.totalItems}
                      page={pagination.page}
                      perPage={pagination.perPage}
                    />
                  </BottomWrapper>
                </>
              </Fade>
            </Content>
          </AnimatePresence>
        )
      }
    </>
  );
};

export default ContractVehicles;
