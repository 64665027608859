import React, { useEffect, useState } from 'react';
import VehicleForm from 'components/Vehicles/VehicleForm';
import VehiclesService from 'services/VehiclesService';
import PageLoader from 'components/PageLoader';
import { useParams } from 'react-router-dom';
import { prepareErrors } from 'utils/form';
import { displayToast } from 'utils/toast';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import history from 'config/history';

const VehicleManagement = () => {
  const { vehicle_id, carrier_id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [vehicleLoading, setVehicleLoading] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    if (vehicle_id && vehicle_id !== 'create') {
      getVehicle(vehicle_id);
    } else {
      setVehicle(null);
    }

    return () => setVehicle(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle_id]);

  const goToVehiclesList = () => {
    history.push(`/panel/carriers/${carrier_id}/vehicles`);
  };

  const getVehicle = async id => {
    try {
      setVehicleLoading(true);
      const res = await VehiclesService.getVehicle(id);
      setVehicle(res.data)
      setVehicleLoading(false);
    } catch (err) {
      console.error(err);
      setVehicleLoading(false);
    }
  };

  const createVehicle = async form => {
    try {
      setFormSubmitting(true);
      form.carrierId = carrier_id;
      await VehiclesService.createVehicle(form);
      setFormSubmitting(false);
      goToVehiclesList();
    } catch (err) {
      console.log(err);
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const updateVehicle = async form => {
    try {
      setFormSubmitting(true);
      form.carrierId = carrier_id;
      await VehiclesService.updateVehicle(vehicle_id, form);
      setFormSubmitting(false);
      goToVehiclesList();
      displayToast('success', 'Dane pojazdu zostały zapisane!');
    } catch (err) {
      console.log(err);
      setFormSubmitting(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  return (
    <AnimatePresence>
      {vehicleLoading
        ? <PageLoader />
        : (
          <Fade>
            <VehicleForm
              vehicle={vehicle}
              formSubmitting={formSubmitting}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onSubmit={vehicle ? updateVehicle : createVehicle}
              goToVehiclesList={goToVehiclesList}
            />
          </Fade>
        )
      }
    </AnimatePresence>
  );
};

export default VehicleManagement;
