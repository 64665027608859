import styled, { css } from 'styled-components';
import { COLORS, FONTS } from 'config/theme';

export const InputWrapper = styled.div`
  flex: 1;
`;

export const SystemInput = styled.input`
  padding: 8px 15px;
  width: 100%;
  border: 1px solid ${({ isError }) => isError ? COLORS.errorRed : COLORS.middleGray};
  font-size: 12px;
  font-family: ${FONTS.regular};
  border-radius: 6px;
  outline: none;
  min-height: 40px;
  &:active,
  &:focus {
    border-color: ${({ isError }) => isError ? COLORS.errorRed : COLORS.main};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  }
  ${({ disabled }) => disabled && css`
    background-color: rgba(0,0,0,0.05);
    opacity: 0.6;
  `};
  ${({ small }) => small && css`
    padding: 8px;
    min-height: 20px;
  `};
`;
