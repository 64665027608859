import React, { useState } from 'react';
import {
  ModalButtonsWrapper,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalWrapper,
  RejectMessageContent,
  RejectedCourses,
  RejectedCoursesTitle,
} from './styles';
import { Tbl, THead, TBody, Tr, Th, Td } from 'components/__styled/Table';
import Button from 'components/__common/Button';
import Textarea from 'components/__common/Textarea';
import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

const AcceptOrRejectScheduleModal = ({
  isVisible,
  closeModal,
  modalContent,
  rejectProcessing,
  rejectInputPlaceholder = 'Podaj powód odrzucenia (min. 10 znaków)'
}) => {
  const [rejectMessage, setRejectMessage] = useState('');

  const handleOnChange = e => {
    setRejectMessage(e.target.value);
  };

  const component = (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <ModalWrapper
          key={'reject-modal'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent>
            <ModalHeader>{modalContent && modalContent.header}</ModalHeader>
            <ModalDescription>{modalContent && modalContent.description}</ModalDescription>
            {modalContent && modalContent.rejectedCourses?.length > 0 && (
              <RejectedCourses>
                <RejectedCoursesTitle>Odrzucone kursy:</RejectedCoursesTitle>
                <Tbl>
                  <THead>
                    <Tr>
                      <Th>Nr rejestracyjny</Th>
                      <Th>Pojazd</Th>
                      <Th>Podstawienie na wytwórnię</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {modalContent.rejectedCourses.map(course => {
                      return (
                        <Tr key={course.id}>
                          <Td>{course._embedded.car.registrationNumber}</Td>
                          <Td>{course.carNumber}</Td>
                          <Td>{course.timeOfLoading}</Td>
                        </Tr>
                      )
                    })}
                  </TBody>
                </Tbl>
              </RejectedCourses>
            )}
            {modalContent && modalContent.withRejectReason && (
              <RejectMessageContent>
                <Textarea placeholder={rejectInputPlaceholder} onChange={handleOnChange}/>
              </RejectMessageContent>
            )}
            <ModalButtonsWrapper>
              <Button
                isLoading={rejectProcessing}
                disabled={(modalContent.withRejectReason && rejectMessage.length < 10) || rejectProcessing}
                onClick={() => modalContent.callback(rejectMessage)}
              >
                Tak
              </Button>
              <Button
                disabled={rejectProcessing}
                secondary
                onClick={closeModal}
              >
                Anuluj
              </Button>
            </ModalButtonsWrapper>
          </ModalContent>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );

  return createPortal(component, document.body);
};

export default AcceptOrRejectScheduleModal;
