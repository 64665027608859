import React from 'react';
import { StyledHeader } from './styles';

const Header = ({ level, size = 20, children, beFlex = false }) => {
  const sizes = [1, 2, 3, 4, 5, 6];
  const Tag = sizes.includes(level) ? `h${level}` : `h2`;

  return (
    <StyledHeader beFlex={beFlex} size={size} as={Tag}>{children}</StyledHeader>
  );
};

export default Header;
