import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import {
  AcceptWindow,
  ButtonContainer,
  CloseModalButton,
} from "./styles";
import Button from 'components/__common/Button';
import locationIcon from "../../assets/img/location_icon.png";
import {IoCloseOutline} from "react-icons/io5";


var pngIcon = new H.map.Icon(locationIcon, { size: { w: 18, h: 32 } });

const MapModal = (props) => {
  const mapRef = useRef(null);
  const map = useRef(null);
  const close = props.close;
  const platform = useRef(null);
  const action = props.action;
  const actionRouteDetails = props.actionRouteDetails;
  const coords = props.coords;
  const apikey = props.apikey;
  const currentRoute = props.currentRoute;
  const [isOpenAccept, setIsOpenAccept] = useState(false);
  const [destCoords, setDestCoords] = useState(null);
  const [totalDistance, setTotalDistance] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [routeDetails, setRouteDetails] = useState(null);
  const geoZone = props.geoZone;
  const center = (geoZone.points.length > 0) ? geoZone.points[0] : {latitude: 51.9189046, longitude: 19.1343786};


  function addPolylineToMap(map) {
    var lineString = new H.geo.LineString();


    geoZone?.points.forEach((pos) => {
      lineString.pushPoint({lat:pos.latitude, lng:pos.longitude});
    });

    map.addObject(new H.map.Polyline(
        lineString, { style: { lineWidth: 4 , strokeColor: "#000"}}
    ));
  }

  useEffect(() => {
    let isAdding = false;

    if (!map.current) {

      platform.current = new H.service.Platform({ apikey });
      const rasterTileService = platform.current.getRasterTileService({
        queryParams: {
          style: "explore.day",
          features: 'vehicle_restrictions:active_and_inactive,pois:disabled',
          size: 512,
        },
      });
      const rasterTileProvider = new H.service.rasterTile.Provider(
        rasterTileService
      );
      const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
      const newMap = new H.Map(mapRef.current, rasterTileLayer, {
        pixelRatio: window.devicePixelRatio,
        center: {
          lat: center.latitude,
          lng: center.longitude,
        },
        zoom: 14,
      });

      addPolylineToMap(newMap)

      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );

      if(currentRoute) {
        currentRoute.sections.forEach((section) => {
          let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

          var polyline = new H.map.Polyline(linestring, {
            style: {
              lineWidth: 4,
              strokeColor: "rgb(65,253,0)",
            },
          });

          newMap.addObject(polyline);
          newMap.getViewModel().setLookAtData({
            bounds: polyline.getBoundingBox(),
          });
          /*resetBtn.addEventListener("click", () => {
            newMap.removeObject(polyline);
          });*/
        });
        var svgMarkup =
                '<svg width="18" height="18" ' +
                'xmlns="http://www.w3.org/2000/svg">' +
                '<circle cx="8" cy="8" r="8" ' +
                'fill="#1b468d" stroke="white" stroke-width="1" />' +
                "</svg>",
            dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 8, y: 8 } }),
            group = new H.map.Group(),
            i,
            j;

        currentRoute.sections.forEach((section) => {
          let poly = H.geo.LineString.fromFlexiblePolyline(
              section.polyline
          ).getLatLngAltArray();

          let actions = section.actions;
          for (i = 0; i < actions.length; i += 1) {
            let action = actions[i];
            var marker = new H.map.Marker(
                {
                  lat: poly[action.offset * 3],
                  lng: poly[action.offset * 3 + 1],
                },
                { icon: dotIcon }
            );
            marker.instruction = action.instruction;
            group.addObject(marker);
          }


          newMap.addObject(group);
          /*resetBtn.addEventListener("click", () => {
            map.removeObject(group);
          });*/
        });
      }

      newMap.addEventListener("tap", (evt) => {
        if (!isAdding) {
          const coord = newMap.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY
          );
          const marker = new H.map.Marker(coord,  { icon: pngIcon });
          newMap.addObject(marker);
          isAdding = true;
          setIsOpenAccept(true);
          setDestCoords([coord.lat.toFixed(4), coord.lng.toFixed(4)]);
          calculateRouteFromAtoB(platform, { lat: center.latitude, lng: center.longitude }, coord);

          function calculateRouteFromAtoB(
              platform,
              origin = { lat:54.392115082912, lng:18.365601013797 },
              dest = { lat: 54.392, lng: 18.36 }
          ) {
            const { lat: originLat, lng: originLng } = origin;
            const { lat: destLat, lng: destLng } = dest;


            var router = platform.current.getRoutingService(null, 8),
                routeRequestParams = {
                  routingMode: "short",
                  transportMode: "truck",
                  spans: "notices",
                  'vehicle[grossWeight]': 40000,
                  'vehicle[height]': 300,
                //  ignoreWaypointVehicleRestriction: "2000;0;construction",
                  origin: `${originLat},${originLng}`,
                  destination: `${destLat},${destLng}`,
                  return: "polyline,turnByTurnActions,actions,instructions,travelSummary",
                };

            router.calculateRoute(routeRequestParams, onSuccess, onError);
          }
          function onSuccess(result) {
            var route = result.routes[0];
            addRouteShapeToMap(route);
            addManueversToMap(route);
            //addWaypointsToPanel(route);
            // addManueversToPanel(route);
            calculateSummary(route);
            setRouteDetails(route);
          }

          function onError(error) {
            alert("Can't reach the remote server");
          }

          function addRouteShapeToMap(route) {
            route.sections.forEach((section) => {
              let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

              var polyline = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 4,
                  strokeColor: "rgba(0, 128, 255, 0.7)",
                },
              });

              newMap.addObject(polyline);
              newMap.getViewModel().setLookAtData({
                bounds: polyline.getBoundingBox(),
              });
              /*resetBtn.addEventListener("click", () => {
                newMap.removeObject(polyline);
              });*/
            });
          }

          function addManueversToMap(route) {
            var svgMarkup =
                    '<svg width="18" height="18" ' +
                    'xmlns="http://www.w3.org/2000/svg">' +
                    '<circle cx="8" cy="8" r="8" ' +
                    'fill="#1b468d" stroke="white" stroke-width="1" />' +
                    "</svg>",
                dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 8, y: 8 } }),
                group = new H.map.Group(),
                i,
                j;

            route.sections.forEach((section) => {
              let poly = H.geo.LineString.fromFlexiblePolyline(
                  section.polyline
              ).getLatLngAltArray();

              let actions = section.actions;
              for (i = 0; i < actions.length; i += 1) {
                let action = actions[i];
                var marker = new H.map.Marker(
                    {
                      lat: poly[action.offset * 3],
                      lng: poly[action.offset * 3 + 1],
                    },
                    { icon: dotIcon }
                );
                marker.instruction = action.instruction;
                group.addObject(marker);
              }


              newMap.addObject(group);
              /*resetBtn.addEventListener("click", () => {
                map.removeObject(group);
              });*/
            });
          }

          var defaultLayers = platform.current.createDefaultLayers();
          var bubble;
          var ui = H.ui.UI.createDefault(newMap, defaultLayers);

          function openBubble(position, text) {
            if (!bubble) {
              bubble = new H.ui.InfoBubble(position, { content: text });
              ui.addBubble(bubble);
            } else {
              bubble.setPosition(position);
              bubble.setContent(text);
              bubble.open();
            }
          }

          function calculateSummary(route) {
            let duration = 0,
                distance = 0;

            route.sections.forEach((section) => {
              distance += section.travelSummary.length;
              duration += section.travelSummary.duration;
            });
            setTotalDistance(distance);
            setTotalDuration(Math.round(duration / 60));
          }
        }
      });
      map.current = newMap;
    }
  }, [isOpenAccept]);

  const handleConfirm = () => {
    close();
    action(totalDuration);
    actionRouteDetails(routeDetails);
  };

  const handleDecline = () => {
    close();
  };

  return (
    <>
      <div style={{ width: "100%", height: "100%" }} ref={mapRef}></div>
      {isOpenAccept ? (
        <AcceptWindow>
          <span>Potwierdź wybrany punkt</span>
          <div>Dystans: {totalDistance ? `${totalDistance/1000} km` : ""}</div>
          <div>Czas dojazdu: {totalDuration ? `${totalDuration} min` : ""}</div>
          <ButtonContainer>
            <Button onClick={handleConfirm}>Tak</Button>
            <Button secondary onClick={handleDecline}>Nie</Button>
          </ButtonContainer>
        </AcceptWindow>
      ) : (
        ""
      )}
      <CloseModalButton onClick={handleDecline}>
        <IoCloseOutline size={20}/>
      </CloseModalButton>
    </>
  );
};

export default MapModal;
