import React, { useState, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { FilterContent, FilterWrapper, TableBox } from 'components/__styled/Table';
import CarriersTable from 'components/Carriers/CarriersTable';
import CarriersService from 'services/CarriersService';
import Pagination from 'components/Pagination';
import history from 'config/history';
import { Subtitle } from 'components/__styled/Subtitle';
import { BsTruck } from 'react-icons/bs';
import SearchBar from 'components/SearchBar';
import SelectFilter from 'components/SelectFilter';
import ContractsService from 'services/ContractsService';
import { FlexWrapper } from 'components/__styled/Layout';
import { IoMdAdd } from 'react-icons/io';
import Button from 'components/__common/Button';
import { FaRegEdit } from 'react-icons/fa';

const CarrierList = () => {
  const [carriers, setCarriers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [carriersLoading, setCarriersLoading] = useState(false);
  const [filters, setFilters] = useState({
    contract: null,
  });
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  const actions = [
    row => ({
      label: 'Pojazdy',
      icon: <BsTruck size={20}/>,
      event: id => gotToCarrierVehicles(id),
    }),
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => history.push(`/panel/carriers/${id}`),
    }),
  ];

  useEffect(() => {
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCarriers(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const gotToCarrierVehicles = carrierId => {
    history.push(`/panel/carriers/${carrierId}/vehicles`);
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
    getCarriers({ ...pagination, page });
  };

  const getCarriers = async (pagination, searchParam = search) => {
    try {
      setCarriersLoading(true);
      let options = {
        itemsPerPage: pagination.perPage,
        page: pagination.page,
      };
      if (searchParam.length) options.name = searchParam;
      if (filters && filters.contract) options.contractId = filters.contract.value;

      const res = await CarriersService.getCarriers(options);
      setCarriers(res.data._embedded?.item ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems });
      setCarriersLoading(false);
    } catch (err) {
      setCarriersLoading(false);
      console.error(err);
    }
  };

  const getContracts = async () => {
    try {
      const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
      const options = res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id }));
      setContracts(options);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const goToCreateCarrier = () => {
    history.push('/panel/carriers/create');
  };

  return (
    <AnimatePresence>
      {carriersLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-preview-content'>
              <FlexWrapper justify={'space-between'}>
                <div>
                  <Header size={23} beFlex>Przewoźnicy</Header>
                  <Subtitle>Lista dostępnych przewoźników w systemie</Subtitle>
                </div>
                <Button zoom onClick={goToCreateCarrier} leftIcon={<IoMdAdd />}>Nowy przewoźnik</Button>
              </FlexWrapper>
              <TableBox>
                <FilterWrapper>
                  <SearchBar
                    placeholder={'Wyszukaj...'}
                    onSubmit={searchParam => getCarriers(pagination, searchParam)}
                    onChange={setSearch}
                    value={search}
                  />
                  <FilterContent>
                    <SelectFilter label={'Kontrakt'} value={filters.contract} onChange={option => handleFilterChange('contract', option)} options={contracts} />
                  </FilterContent>
                </FilterWrapper>
                <CarriersTable
                  carriers={carriers}
                  actions={actions}
                  pagination={pagination}
                />
              </TableBox>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default CarrierList;
