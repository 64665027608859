import { axios } from 'api';

const LaboratoriesService = {
  getLaboratories: params => axios.get(`/delivery-schedule/laboratories`, { params: { ...params }}),
  createLaboratory: form => axios.post(`/delivery-schedule/laboratories`, form),
  removeLaboratory: id => axios.delete(`/delivery-schedule/laboratories/${id}`),
  updateLaboratory: (id, form) => axios.put(`/delivery-schedule/laboratories/${id}`, form),
  getLaboratory: id => axios.get(`/delivery-schedule/laboratories/${id}`),
};

export default LaboratoriesService;
