import React, { useState, useEffect } from 'react';
import ReportForm from 'components/Reports/ReportForm';
import { AnimatePresence } from 'framer-motion';
import Fade from 'components/Fade';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import ContractsService from 'services/ContractsService';
import PageLoader from 'components/PageLoader';
import { displayToast } from 'utils/toast';
import ReportRecalc from "../../components/Reports/ReportRecalc";

const Reports = () => {
  const [contracts, setContracts] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [reportGenerating, setReportGenerating] = useState(false);
  const [reportRecalcing, setReportRecalcing] = useState(false);

  useEffect(() => {
    getContracts();
  }, []);

  const getContracts = async () => {
    try {
      setDataLoading(true);
      const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
      const options = res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id }));
      setDataLoading(false);
      setContracts(options);
    } catch (err) {
      console.error(err);
    }
  };

    const recalc = async (e,params) => {
        try {
            setReportRecalcing(true);
            const res = await ContractsService.recalcReportForContract(params);
            setReportRecalcing(false);
            displayToast('success', 'Zaplanowano ponowne przeliczenie kursów');
        } catch (err) {
            setReportRecalcing(false);
          if (err.response.status === 422) {
            displayToast('error', err.response.data.detail);
          } else {
            displayToast('error', 'Wystąpił błąd');
          }
        }
    };

  const generateReport = async params => {
    try {
      setReportGenerating(true);
      const res = await ContractsService.generateReportForContract(params);
      const fileName = res.headers['content-disposition'].split('filename=')[1];
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      setReportGenerating(false);
      link.click();
    } catch (err) {
      setReportGenerating(false);
      if (err.response.status === 422) {
        displayToast('error', 'Wypełnij wszystkie pola formularza!');
      }
    }
  };

  return (
    <AnimatePresence>
      { dataLoading ? (
        <PageLoader />
      ) : (
        <Fade>
          <Header size={23}>
            Generowanie raportu
          </Header>
          <Subtitle>Wybierz zakres dat i interesujący Cię kontrakt do wygenerowania raportu</Subtitle>
          <ReportForm
            contracts={contracts}
            generateReport={generateReport}
            reportGenerating={reportGenerating}
          />
            <br/>
            <br/>
            <br/>
            <Header size={23}>
                Przeliczanie raportu
            </Header>
            <Subtitle>Wybierz zakres dat do przeliczenia danych raportu</Subtitle>
            <Subtitle>Proces przeliczania trwa ok 5-10 minut</Subtitle>
            <ReportRecalc
             recalc={ recalc}
             reportRecalcing={reportRecalcing}
            />
        </Fade>
      )}
    </AnimatePresence>
  );
};

export default Reports;
