import axios from 'axios';
import { displayToast } from 'utils/toast';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

api.interceptors.request.use(
  async request => {
    return request;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status === 500) {
      displayToast('error', 'Wystąpił nieoczekiwany błąd!');
    }
    return Promise.reject(error);
  },
);


export { api as clientAxios };
