import React from 'react';
import { Wrapper } from './styles';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import RangeForm from 'components/Contracts/RangesForm';

const DistanceRanges = ({ ranges, setRanges, formErrors, setFormErrors }) => {
  return (
    <Wrapper>
      <Header size={16}>Szablon przedziałów</Header>
      <Subtitle>Konfiguracja szablonu przedziałów w kilometrach dla przewoźników.</Subtitle>
      <RangeForm
        ranges={ranges}
        setRanges={setRanges}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </Wrapper>
  );
};

export default DistanceRanges;
