import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const Wrapper = styled.div`
  padding: 0 15px 15px;
  width: 50%;
  @media (max-width: ${BREAKPOINTS.large}) {
    width: 100%;
  }
`;
export const ItemRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const ItemElem = styled.div`
  margin-right: 10px;
  flex: 1;
`;

export const ItemsContent = styled.div`
  padding-top: 20px;
`;
