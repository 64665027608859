import React, { useContext, useEffect, useState } from 'react';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { FlexWrapper } from 'components/__styled/Layout';
import {
  HeaderContent,
} from 'views/Tasks/CarriersPlanner/styles';
import {
  Content,
} from './styles';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import PlannerSummary from 'components/Tasks/PlannerSummary';
import { TableBox } from 'components/__styled/Table';
import LogisticScheduleTable from 'components/Tasks/LogisticScheduleTable';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import TaskContext from 'context/TaskContext';
import TasksService from 'services/TasksService';
import InfoBanner from 'components/Tasks/InfoBanner';
import { InfoOuterWrapper } from 'components/Tasks/TaskForm/styles';

const SchedulePreview = () => {
  const [schedule, setSchedule] = useState([]);
  const { task_id } = useParams();
  const { currentTask, setCurrentTask, setTaskLoading, taskLoading } = useContext(TaskContext);

  useEffect(() => {
    return () => setCurrentTask(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData(task_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  const getData = async task_id => {
    try {
      setTaskLoading(true);
      await getTask(task_id);
      setTaskLoading(false);
    } catch (err) {
      setTaskLoading(false);
    }
  };

  const getTask = async id => {
    const res = await TasksService.getTask(id);
    setCurrentTask(res.data);
    setSchedule(res.data._embedded && res.data._embedded.courses ? res.data._embedded.courses : []);
  };

  return (
    <AnimatePresence>
      {taskLoading
        ? <PageLoader />
        : (
          <Fade>
            <div data-testid='planner-content'>
              <FlexWrapper justify={'space-between'} flexWrap>
                <HeaderContent>
                  <Header size={23} beFlex>
                    Harmonogram
                  </Header>
                  <Subtitle>{currentTask && currentTask.name}</Subtitle>
                </HeaderContent>
              </FlexWrapper>
              {currentTask && currentTask._embedded.courses ? (
                <Content>
                  <PlannerSummary task={currentTask} readOnly={true}/>
                  <TableBox>
                    <LogisticScheduleTable
                      schedule={schedule}
                      readOnly={true}
                    />
                  </TableBox>
                </Content>
              ) : (
                <InfoOuterWrapper>
                  <InfoBanner>Harmonogram nie został jeszcze ustalony przez logistyka!</InfoBanner>
                </InfoOuterWrapper>
              )}
            </div>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default SchedulePreview;
