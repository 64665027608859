import React from 'react';
import { StyledButton, IconWrapper, Loader } from './styles';

const Button = ({
  zoom = false,
  children,
  onClick,
  secondary = false,
  leftIcon = null,
  rightIcon = null,
  isLoading = false,
  as = 'button',
  small = false,
  name,
  disabled = false,
  isLong = false
}) => {
  return (
    <StyledButton
      as={as}
      data-testid={name}
      onClick={onClick}
      disabled={isLoading || disabled}
      leftIcon={leftIcon}
      secondary={secondary}
      small={small}
      zoom={zoom}
      isLong={isLong}
    >
      {isLoading ? (
        <Loader data-testid='loader' />
      ) : (
        <>
          {leftIcon && <IconWrapper left>{leftIcon}</IconWrapper>}
          {children}
          {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
