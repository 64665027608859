import React, { useContext, useState, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import Fade from 'components/Fade';
import { FlexWrapper } from 'components/__styled/Layout';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import Button from 'components/__common/Button';
import { IoMdAdd } from 'react-icons/io';
import { TableBox } from 'components/__styled/Table';
import LaboratoriesTable from 'components/Laboratories/LaboratoryTable';
import { BottomWrapper } from './styles';
import history from 'config/history';
import Pagination from 'components/Pagination';
import { AnimatePresence } from 'framer-motion';
import ModalContext from 'context/ConfirmModalContext';
import { FaRegEdit } from 'react-icons/fa';
import { VscTrash} from 'react-icons/vsc';
import { COLORS } from 'config/theme';
import LaboratoriesService from 'services/LaboratoriesService';

const LaboratoriesList = () => {
  const { openModal } = useContext(ModalContext);
  const [laboratories, setLaboratories] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 10,
  });

  useEffect(() => {
    getLaboratories(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [
    row => ({
      label: 'Edytuj',
      icon: <FaRegEdit size={20}/>,
      event: id => goToEditLaboratory(id),
    }),
    row => ({
      label: 'Usuń',
      icon: <VscTrash size={24} />,
      event: id => openModal('Czy chcesz usunąć rekord?', 'Pamiętaj, że operacja jest nieodwracalna.', () => removeLaboratory(id)),
      color: COLORS.errorRed2,
    }),
  ];

  const goToEditLaboratory = laboratoryId => {
    history.push(`/panel/laboratories/${laboratoryId}`);
  };

  const goToCreateLaboratory = () => {
    history.push(`/panel/laboratories/create`);
  };

  const removeLaboratory = async id => {
    try {
      setDataLoading(true);
      await LaboratoriesService.removeLaboratory(id);
      await getLaboratories(pagination);
    } catch (err) {
      console.error(err);
      setDataLoading(false);
    }
  };

  const handlePaginationChange = page => {
    setPagination({ ...pagination, page });
  };

  const getLaboratories = async pagination => {
    try {
      setDataLoading(true);
      const res = await LaboratoriesService.getLaboratories({
        page: pagination.page,
        itemsPerPage: pagination.perPage,
      });
      setLaboratories(res.data._embedded?.item ? res.data._embedded.item : []);
      setPagination({ ...pagination, totalItems: res.data.totalItems, page: pagination.page });
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {dataLoading
        ? <PageLoader />
        : (
          <Fade>
            <FlexWrapper justify={'space-between'}>
              <div>
                <Header size={23} beFlex>Laboratoria</Header>
                <Subtitle>Lista dostępnych laboratoriów w systemie.</Subtitle>
              </div>
              <Button zoom onClick={goToCreateLaboratory} leftIcon={<IoMdAdd />}>Nowe laboratorium</Button>
            </FlexWrapper>
            <TableBox>
              <LaboratoriesTable
                laboratories={laboratories}
                pagination={pagination}
                actions={actions}
              />
            </TableBox>
            <BottomWrapper>
              <Pagination
                onChange={page => handlePaginationChange(page)}
                totalItems={pagination.totalItems}
                page={pagination.page}
                perPage={pagination.perPage}
              />
            </BottomWrapper>
          </Fade>
        )}
    </AnimatePresence>
  );
};

export default LaboratoriesList;
