import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  ActionButton,
  ActionButtonWrapper,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import { BreakMinutesAfter, BreakMinutesAfterAdditional } from 'components/__styled/Break';
import ReactTooltip from 'react-tooltip';
import { COLORS } from 'config/theme';

const CreateScheduleSummaryTable = ({ headers, rows, actions, pagination = null }) => {

  const getPreviousBreak = index => {
    const prev = rows ? rows.filter((r, i) => r.breakMinutesAfter > 0 && i < index) : [];
    return prev.length > 0 ? prev[prev.length - 1] : null;
  };

  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Auto</Th>
            <Th>Podstawienie na WMB</Th>
            <Th>Start wbudowania</Th>
            <Th>Powrót na WMB</Th>
          </Tr>
        </THead>
        <TBody>
          {rows && rows.length > 0 ?
            rows.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Tr>
                    <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                    <Td>{row.carNumber}</Td>
                    <Td>{row.timeOfLoading}</Td>
                    <Td>{row.timeOfBuilding}</Td>
                    <Td>{row.timeOfReturn}</Td>
                    {actions && (
                      <Td>
                        <ActionButtonWrapper>
                          {actions.map((actionFn, i) => {
                            const action = actionFn(row);
                            return (
                              <ActionButton
                                key={i}
                                color={action.color}
                                onClick={() => action.event(row.id)}
                                data-tip={action.label}
                              >
                                {action.icon}
                              </ActionButton>
                            );
                          })}
                        </ActionButtonWrapper>
                      </Td>
                    )}
                  </Tr>
                  {row.breakMinutesAfter ? (
                    <Tr>
                      <Td colSpan={"5"} style={{textAlign: 'center', backgroundColor: COLORS.lightMain, padding: '5px 10px'}}>
                        <BreakMinutesAfter>
                          {
                            getPreviousBreak(i)
                            ? `po ${row.deliveryScheduleUsedTonnage - getPreviousBreak(i).deliveryScheduleUsedTonnage} t (łącznie ${row.deliveryScheduleUsedTonnage} t) `
                            : `po ${row.deliveryScheduleUsedTonnage} t `}
                          + {row.breakMinutesAfter} min przerwy
                          <BreakMinutesAfterAdditional>
                            pomiędzy końcem wbudowania a początkiem następnego wbudowania
                          </BreakMinutesAfterAdditional>
                        </BreakMinutesAfter>
                      </Td>
                    </Tr>
                  ) : null}
                </React.Fragment>
              );
            }) : (
              <Tr>
                <Td colSpan={actions ? headers.length + 2 : headers.length + 1}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default CreateScheduleSummaryTable;
