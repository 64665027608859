import React from 'react';
import { Label, Check, Checkmark } from './styles';

const Checkbox = ({ value, onChange, label }) => {
  return (
    <Label isLabel={label && label.length}>
      {label}
      <Check isLabel={label && label.length} type="checkbox" checked={value} onChange={onChange} />
      <Checkmark isLabel={label && label.length} />
    </Label>
  );
};

export default Checkbox;
