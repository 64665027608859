import styled from 'styled-components';
import { FONTS, COLORS, BREAKPOINTS } from 'config/theme';
import { rem } from 'polished';

export const SummaryWrapper = styled.div`
  display: block;
`;

export const SummaryItem = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINTS.small}) {
    margin-bottom: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const SummaryItemText = styled.div`
  font-size: ${rem('14px')};
  font-family: ${FONTS.semiBold};
  color: ${COLORS.dark};
  @media (max-width: ${BREAKPOINTS.small}) {
    margin-left: 5px;
    margin-bottom: 2px;
  }
`;

export const SummaryItemSpace = styled.div`
  flex: 1;
  border-bottom: 1px dotted ${COLORS.lightGray};
  margin: 0 5px 3px 5px;
  @media (max-width: ${BREAKPOINTS.small}) {
    display: none;
  }
`;

export const SummaryItemValue = styled.div`
  font-family: ${FONTS.bold};
  color: ${COLORS.main};
  font-size: ${rem('20px')};
  line-height: 1;
  font-variant-numeric: tabular-nums;
`;
