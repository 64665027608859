import React from 'react';
import { Wrapper } from './styles';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import {ItemsContent, ItemElem, ItemRow} from "./styles";
import {FormError} from "../../__styled/Form";
import Input from "../../__common/Input";

const Resources = ({ formErrors, setFormErrors, form, setForm }) => {
    const handleFormChange = (name, value) => {
        let errors = { ...formErrors };
        delete errors[name];
        setFormErrors(errors);
        setForm( {...form, [name]: value });
    };
  return (
    <Wrapper>
      <Header size={14}>Zasoby InSite</Header>
        <ItemsContent>
            <ItemRow>
                <ItemElem>
                    <Input
                        type='text'
                        disabled={true}
                        placeholder={'Transport'}
                    />
                </ItemElem>
                <ItemElem>
                    <Input
                        type='text'
                        name="resourceRide"
                        placeholder={'Kod zasobu'}
                        onChange={e => handleFormChange('resourceRide', e.target.value)}
                        value={form.resourceRide}
                        isError={formErrors && formErrors[`resourceRide`]}
                    />
                    {formErrors && formErrors[`resourceRide`]  && <FormError>{formErrors[`resourceRide`] }</FormError>}
                </ItemElem>
            </ItemRow>
            <ItemRow>
                <ItemElem>
                    <Input
                        type='text'
                        disabled={true}
                        placeholder={'Postój na WMB'}
                    />
                </ItemElem>
                <ItemElem>
                    <Input
                        type='text'
                        name="resourceStoppageAtFactory"
                        placeholder={'Kod zasobu'}
                        onChange={e => handleFormChange('resourceStoppageAtFactory', e.target.value)}
                        value={form.resourceStoppageAtFactory}
                        isError={formErrors && formErrors[`resourceStoppageAtFactory`]}
                    />
                    {formErrors && formErrors[`resourceStoppageAtFactory`]  && <FormError>{formErrors[`resourceStoppageAtFactory`] }</FormError>}
                </ItemElem>
            </ItemRow>
            <ItemRow>
                <ItemElem>
                    <Input
                        type='text'
                        disabled={true}
                        placeholder={'Postój na budowie'}
                    />
                </ItemElem>
                <ItemElem>
                    <Input
                        type='text'
                        name="resourceStoppageAtConstruction"
                        placeholder={'Kod zasobu'}
                        onChange={e => handleFormChange('resourceStoppageAtConstruction', e.target.value)}
                        value={form.resourceStoppageAtConstruction}
                        isError={formErrors && formErrors[`resourceStoppageAtConstruction`]}
                    />
                    {formErrors && formErrors[`resourceStoppageAtConstruction`]  && <FormError>{formErrors[`resourceStoppageAtConstruction`] }</FormError>}
                </ItemElem>
            </ItemRow>
        </ItemsContent>
    </Wrapper>
  );
};

export default Resources;
