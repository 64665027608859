import React from 'react';
import {
  Tbl,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  NoRecords,
  TblWrapper,
} from 'components/__styled/Table';
import ReactTooltip from 'react-tooltip';
import { Parser } from 'html-to-react'
import {Link} from "react-router-dom";
import moment from "moment";
import Button from "../../__common/Button";

const AlertsTable = ({ alerts, pagination }) => {
  return (
    <TblWrapper>
      <ReactTooltip className="tooltip" effect="solid" />
      <Tbl>
        <THead>
          <Tr>
            <Th>Lp</Th>
            <Th>Typ</Th>
            <Th>Treść</Th>
            <Th>Czas wygenerowania</Th>
            <Th>Status</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </THead>
        <TBody>
          {alerts && alerts.length > 0 ?
              alerts.map((row, i) => {
                  return (
                <Tr key={i}>
                  <Td>{pagination ? (pagination.perPage * pagination.page) - pagination.perPage + (i + 1) : i + 1}</Td>
                  <Td>{row.type}</Td>
                  <Td> {Parser().parse(row.message)} </Td>
                  <Td>{row.createdAt}</Td>
                  <Td>{row.resolved}</Td>


                    <Td>
                        {row.typeEnum === 'VEHICLE_NO_LOCATION' && row.contractId && row.resolved === '---'  ? (
                            <>
                            <Link
                                // target="_blank"
                                onClick={() => {
                                    let registrationList = row.message;
                                    let index = registrationList.indexOf('<b>');
                                    let temp = registrationList.substr(index + 3,7) ;
                                    localStorage.removeItem("register_number");
                                    localStorage.setItem("register_number",temp);
                                    localStorage.removeItem("state");
                                    localStorage.setItem('state', JSON.stringify(
                                        { 
                                            contractId: row.contractId,
                                            date: moment(row.createdAt).format('YYYY-MM-DD')
                                        }
                                    ));
                                }}
                                to={{pathname: "/panel/contract-cars"}}
                            >
                                <Button> Przejdź </Button>
                            </Link>
                            </>) : null }

                        {row.typeEnum === 'WEIGHT_UN_KNOW_CAR' && row.contractId && row.resolved === '---'  ? (
                            <Link
                                onClick={() => {
                                    localStorage.removeItem("state");
                                    localStorage.setItem('state', JSON.stringify({contractId: row.contractId, date: moment(row.createdAt).format('YYYY-MM-DD')}));
                                }}
                                target="_blank"
                                to ={{pathname: "/panel/weightings"}}
                            >
                                <Button> Przejdź </Button>
                            </Link>) : null }
                    </Td>
                </Tr>
              );
            }) : (
              <Tr>
                <Td colSpan={6}>
                  <NoRecords>Brak rekordów...</NoRecords>
                </Td>
              </Tr>
            )}
        </TBody>
      </Tbl>
    </TblWrapper>
  );
};

export default AlertsTable;
