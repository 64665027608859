import React, {useContext} from 'react';
import {useEffect, useState} from "react";
import PageLoader from "../../../components/PageLoader";
import Header from "../../../components/__common/Header";
import {Subtitle} from "../../../components/__styled/Subtitle";
import {AnimatePresence} from "framer-motion";
import Fade from 'components/Fade';
import FriSettlementService from "../../../services/FriSettlementService";
import {useParams} from "react-router-dom";
import moment from "moment";
import { FlexWrapper } from 'components/__styled/Layout';
import {Content} from './styles';
import FriSettlementContractor from "../../../components/FriSettlement/FriSettlementContractor";
import Button from "../../../components/__common/Button";
import { BsCheck2 } from 'react-icons/bs';
import { BiDownload } from 'react-icons/bi';
import ContractsService from "../../../services/ContractsService";
import {displayToast} from "../../../utils/toast";
import ReactTooltip from "react-tooltip";
import ConfirmModalContext from "../../../context/ConfirmModalContext";
import { MdRefresh } from 'react-icons/md';
import {friSettlementStatusColors} from "../../../config/statuses";
import StatusChip from "../../../components/Tasks/StatusChip";


const FriSettlementManagement = () => {
    const { fri_id } = useParams();
    const [friLoading, setFriLoading] = useState(false);
    const [friSettlement, setFriSettlement] = useState(null);
    const { openModal } = useContext(ConfirmModalContext);
    const getSettlement = async id => {
        try {
            setFriLoading(true);
            const res = await FriSettlementService.getFriSettlement(id);
            setFriSettlement(res.data);
            setFriLoading(false);
        } catch (err) {
            console.error(err);
            setFriLoading(false);
        }
    };

    const refreshSettlement = async () => {
        try {
            setFriLoading(true);
            const res = await FriSettlementService.refreshFriSettlement(friSettlement.id);
            setFriSettlement(res.data);
            setFriLoading(false);
        } catch (err) {
            if (err.response && err.response.status === 422) {
                displayToast('error', 'Wystąpił błąd podczas aktualizacji rozliczenia: ' . err.response.data.detail);
            } else {
                displayToast('error', 'Wystąpił błąd podczas aktualizacji rozliczenia');
            }
            setFriLoading(false);
        }
    };

    const invoiceSettlement = () => {

        const callback = async () => {
            try {
                setFriLoading(true);
                const res = await FriSettlementService.invoiceFriSettlement(friSettlement.id);
                setFriSettlement(res.data);
                setFriLoading(false);
            } catch (err) {
                if (err.response && err.response.status === 422) {
                    displayToast('error', 'Wystąpił błąd podczas ustawienia jako zafakturowane rozliczenia: ' . err.response.data.detail);
                } else {
                    displayToast('error', 'Wystąpił błąd podczas ustawienia jako zafakturowane rozliczenia');
                }
                setFriLoading(false);
            }
        }

        openModal(
            'Czy potwierdzasz ustawienie rozliczenia jako zafakturowane?',
            'Operacji nie można cofnąć',
            callback,
        );

    };

    const confirmSettlement = () => {
        const callback = async () => {
            try {
                setFriLoading(true);
                const res = await FriSettlementService.confirmFriSettlement(friSettlement.id);
                const fileName = res.headers['content-disposition'].split('filename=')[1];
                const blob = new Blob([res.data], {
                    type: 'application/zip'
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                setFriLoading(false);
                link.click();
            } catch (err) {
                setFriLoading(false);
                if (err.response.status === 422) {
                    displayToast('error', 'Wystąpił błąd podczas zatwierdzania raportu!');
                }
            }
        }
        if (friSettlement.status === 'DRAFT') {
            if (friSettlement.errors.length > 0) {
                openModal(
                    'Czy potwierdzasz zatwierdzenie rozliczenia?',
                    'Rozliczenie zawiera błędy które mogą spowodować problem podczas importu do InSite',
                    callback,
                );
            } else {
                openModal(
                    'Czy potwierdzasz zatwierdzenie rozliczenia?',
                    'Rozliczenie nie zawiera błędów',
                    callback,
                );
            }

        } else {
            callback();
        }

    };

    const generateReport = async () => {
        try {
            let params = {
                contractId: friSettlement._embedded.contract.id,
                type: "SETTLING",
                dateFrom: friSettlement.dateFrom,
                dateTo: friSettlement.dateTo,
            };
            setFriLoading(true);
            const res = await ContractsService.generateReportForContract(params);
            const fileName = res.headers['content-disposition'].split('filename=')[1];
            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            setFriLoading(false);
            link.click();
        } catch (err) {
            setFriLoading(false);
            if (err.response.status === 422) {
                displayToast('error', 'Wystąpił błąd podczas pobierania raportu!');
            }
        }
    };

    useEffect(() => {
        getSettlement(fri_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fri_id]);

    return (
        <AnimatePresence>
            {friLoading ? (
                <PageLoader />
            ) : (
                <Fade>
                        <div>
                            <FlexWrapper>
                                <Header size={23} beFlex>
                                    Rozliczenie FRI dla kontraktu: {friSettlement && friSettlement._embedded && friSettlement._embedded.contract
                                    ? friSettlement._embedded.contract.name
                                    : '-'
                                }
                                </Header>
                                <StatusChip
                                    withIndent
                                    bgColor={friSettlement && friSettlementStatusColors[friSettlement.status].color}
                                >
                                    {friSettlement && friSettlementStatusColors[friSettlement.status].name}
                                </StatusChip>
                            </FlexWrapper>
                            <Subtitle>
                                {friSettlement && friSettlement.dateFrom ? moment(friSettlement.dateFrom).format('YYYY.MM.DD') : ''} -
                                {friSettlement && friSettlement.dateTo ? moment(friSettlement.dateTo).format('YYYY.MM.DD') : ''}
                            </Subtitle>
                        </div>
                    <FlexWrapper justify={'flex-end'}>
                        {friSettlement && friSettlement.status === 'DRAFT'
                            ? <Button zoom onClick={refreshSettlement} leftIcon={<MdRefresh/>}>Aktualizuj</Button>
                            : ''
                        }
                            <Button zoom onClick={confirmSettlement} leftIcon={<BsCheck2/>}>{friSettlement && friSettlement.status && friSettlement.status === 'DRAFT' ? 'Zatwierdź' : "Pobierz paczkę"}</Button>
                            <Button zoom onClick={generateReport} leftIcon={<BiDownload/>}>Pobierz raport</Button>
                        {friSettlement && friSettlement.status === 'CONFIRMED'
                            ? <Button zoom onClick={invoiceSettlement} leftIcon={<BsCheck2/>}>Zafakturowane</Button>
                            : ''
                        }
                    </FlexWrapper>
                    <Content>
                        <ReactTooltip id="mainTooltip" className="tooltip" effect="solid" multiline={true} html={true} />
                        {friSettlement && friSettlement.contractors && friSettlement.contractors.map((contractor, i) => {
                            return (
                                <FriSettlementContractor
                                    key={i}
                                    settlementContractor={contractor}
                                />
                            );
                        })}
                    </Content>
                </Fade>
            )
            }
        </AnimatePresence>
    );
};

export default FriSettlementManagement;