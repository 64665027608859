import { axios } from 'api';

const CarriersService = {
  getCarriers: params => axios.get(`/delivery-schedule/carriers`, { params: { ...params }}),
  getCarrier: id => axios.get(`/delivery-schedule/carriers/${id}`),
  createCarrier: form => axios.post(`/delivery-schedule/carriers`, form),
  updateCarrier: (id, form) => axios.put(`/delivery-schedule/carriers/${id}`, form),
};

export default CarriersService;
