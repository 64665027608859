import React from 'react';
import { Wrapper } from './styles';

const StatusChip = props => {
  return (
    <Wrapper {...props}>
      {props.children}
    </Wrapper>
  );
};

export default StatusChip;
