import styled from 'styled-components';
import { BREAKPOINTS } from 'config/theme';

export const ClientScreenWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
`;

export const ClientContent = styled.div`
  padding: 40px;
  width: 100%;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 25px;
    padding-top: 80px;
  }
`;
