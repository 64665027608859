import React, { useState, useEffect, useRef } from 'react';
import {
  MainRow,
  NewRateButtonWrapper,
  RateElem,
  RateRow,
  HeadersRow,
  RatesContent,
  RemoveButton,
  RateHeader,
  Placeholder,
  OuterRatesContent,
  ArrowIcon,
  CarrierRatesRow,
  Name,
  NameWrapper,
  RatesGroupSelectWrapper,
  FilterField,
  RemoveButtonText,
  LoadingVignette,
  RateFormButtonsWrapper,
  MainFieldsWrapper,
  AgreementField,
  AgreementLink
} from './styles';
import Input from 'components/__common/Input';
import Select from 'components/__common/Select';
import Button from 'components/__common/Button';
import { FormError, Label } from 'components/__styled/Form';
import { VscTrash } from 'react-icons/vsc';
import { GoPlus } from 'react-icons/go';
import { BsCheck2 } from 'react-icons/bs';
import {prepareErrors, setSelectOption} from 'utils/form';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Datepicker from 'components/__common/Datepicker';
import { FlexWrapper } from 'components/__styled/Layout';
import moment from 'moment';
import ContractsService from 'services/ContractsService';
import { Spinner } from 'components/__styled/Spinner';
import { displayToast } from 'utils/toast';
import InputModal from "../../__common/InputModal";

const RatesForm = ({
  ranges,
  formSending,
  dateFrom,
  paidStopover,
  rangeWorkRate,
  rangeSleepRate,
  setCarriersRates,
  defaultRates,
  carrier,
  ratesId,
  ratesDates,
  contractId, agreements, agreement
}) => {
  const ratesContent = useRef();
  const [newRatesGroup, setNewRatesGroup] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [dates, setDates] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [formData, setFormData] = useState({
    carriersRatesRanges: [],
    dateFrom: dateFrom ? dateFrom : moment(new Date()).format('YYYY-MM-DD'),
    paidStopover: null,
    rangeWorkRate: null,
    rangeSleepRate: null,
    agreementId: null,
  });
  const [rateGroupLoading, setRateGroupLoading] = useState(false);

  const rateTypes = [
    { label: 'Tona', value: 'TONE' },
    { label: 'Tonokilometr', value: 'TONE_KM' },
    { label: 'Kilometr', value: 'KM' },
  ];

  const agreementOptions = agreements ? agreements.map(el => ({ label: el.name, value: el.id, url: el.registerLink })) : [];


  useEffect(() => {
    if (newRatesGroup) {
      const newRates = ranges.map(r => ({ rangeFrom: r.from, rangeTo: r.to, rangeRate: null, rangeUnit: 'TONE' }))
      setFormData({
        paidStopover: 15,
        rangeWorkRate: null,
        rangeSleepRate: null,
        agreementId: null,
        carriersRatesRanges: newRates,
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      });
      setFormErrors(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRatesGroup]);


  useEffect(() => {
    if (defaultRates) {
      const rates = defaultRates.map(r => r.type || r.rangeUnit ? r : { ...r, rangeUnit: 'TONE' });
      setFormData({
        ...formData,
        paidStopover,
        dateFrom,
        rangeWorkRate,
        rangeSleepRate,
        carriersRatesRanges: rates,
        rangeContractorAgreement: agreement ? {'value': agreement.id, 'label': agreement.name} : null,
        rangeContractorAgreementUrl: agreement ? agreement.registerLink : null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRates, rangeWorkRate, rangeSleepRate, dateFrom]);

  useEffect(() => {
    if (ratesDates) {
      const dateOptions = Object.keys(ratesDates).map(d => ({ label: ratesDates[d], value: d }));
      const currentOption = dateOptions.find(opt => opt.value === ratesId);
      setDates(dateOptions);
      setCurrentDate(currentOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratesDates]);

  const removeElem = index => {
    const filteredRates = formData.carriersRatesRanges.filter((r, i) => i !== index);
    setFormData({
      ...formData,
      carriersRatesRanges: filteredRates,
    })
  };

  const addRange = () => {
    const newRates = [...formData.carriersRatesRanges, { rangeFrom: null, rangeTo: null, rangeRate: null, rangeUnit: 'TONE' }];
    setFormData({
      ...formData,
      carriersRatesRanges: newRates,
    })
  };

  const handleFormChange = (field, value) => {
    console.log(field);
    console.log(value);
    let errors = { ...formErrors };
    delete errors[field];
    setFormErrors(errors);
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleRatesChange = (index, field, value) => {
    let errors = { ...formErrors };
    delete errors[`carriersRatesRanges[${index}].${field}`];
    setFormErrors(errors);
    let newRates = [...formData.carriersRatesRanges];
    newRates[index] = {
      ...newRates[index],
      [field]: value,
    };

    setFormData({
      ...formData,
      carriersRatesRanges: newRates,
    })
  };

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = (saveAll = false) => {
    const { dateFrom, rangeWorkRate, rangeSleepRate, carriersRatesRanges, paidStopover, rangeContractorAgreement } = formData;
    const form = {
      contractId,
      carrierId: carrier?.id,
      dateFrom,
      paidStopover,
      rangeWorkRate,
      rangeSleepRate,
      carriersRatesRanges,
      agreementId: rangeContractorAgreement?.value
    };

    if (newRatesGroup) {
      createCarrierRates(form, saveAll);
    } else {
      updateCarrierRates(currentDate.value, form, saveAll);
    }
  };

  const handleCurrentRatesDate = date => {
    setCurrentDate(date);
    getSpecificRateGroup(date.value);
  };

  const getSpecificRateGroup = async id => {
    try {
      setRateGroupLoading(true);
      const res = await ContractsService.getSingleRatesGroup(contractId, id);
      const { dateFrom, carriersRatesRanges, rangeWorkRate, rangeSleepRate, agreement, paidStopover } = res.data;
      setFormData({
        ...formData,
        dateFrom,
        carriersRatesRanges,
        rangeWorkRate,
        rangeSleepRate,
        paidStopover,
        rangeContractorAgreement: agreement ? {'value': agreement.id, 'label': agreement.name} : null,
        rangeContractorAgreementUrl: agreement ? agreement.registerLink : null
      });
      setRateGroupLoading(false);
    } catch (err) {
      setRateGroupLoading(false);
    }
  };

  const updateCarrierRates = async (rateId, form, saveAll = false) => {
    try {
      setRateGroupLoading(true);
      const data = {...form, saveAll };
      await ContractsService.updateContractRates(contractId, rateId, data);
      const res = await ContractsService.getRatesByDate(contractId, formData.dateFrom);
      setCarriersRates(res.data.carriersRates._embedded.item);
      setRateGroupLoading(false);
      setFormErrors(null);
      displayToast('success', 'Zaktualizowano cennik!');
    } catch (err) {
      setRateGroupLoading(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const createCarrierRates = async (form, saveAll = false) => {
    try {
      setRateGroupLoading(true);
      const data = {...form, saveAll };
      await ContractsService.createContractRates(contractId, data);
      const res = await ContractsService.getRatesByDate(contractId, formData.dateFrom);
      setCarriersRates(res.data.carriersRates._embedded.item);
      setRateGroupLoading(false);
      setFormErrors(null);
      setNewRatesGroup(false);
      displayToast('success', 'Dodano nowy cennik!');
    } catch (err) {
      setRateGroupLoading(false);
      if (err.response && err.response.status === 422) {
        displayToast('error', 'Formularz zawiera błędy!');
        setFormErrors(prepareErrors(err.response.data.violations));
      }
    }
  };

  const handleItemsSelectChange = (index, option) => {
    let errors = { ...formErrors };
    delete errors[`items[${index}]`];
    setFormErrors(errors);
    setFormData({
      ...formData,
      ['rangeContractorAgreement']: option,
      ['rangeContractorAgreementUrl']: option.url,
    });
  }

  const style = isExpanded ? { height: 'auto' } : { height: 0 };

  return (
    <CarrierRatesRow>
      {(rateGroupLoading || formSending) && (
        <LoadingVignette>
          <Spinner />
        </LoadingVignette>
      )}
      <NameWrapper onClick={toggleAccordion}>
        <Name>{carrier?.name}</Name>
        <ArrowIcon>
          {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </ArrowIcon>
      </NameWrapper>
      <OuterRatesContent ref={ratesContent} style={style}>
        <RatesContent>
          <RatesGroupSelectWrapper>
            <FlexWrapper>
              {!newRatesGroup && (
                <FilterField>
                  <Label>Cennik</Label>
                  <Select
                    placeholder={'Wybierz cennik'}
                    name="ratesGroup"
                    options={dates}
                    asPortal={true}
                    value={currentDate}
                    onChange={option => handleCurrentRatesDate(option)}
                  />
                </FilterField>
              )}
            </FlexWrapper>
            {!newRatesGroup && (
              <RateFormButtonsWrapper>
                <Button
                  zoom
                  onClick={() => setNewRatesGroup(true)}
                  leftIcon={<GoPlus size={16}/>}
                >
                  Nowy cennik
                </Button>
              </RateFormButtonsWrapper>
            )}
          </RatesGroupSelectWrapper>
          <MainFieldsWrapper>
            <FilterField>
              <Label>Postój w cenie (min)</Label>
              <InputModal
                type='number'
                disabled={true}
                min={1}
                placeholder={'Postój w cenie kursu'}
                onChange={e => handleFormChange('paidStopover', Number(e.target.value))}
                value={formData.paidStopover}
                handleChange={handleFormChange}
                description={'Ustalona wartość jest przestojem ujętym w cenie transportu - czyli <strong>wartość odejmuje się od postoju na WMB i postoju na budowie</strong>. Do czasów przestoju dodawane są jeszcze elementy technologiczne - czas zakrycia plandeki, czas przygotowania skrzyni ładunkowej itd. <strong>Ustalona przez Ciebie wartość daje łącznie odliczenie (wynik wartości x 2) minut od czasu przestoju dla jednego kursu</strong>'}
                inputLabel={'Postój w cenie (min)'}
                isError={formErrors && formErrors[`paidStopover`]}
              />
              {formErrors && formErrors[`paidStopover`]  && <FormError>{formErrors[`paidStopover`] }</FormError>}
            </FilterField>
            <FilterField>
              <Label>Stawka (praca)</Label>
              <Input
                type='number'
                min={1}
                placeholder={'Stawka (praca)'}
                onChange={e => handleFormChange('rangeWorkRate', Number(e.target.value))}
                value={formData.rangeWorkRate}
                isError={formErrors && formErrors[`rangeWorkRate`]}
              />
              {formErrors && formErrors[`rangeWorkRate`]  && <FormError>{formErrors[`rangeWorkRate`] }</FormError>}
            </FilterField>
            <FilterField>
              <Label>Stawka (postój)</Label>
              <Input
                type='number'
                min={1}
                placeholder={'Stawka (postój)'}
                onChange={e => handleFormChange('rangeSleepRate', Number(e.target.value))}
                value={formData.rangeSleepRate}
                isError={formErrors && formErrors[`rangeSleepRate`]}
              />
              {formErrors && formErrors[`rangeSleepRate`]  && <FormError>{formErrors[`rangeSleepRate`] }</FormError>}
            </FilterField>

            <FilterField>
              <Label>Data rozpoczęcia</Label>
              <Datepicker
                name="dateFrom"
                onChange={value => handleFormChange('dateFrom', value)}
                value={formData.dateFrom}
              />
            </FilterField>
          </MainFieldsWrapper>
          <MainFieldsWrapper>
            <AgreementField>
              <Label>Umowa</Label>
              <Select
                  isError={formErrors && formErrors[`rangeContractorAgreement`]}
                  name="rangeContractorAgreement"
                  options={agreementOptions}
                  asPortal
                  value={formData.rangeContractorAgreement}
                  onChange={option => handleItemsSelectChange('rangeContractorAgreement', option)}
              />
            </AgreementField>
          </MainFieldsWrapper>
          <MainFieldsWrapper>
            <AgreementField>
              <Label>Link do umowy</Label>
                <AgreementLink
                    target="_blank"
                    href={formData.rangeContractorAgreementUrl}
                >
                  {formData.rangeContractorAgreementUrl}
                </AgreementLink>
            </AgreementField>
          </MainFieldsWrapper>
          {formData.carriersRatesRanges.length > 0 && (
            <>
              <RateRow showOnDesktop>
                <RateHeader small>Wartość od (km)</RateHeader>
                <RateHeader small>Wartość do (km)</RateHeader>
                <RateHeader small>Stawka</RateHeader>
                <RateHeader>Typ rozliczenia</RateHeader>
                <Placeholder />
              </RateRow>
            </>
          )}
          {formData.carriersRatesRanges.map((rate, i) => {
            return (
              <MainRow key={i}>
                <HeadersRow showOnMobile>
                  <RateHeader small>Wartość od (km)</RateHeader>
                  <RateHeader small>Wartość do (km)</RateHeader>
                  <RateHeader small>Stawka (praca)</RateHeader>
                  <RateHeader small>Stawka (postój)</RateHeader>
                  <RateHeader>Typ rozliczenia</RateHeader>
                  <Placeholder />
                </HeadersRow>
                <RateRow>
                  <RateElem small>
                    <Input
                      type='number'
                      min={1}
                      placeholder={'Wartość w km od'}
                      onChange={e => handleRatesChange(i, 'rangeFrom', Number(e.target.value))}
                      value={rate.rangeFrom}
                      isError={formErrors && formErrors[`carriersRatesRanges[${i}].rangeFrom`]}
                    />
                    {formErrors && formErrors[`carriersRatesRanges[${i}].rangeFrom`] && <FormError>{formErrors[`carriersRatesRanges[${i}].rangeFrom`] }</FormError>}
                  </RateElem>
                  <RateElem small>
                    <Input
                      type='number'
                      min={1}
                      placeholder={'Wartość w km do'}
                      onChange={e => handleRatesChange(i, 'rangeTo', Number(e.target.value))}
                      value={rate.rangeTo}
                      isError={formErrors && formErrors[`carriersRatesRanges[${i}].rangeTo`]}
                    />
                    {formErrors && formErrors[`carriersRatesRanges[${i}].rangeTo`]  && <FormError>{formErrors[`carriersRatesRanges[${i}].rangeTo`] }</FormError>}
                  </RateElem>
                  <RateElem small>
                    <Input
                      type='number'
                      min={1}
                      placeholder={'Stawka'}
                      onChange={e => handleRatesChange(i, 'rangeRate', Number(e.target.value))}
                      value={rate.rangeRate}
                      isError={formErrors && formErrors[`carriersRatesRanges[${i}].rangeRate`]}
                    />
                    {formErrors && formErrors[`carriersRatesRanges[${i}].rangeRate`]  && <FormError>{formErrors[`carriersRatesRanges[${i}].rangeRate`] }</FormError>}
                  </RateElem>
                  <RateElem>
                    <Select
                      isError={formErrors && formErrors[`carriersRatesRanges[${i}].rangeUnit`]}
                      name="rangeUnit"
                      options={rateTypes}
                      asPortal
                      value={setSelectOption(rateTypes, rate.rangeUnit)}
                      onChange={option => handleRatesChange(i, 'rangeUnit', option.value)}
                    />
                    {formErrors && formErrors[`carriersRatesRanges[${i}].rangeUnit`]  && <FormError>{formErrors[`carriersRatesRanges[${i}].rangeUnit`] }</FormError>}
                  </RateElem>
                  <RemoveButton onClick={() => removeElem(i)}>
                    <VscTrash size={20} />
                    <RemoveButtonText>Usuń przedział</RemoveButtonText>
                  </RemoveButton>
                </RateRow>
              </MainRow>
            );
          })}
        </RatesContent>
        <NewRateButtonWrapper>
          <Button
            leftIcon={<GoPlus size={16}/>}
            zoom
            onClick={() => addRange()}
            secondary
          >
            Nowy przedział
          </Button>
          <FlexWrapper>
            <Button
              secondary
              leftIcon={<BsCheck2 size={18}/>}
              zoom
              onClick={() => handleSubmit(true)}
            >
              {newRatesGroup ? 'Utwórz dla wszystkich' : 'Zapisz dla wszystkich'}
            </Button>
            <Button
              leftIcon={<BsCheck2 size={18}/>}
              zoom
              onClick={() => handleSubmit()}
            >
              {newRatesGroup ? 'Utwórz cennik' : 'Zapisz cennik'}
            </Button>
          </FlexWrapper>
        </NewRateButtonWrapper>
      </OuterRatesContent>
    </CarrierRatesRow>
  );
}

export default RatesForm;
