import React, { useEffect, useContext, useState } from 'react';
import { Subtitle } from 'components/__styled/Subtitle';
import Header from 'components/__common/Header';
import TaskSummary from '../TaskSummary';
import Button from 'components/__common/Button';
import CreateScheduleSummaryTable from 'components/Tasks/CreateScheduleSummaryTable';
import Fade from 'components/Fade';
import { BsArrowLeftShort, BsCheck2 } from 'react-icons/bs';
import { CurrentTaskName } from '../TaskForm/styles';
import { FormButtonsWrapper } from 'components/__styled/Layout';
import { ContentWrapper } from './styles';
import { TableBox } from 'components/__styled/Table';
import history from 'config/history';
import TasksService from 'services/TasksService';
import TaskContext from 'context/TaskContext';
import PageLoader from 'components/PageLoader';

const FourthFormStep = ({ saveFinalStep, formSubmitting, task }) => {
  const { setCurrentTask } = useContext(TaskContext);
  const [loading, setLoading] = useState(false);
  const tableHeaders = [
    { name: 'carNumber', label: 'Auto'},
    { name: 'timeOfLoading', label: 'Podstawienie na WMB'},
    { name: 'timeOfBuilding', label: 'Start wbudowania'},
    { name: 'timeOfReturn', label: 'Powrót na WMB'},
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getTask(task.id), []);

  const getTask = async id => {
    try {
      setLoading(true);
      const res = await TasksService.getTask(id);
      setCurrentTask(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading
        ? <PageLoader />
        : (
          <Fade>
            <Header size={23}>
              Wyliczenia
              {task && <CurrentTaskName>{task.name}</CurrentTaskName>}
            </Header>
            <Subtitle>Wyliczone wartości dla podanego projektu na podstawie podanych danych</Subtitle>
            <ContentWrapper>
              <TaskSummary task={task} />
                {task && task._embedded && (
                  <TableBox>
                    <CreateScheduleSummaryTable
                      headers={tableHeaders}
                      rows={task && task._embedded && task._embedded.courses ? task._embedded.courses : []}
                    />
                  </TableBox>
                )}
            </ContentWrapper>
            <FormButtonsWrapper>
              <Button onClick={() => history.push('/panel/tasks')} leftIcon={<BsArrowLeftShort />} secondary>Wróć do listy</Button>
              {task && task.status === 'TIMES' && (
                <Button
                  zoom
                  onClick={() => saveFinalStep(task.id)}
                  rightIcon={<BsCheck2 size={18} />}
                  isLoading={formSubmitting}
                >
                  Zatwierdź i wyślij do wytwórni
                </Button>
              )}
            </FormButtonsWrapper>
          </Fade>
        )
      }
    </>
  )
};

export default FourthFormStep;

