import styled, { keyframes } from 'styled-components';
import { COLORS } from 'config/theme';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  width: ${({ size }) => size ? `${size}px` : '20px'};
  height: ${({ size }) => size ? `${size}px` : '20px'};
  border-radius: 50%;
  border: ${({ width }) => width ? `${width}px solid transparent` : '2px solid transparent'};
  border-top: ${({ width, color }) => width ? `${width}px solid ${color ? color : COLORS.dark}` : `2px solid ${color ? color : COLORS.dark}`};
  border-bottom: ${({ width, color }) => width ? `${width}px solid ${color ? color : COLORS.dark}` : `2px solid ${color ? color : COLORS.dark}`};
  animation: ${rotate} 1s linear infinite;
`;
