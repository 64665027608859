import React from 'react';
import { SearchInput, SearchWrapper, SearchButton, ClearButton } from './styles';
import { AiOutlineSearch } from 'react-icons/ai';
import { VscClose } from 'react-icons/vsc';

const SearchBar = ({ placeholder, value, onChange, onSubmit }) => {

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <SearchWrapper onSubmit={handleSubmit}>
      <SearchInput placeholder={placeholder} onChange={e => onChange(e.target.value)} value={value} />
      <SearchButton type="submit">
        <AiOutlineSearch size={22}/>
      </SearchButton>
      {value && value.length && (
        <ClearButton type="button" onClick={() => {
          onChange('');
          onSubmit('');
        }}>
          <VscClose size={22}/>
        </ClearButton>
      )}
    </SearchWrapper>
  );
};

export default SearchBar;
