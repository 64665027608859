import React, { useEffect, useState } from 'react';
import ScheduleStatusModalContext from 'context/ScheduleStatusModalContext';
import { AnimatePresence} from 'framer-motion';
import {
  ModalButtonsWrapper,
  ModalContent,
  ModalDescription,
  ModalWrapper,
  ModalHeader,
  CurrentStatus,
  CurrentStatusItem,
  CurrentStatusName,
  CurrentStatusWrapper,
  AssignedCarriers,
  AssignedCarrier,
  AssignedCarriersLabel,
  AssignedCarrierItem,
  CloseModalButton,
} from './styles';
import { StatusDot } from 'components/__styled/StatusDot';
import Button from 'components/__common/Button';
import DetailedStatuses from 'components/Tasks/DetailedStatuses';
import { createPortal } from 'react-dom';
import ScheduleStatusesLegend from 'components/Tasks/ScheduleStatusesLegend';
import { Subtitle } from 'components/__styled/Subtitle';
import { statusesLegend, detailedScheduleStatuses } from 'config/statuses';
import TasksService from 'services/TasksService';
import { Spinner } from 'components/__styled/Spinner';
import ReactTooltip from 'react-tooltip';
import { IoCloseOutline } from 'react-icons/io5';

const ScheduleStatusModal = () => {
  const { closeStatusesModal, modalVisible, scheduleStatuses, currentSchedule } = React.useContext(ScheduleStatusModalContext);
  const [currentStatuses, setCurrentStatuses] = useState([]);
  const [currentStatusesKeys, setCurrentStatusesKeys] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [carriersLoading, setCarriersLoading] = useState(false);

  useEffect(() => {
    if (!modalVisible) {
      setCurrentStatuses([]);
      setCurrentStatusesKeys([]);
      setCarriers([]);
    }
  }, [modalVisible]);

  const getAssignedCarriers = async () => {
    try {
      setCarriersLoading(true);
      const res = await TasksService.getCarriers(currentSchedule, { assigned: true });
      setCarriersLoading(false);
      setCarriers(res.data._embedded ? res.data._embedded.item : []);
    } catch (err) {
      setCarriersLoading(false);
    }
  };

  useEffect(() => {
    if (scheduleStatuses) {
      if (scheduleStatuses[0].stage === 'NONE') {
        setCurrentStatuses([scheduleStatuses[0]]);
      } else {
        const currStatuses = scheduleStatuses.filter(status => status.stage !== 'NONE');
        setCurrentStatuses(currStatuses);
      }
    }
  }, [scheduleStatuses]);

  useEffect(() => {
    const keys = currentStatuses ? currentStatuses.map(el => el.levelName) : [];
    setCurrentStatusesKeys(keys);

    if (keys.includes('CARRIER')) {
      getAssignedCarriers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatuses]);

  const component = (
    <AnimatePresence exitBeforeEnter>
      {modalVisible && (
        <ModalWrapper
          key={'confirm-modal'}
          data-testid={'confirm-modal'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent>
            <CloseModalButton onClick={() => closeStatusesModal()}>
              <IoCloseOutline size={20}/>
            </CloseModalButton>
            <ModalHeader>Status harmonogramu</ModalHeader>
            <Subtitle>Schemat przebiegu ustalania statusów dla harmonogramu</Subtitle>
            <ModalDescription>
              <DetailedStatuses statuses={scheduleStatuses} />
              <CurrentStatusWrapper>
                <CurrentStatus>Aktualny status:</CurrentStatus>
                {currentStatuses.length > 0 && currentStatuses.map((status, i) => {
                  return (
                    <CurrentStatusItem key={i}>
                      <StatusDot color={statusesLegend[status.levelName].statuses[status.stage].color}/>
                      <CurrentStatusName>{statusesLegend[status.levelName].statuses[status.stage].name}</CurrentStatusName>
                    </CurrentStatusItem>
                  );
                })}
              </CurrentStatusWrapper>
              {currentStatusesKeys && currentStatusesKeys.includes('CARRIER') && (
                <AssignedCarriers>
                  <AssignedCarriersLabel>Przewoźnicy:</AssignedCarriersLabel>
                  {carriersLoading
                    ? <Spinner />
                    : (
                      <>
                        <ReactTooltip className="tooltip" effect="solid" />
                        {carriers.map((carrier, i) => {
                          const stat = detailedScheduleStatuses[carrier.status];
                          return (
                            <AssignedCarrierItem key={i}>
                              <StatusDot data-tip={stat.name} color={stat.color}/>
                              <AssignedCarrier>{carrier.name}</AssignedCarrier>
                            </AssignedCarrierItem>
                          )
                        })}
                      </>
                    )
                  }
                </AssignedCarriers>
              )}
              <ScheduleStatusesLegend />
            </ModalDescription>
            <ModalButtonsWrapper>
              <Button secondary onClick={closeStatusesModal}>Zamknij</Button>
            </ModalButtonsWrapper>
          </ModalContent>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );

  return createPortal(component, document.body);
};

export default ScheduleStatusModal;
